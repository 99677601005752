import { StarsIcon } from 'components/common/Icon';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { FormationsGhostButton } from 'components/common/Buttons';
import { Box } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { useCurrentUserRole } from 'hooks/api';

export interface AutoCompleteOptions {
  label: string;
  id: number;
}

const SuccessCenterButton = styled(FormationsGhostButton)(({ theme }) => ({
  '&:hover': {
    '.starIcon': {
      color: theme.palette.blue.hover,
      backgroundColor: '#FFFFFF',
    },
  },
}));

const BorderedStarIcon = styled(StarsIcon)(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.palette.gray[100],
  borderRadius: '50%',
}));

export const HelpCenterMenu = () => {
  const { t } = useTranslation();
  const { isAdmin } = useCurrentUserRole();
  const openHelpCenter = () => {
    const knowledgeBaseLink =
      'https://learn.formationscorp.com/using-formations-platform';
    const newWindow = window.open(knowledgeBaseLink, '_blank');
    if (newWindow) newWindow.opener = null;
  };
  if (!isAdmin) {
    return null;
  }
  return (
    <Box>
      <Tooltip title={t('topNavBar.helpCenter.tooltipTitle')}>
        <SuccessCenterButton rounded onClick={openHelpCenter}>
          <BorderedStarIcon className="starIcon" data-testid="menu-main-icon" />
        </SuccessCenterButton>
      </Tooltip>
    </Box>
  );
};
