import { Components } from '@mui/material';
import { MAIN_DARK_COLOR } from 'theme/constant';

export const tooltip: Components = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: MAIN_DARK_COLOR,
        padding: "16px",
        fontFamily: 'Spartan, sans-serif',
        fontSize: '12px',
        lineHeight: '20px',
        fontWeight: 500,
      },
      arrow: {
        color: MAIN_DARK_COLOR
      }
    },
  },
};
