import React from 'react';
import Avatar from '@mui/material/Avatar';
import { ArrowForwardIcon } from 'components/common/Icon';
import { useLogout } from 'hooks';
import { Routes } from 'fnRoutes';
import {
  useCurrentAccount,
  useCurrentUser,
  useCurrentUserRole,
  useNavigate,
} from 'hooks/api';
import { useTranslation } from 'react-i18next';
import { NavMenuItem } from './NavMenuItem';

export const ProfileMenu = () => {
  const { t } = useTranslation();
  const { logout } = useLogout();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { currentAccount } = useCurrentAccount();
  const { isAdmin } = useCurrentUserRole();

  const customerStatus = currentAccount?.status?.label ?? '';

  const getMenuIconText = () => {
    let text = '';
    if (currentUser?.name?.first) {
      text += currentUser?.name?.first.charAt(0).toUpperCase();
    }
    if (currentUser?.name?.last) {
      text += currentUser?.name?.last.charAt(0).toUpperCase();
    }
    return text;
  };

  const goToPersonalProfile = () => {
    navigate(Routes.MY_ACCOUNT);
  };

  const goToBusinessProfile = () => {
    navigate(Routes.MY_COMPANY);
  };

  const goToAdminGustoSettings = () => {
    navigate(Routes.GUSTO);
  };

  const menuList = [
    {
      name: t('topNavBar.profile.personalProfile'),
      clickHandler: goToPersonalProfile,
      disabledForStatus: ['NEW'],
    },
    {
      name: t('topNavBar.profile.businessProfile'),
      clickHandler: goToBusinessProfile,
      hiddenForStatus: ['NEW'],
    },
  ];
  const adminMenuList = [
    {
      name: t('topNavBar.profile.gustoSetting'),
      clickHandler: goToAdminGustoSettings,
      disabledForStatus: [],
    },
  ];

  const action = {
    name: t('actions.logout'),
    icon: ArrowForwardIcon,
    clickHandler: logout,
  };

  return (
    <NavMenuItem
      tooltipTitle={t('topNavBar.profile.tooltipTitle')}
      menuList={!isAdmin ? menuList : adminMenuList}
      mainIcon={Avatar as React.FC}
      mainIconText={getMenuIconText()}
      menuName={t('topNavBar.profile.name')}
      action={action}
      customerStatus={customerStatus}
      account={currentAccount}
    />
  );
};
