import { useMutation, useQuery } from 'react-query';
import {
  DropboxSignTemplates,
  HelloSignSignedDocument,
  getSignedDocument,
  saveHelloSignDocument,
} from 'services/dropboxSign';

export const useSignedDocument = ({
  accountId,
  template,
}: {
  accountId?: string;
  template: DropboxSignTemplates;
}) => {
  const { data: signedDocuments, ...rest } = useQuery<
    HelloSignSignedDocument[]
  >(
    ['dropboxSignedDocuments', accountId, template],
    () => getSignedDocument(template, accountId),
    {
      enabled: !!accountId,
    },
  );
  return {
    signedDocuments: signedDocuments || [],
    ...rest,
  };
};

export const useSaveHelloSignDocument = (queryProps?: any) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    (params: { accountId: string; signatureRequestId: string }) =>
      saveHelloSignDocument(params.accountId, params.signatureRequestId),
    queryProps,
  );
  return {
    saveHelloSignDocument: mutate,
    saveHelloSignDocumentAsync: mutateAsync,
    ...rest,
  };
};
