import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { ArrowBackIosNewIcon } from 'components/common/Icon';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { queryClient } from 'states/reactQueryClient';
import { AuthService } from 'services';
import {
  FormationsGhostButton,
  FormationsPrimaryButton,
} from 'components/common/Buttons';
import {
  useDocument,
  useDownloadDocument,
  useUpdateDocument,
} from 'hooks/api/useDocuments';
import { VisibilityPopup } from 'components/documentsV1/VisibilityPopup';
import { Loading } from 'components/common';
import { Routes } from 'fnRoutes';
import {
  castFDToFormationsPreviewDocument,
  downloadFileBlob,
} from 'helpers/documents';
import { AxiosError } from 'axios';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useSpoof } from 'hooks/api';
import { increaseCustomerReadCount } from 'services/document';
import { readCountShouldIncrease } from './helpers'
import { FilePreviewComponent } from './FilePreviewComponent';
import { IFormationsPreviewDocument } from './FilePreviewComponent/FilePreviewComponent';
import { PreviewHeader, PreviewHeaderAction } from './PreviewActionComponent';

interface IFilePreviewPageProps {}

const PreviewContainer = styled(Grid)(({ theme }) => ({
  borderTopWidth: 1,
  borderTopColor: theme.palette.grey[500],
  borderTopStyle: 'solid',
  padding: theme.spacing(2),
}));

interface IFileAccessStatus {
  noAccessPermission: boolean;
  errorMessage: string;
}

const initFileAccessStatus = {
  noAccessPermission: false,
  errorMessage: '',
};

export const FilePreviewPage: React.FC<IFilePreviewPageProps> = () => {
  const navigate = useNavigate();
  const isAdmin = AuthService.isAdmin();
  const { isAdminSpoof } = useSpoof(); 
  const [query] = useQueryParams({
    fileId: withDefault(StringParam, ''),
    accountId: withDefault(StringParam, ''),
  });
  const { accountId, fileId } = query;
  const [loading, setLoading] = useState<boolean>(false);
  const [accessFileStatus, setAccessFileStatus] =
    useState<IFileAccessStatus>(initFileAccessStatus);
  const { noAccessPermission, errorMessage } = accessFileStatus;

  const onPermissionDenyError = (e: unknown) => {
    let newErrorMessage = '';
    switch ((e as AxiosError)?.response?.status) {
      case 403:
        newErrorMessage = 'You do not have permission to access this file.';
        break;
      case 404:
        newErrorMessage = 'The file you are trying to access has been deleted.';
        break;
      case 401:
        // 401 will trigger redirect to login, hence no error message needed
        break;
      default:
        newErrorMessage =
          'An error has occurred loading the document, please check your internet connection and try again.';
        break;
    }

    setAccessFileStatus({
      noAccessPermission: true,
      errorMessage: newErrorMessage,
    });
  };

  const { document: file, isLoading: isDocumentFetching } = useDocument(
    fileId,
    {
      onSuccess: () => {
        setAccessFileStatus(initFileAccessStatus);
      },
      onError: onPermissionDenyError,
    },
  );

  const deeplinkUrl = useMemo(
    () =>
      `${window.location.origin}${Routes.DOCUMENT_PREVIEW}?accountId=${accountId}&fileId=${file?.id}`,
    [file, accountId],
  );

  const { blob: downloadDocumentBlob, isLoading: isDocumentDownloading } =
    useDownloadDocument(fileId || '', {
      onSuccess: () => {
        setAccessFileStatus(initFileAccessStatus);
      },
      onError: onPermissionDenyError,
    });

  const [isVisibilityModalOpen, setIsVisibilityModalOpen] =
    useState<boolean>(false);
  const [currentViewingFile, setCurrentViewingFile] =
    useState<IFormationsPreviewDocument>();

  useEffect(() => {
    if (file) {
      setCurrentViewingFile(castFDToFormationsPreviewDocument(file));
    }
  }, [file]);

  useEffect(() => {
    if (readCountShouldIncrease(currentViewingFile, isAdmin, isAdminSpoof, accountId)) {
      increaseCustomerReadCount(currentViewingFile!.id);
    }
  }, [currentViewingFile]);

  const onClosePreview = useCallback(() => {
    if (isAdmin) {
      navigate(`${Routes.ADMIN_HOME}/${accountId}`);
    } else {
      navigate(Routes.DOCUMENTS);
    }
  }, [accountId, navigate, isAdmin]);

  useEffect(() => {
    if (!fileId || !accountId) {
      // Incorrect URL, redirect back
      onClosePreview();
    }
  }, [fileId, accountId, onClosePreview]);

  const disableVisibleEdit = useMemo(() => {
    if (
      currentViewingFile?.isVisibilityEditable ||
      currentViewingFile?.role === 'Admin'
    ) {
      return false;
    }
    return true;
  }, [currentViewingFile]);

  const refreshGrid = () => {
    queryClient.invalidateQueries(['documents', fileId]);
  };

  const { mutateAsync: updateDocument, isLoading: isDocumentUpdating } =
    useUpdateDocument({
      onSuccess: () => {
        refreshGrid();
      },
    });

  const isLoading = useMemo(
    () =>
      loading ||
      isDocumentFetching ||
      isDocumentUpdating ||
      isDocumentDownloading,
    [isDocumentFetching, isDocumentUpdating, loading, isDocumentDownloading],
  );
  const handleEditVisibility = async () => {
    setIsVisibilityModalOpen(false);
    await updateDocument({
      id: currentViewingFile?.id || '',
      form: {
        visibleToCustomer: !currentViewingFile?.visibleToCustomer,
      },
    });
  };

  const handleDocumentDownload = async () => {
    if (downloadDocumentBlob) {
      setLoading(true);
      downloadFileBlob(downloadDocumentBlob, currentViewingFile?.title || '');
      setLoading(false);
    }
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <FormationsGhostButton
              rounded
              aria-label="close"
              onClick={onClosePreview}
              sx={{
                mr: 2,
              }}
            >
              <ArrowBackIosNewIcon />
            </FormationsGhostButton>
            <PreviewHeader
              document={currentViewingFile}
              title={currentViewingFile?.title || ''}
              disableEditVisibility={disableVisibleEdit}
              visibleToCustomer={currentViewingFile?.visibleToCustomer || false}
              onToggleVisibility={() => setIsVisibilityModalOpen(true)}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {!noAccessPermission && (
              <PreviewHeaderAction
                isLoading={isLoading}
                showCopyUrlButtons={isAdmin}
                deeplinkUrl={deeplinkUrl}
                handleDocumentDownload={handleDocumentDownload}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <PreviewContainer item container alignItems="center" xs={12}>
        {currentViewingFile && (
          <FilePreviewComponent
            accountId={accountId}
            enableAction
            file={currentViewingFile}
            files={[]}
            actionCallBack={onClosePreview}
          />
        )}
        {noAccessPermission && (
          <Grid container alignItems="center" flexDirection="column">
            <Typography variant="h6" sx={{ mb: 4 }}>
              {errorMessage}
            </Typography>
            {!isAdmin && (
              <FormationsPrimaryButton
                size="large"
                onClick={onClosePreview}
                disabled={isLoading}
                data-testid="save-btn"
              >
                Back to Document Hub
              </FormationsPrimaryButton>
            )}
          </Grid>
        )}
      </PreviewContainer>
      {isLoading && <Loading />}
      <VisibilityPopup
        open={isVisibilityModalOpen}
        status={!currentViewingFile?.visibleToCustomer}
        onSave={handleEditVisibility}
        onClose={() => setIsVisibilityModalOpen(false)}
      />
    </Grid>
  );
};
