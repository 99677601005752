import { get, isObject, isString } from 'lodash';
import { AxiosError } from 'axios';

const DEFAULT_MSG = 'An error has occurred, please try again.';
const extractMessage = (resp: unknown, defaultMsg: string = DEFAULT_MSG) => {
  if (isString(resp)) {
    return resp
  }
  if (isObject(resp) && get(resp, 'message') != null) {
    return get(resp, 'message', defaultMsg)!;
  }
  return defaultMsg;
};

export const getErrorMessage = (
  error: unknown,
  defaultMsg: string = DEFAULT_MSG,
): string => {
  if ((error as AxiosError)?.isAxiosError === true) {
    return extractMessage(get(error, 'response.data', defaultMsg));
  }
  if (error instanceof Error) {
    return error.message;
  }
  if (isString(error)) {
    return error;
  }
  return defaultMsg;
};
