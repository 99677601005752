import { api } from './axios';
import { toFormData } from './documentTypes';

export type AutoMileageExpense = {
  year: number;
  month: number;
  expense: number;
};

export const insertNewMileage = async (miles: AutoMileageExpense[]) => {
  const { data } = await api.put('auto-mileage-expense', miles);
  return data;
};

export type CreateExpensesFile = {
  file: any;
  accountId: string;
};

export const createAutoMileageExpensesViaFile = async (
  form: CreateExpensesFile,
) => {
  const { data } = await api.post<FormData, { data: CreateExpensesFile }>(
    'auto-mileage-expense/upload-file',
    toFormData(form),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 120000,
    },
  );
  return data;
};
