import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import { 
  ArrowBackIosNewIcon, 
  CloseIcon 
} from 'components/common/Icon';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import {
  FormationsPrimaryButton,
  FormationsGhostButton,
} from 'components/common/Buttons';
import {
  Grid,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from '@mui/material';

const BootstrapDialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2, 2, 2, 3),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2, 3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 4, 0),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(0),
  },
}))(MuiDialogActions);

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface Props {
  open: boolean;
  children?: ReactNode;
  heading?: string | ReactNode;
  headingRight?: ReactNode;
  okText?: string;
  cancelText?: string;
  onSave?: () => void;
  onClose?: () => void;
  excludeFooter?: boolean;
  maxWidth?: any;
  fullScreen?: boolean;
  fullWidth?: boolean;
  backButton?: boolean;
}

export const FormationsDialog: React.FC<Props> = ({
  open,
  children = null,
  heading = '',
  onSave,
  onClose,
  okText = 'Save',
  cancelText = 'Cancel',
  excludeFooter = false,
  maxWidth = false,
  fullScreen = false,
  fullWidth = false,
  backButton = false,
  headingRight = null,
}) => {
  const handleSave = () => {
    onSave?.();
  };

  const handleClose = () => {
    onClose?.();
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      open={open}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              {backButton && (
                <FormationsGhostButton
                  rounded
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                    mr: 2,
                  }}
                >
                  <ArrowBackIosNewIcon />
                </FormationsGhostButton>
              )}

              {typeof heading === 'string' ? (
                <Typography variant="h6">{heading}</Typography>
              ) : (
                heading
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              {headingRight}
              {!backButton && (
                <FormationsGhostButton
                  rounded
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                    ml: 2,
                  }}
                >
                  <CloseIcon />
                </FormationsGhostButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {!excludeFooter && (
        <DialogActions>
          <FormationsGhostButton size="large" onClick={handleClose}>
            {cancelText}
          </FormationsGhostButton>
          <FormationsPrimaryButton size="large" onClick={handleSave}>
            {okText}
          </FormationsPrimaryButton>
        </DialogActions>
      )}
    </BootstrapDialog>
  );
};
