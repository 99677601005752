import { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@mui/material';

interface BoxderedBoxProps extends PropsWithChildren<any> {
  containerProps?: BoxProps;
  innerProps?: BoxProps;
}
export const BorderedBox = ({
  children,
  containerProps = {},
  innerProps = {},
}: BoxderedBoxProps) => (
  <Box
    sx={{
      backgroundColor: 'others.backgroundLight',
      padding: 2,
      borderRadius: 2,
    }}
    {...containerProps}
  >
    <Box
      sx={{
        backgroundColor: 'white.main',
        padding: 2,
        borderRadius: 1,
        boxShadow: 1,
      }}
      {...innerProps}
    >
      {children}
    </Box>
  </Box>
);
