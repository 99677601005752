import { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export const MOBILE_BREAKPOINT = 1025; // 1025px is the breakpoint for mobile, which is the width of the larger Tablets
export const MOBILE_DIALOG_WIDTH = '100%';

export const useMediaBreakpoints = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between('sm', 'md'),
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(MOBILE_BREAKPOINT),
  );

  return {
    isDesktop,
    isTablet,
    isMobile,
  };
};
