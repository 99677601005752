import { TextField, InputAdornment, Theme } from '@mui/material';
import { 
  ClearIcon, 
  SearchIcon 
} from 'components/common/Icon';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  // we don't have design for this currently, so setting these properties
  searchDocument: {
    [theme.breakpoints.up('sm')]: {
      width: '25%',
    },
    paddingLeft: theme.spacing(2),
  },
}));

export interface IProps {
  handleSearchChange: (val: string) => void;
  searchText: string;
  sx?: object;
}

export const DocumentSearch = ({
  handleSearchChange,
  searchText,
  sx,
}: IProps) => {
  const classes = useStyles();
  return (
    <TextField
      className={classes.searchDocument}
      value={searchText}
      placeholder="Search"
      onChange={({ target: { value: v } }) => handleSearchChange(v)}
      autoComplete="off"
      sx={sx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: searchText && (
          <InputAdornment position="end">
            <ClearIcon
              style={{ cursor: 'pointer' }}
              onClick={() => handleSearchChange('')}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};
