import React, {
  useMemo,
  SetStateAction,
  Dispatch,
  CSSProperties,
  ReactElement,
  useState,
  useEffect,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Collapse,
  SvgIcon,
  Grid,
  Typography,
  Badge,
  Icon as EmptyIcon
} from '@mui/material';
import { 
  CustomIconComponent, 
  ExpandMoreIcon as ExpandMore,
  KeyboardArrowRightIcon,
} from 'components/common/Icon';
import { styled } from '@mui/material/styles';
import { useFlags } from 'hooks/useFeatureFlag';
import { useSidebarOpen } from 'components/common/sidebar/desktop/sidebarContext';
import { useCurrentAccount, useAccount, usePathWithSpoof } from 'hooks/api';
import { SidebarMenuID } from 'enums';
import { useCurrentUserFeatureList } from 'hooks/api/useFeatures';
import { calculateNotificationCount, checkFeaturePermission, filterVisibleActions } from '../helpers';
import { IAction, ISubAction } from '../constant';
import { isSamePathWithSpoof } from '../../../../helpers/routes';

export const PrimaryChipBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#FF4E4E',
    color: '#FFFFFF',
    top: '50%',
    right: '50%',
  },
  '& .MuiBadge-standard': {
    fontSize: '8px',
    height: '18px',
    width: '18px',
    borderRadius: '50%',
    minWidth: 0,
  },
}));

const SideBarListItem = styled(ListItemButton)(({ theme }) => ({
  height: '72px',
  padding: theme.spacing(3),
  '& .sideBarItemIcon': {
    color: '#696C80',
  },
  '& .MuiTypography-root': {
    color: '#696C80',
  },
  '& .sideBarItemSecondaryIcon': {
    color: '#696C80',
  },
  '&:hover': {
    backgroundColor: '#FFFFFF',
    '& .sideBarItemIcon': {
      color: theme.palette.gray[100],
    },
    '& .MuiTypography-root': {
      color: theme.palette.gray[100],
    },
    '& .sideBarItemSecondaryIcon': {
      color: theme.palette.gray[100],
    },
  },
  '&:focus': {
    backgroundColor: theme.palette.gray[20],
    '& .sideBarItemIcon': {
      color: theme.palette.gray[100],
    },
    '& .MuiTypography-root': {
      color: theme.palette.gray[100],
    },
    '& .sideBarItemSecondaryIcon': {
      color: theme.palette.gray[100],
    },
  },
  '&.Mui-disabled': {
    opacity: 0.5,
  },
  '&.Mui-selected': {
    backgroundColor: '#FFFFFF',
    '& .sideBarItemIcon': {
      color: '#696C80',
    },
    '& .MuiTypography-root': {
      color: '#696C80',
    },
    '& .sideBarItemSecondaryIcon': {
      color: '#696C80',
    },
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    '&.MuiListItemButton-root:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '8px',
      height: '56px',
      backgroundColor: theme.palette.blue.primary,
      borderTopRightRadius: theme.spacing(3),
      borderBottomRightRadius: theme.spacing(3),
      left: '0px',
    },
  },
}));

type Props = {
  Icon?: typeof SvgIcon | CustomIconComponent;
  style?: CSSProperties;
  sidebarOpen: boolean;
  text: string;
  secondaryIcon: ReactElement | null;
  notificationIcon: ReactElement | null;
  handleClick?: () => void;
};

const SideBarItemContent = ({
  Icon,
  style,
  sidebarOpen,
  text,
  secondaryIcon,
  notificationIcon,
  handleClick,
}: Props) => (
  <Grid container alignItems="center" flexWrap="nowrap" style={style} onClick={handleClick} >
    {Icon && (
      <Grid item sx={{ mr: 2 }} display="flex" alignItems="center">
        <ListItemIcon sx={{ minWidth: 0, opacity: 1 }}>
          <Icon
            className="sideBarItemIcon"
            sx={{ color: '#000000 !important' }}
            fontSize="medium"
          />
        </ListItemIcon>
      </Grid>
    )}
    <Grid item container wrap="nowrap">
      <Grid
        container
        item
        sx={{
          width: secondaryIcon || notificationIcon ? 'fit-content' : '100%',
        }}
      >
        <ListItemText
          sx={{
            opacity: sidebarOpen ? 1 : 0,
            whiteSpace: 'normal',
          }}
        >
          <Typography
            variant="body2S"
            sx={{
              color: text === 'Accounts' ? '#000000 !important' : 'inherit',
            }}
          >
            {text}
          </Typography>
        </ListItemText>
      </Grid>
      {notificationIcon && secondaryIcon && (
        <Grid
          item
          display="flex"
          alignItems="center"
          flexWrap="nowrap"
          sx={{ marginLeft: 'auto' }}
        >
          {notificationIcon}
        </Grid>
      )}
      <Grid
        item
        sx={{
          marginLeft: notificationIcon && secondaryIcon ? 0 : 'auto',
        }}
        display="flex"
        alignItems="center"
        flexWrap="nowrap"
      >
        {secondaryIcon ?? notificationIcon}
      </Grid>
    </Grid>
  </Grid>
);

type MenuItemProps = Omit<IAction, 'Icon' | 'subActions'> & {
  Icon?: typeof SvgIcon | CustomIconComponent;
  subActions?: ISubAction[];
  active?: boolean;
  style?: CSSProperties;
  showSubmenuId?: string;
  setShowSubmenuId?: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
  handleClose?: () => void;
};

export const AccountMenuItem = ({
  id,
  path,
  Icon,
  text,
  actionPermission,
  subActions = [],
  disabledForStatus = [],
  hiddenForStatus = [],
  disabled = false,
  handleClose,
  style,
}: MenuItemProps) => {
  const { pathname } = useLocation();
  const sidebarOpen = useSidebarOpen();
  const { currentAccount } = useCurrentAccount();
  const pathParts = window.location.pathname.split('/');
  const customerAccountId = pathParts[3];
  const { account: customerAccount } = useAccount(customerAccountId);
  const customerStatus = currentAccount?.status?.label ?? '';
  const [openMenuId, setOpenMenuId] = useState<string | null>();
  const open = useMemo(() => openMenuId === id, [openMenuId]);
  const flags = useFlags();
  const featureList = useCurrentUserFeatureList();

  const filteredSubActions = useMemo(
    () => filterVisibleActions(subActions || [], flags, featureList),
    [subActions],
  );

  // always show opened submenu if being redirected to submenu item or on intial click of a submenu item
  useEffect(() => {
    if (
      filteredSubActions
        .map((item) => item.path)
        .some((subpath) => subpath === pathname)
    ) {
      setOpenMenuId(id);
    }
  }, [pathname, filteredSubActions]);

  const permissionDisabled = !checkFeaturePermission(
    flags,
    featureList,
    actionPermission,
  );
  const hasSubActions =
    Array.isArray(filteredSubActions) && filteredSubActions.length > 0;
  // we use startWith to match path, this way any action perform under that particular menu should have the parent item highlighted
  // for example dashboard/settings-and-requests/request-change vs dashboard/settings-and-requests
  const isActive =
    path !== '' && path != null && isSamePathWithSpoof(path, pathname);
  const pathWithSpoof = usePathWithSpoof(path);
  let buttonProps: Partial<typeof ListItemButton> = {
    component: Link,
    to: pathWithSpoof,
  };

  const defaultPath = hasSubActions && filteredSubActions[0].path;
  let secondaryIcon = null;

  const notificationCount = useMemo(
    () => calculateNotificationCount(
      id,
      customerAccount,
      ), 
    [customerAccount, id]);

  const notificationIcon = useMemo(() => {
    if (notificationCount > 0) {
      return (
        <PrimaryChipBadge
          badgeContent={notificationCount === 1 ? ' ' : notificationCount}
          variant={notificationCount === 1 ? 'dot' : 'standard'}
        >
          <EmptyIcon />
        </PrimaryChipBadge>
      );
    }
    return null;
  }, [notificationCount]);

  if (hasSubActions) {
    buttonProps = {
      onClick: () => {
        if (!open) setOpenMenuId(id as SidebarMenuID);
        else setOpenMenuId(null);
      },
      component: !open ? Link : undefined,
      to: defaultPath,
    };
    secondaryIcon = open ? (
      <ExpandMore className="sideBarItemSecondaryIcon" />
    ) : (
      <KeyboardArrowRightIcon className="sideBarItemSecondaryIcon" />
    );
  }

  if (hiddenForStatus.includes(customerStatus)) {
    return null;
  }

  const handleSidebarItemClick = () => {
    if (handleClose && !hasSubActions) {
      handleClose();
    }
  }

  return (
    <>
      <SideBarListItem
        key={id}
        data-testid={`menu_item_${id}`}
        selected={isActive}
        {...buttonProps}
        disabled={
          disabled ||
          permissionDisabled ||
          disabledForStatus.includes(customerStatus)
        }
      >
        <SideBarItemContent
          Icon={Icon}
          style={style}
          sidebarOpen={sidebarOpen}
          text={text}
          secondaryIcon={secondaryIcon}
          notificationIcon={notificationIcon}
          handleClick={handleSidebarItemClick}
        />
      </SideBarListItem>
      {hasSubActions && (
        <Collapse in={open} timeout="auto">
          <List sx={{ p: 0 }}>
            {filteredSubActions.map((item) => (
              <AccountMenuItem
                key={item.id}
                {...item}
                style={{
                  paddingLeft: '20px',
                }}
                handleClose={handleClose}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};
