import { ITodoItem, ITodoItemType, ITodoItemStatus } from './types';

export const filterTodoByFeatureFlag = (
  todos: ITodoItem[],
  filterType: ITodoItemType,
  isFeatureAllowed: boolean,
): ITodoItem[] => {
  if (isFeatureAllowed) {
    return todos;
  }
  return isFeatureAllowed
    ? todos
    : todos.filter((todo) => todo.type !== filterType);
};

export const filterTodoByStatus = (
  todos: ITodoItem[],
  status: ITodoItemStatus,
) => todos.filter((todo) => todo.status === status);
