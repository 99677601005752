import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  Stepper,
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { ExpandMoreIcon } from 'components/common/Icon';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useCurrentUser } from 'hooks/api';
import { styled } from '@mui/material/styles';
import {
  IAccount,
  ProgressTrackerGroupRequest,
  ProgressTrackerGroups,
  ProgressTrackerStages,
  ProgressTrackerStatus,
} from 'services/account';
import { useTranslation } from 'react-i18next';
import {
  useCurrentAccount,
  useUpdateAccountProgress,
} from 'hooks/api/useAccounts';
import { queryClient } from 'states/reactQueryClient';
import { useCurrentCompany } from 'hooks/api/useCompanies';
import { Company } from 'services/companies';
import { FormationsStep } from 'components/FormationsStep';
import { BankSelection } from './BankSelection';
import { DocumentSigning } from './DocumentSigning';
import { initialState, reducer } from './state';
import { StageSkeleton } from '../StageSkeleton';
import { AccordionCTA } from '../AccordionCTA';
import { getCompletedCount, getFirstNoCompleted } from '../util';

const StepContentWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    '& > div': {
      maxWidth: '546px',
    },
  },
}));

interface TParams {
  company: Company;
  account: IAccount;
  isDesktop: boolean;
  expanded: boolean | string;
  handleExpand: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  disabled: boolean;
}

const BankSetupLayout = ({ company, account: accData, isDesktop, expanded, handleExpand, disabled }: TParams) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();

  const [state, dispatch] = useReducer(reducer, initialState());

  const completed = useMemo(() => getCompletedCount(state), [state])
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const firstNotCompleted = getFirstNoCompleted(state)
    if (firstNotCompleted !== -1) {
      setActiveStep(firstNotCompleted);
    }
  }, [state]);

  useEffect(() => {
    if (accData?.rightSignatureURL) {
      dispatch({
        type: 'updateRightSignature',
        value: accData,
      });
    }
    if (accData?.progress.length > 0) {
      dispatch({
        type: ProgressTrackerStages.BankSelection,
        value: accData,
      });
      dispatch({
        type: ProgressTrackerStages.DocumentSigning,
        value: accData,
      });
    }
  }, [accData]);

  const { mutateAsync: updateAccountProgress } = useUpdateAccountProgress(
    accData?.id,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['progressTracker']);
        queryClient.invalidateQueries(['getCompany', company?.id]);
      },
    },
  );

  const handleComplete = async (
    status: ProgressTrackerStatus,
    eventData: Object,
  ) => {
    const requestProps: ProgressTrackerGroupRequest = {
      stage: ProgressTrackerStages.BankSelection,
      group: ProgressTrackerGroups.FormCompletion,
      status,
    };

    await updateAccountProgress({
      progress: [requestProps],
      eventData: {
        stage: ProgressTrackerStages.BankSelection,
        stageStatus: status,
        entityType: (company?.entityType ?? company?.legacyEntityType) ?? '',
        ...eventData,
      },
    });
  };

  const steps = Object.keys(state.steps);
  const stageCompleted = useMemo(() => completed === steps.length, [completed, steps])

  return (
    <Accordion
      expanded={expanded === 'bank-setup'}
      onChange={handleExpand('bank-setup')}
      disabled={disabled}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="bank-setup-content"
        id="bank-setup-header"
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h6B">Bank Setup</Typography>
            <Typography variant="subtitle1">
              {t('progressTracker.bankSetup.subTitle')}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('progressTracker.bankSetup.processingTime')}
            </Typography>
            <AccordionCTA
              expanded={expanded}
              handleExpand={handleExpand}
              completed={completed}
              totalStep={steps.length}
              testId="start-bank-setup"
              accordionId="bank-setup"
            />
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
            {!stageCompleted && !expanded && (
              <Typography variant="h8B">
                {completed}/{steps.length}
              </Typography>
            )}
            {stageCompleted && !expanded && (
              <Typography variant="h8B" sx={{ display: 'flex' }}>
                <CheckCircle color="secondary" />
                &nbsp; Complete
              </Typography>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={{ md: 2 }}>
          <Grid item xs={12} md={5}>
            <Stepper
              activeStep={activeStep}
              nonLinear
              orientation={isDesktop ? 'vertical' : 'horizontal'}
              alternativeLabel={!isDesktop}
              data-testid="bank-setup-stages"
            >
              {steps.map((key, index) => (
                <FormationsStep
                  key={key}
                  index={index}
                  setActiveStep={setActiveStep}
                  status={state.steps[key]?.status}
                  label={state.steps[key]?.label}
                  isTrainer={false}
                />
              ))}
            </Stepper>
          </Grid>
          <StepContentWrapper item xs={12} md={7}>
            {activeStep === 0 && currentUser && (
              <BankSelection
                handleComplete={handleComplete}
                company={company}
                user={currentUser}
              />
            )}
            {activeStep === 1 && (
              <DocumentSigning
                accountId={accData?.id}
                accountEntity={
                  (company?.entityType ?? company?.legacyEntityType) ?? ''
                }
                completedSteps={completed}
                hasBankAccount={!!company?.bankName}
                rightSignatureURL={state.rightSignatureURL}
              />
            )}
          </StepContentWrapper>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export const BankSetup = (props:
  { 
    isDesktop: boolean
    expanded: boolean | string;
    handleExpand: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
    disabled: boolean;
  }) => {
  const {
    currentCompany: companyData,
    isLoading: companyDataLoading,
    status: companyDataStatus,
  } = useCurrentCompany();
  const {
    currentAccount: accountData,
    isLoading: accountDataLoading,
    status: accountDataStatus,
  } = useCurrentAccount();

  return !companyDataLoading && companyDataStatus === 'success' && companyData &&
         !accountDataLoading && accountDataStatus === 'success' && accountData
  ? (
    <BankSetupLayout {...props} company={companyData} account={accountData} />
  ) : (
    <StageSkeleton />
  );
};
