import { useQuery } from 'react-query';
import create from 'zustand';
import { getHurdlrAccessToken } from 'services/bankAccounts';
import { useEffect, useState } from 'react';

interface HurdlrAccessToken {
  accessToken: undefined | string;
  setAccessToken: (accessToken: string) => void;
}

export const useHurdlrAccessToken = create<HurdlrAccessToken>((set) => ({
  accessToken: undefined,
  setAccessToken: (accessToken: string) => set({ accessToken }),
}));

export const useHurdlrAccount = (accountId: string) => {
  const [ready, setReady] = useState(false);
  const setAccessToken = useHurdlrAccessToken((state) => state.setAccessToken);
  const { data } = useQuery(['hurdlr-access-token', accountId], async () =>
    getHurdlrAccessToken(accountId),
  );
  useEffect(() => {
    if (data != null) {
      setAccessToken(data);
      setReady(true);
    }
  }, [setAccessToken, data]);

  return {
    ready,
  };
};
