import { ITodoItem } from 'views/dashboard/insight/todos/types';
import { getTodos } from 'services/todos';
import { useQuery } from 'react-query';

export const useTodos = (accountID: string | undefined) => {
  const { data: todos, ...rest } = useQuery<ITodoItem[], unknown>(
    [
      'todos',
      {
        accountID,
      },
    ],
    () => getTodos(accountID!),
  );

  return {
    todos,
    ...rest,
  };
};

export const useLeftNavNotifications = (accountID: string | undefined) => {
  const { data: todos, ...rest } = useQuery<ITodoItem[], unknown>(
    [
      'leftNavNotification',
      {
        accountID,
      },
    ],
    () => getTodos(accountID!),
    {
      enabled: accountID !== undefined,
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    },
  );

  return {
    todos,
    ...rest,
  };
};
