import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { CardContent, Grid, Typography, Button } from '@mui/material';
import { downloadDocument, getDocumentById } from 'services/document';
import { downloadFileBlob } from 'helpers/documents';
import { sendChurnZeroEvent } from 'helpers/churnZero';
import { CHURN_ZERO_EVENT_NAMES } from 'constants/common';
import { showErrorToast } from 'components/toast/showToast';
import moment from 'moment';
import { MOMENT_DATE_FORMAT } from 'helpers/dateTimeFormat';
import { numberFormat } from 'helpers/currencyFormat';
import { capitalizeFirstLetter } from 'helpers/text-transformer';
import { queryClient } from 'states/reactQueryClient';
import { textMappings } from './TaxesTableCells';
import { DirectPayment } from './types';
import { DeletePopover } from './DeletePopover';

const LinkButton = styled(Button)({
  color: 'inherit',
  textTransform: 'none',
  textDecoration: 'underline',
  fontWeight: 'normal',
  padding: 0,
  margin: 0,
  minWidth: 0,
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: 'transparent',
  },
});

export const TaxesCardCell = (props: {
  card: DirectPayment
  onCardClick: (card: DirectPayment) => void
  onViewClick: (card: DirectPayment) => void
}) => {
  const { card, onCardClick, onViewClick } = props

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [deletePopoverOpen, setDeletePopoverOpen] = useState(false);

  const handleOnCardClick = () => {
    onCardClick(card);
  }

  const handleOnDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setDeletePopoverOpen(true);
    setAnchorElement(event.currentTarget);
  }
  
  const handleDeletePopoverClose = () => {
    setDeletePopoverOpen(false);
    const { accountId } = card;
    queryClient.invalidateQueries(['documents', 'accountId', accountId]);
    queryClient.invalidateQueries(['direct-payments']);
  }


  const handleOnViewClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onViewClick(card);
  }

  const onDownload = async (event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      const document = await getDocumentById(card.documentId);
      const blob = await downloadDocument(card.documentId);
      downloadFileBlob(blob, document.title);
      sendChurnZeroEvent(CHURN_ZERO_EVENT_NAMES.DOCUMENT_DOWNLOAD);
    } catch (error) {
      showErrorToast('Error when downloading file');
    }
  };

  return (
    <CardContent onClick={handleOnCardClick} data-testid='card-cell'>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant='body3B' data-testid='year-text'>{card.year}</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body3S'>{moment(card.paymentDate).utc().format(MOMENT_DATE_FORMAT)}</Typography>
        </Grid>
        <Grid container item direction='row' justifyContent="space-between" sx={{ pb: 0.5 }}>
          <Grid item>
            <Typography variant='body3S'>
              {`${capitalizeFirstLetter(card.source)} - ${textMappings[card.type as keyof typeof textMappings] ?? capitalizeFirstLetter(card.type)}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1L'>{numberFormat(card.amount, 2)}</Typography>
          </Grid>
        </Grid>
        <Grid container item direction='row' justifyContent='flex-end' gap={2} data-testid='button-container'>
          <LinkButton onClick={handleOnViewClick}>
            <Typography variant='body3S'>View</Typography>
          </LinkButton>
          <LinkButton onClick={onDownload}>
            <Typography variant='body3S'>Download</Typography>
          </LinkButton>
          <LinkButton onClick={handleOnDeleteClick}>
            <Typography variant='body3S'>Delete</Typography>
          </LinkButton>
        </Grid>
      </Grid>
      {deletePopoverOpen && (
        <DeletePopover
          open={deletePopoverOpen}
          onClose={handleDeletePopoverClose}
          row={card}
          accountId={card.accountId}
          anchorEl={anchorElement}
        />
      )}
    </CardContent>
  );
}