import { Box, Grid } from '@mui/material';
import { Incorporation1120sOnly } from 'components/ProgressTracker/Incorporation/Incorporate1120sOnly';
import { AccountVerificationComponent } from 'components/ProgressTracker/AccountVerification/AccountVerificationComponent';
import { FormationsPrimaryButton } from 'components/common/Buttons';
import {
  IAccount,
  ProgressTrackerStages,
  ProgressTrackerStatus,
} from 'models/account';
import { useMediaBreakpoints } from 'hooks';
import { useActivateAccount, useNavigate } from 'hooks/api';
import { showErrorToast, showToastOnError } from 'components/toast/showToast';
import { Routes } from 'fnRoutes';
import { queryClient } from 'states/reactQueryClient';
import React, { useMemo, useState } from 'react';
import {
  getProgressTrackerStageStatus,
  isProgressTrackerStageCompleted,
} from 'components/ProgressTracker/Incorporation/state';

interface Props {
  account: IAccount;
}

export const ProgressTracker1120sOnly = ({ account }: Props) => {
  const { isMobile, isDesktop } = useMediaBreakpoints();
  const { activateAccountAsync: activate, isLoading } = useActivateAccount(
    account.id,
  );
  const navigate = useNavigate();

  const [isExpandedIncorporationSection, setIsExpandedIncorporationSection] = useState(false);
  const [expandedAccountVerificationSection, setExpandedAccountVerificationSection] = useState<string | false>(false);

  const handleExpandIncorporationSection = () => setIsExpandedIncorporationSection(!isExpandedIncorporationSection);
  const handleExpandAccountVerificationSection =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedAccountVerificationSection(isExpanded ? panel : false);
    };

  const onContinueToAccountVerification = () => {
    setIsExpandedIncorporationSection(false);
    setExpandedAccountVerificationSection('account-verification');
  }

  const onCollapseAllSections = () => {
    setIsExpandedIncorporationSection(false);
    setExpandedAccountVerificationSection(false);
  }

  const finishAllSteps = useMemo(() => {
    const statusUploadIdentifyProof = getProgressTrackerStageStatus(
      account,
      ProgressTrackerStages.UploadIdentityProof,
    );
    const statusUploadLastYearTaxStatement = getProgressTrackerStageStatus(
      account,
      ProgressTrackerStages.UploadLastYearTaxStatement,
    );
    const statusDocumentOfIncorporation = getProgressTrackerStageStatus(
      account,
      ProgressTrackerStages.DocumentOfIncorporation,
    )
    return (
      isProgressTrackerStageCompleted(
        account,
        ProgressTrackerStages.PersonalDetails,
      ) &&
      isProgressTrackerStageCompleted(
        account,
        ProgressTrackerStages.CompanyDetails,
      ) &&
      isProgressTrackerStageCompleted(
        account,
        ProgressTrackerStages.AddressDetails,
      ) &&
      (statusUploadIdentifyProof === ProgressTrackerStatus.InProgress ||
        statusUploadIdentifyProof === ProgressTrackerStatus.Completed) &&
      (statusUploadLastYearTaxStatement === ProgressTrackerStatus.InProgress ||
        statusUploadLastYearTaxStatement === ProgressTrackerStatus.Completed) &&
      (statusDocumentOfIncorporation === ProgressTrackerStatus.InProgress ||
        statusDocumentOfIncorporation === ProgressTrackerStatus.Completed)
    );
  }, [account]);

  const handleGetStarted = async () => {
    if (!finishAllSteps) {
      showErrorToast(
        'Please complete all the steps in the Create Your Account and Account Verification sections before you can go to your dashboard.',
      );
      return;
    }
    try {
      await activate();
      await queryClient.invalidateQueries('currentAccount');
      await queryClient.invalidateQueries(['account', account.id]);
      navigate(Routes.HOME());
    } catch (e) {
      showToastOnError(e);
    }
  };

  return (
    <Box
      sx={{
        width: 'auto',
        px: isMobile ? 2 : 9,
        py: 2,
        mt: 10,
      }}
    >
      <Incorporation1120sOnly 
        isDesktop={isDesktop} 
        isExpanded={isExpandedIncorporationSection} 
        expandHandler={handleExpandIncorporationSection}
        onContinueToAccountVerification={onContinueToAccountVerification}
      />
      <AccountVerificationComponent 
        handleExpand={handleExpandAccountVerificationSection} 
        expanded={expandedAccountVerificationSection}
        onContinueToNextSection={onCollapseAllSections}
      />
      <Grid container justifyContent="center">
        <FormationsPrimaryButton
          size="large"
          onClick={handleGetStarted}
          isLoading={isLoading}
        >
          Go to My Dashboard
        </FormationsPrimaryButton>
      </Grid>
    </Box>
  );
};
