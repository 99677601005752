import { Divider, Grid } from '@mui/material';
import { FormationsTertiaryButton } from 'components/common/Buttons';
import { ArrowDownwardIcon, ArrowUpwardIcon } from 'components/common/Icon';
import { SortingDirection } from 'enums';
import { Heading } from './Heading';

interface SortProps {
  sortOrder?: SortingDirection | null;
  onSort: (order: SortingDirection | null) => void;
}
export const Sort = ({ sortOrder = null, onSort }: SortProps) => {
  const buttonStyle = { backgroundColor: '#004DE5', color: '#FFFFFF' };
  const onSortAsc = () => {
    onSort(sortOrder === SortingDirection.Asc ? null : SortingDirection.Asc);
  };
  const onSortDesc = () => {
    onSort(sortOrder === SortingDirection.Desc ? null : SortingDirection.Desc);
  };
  return (
    <>
      <Heading>Sort</Heading>
      <Grid container justifyContent="space-between">
        <Grid item>
          <FormationsTertiaryButton
            size="small"
            startIcon={<ArrowUpwardIcon size={20} />}
            onClick={onSortAsc}
            sx={sortOrder === SortingDirection.Asc ? buttonStyle : {}}
            data-testid="ascending-btn"
          >
            Ascending
          </FormationsTertiaryButton>
        </Grid>

        <Grid item>
          <FormationsTertiaryButton
            size="small"
            startIcon={<ArrowDownwardIcon size={20} />}
            onClick={onSortDesc}
            sx={sortOrder === SortingDirection.Desc ? buttonStyle : {}}
            data-testid="descending-btn"
          >
            Descending
          </FormationsTertiaryButton>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 1, mb: 2 }} />
    </>
  );
};
