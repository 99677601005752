import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Routes } from 'fnRoutes';
import { Loading } from 'components/common';
import { LoginPage } from 'views/LoginPage';

export const RedirectToHomeOrLoginPage = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { pathname, search } = useLocation();

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <LoginPage />;
  }

  if (pathname === '/' && search) {
    return <Navigate to={`${Routes.HOME()}${search}`} replace />;
  }
  return <Navigate to={`${Routes.HOME()}`} replace />;
};