import { useState, useEffect } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { 
  ClearIcon, 
  SearchIcon 
} from 'components/common/Icon';
import { debounce } from 'lodash';

// debounce function, for not re-fetch query while typing search
const debounceSearch = debounce<
  (onSearch: (value: string) => void, value: string) => void
>((onSearch, value) => {
  onSearch(value);
}, 500);

type Props = {
  query: string | null | undefined;
  onSearch: (value: string) => void;
  placeHolderText: string;
};

export const SearchBar = ({ query, onSearch, placeHolderText }: Props) => {
  const [queryText, setQueryText] = useState(query);

  // to make the search bar clear when quick filters are selected
  useEffect(() => {
    setQueryText(query ?? '');
  }, [query]);

  const onTextChange = (input: string) => {
    setQueryText(input);
    debounceSearch(onSearch, input);
  };

  return (
    <TextField
      value={queryText}
      placeholder={placeHolderText}
      onChange={({ target: { value: v } }) => onTextChange(v)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: queryText && (
          <InputAdornment position="end">
            <ClearIcon
              style={{ cursor: 'pointer' }}
              onClick={() => onTextChange('')}
            />
          </InputAdornment>
        ),
      }}
      margin="dense"
      sx={{ width: '100% !important', paddingLeft: '0 !important' }}
    />
  );
};
