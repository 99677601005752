import {
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormHelperText,
} from '@mui/material';

import { ALLOWED_BANKS_OPTIONS } from 'enums';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const HaveAccountWith = () => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Typography variant="h5B" component="h5">
        {t('progressTracker.bankSetup.whichBankDoYouHaveAccount')}
      </Typography>
      <Controller
        name="haveAccountWith"
        control={control}
        rules={{
          required: true,
        }}
        render={({ onChange, value }) => (
          <RadioGroup
            value={value || ''}
            onChange={onChange}
            sx={{ mt: 4, mb: 4 }}
          >
            {ALLOWED_BANKS_OPTIONS.map((option) => (
              <FormControlLabel
                value={option.value}
                control={<Radio checked={value === option.value} />}
                label={option.label}
                key={option.value}
              />
            ))}
            <FormControlLabel value="other" control={<Radio />} label="Other" />
            {errors?.haveAccountWith && (
              <FormHelperText error={errors?.haveAccountWith}>{t('progressTracker.error.selectAnOption')}</FormHelperText>
            )}
          </RadioGroup>
        )}
      />
    </>
  );
};
