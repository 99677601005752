import {
  Box, Container, Grid, Typography,
} from '@mui/material';
import { FormationsPrimaryButton } from 'components/common/Buttons';
import { useSpoof } from 'hooks/api';
import { Link } from 'components/Link';
import { Routes } from 'fnRoutes';

export const NotFoundPage = () => {
  const { isAdminSpoof } = useSpoof();
  const home = isAdminSpoof ? `${Routes.CUSTOMER_HOME}` : Routes.HOME();
  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid sx={{ maxWidth: 800 }}>
          <Box mt={8} sx={{ textAlign: 'center' }}>
            <Typography variant="h1" data-testid="title-not-found">Page not found</Typography>
            <Typography mt={4}>
              We are sorry, the page you requested could not be found. Please go back to homepage.
            </Typography>
            <Box mt={2}>
              <Link to={home}>
                <FormationsPrimaryButton data-testid="btn-homepage">Go to Homepage</FormationsPrimaryButton>
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
