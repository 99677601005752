import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  Stepper,
  Box,
} from '@mui/material';
import { ExpandMoreIcon } from 'components/common/Icon';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CheckCircle } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Company } from 'models/company';
import {
  IAccount,
  ProgressTrackerStages,
  ProgressTrackerStatus,
} from 'models/account';
import { useCurrentAccount, useCurrentCompany } from 'hooks/api';
import { isAccountBusinessInABox, getCompletedCount, getFirstNoCompleted } from 'components/ProgressTracker/util';
import { FormationsStep } from 'components/FormationsStep';
import { BUSINESS_OWNERSHIP } from 'enums';
import { Routes } from 'fnRoutes';
import { PersonalDetailsContainer as PersonalDetails } from './PersonalDetails';
import { CompanyDetailsContainerBusinessInABox as CompanyDetailsBusinessInABox } from './CompanyDetailsBusinessInABox';
import { OwnershipDetailsContainer as OwnershipDetails } from './OwnershipDetails'
import { AddressInformationContainer as AddressInformation } from './AddressInformation';
import { initialState, reducer } from './state';
import { StageSkeleton } from '../StageSkeleton';
import { AccordionCTA } from '../AccordionCTA';
import { FilingDocumentsBusinessInABox } from './FilingDocumentsBusinessInABox';

const StepContentWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    '& > div': {
      maxWidth: '546px',
    },
  },
}));

const StageStatusColumn = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'flex-start',
}));

interface TParams {
  company: Company;
  isDesktop: boolean;
  account: IAccount;
  expanded: boolean | string;
  handleExpand: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  onContinueToAccountVerification: () => void;
}

const IncorporationLayout = ({ company, isDesktop, account: accData, expanded, handleExpand, onContinueToAccountVerification }: TParams) => {
  const { t } = useTranslation();
  const isBIB = isAccountBusinessInABox(accData);
  const isMultiMemberOwnedBusiness = accData?.businessOwnerShip === BUSINESS_OWNERSHIP.PARTNERSHIP;
  // const addressState = company.contactDetails?.mailingAddress?.state
  // const taxFilingStatus = accData.generalTaxInfo?.taxFilingStatus
  // const isAdditionalOwnerNeeded = useMemo(() => needAdditionalOwner(addressState, taxFilingStatus), [addressState, taxFilingStatus]);

  const [state, dispatch] = useReducer(
    reducer,
    initialState((company?.entityType ?? company?.legacyEntityType) ?? '', isMultiMemberOwnedBusiness, isBIB),
  );

  const completed = useMemo(() => getCompletedCount(state, true), [state])
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const firstNotCompleted = getFirstNoCompleted(state, true)
    if (firstNotCompleted !== -1) {
      setActiveStep(firstNotCompleted);
    }
  }, [state]);

  useEffect(() => {
    if (accData?.progress.length > 0) {
      dispatch({
        type: ProgressTrackerStages.PersonalDetails,
        value: accData?.progress,
      });
      dispatch({
        type: ProgressTrackerStages.CompanyDetails,
        value: accData?.progress,
      });
      dispatch({
        type: ProgressTrackerStages.AddressDetails,
        value: accData?.progress,
      });
      // In future, if needed to implement the additional owner needed for community states, 
      // add the isAdditionalOwnerNeeded boolean here and wherever else you see the isMultiMemberOwnedBusiness boolean in this file
      // it should triggor this ownership details section to be added if the criteria is met once they fill out their business address information
      if (isMultiMemberOwnedBusiness) {
        dispatch({
          type: ProgressTrackerStages.OwnershipDetails,
          value: accData?.progress,
        });
      };
      dispatch({
        type: ProgressTrackerStages.FilingDocuments,
        value: accData?.progress,
      });
    }
  }, [accData, isMultiMemberOwnedBusiness]);

  const steps = Object.keys(state);
  const stageCompleted = useMemo(() => completed === steps.length, [completed, steps])

  return (
    <Accordion
      expanded={expanded === 'incorporation'}
      onChange={handleExpand('incorporation')}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="incorporation-content"
        id="incorporation-header"
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h6B">Incorporate Your Business</Typography>
            <Typography variant="subtitle1" color="primary">
              {!stageCompleted && (
                t('progressTracker.incorporation.businessInABoxSubtitle')
              )}
              {stageCompleted && (
                <Box>
                  {t(
                    'progressTracker.incorporation.incorporationCompletedInfo',
                  )}
                  &nbsp;
                  <Link to={Routes.MY_ACCOUNT}>
                    <Typography variant="body1B">
                      {t('progressTracker.incorporation.profilePageLink')}
                    </Typography>
                  </Link>
                  .
                </Box>
              )}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('progressTracker.incorporation.processingTime')}
            </Typography>
            <AccordionCTA
              expanded={expanded}
              handleExpand={handleExpand}
              completed={completed}
              totalStep={steps.length}
              testId="start-incorporation"
              accordionId="incorporation"
            />
          </Grid>

          <StageStatusColumn item xs={2}>
            {!stageCompleted && !expanded && (
              <Typography variant="h8B">
                {completed}/{steps.length}
              </Typography>
            )}
            {stageCompleted && !expanded && (
              <Typography variant="h8B" sx={{ display: 'flex' }}>
                <CheckCircle color="secondary" />
                &nbsp; {t('progressTracker.complete')}
              </Typography>
            )}
          </StageStatusColumn>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={{ md: 2 }}>
          <Grid item xs={12} md={(activeStep === 3 && accData?.id && (isMultiMemberOwnedBusiness)) ? 3 : 5}>
            <Stepper
              activeStep={activeStep}
              nonLinear
              orientation={isDesktop ? 'vertical' : 'horizontal'}
              alternativeLabel={!isDesktop}
              data-testid="incorporation-stages"
            >
              {steps.map((key, index) => (
                <FormationsStep
                  key={key}
                  index={index}
                  setActiveStep={setActiveStep}
                  status={state[key]?.status}
                  label={state[key]?.label}
                  isTrainer={false}
                />
              ))}
            </Stepper>
          </Grid>
          <StepContentWrapper item xs={12} md={(activeStep === 3 && accData?.id && (isMultiMemberOwnedBusiness)) ? 9 : 7}>
            {activeStep === 0 && <PersonalDetails company={company} />}
            {activeStep === 1 && <CompanyDetailsBusinessInABox company={company} />}
            {activeStep === 2 && accData != null && (
              <AddressInformation
                account={accData}
                company={company}
                hasOwnershipSection={isMultiMemberOwnedBusiness}
                onContinueToAccountVerification={onContinueToAccountVerification}
              />
            )}
            {activeStep === 3 && accData?.id && (isMultiMemberOwnedBusiness) && (
              <OwnershipDetails
                account={accData}
                company={company}
                onContinueToAccountVerification={onContinueToAccountVerification}
              />
            )}
            {activeStep === 3 && accData?.id && !isMultiMemberOwnedBusiness && (
              <FilingDocumentsBusinessInABox
                accountId={accData?.id}
                companyData={company}
                completedSteps={completed}
                stageCompleted={
                  state[ProgressTrackerStages.FilingDocuments]?.status ===
                  ProgressTrackerStatus.Completed
                }
                onContinueToAccountVerification={onContinueToAccountVerification}
              />
            )}
            {activeStep === 4 && accData?.id && (isMultiMemberOwnedBusiness) && (
              <FilingDocumentsBusinessInABox
                accountId={accData?.id}
                companyData={company}
                completedSteps={completed}
                stageCompleted={
                  state[ProgressTrackerStages.FilingDocuments]?.status ===
                  ProgressTrackerStatus.Completed
                }
                onContinueToAccountVerification={onContinueToAccountVerification}
              />
            )}
          </StepContentWrapper>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export const IncorporationBusinessInABox = (props:
  { 
    isDesktop: boolean,
    expanded: boolean | string;
    handleExpand: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
    onContinueToAccountVerification: () => void;  
  }) => {
  const {
    currentCompany: companyData,
    isLoading: companyDataLoading,
    status: companyDataStatus,
  } = useCurrentCompany();
  const {
    currentAccount: accountData,
    isLoading: accountDataLoading,
    status: accountDataStatus,
  } = useCurrentAccount();

  return !companyDataLoading && companyDataStatus === 'success' && companyData &&
         !accountDataLoading && accountDataStatus === 'success' && accountData
  ? (
    <IncorporationLayout {...props} company={companyData} account={accountData} />
  ) : (
    <StageSkeleton />
  );
};