import { Box, FormHelperText } from '@mui/material';
import { FormationsSecondaryButton } from 'components/common/Buttons';
import { useDropzone, DropzoneProps } from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import { SearchIcon } from 'components/common/Icon';
import { SECONDARY_COLOR } from 'theme/constant';

const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(4),
    border: `1px dashed ${SECONDARY_COLOR}`,
  },
  boxDisabled: {
    borderColor: theme.palette.primary.border,
  },
  secondaryText: {
    ...theme.typography.h9B,
    marginTop: theme.spacing(1),
    color: theme.palette.secondary.dark,
  },
  disabledText: {
    color: theme.palette.text.disabled,
  },
}));

interface UploadProps {
  uploadProps: DropzoneProps;
  supportedFileHelperText?: string;
  disabled?: boolean;
}

export const UploadFile = ({
  disabled = false,
  supportedFileHelperText,
  uploadProps,
}: UploadProps) => {
  const classes = useStyles();
  const { getRootProps, getInputProps, isDragReject, isDragActive, open } =
    useDropzone(uploadProps);

  return (
    <Box display="flex" flexDirection="column">
      <div {...getRootProps()}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className={`${classes.box} ${disabled && classes.boxDisabled}`}
        >
          <FormationsSecondaryButton
            startIcon={<SearchIcon sx={{ pb: 0.25 }}/>}
            size="large"
            onClick={open}
            disabled={disabled}
          >
            Browse Files
          </FormationsSecondaryButton>
          <label
            className={`${classes.secondaryText} ${
              disabled && classes.disabledText
            }`}
            htmlFor="file"
          >
            or Drop Them Here
            <input id="file" {...getInputProps()} disabled={disabled} />
          </label>
        </Box>
        {supportedFileHelperText && (
          <FormHelperText sx={{ textAlign: 'center', my: 2 }}>
            {supportedFileHelperText}
          </FormHelperText>
        )}
        {isDragReject && !isDragActive && 'File type not accepted, sorry!'}
      </div>
    </Box>
  );
};
