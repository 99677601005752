import { SortingDirection } from 'enums';
import { omitBy } from 'lodash';
import {
  AccountStatus,
  IAccount,
  ProgressTrackerGroup,
  ProgressTrackerGroupRequest,
  ProgressTrackerGroups,
  ProgressTrackerStages,
  ProgressTrackerStatus,
  Status,
  IAccountRequest,
  IAccountListItem,
  IAccountXeroInfo,
  IAccountXeroInfoResp,
  IAccountBase,
  XeroLastError,
  IAccountXeroConnectionHistory,
  IAccountXeroConnectionHistoryResp,
  FormationsPlan, AccountPreference,
} from 'models/account';
import { ApiListResp, PageInfo } from 'models/api';
import { defaultTablePageSize } from 'constants/common';
import { api } from './axios';

export interface GetAccountsProps {
  page?: number;
  size?: number;
  status?: AccountStatus;
  search?: string;
  from?: string;
  to?: string;
  sortingName: string;
  sortingDirection?: SortingDirection;
  taxLiabilityStatus?: string;
  taxLiabilityYear?: string;
}

export interface GetAccountsListProps {
  page?: number;
  size?: number;
  status?: AccountStatus;
  search?: string;
  from?: string;
  to?: string;
  sortingName: string;
  sortingDirection?: SortingDirection;
  taxLiabilityStatus: string;
  taxLiabilityYear: string;
  entityType: string;
  documentNotes: string;
  role?: string;
  csm: string;
  state: string;
  lastLoginDate: string;
  stateOfIncorporation: string;
  plan: string;
  xeroBroken?: boolean;
  oilPending?: boolean;
  bookkeeper?: string;
  formationsPlan?: string;
  dataSource?: string;
  bankConnectionStatus?: string;
}

export interface IMetadata {
  total: number;
  types: Array<{
    label: AccountStatus;
    count: number;
  }>;
}

export type ProgressTrackerGroupResp = Omit<ProgressTrackerGroup, 'status'> & {
  status: Status[];
};

type IAccountResp = Omit<IAccountBase, 'progress'> & {
  progress: ProgressTrackerGroupResp[];
  xeroTransactionsError?: XeroLastError[];
  oilUnreadCustomerNoteItemsCount?: number;
  oilPendingReviewItemsCount?: number;
  formationsPlan?: string;
};

const setDefaultValueForIncomeExpense = (account: IAccountResp) =>
  account?.incomeExpense || {
    income: 0,
    associatedWithBrokerage: false,
    categoryExpense: {},
  };

// set default value for some attributes in account
const transformAccountRespData = (account: IAccountResp): IAccount => ({
  ...account,
  entityType: account.entityType,
  status: account.status,
  incomeExpense: setDefaultValueForIncomeExpense(account),
  payrollEnabled: account?.payrollEnabled ?? false,
  payrollRunNumber: account?.payrollRunNumber ?? 0,
  companyName: account?.companyName ?? '',
  payroll: account?.payroll ?? {
    estimatedSalary: 0,
    ytdOfficerWages: 0,
    ytdNonOfficerWages: 0,
    lyOfficerWages: 0,
    lyNonOfficerWages: 0,
  },
  progress: account?.progress
    ? account.progress.map((prog: ProgressTrackerGroupResp) => ({
        ...prog,
        status: prog.status,
      }))
    : [], // progress will be null when progress tracker have no step
  taxes: {
    annualEstimated: account?.taxes?.annualEstimated || 0,
    ytdTotalPaid: account?.taxes?.ytdTotalPaid || 0,
    updatedAt: account?.taxes?.updatedAt,
  },
  xeroTransactionsError: account.xeroTransactionsError ?? [],
  xeroLastUpdatedAt: account?.xeroLastUpdatedAt,
  xeroLastUpdatedAtTransactions: account?.xeroLastUpdatedAtTransactions,
  createdAt: account?.createdAt,

  healthInsurance: 'todo', // to remove along with business health
  retirementPlan: 'todo', // to remove along with business health

  oilUnreadCustomerNoteItemsCount: account?.oilUnreadCustomerNoteItemsCount,
  oilPendingReviewItemsCount: account?.oilPendingReviewItemsCount,
  formationsPlan: account?.formationsPlan ?? FormationsPlan.FullSuite, // default to full suite,
  generalTaxInfo: account?.generalTaxInfo,
  homeAddress: account?.homeAddress,
});

const transformResponseKeysToCamelcase = (xeroInfo: IAccountXeroInfoResp) => ({
  clientId: xeroInfo.client_id,
  isConnected: xeroInfo.is_connected,
});

export const getAccount = (id: string): Promise<IAccount> =>
  api.get<{ data: IAccount }>(`accounts/${id}`)
    .then((resp) => resp.data.data as unknown as IAccountResp)
    .then((account: IAccountResp) => transformAccountRespData(account));

export const updateAccountById = (
  id: string,
  params: Partial<IAccountRequest>,
) => api.patch(`accounts/${id}`, params);

export const getAccountsMeta = async () =>
  api.get<{ data: IMetadata }>('/accounts/meta').then((resp) => resp.data.data);

export const updateStatus = async (id: string, label: AccountStatus) => {
  const { data } = await api.patch<{ label: AccountStatus }>(
    `/accounts/${id}`,
    {
      label,
    },
  );
  return data;
};

/** Get account service */
const getSortingName = (tableHeaderName: string) => {
  let name = '';

  switch (tableHeaderName) {
    case 'dayInStatus':
      name = 'dayInStatus';
      break;
    case 'registeredDate':
      name = 'created_at';
      break;
    case 'companyName':
      name = 'company_name';
      break;
    case 'ownerName':
      name = 'owner_name';
      break;
    default:
      break;
  }

  return name;
};

export type IAccountListItemResp = Omit<IAccountListItem, 'progress'> & {
  progress: ProgressTrackerGroupResp[];
};
export type ApiListAccountResp = ApiListResp<IAccountListItemResp[]>;

export const defaultPageInfo: PageInfo = {
  currentPage: 1,
  nextPage: null,
  pageCount: 1,
  pageSize: defaultTablePageSize,
  prevPage: null,
  totalCount: 0,
};
export const getAccounts = ({
  page = 1,
  size = 20,
  status,
  search,
  from,
  to,
  sortingName,
  sortingDirection = SortingDirection.Asc,
  taxLiabilityStatus,
  taxLiabilityYear,
}: GetAccountsProps) => {
  const params = omitBy(
    {
      status,
      keyword: search,
      from,
      to,
      taxLiabilityStatus,
      taxLiabilityYear,
      sort: getSortingName(sortingName),
      order: sortingDirection,
      page,
      size,
    },
    (value) => value == null || value === '',
  );

  return api.get<ApiListAccountResp>(`/accounts/search`, { params }).then(
    (resp) => resp.data,
  );
};

const transformAccountList = (
  account: IAccountListItemResp,
): IAccountListItem => ({
  ...account,
  createdAt: account.createdAt,
  progress: account.progress
    ? account.progress.map((prog: ProgressTrackerGroupResp) => ({
        ...prog,
        status: prog.status,
      }))
    : [],
});

export const getAccountsList = async ({
  page = 1,
  size = 20,
  status,
  search,
  from,
  to,
  sortingName,
  sortingDirection = SortingDirection.Asc,
  taxLiabilityStatus,
  taxLiabilityYear,
  entityType,
  documentNotes,
  role,
  csm,
  state,
  lastLoginDate,
  stateOfIncorporation,
  plan,
  xeroBroken,
  oilPending,
  bookkeeper,
  formationsPlan,
  dataSource,
  bankConnectionStatus,
}: GetAccountsListProps) => {
  const params = omitBy(
    {
      status,
      keyword: search,
      from,
      to,
      taxLiabilityStatus,
      taxLiabilityYear,
      sort: getSortingName(sortingName),
      order: sortingDirection,
      page,
      size,
      entityType,
      documentNotes,
      role,
      csm,
      state,
      lastLoginDate,
      stateOfIncorporation,
      plan,
      xeroBroken,
      oilPending,
      bookkeeper,
      formationsPlan,
      dataSource,
      bankConnectionStatus,
    },
    (value) => value == null || value === '',
  );

  const resp = await api.get<ApiListAccountResp>(`/accounts`, { params });
  return {
    data: resp.data.data?.map(transformAccountList),
    pageInfo: resp.data.pageInfo,
  } as ApiListResp<IAccountListItem[]>;
};

export const getAccountsXero = async (id: string): Promise<IAccountXeroInfo> =>
  api.get<IAccountXeroInfoResp>(`accounts/${id}/xero`).then((resp) =>
    transformResponseKeysToCamelcase(resp.data),
  );

const transformConnectionResponse = (
  data: IAccountXeroConnectionHistoryResp[],
) =>
  data.map((item) => ({
    id: item.id,
    xeroAccountId: item.xero_account_id,
    operation: item.operation,
    createdAt: item.created_at,
    createdBy: item.created_by,
    xeroErrors: item.xero_errors ?? null,
    xeroCompanyName: item.xero_company_name,
  }));

export const getXeroConnectionHistory = async (
  accountId: string,
): Promise<IAccountXeroConnectionHistory[]> =>
  api
    .get<IAccountXeroConnectionHistoryResp[]>(
      `accounts/${accountId}/xeroConnectionHistory`,
    )
    .then((resp) => transformConnectionResponse(resp.data));

export const AccountService = {
  getAccount,
  updateAccountById,
  getAccountsXero,
};

// we are only returning email for now, in the future we might need to return more info

export const getCSMList = async (): Promise<string[]> => {
  const { data } = await api.get<string[]>('accounts/csms');
  return data;
};

export type {
  AccountStatus,
  IAccount,
  ProgressTrackerGroup,
  ProgressTrackerGroupRequest,
  Status,
};

export type BookkeeperOption = {
  id: string;
  name: string;
};
export const getBookkeepersList = async (): Promise<BookkeeperOption[]> => {
  const { data } = await api.get<BookkeeperOption[]>('accounts/bookkeepers');
  return data;
};

export { ProgressTrackerGroups, ProgressTrackerStages, ProgressTrackerStatus };

export const activateAccount = async (accountId: string) => {
  const { data } = await api.post<{ data: IAccount }>(`accounts/${accountId}/activate`);
  return data;
}

export const getAccountPreference = async (id: string) => {
  const { data } = await api.get<AccountPreference>(`accounts/${id}/preferences`);
  return data;
}

export interface UpdateAccountPreferencePayload {
  textNotify: boolean;
  phoneNumber: string;
  emailNotification?: {
    productCommunication?: boolean;
    incorporationUpdate?: boolean;
    bankingUpdate?: boolean;
  }
}

export const updateAccountPreference = async (
  id: string,
  payload: UpdateAccountPreferencePayload,
) => {
  const { data } = await api.post(
    `accounts/${id}/preferences`,
    payload,
  );
  return data;
};