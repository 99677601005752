import {
  DirectPaymentCreateMutateVariables,
  DirectPaymentUpdateMutateVariables,
  DirectPaymentDeleteMutateVariables,
  DirectPaymentGetResult,
} from 'components/taxes/types';
import { TaxesFilters } from 'hooks/api/useTaxes';
import queryString from 'query-string';
import { isNil, omitBy } from 'lodash';
import { PayrollTax, PayrollTaxResp } from 'models/payroll';
import { api, payrollService } from './axios';

const emptyPageInfo = {
  currentPage: 0,
  nextPage: null,
  pageCount: 0,
  pageSize: 0,
  prevPage: null,
  totalCount: 0,
};

export const getDirectPayments = async (
  accountId: string | undefined,
  params: TaxesFilters,
): Promise<DirectPaymentGetResult> => {
  if (!accountId) {
    return { data: [], pageInfo: emptyPageInfo };
  }
  const nonNullParams = omitBy(params, isNil);
  const { data } = await api.get<DirectPaymentGetResult>(
    `${accountId}/tax-payments?${queryString.stringify(nonNullParams)}`,
  );
  return data;
};

export const deleteDirectPayment = async (
  mutateVariables: DirectPaymentDeleteMutateVariables,
): Promise<void> => {
  const { accountId, paymentId } = mutateVariables;
  await api.delete(`${accountId}/tax-payments/${paymentId}`);
};

export const updateDirectPayment = async (
  mutateVariables: DirectPaymentUpdateMutateVariables,
): Promise<void> => {
  const { accountId, data, paymentId } = mutateVariables;
  await api.patch(`${accountId}/tax-payments/${paymentId}`, data);
};

export const createDirectPayment = async (
  mutateVariables: DirectPaymentCreateMutateVariables,
): Promise<void> => {
  const { accountId, data } = mutateVariables;
  await api.post(`${accountId}/tax-payments`, data);
};

export const getPayrollTaxes = async (): Promise<PayrollTax[]> =>
  payrollService.get<PayrollTaxResp[]>(`state-taxes`).then(
    (data) =>
      data.data
        .map((item) => ({
          isStateTax: item.is_state_tax,
          name: item.name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)), // the data backend return is not in consistent order
  );

export const setPayrollStateTaxes = async (names: string[]) =>
  payrollService.post(`state-taxes`, { names });
