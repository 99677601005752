import React from 'react';
import { FormationsIcon, FormationsIconProps } from './FormationsIcon';

type CustomIconProps = Omit<FormationsIconProps, 'name'>;
export type CustomIconComponent = React.FC<CustomIconProps>;

export const AddIcon: CustomIconComponent = (props) => <FormationsIcon name='add' {...props} />;
export const CheckIcon: CustomIconComponent = (props) => <FormationsIcon name='check' {...props} />;
export const MoreVertIcon: CustomIconComponent = (props) => <FormationsIcon name='more_vert' {...props} />;
export const AccessTimeIcon: CustomIconComponent = (props) => <FormationsIcon name='schedule' {...props} />;
export const AccountBalanceIcon: CustomIconComponent = (props) => <FormationsIcon name='account_balance' {...props} />;
export const AccountBalanceWalletIcon: CustomIconComponent = (props) => <FormationsIcon name='account_balance_wallet' {...props} />;
export const AccountCircleIcon: CustomIconComponent = (props) => <FormationsIcon name='account_circle' {...props} />;
export const AddCircleOutlineIcon: CustomIconComponent = (props) => <FormationsIcon name='add_circle' {...props} />;
export const AddCommentIcon: CustomIconComponent = (props) => <FormationsIcon name='add_comment' {...props} />;
export const ArchiveIcon: CustomIconComponent = (props) => <FormationsIcon name='inventory_2' {...props} />;
export const DoneAllIcon: CustomIconComponent = (props) => <FormationsIcon name='done_all' {...props} />;
export const PublishIcon: CustomIconComponent = (props) => <FormationsIcon name='publish' {...props} />;
export const ArrowBackIcon : CustomIconComponent = (props) => <FormationsIcon name='arrow_back' {...props} />;
export const ArrowBackIosNewIcon: CustomIconComponent = (props) => <FormationsIcon name='arrow_back_ios_new' {...props} />;
export const ArrowDownwardIcon: CustomIconComponent = (props) => <FormationsIcon name='arrow_downward' {...props} />;
export const ArrowDropDownIcon: CustomIconComponent = (props) => <FormationsIcon name='arrow_drop_down' {...props} />;
export const ArrowForwardIcon: CustomIconComponent = (props) => <FormationsIcon name='arrow_forward' {...props} />;
export const ArrowUpwardIcon: CustomIconComponent = (props) => <FormationsIcon name='arrow_upward' {...props} />;
export const AssessmentIcon: CustomIconComponent = (props) => <FormationsIcon name='analytics' {...props} />;
export const AssignmentIcon: CustomIconComponent = (props) => <FormationsIcon name='assignment' {...props} />;
export const AttachFileIcon: CustomIconComponent = (props) => <FormationsIcon name='attach_file' {...props} />;
export const AttachMoneyIcon: CustomIconComponent = (props) => <FormationsIcon name='attach_money' {...props} />;
export const BarChartOutlinedIcon: CustomIconComponent = (props) => <FormationsIcon name='bar_chart_4_bars' {...props} />;
export const BusinessIcon: CustomIconComponent = (props) => <FormationsIcon name='domain' {...props} />;
export const CancelIcon: CustomIconComponent = (props) => <FormationsIcon name='cancel' {...props} />;
export const ChatIcon: CustomIconComponent = (props) => <FormationsIcon name='chat' {...props} />;
export const CheckCircleIcon: CustomIconComponent = (props) => <FormationsIcon name='check_circle' {...props} />;
export const ChevronRightIcon: CustomIconComponent = (props) => <FormationsIcon name='chevron_right' {...props} />;
export const CircleIcon: CustomIconComponent = (props) => <FormationsIcon name='circle' {...props} />;
export const ClearIcon: CustomIconComponent = (props) => <FormationsIcon name='close' {...props} />;
export const CloseIcon: CustomIconComponent = (props) => <FormationsIcon name='close' {...props} />;
export const CloudDownloadIcon: CustomIconComponent = (props) => <FormationsIcon name='cloud_download' {...props} />;
export const ContactSupportIcon: CustomIconComponent = (props) => <FormationsIcon name='contacts_product' {...props} />;
export const ContentCopyIcon: CustomIconComponent = (props) => <FormationsIcon name='content_copy' {...props} />;
export const CreateIcon: CustomIconComponent = (props) => <FormationsIcon name='edit' {...props} />;
export const DangerousIcon: CustomIconComponent = (props) => <FormationsIcon name='dangerous' {...props} />;
export const DateRangeIcon: CustomIconComponent = (props) => <FormationsIcon name='date_range' {...props} />;
export const DeleteIcon: CustomIconComponent = (props) => <FormationsIcon name='delete' {...props} />;
export const DescriptionIcon: CustomIconComponent = (props) => <FormationsIcon name='description' {...props} />;
export const DoneIcon: CustomIconComponent = (props) => <FormationsIcon name='done' {...props} />;
export const DownloadIcon: CustomIconComponent = (props) => <FormationsIcon name='download' {...props} />;
export const EditIcon: CustomIconComponent = (props) => <FormationsIcon name='edit' {...props} />;
export const EmailIcon: CustomIconComponent = (props) => <FormationsIcon name='mail' {...props} />;
export const ErrorOutlinedIcon: CustomIconComponent = (props) => <FormationsIcon name='error' {...props} />;
export const EventIcon: CustomIconComponent = (props) => <FormationsIcon name='event' {...props} />;
export const ExitToAppIcon: CustomIconComponent = (props) => <FormationsIcon name='exit_to_app' {...props} />;
export const ExpandMoreIcon: CustomIconComponent = (props) => <FormationsIcon name='expand_more' {...props} />;
export const ExpandLessIcon: CustomIconComponent = (props) => <FormationsIcon name='expand_less' {...props} />;
export const FavoriteIcon: CustomIconComponent = (props) => <FormationsIcon name='favorite' {...props} />;
export const FileCopyIcon: CustomIconComponent = (props) => <FormationsIcon name='file_copy' {...props} />;
export const FileOpenIcon: CustomIconComponent = (props) => <FormationsIcon name='file_open' {...props} />;
export const FileUploadIcon: CustomIconComponent = (props) => <FormationsIcon name='upload' {...props} />;
export const FilterListIcon: CustomIconComponent = (props) => <FormationsIcon name='filter_list' {...props} />;
export const FlagOutlinedIcon: CustomIconComponent = (props) => <FormationsIcon name='flag_circle' {...props} />;
export const FolderOpenIcon: CustomIconComponent = (props) => <FormationsIcon name='folder_open' {...props} />;
export const FormatListBulletedIcon: CustomIconComponent = (props) => <FormationsIcon name='format_list_bulleted' {...props} />;
export const GamesIcon: CustomIconComponent = (props) => <FormationsIcon name='auto_stories' {...props} />;
export const GroupIcon: CustomIconComponent = (props) => <FormationsIcon name='group' {...props} />;
export const HelpIcon: CustomIconComponent = (props) => <FormationsIcon name='help' {...props} />;
export const HistoryIcon: CustomIconComponent = (props) => <FormationsIcon name='history' {...props} />;
export const InfoIcon: CustomIconComponent = (props) => <FormationsIcon name='info' {...props} />;
export const InsertDriveFileIcon: CustomIconComponent = (props) => <FormationsIcon name='draft' {...props} />;
export const InsertLinkIcon: CustomIconComponent = (props) => <FormationsIcon name='link' {...props} />;
export const KeyboardArrowRightIcon: CustomIconComponent = (props) => <FormationsIcon name='keyboard_arrow_right' {...props} />;
export const ListAltIcon: CustomIconComponent = (props) => <FormationsIcon name='list_alt' {...props} />;
export const MenuIcon: CustomIconComponent = (props) => <FormationsIcon name='menu' {...props} />;
export const MoreHorizIcon: CustomIconComponent = (props) => <FormationsIcon name='more_horiz' {...props} />;
export const NoteAddIcon: CustomIconComponent = (props) => <FormationsIcon name='note_add' {...props} />;
export const NotesIcon: CustomIconComponent = (props) => <FormationsIcon name='notes' {...props} />;
export const NotificationsActiveIcon: CustomIconComponent = (props) => <FormationsIcon name='notifications_active' {...props} />;
export const OutlinedFlagIcon: CustomIconComponent = (props) => <FormationsIcon name='flag' {...props} />;
export const PermPhoneMsgIcon: CustomIconComponent = (props) => <FormationsIcon name='perm_phone_msg' {...props} />;
export const PinDropIcon: CustomIconComponent = (props) => <FormationsIcon name='pin_drop' {...props} />;
export const PlayCircleIcon: CustomIconComponent = (props) => <FormationsIcon name='play_circle' {...props} />;
export const PlaylistAddCheckIcon: CustomIconComponent = (props) => <FormationsIcon name='playlist_add_check' {...props} />;
export const PreviewIcon: CustomIconComponent = (props) => <FormationsIcon name='preview' {...props} />;
export const QuizIcon: CustomIconComponent = (props) => <FormationsIcon name='quiz' {...props} />;
export const RateReviewIcon: CustomIconComponent = (props) => <FormationsIcon name='rate_review' {...props} />;
export const RefreshIcon: CustomIconComponent = (props) => <FormationsIcon name='refresh' {...props} />;
export const SaveIcon: CustomIconComponent = (props) => <FormationsIcon name='save' {...props} />;
export const SearchIcon: CustomIconComponent = (props) => <FormationsIcon name='search' {...props} />;
export const SendIcon: CustomIconComponent = (props) => <FormationsIcon name='send' {...props} />;
export const SettingsIcon: CustomIconComponent = (props) => <FormationsIcon name='settings' {...props} />;
export const StarsIcon: CustomIconComponent = (props) => <FormationsIcon name='stars' {...props} />;
export const SyncIcon: CustomIconComponent = (props) => <FormationsIcon name='sync' {...props} />;
export const TableChartIcon: CustomIconComponent = (props) => <FormationsIcon name='table_chart' {...props} />;
export const TimelapseIcon: CustomIconComponent = (props) => <FormationsIcon name='timelapse' {...props} />;
export const TrendingDownIcon: CustomIconComponent = (props) => <FormationsIcon name='trending_down' {...props} />;
export const TrendingUpIcon: CustomIconComponent = (props) => <FormationsIcon name='trending_up' {...props} />;
export const UpdateIcon: CustomIconComponent = (props) => <FormationsIcon name='update' {...props} />;
export const ViewListIcon: CustomIconComponent = (props) => <FormationsIcon name='view_list' {...props} />;
export const WarningIcon: CustomIconComponent = (props) => <FormationsIcon name='warning' {...props} />;
export const PaymentsIcon: CustomIconComponent = (props) => <FormationsIcon name='payments' {...props} />;
export const SelectWindowIcon: CustomIconComponent = (props) => <FormationsIcon name='select_window' {...props} />;
export const SelectWindowOffIcon: CustomIconComponent = (props) => <FormationsIcon name='select_window_off' {...props} />;
export const SupportAgentIcon: CustomIconComponent = (props) => <FormationsIcon name='support_agent' {...props} />;
export const GridViewIcon: CustomIconComponent = (props) => <FormationsIcon name='grid_view' {...props} />;