import {
  TextField,
  InputAdornment,
  Theme,
  TextFieldProps,
} from '@mui/material';
import { 
  ClearIcon, 
  SearchIcon 
} from 'components/common/Icon';
import makeStyles from '@mui/styles/makeStyles';
import React, { ChangeEvent, useCallback } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    [theme.breakpoints.up('sm')]: {
      width: '25%',
    },
    paddingLeft: theme.spacing(2),
  },
}));

export interface SearchPropsOnly {
  onChange: (val: string) => void;
  value?: string;
}
export type SearchProps = SearchPropsOnly &
  Omit<TextFieldProps, 'onChange' | 'onClear' | 'value'>;

export const Search = ({ onChange, value = '', ...props }: SearchProps) => {
  const classes = useStyles();

  const clearSearch = useCallback(() => {
    onChange('');
  }, [onChange]);

  const searchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <TextField
      {...props}
      className={classes.search}
      value={value}
      placeholder="Search"
      onChange={searchChange}
      autoComplete="off"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <ClearIcon
              data-testid="clear-search"
              style={{ cursor: 'pointer' }}
              onClick={clearSearch}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};
