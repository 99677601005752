import { Button, Grid } from '@mui/material';
import { GroupIcon } from 'components/common/Icon';
import { FormationsTertiaryButton } from 'components/common/Buttons';
import { AuthService } from 'services';
import { useTranslation } from 'react-i18next';

export const FooterDesktop = () => {
  const { t } = useTranslation();
  const isCustomer = AuthService.isCustomer();
  const LinkButton = FormationsTertiaryButton as typeof Button;

  return (
    <Grid sx={{ p: 2 }} container alignItems="center" justifyContent="center">
      {isCustomer && (
        <Grid item>
          <LinkButton
            sx={{ fontSize: '13px' }}
            startIcon={<GroupIcon size={20} />}
            target="_blank"
            href="https://get.formationscorp.com/customer-referral-program"
          >
            {t('navigation.referral')}
          </LinkButton>
        </Grid>
      )}
    </Grid>
  );
};
