import { Box } from '@mui/material';
import {  
  SaveIcon 
} from 'components/common/Icon';
import { ProgressTrackerStatus } from 'services/account';
import { UserInfo } from 'services/users';
import { FormProvider, useForm } from 'react-hook-form';
import { FormationsTertiaryButton } from 'components/common/Buttons';
import { Company } from 'models/company';
import { useUpdateUser } from 'hooks/api';
import { queryClient } from 'states/reactQueryClient';
import { showErrorToast } from 'components/toast/showToast';
import { BankingInfoBIB } from './BankingInfoBIB';
import { SelectedBankInfo } from './SelectedBankInfo';

type TFormData = {
  bankName: string;
  routingNumber: string;
  bankAccountNumber: string;
  bankAccountType: string;
};

const toFormData = (company: Company) => ({
  bankName: company?.useExistingBank ? company?.bankName ?? '' : '',
});

interface TParams {
  company: Partial<Company>;
  handleComplete: (status: ProgressTrackerStatus, eventData: Object) => void;
  user: Partial<UserInfo>;
  onContinueToNextSection: () => void;
}

export const BankInformationBIB = ({ company, handleComplete, user, onContinueToNextSection }: TParams) => {

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: toFormData(company),
    shouldUnregister: false,
  });

  const { updateUserAsync, isLoading: isUserUpdating } = useUpdateUser({
    onSuccess: () => {
      queryClient.invalidateQueries(['currentUser']);
    },
  });

  const updateBankInfo = async (formData: TFormData) => {
    if (company?.id && user?.contactId) {
      const {
        bankName,
        routingNumber,
        bankAccountNumber,
        bankAccountType,
      } = formData;

      try {
        if (bankName !== '') {
          await updateUserAsync({
            data: {
              bankName,
              routingNumber,
              bankAccountNumber,
              bankAccountType,
            },
          });
        } else {
          await updateUserAsync({
            data: {
              bankName: company.bankName,
              routingNumber,
              bankAccountNumber,
              bankAccountType,
            },
          });
        }
        handleComplete(ProgressTrackerStatus.Completed, {
          routingNumber,
          bankAccountNumber,
          bankAccountType,
        });
      } catch (error) {
        showErrorToast('Failed to update bank information');
      }
    }
  }

  const handleNext = (data: any) => {
    updateBankInfo(data);
  };

  return (
    <div data-testid="step-bank-selection">
      {(user?.routingNumber && user?.bankAccountNumber && user?.bankAccountType) ? (
        <SelectedBankInfo user={user} onContinueToNextSection={onContinueToNextSection} />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleNext)} noValidate>
            <BankingInfoBIB bankName={company?.bankName}/>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '48px',
              }}
            >
              <FormationsTertiaryButton
                isLoading={isUserUpdating}
                type="submit"
                size="large"
                data-testid="continue"
                loadingPosition="end"
                endIcon={<SaveIcon />}
                sx={{ ml: 'auto' }}
              >
                Save and Continue
              </FormationsTertiaryButton>
            </Box>
          </form>
        </FormProvider>
      )}
    </div>
  );
};