import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { showErrorToast } from 'components/toast/showToast';
import makeStyles from '@mui/styles/makeStyles';
import { MAIN_COLOR } from 'theme/constant';

interface IProps {
  displayName: string;
  setDisplayName: (value: string) => void;
}

const useStyles = makeStyles(() => ({
  displayInput: {
    color: MAIN_COLOR,
    fontSize: '20px',
    width: '280px',
  },
}));

export const DisplayName = ({ displayName, setDisplayName }: IProps) => {
  const [name, setName] = useState(displayName);
  const [error, setError] = useState(false);
  const [edit, setEdit] = useState(!displayName);
  const classes = useStyles();

  const checkDisplayName = () => {
    const editedName = name.trim();
    if (/^([A-Za-z0-9]+ )+[A-Za-z0-9]+$|^[A-Za-z0-9]+$/.test(editedName)) {
      setError(false);
      setDisplayName(editedName);
    } else {
      setError(true);
      setTimeout(() => {
        showErrorToast(
          'Your display name can only include letters and numbers.',
        );
      }, 100);
      setDisplayName('');
    }
    setEdit(false);
    setName(editedName);
  };

  return !name || edit ? (
    <TextField
      value={name}
      className={classes.displayInput}
      type="text"
      variant="filled"
      placeholder="enter your display name"
      InputProps={{
        disableUnderline: true,
        style: {
          height: '32px',
          fontSize: '20px',
        },
        inputProps: {
          maxLength: '25',
        },
      }}
      onChange={({ target: { value: v } }) => setName(v)}
      onFocus={() => setEdit(true)}
      autoComplete="off"
      onBlur={checkDisplayName}
      autoFocus
      data-testid="textInput"
    />
  ) : (
    <div
      role="button"
      tabIndex={0}
      onClick={() => setEdit(true)}
      onKeyDown={() => setEdit(true)}
      style={{
        cursor: 'pointer',
        height: '32px',
      }}
    >
      <Typography
        style={{ color: error ? 'red' : '#0D2259' }}
        component="h4"
        color="primary"
        variant="subtitle1L"
        data-testid="textDisplay"
      >
        {name}
      </Typography>
    </div>
  );
};
