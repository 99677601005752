/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCurrentUser } from 'hooks/api/useUser';
import { get } from 'lodash';

const useStyles = makeStyles(() => ({
  headerContainer: {
    borderBottom: '1px solid rgba(13, 34, 89, 0.1)',
  },
}));

export const Header: React.FC = () => {
  const classes = useStyles();
  const { currentUser } = useCurrentUser();
  const name =
    currentUser?.preferredName || get(currentUser, 'name.first', 'Customer');
  return (
    <Grid
      container
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ pt: 2, pb: 2 }}
      className={classes.headerContainer}
    >
      <Typography variant="h7B" color="primary">
        {`Welcome back, ${name}!`}
      </Typography>
      <Typography variant="body3S">Other action placeholder</Typography>
    </Grid>
  );
};
