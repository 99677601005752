import { api } from './axios';

export enum FeatureName {
  DataSourceToggle = 'DataSourceToggle',
}

export enum TransactionDataSource {
  Xero = 'Xero',
  Hurdlr = 'Hurdlr',
}

export type FormationFeature = {
  id: string;
  accountId: string;
  featureName: FeatureName;
  enabled: boolean;
  switches: {
    [key: string]: boolean;
  };
  data?: {
    source?: TransactionDataSource;
  };
  createdAt: string;
  createdBy: string;
  createdByEmail: string;
  updatedAt: string;
  updatedBy: string;
  updatedByEmail: string;
};

export const getFeatureList = async ({
  accountId,
}: {
  accountId?: string;
}): Promise<FormationFeature[]> => {
  const resp = await api.get<FormationFeature[]>('features', {
    params: {
      accountID: accountId,
    },
  });
  return resp.data;
};

export const getSpecificFeature = async ({
  accountId,
  featureName,
}: {
  accountId: string;
  featureName: FeatureName;
}): Promise<FormationFeature | null> => {
  const resp = await api.get<FormationFeature | null>('features', {
    params: {
      accountID: accountId,
      featureName,
    },
  });
  return resp.data;
};

export const createFeature = async (params: Partial<FormationFeature>) => {
  await api.post(`features`, params);
};

export const updateFeature = async (
  featureId: string,
  accountID: string,
  params: Partial<FormationFeature>,
) => {
  await api.patch(`features/${featureId}`, {
    accountID,
    ...params,
  });
};

export const deleteFeature = async (featureId: string): Promise<void> => {
  await api.delete(`features/${featureId}`);
};
