import {
  Box,
  Checkbox,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Grid,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FormationsPrimaryButton } from 'components/common/Buttons';
import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { usePayrollTaxName, useSetPayrollStateTaxes } from 'hooks/api/useTaxes';
import { PayrollTax } from 'models/payroll';
import { isEqual } from 'lodash';
import { showToastOnError } from 'components/toast/showToast';

const TaxManagementContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));
const Title = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  color: theme.palette.text.primary,
}));
const TableButtons = styled(Grid)(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));
const Cell = styled(TableCell)({
  fontSize: 14,
});

export const getStateTaxNames = (taxes: PayrollTax[]): string[] =>
  taxes
    .filter((item) => item.isStateTax)
    .reduce((res: string[], item) => {
      if (item.name !== '') {
        res.push(item.name);
      }
      return res;
    }, []);

const TaxNamesManagement = () => {
  const {
    taxNames,
    isError: isFetchError,
    error: fetchError,
  } = usePayrollTaxName();
  const { setPayrollStateTaxesAsync, isLoading: isSetLoading } =
    useSetPayrollStateTaxes();

  useEffect(() => {
    if (isFetchError) {
      showToastOnError(fetchError);
    }
  }, [isFetchError, fetchError]);

  const [stateTaxes, setStateTaxes] = useState<string[]>([]);
  useEffect(() => {
    if (!isEqual(getStateTaxNames(taxNames), stateTaxes)) {
      setStateTaxes(getStateTaxNames(taxNames));
    }
  }, [taxNames]);

  const toggleTax = (taxName: string) => {
    if (stateTaxes.includes(taxName)) {
      setStateTaxes(stateTaxes.filter((name) => name !== taxName));
    } else {
      setStateTaxes([taxName, ...stateTaxes]);
    }
  };

  const onSaveTaxes = async () => {
    try {
      await setPayrollStateTaxesAsync(stateTaxes);
    } catch (err) {
      showToastOnError(err);
    }
  };
  return (
    <TaxManagementContainer>
      <Title>
        <Typography variant="h4" component="h4" fontWeight="bold">
          Payroll taxes
        </Typography>
      </Title>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={128}>State income tax</TableCell>
              <TableCell width="auto">Taxes name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxNames.length === 0 && (
              <TableRow>
                <Cell colSpan={2}>
                  There are no employee deduction from payroll
                </Cell>
              </TableRow>
            )}
            {taxNames.length > 0 &&
              taxNames.map((item) => (
                <TableRow data-testid="row-tax-name" key={item.name}>
                  <Cell style={{ textAlign: 'center' }}>
                    <Checkbox
                      checked={stateTaxes.includes(item.name)}
                      size="small"
                      onChange={() => toggleTax(item.name)}
                      // @ts-ignore
                      inputProps={{ 'data-testid': `checkbox-${item.name}` }}
                    />
                  </Cell>
                  <Cell>{item.name}</Cell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TableButtons>
          <FormationsPrimaryButton
            isLoading={isSetLoading}
            onClick={onSaveTaxes}
            disabled={isEqual(getStateTaxNames(taxNames), stateTaxes)}
            data-testid="save-taxes"
          >
            Save
          </FormationsPrimaryButton>
        </TableButtons>
      </TableContainer>
    </TaxManagementContainer>
  );
};

// eslint-disable-next-line import/no-default-export
export default TaxNamesManagement;
