import React, { ReactNode } from 'react';
import { Button, ButtonProps } from '@mui/material';

interface Props extends ButtonProps {
  children: ReactNode;
  rounded?: boolean;
}

export const FormationsGhostButton = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const {
      children,
      className,
      variant = 'text',
      color = 'ghost',
      rounded = false,
      ...buttonProps
    } = props;

    return (
      <Button
        variant={variant}
        color={color}
        ref={ref}
        className={
          rounded ? `roundedButton ${className ?? ''}`.trim() : className
        }
        {...buttonProps}
      >
        {children}
      </Button>
    );
  },
);
