import { Popover, Grid, Typography } from '@mui/material';
import {
  FormationsPrimaryButton,
  FormationsGhostButton,
} from 'components/common/Buttons';
import { AlertWarning, AlertSuccess } from 'components/FormationsAlert';
import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { PaymentSource, PaymentStatus } from 'enums';
import { useDeleteDirectPayment } from 'hooks/api/useTaxes';
import { DirectPayment } from 'components/taxes/types';
import { showErrorToast } from 'components/toast/showToast';
import { useTranslation } from 'react-i18next';
import { ActionPermissions, usePermissions } from 'hooks/usePermissions';
import { useMediaBreakpoints} from 'hooks'

const DeleteContent = ({
  onDelete,
  onClose,
  isLoading = false,
}: {
  onDelete: () => Promise<void>;
  onClose: MouseEventHandler<HTMLButtonElement>;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaBreakpoints();

  return (
    <Grid 
      sx={{ 
        p: isMobile ? 2 : 0, 
        mx: isMobile ? 0 : 3, 
        my: isMobile ? 0 : 2, 
        width: isMobile ? '100%' : 384
      }}
    >
      <Typography variant="h6B" component="h6" sx={{ mb: 2 }}>
        {t('taxes.deleteDialog.header')}
      </Typography>
      <AlertWarning text={t('taxes.deleteDialog.confirmText')} />
      <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
        <FormationsGhostButton
          data-testid="confirm-cancel-btn"
          size="large"
          onClick={onClose}
          sx={{ mr: 2 }}
        >
          Cancel
        </FormationsGhostButton>
        <FormationsPrimaryButton
          isLoading={isLoading}
          size="large"
          onClick={(event) => {
            event.stopPropagation();
            onDelete();
          }}
          data-testid="confirm-ok-btn"
        >
          Yes, Delete
        </FormationsPrimaryButton>
      </Grid>
    </Grid>
  );
};

const SuccessContent = ({
  onClose,
}: {
  onClose: MouseEventHandler<HTMLButtonElement>;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaBreakpoints();

  return (
    <Grid 
      sx={{ 
        p: isMobile ? 2 : 0, 
        mx: isMobile ? 0 : 3, 
        my: isMobile ? 0 : 2, 
        width: isMobile ? '100%' : 384
      }}
    >
      <Typography variant="h6B" component="h6" sx={{ mb: 2 }}>
        {t('taxes.deleteDialog.header')}
      </Typography>
      <AlertSuccess text={t('taxes.deleteDialog.successText')} />
      <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
        <FormationsGhostButton
          data-testid="confirm-cancel-btn"
          size="large"
          onClick={onClose}
        >
          Close
        </FormationsGhostButton>
      </Grid>
    </Grid>
  );
};

const WarningContent = ({
  onClose,
}: {
  onClose: MouseEventHandler<HTMLButtonElement>;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaBreakpoints();

  return (
    <Grid 
      sx={{ 
        p: isMobile ? 2 : 0, 
        mx: isMobile ? 0 : 3, 
        my: isMobile ? 0 : 2, 
        width: isMobile ? '100%' : 384
      }}
    >
      <Typography variant="h6B" component="h6" sx={{ mb: 2 }}>
        {t('taxes.deleteDialog.header')}
      </Typography>
      <AlertWarning text={t('taxes.deleteDialog.warningText')} />
      <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
        <FormationsGhostButton
          data-testid="confirm-cancel-btn"
          size="large"
          onClick={onClose}
        >
          Close
        </FormationsGhostButton>
      </Grid>
    </Grid>
  );
};

const popoverContent = {
  delete: DeleteContent,
  success: SuccessContent,
  warning: WarningContent,
};

type Props = {
  open: boolean;
  anchorEl: HTMLElement | null;
  row: DirectPayment;
  accountId: string | undefined;
  onClose: (shouldRefresh: boolean) => void;
};

export const DeletePopover = ({
  open,
  anchorEl,
  row,
  accountId,
  onClose,
}: Props) => {
  const [content, setContent] = useState<'delete' | 'success' | 'warning'>(
    'delete',
  );
  const overrideDelete = usePermissions(
    ActionPermissions.APPROVED_TAX_PAYMENT_DELETE,
  );

  // only allow admins to delete tax payment that are pending
  // superadmins have the permission to override and delete any direct payment
  // payroll tax payments are not deletable as they are generated by Gusto
  const allowDelete = useMemo(
    () =>
      row.source === PaymentSource.Direct &&
      (row.status !== PaymentStatus.Approved || overrideDelete),
    [row.source, row.status, overrideDelete],
  );

  useEffect(() => {
    setContent('delete');
    if (!allowDelete) {
      setContent('warning');
    }
  }, [open, allowDelete]);

  const { mutateAsync: deleteDirectPayment, isLoading: deletingDirectPayment } =
    useDeleteDirectPayment({
      onSuccess: async () => {
        setContent('success');
      },
      onError: () => {
        showErrorToast(`Failed to update direct payment`);
      },
    });

  const onPopoverClose = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    onClose(content === 'success');
  };

  const onDelete = () => {
    const mutateVariables = { accountId: accountId ?? '', paymentId: row.id };
    return deleteDirectPayment(mutateVariables);
  };

  const showContent = () => {
    const ContentComponent =
      popoverContent[content as keyof typeof popoverContent];
    return (
      <ContentComponent
        onDelete={onDelete}
        isLoading={deletingDirectPayment}
        onClose={onPopoverClose}
      />
    );
  };

  return (
    <Popover
      onClose={onPopoverClose}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      {showContent()}
    </Popover>
  );
};
