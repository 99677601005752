import { ReactNode } from 'react';
import { Button, ButtonProps } from '@mui/material';

interface Props extends ButtonProps {
  children: ReactNode;
  rounded?: boolean;
}

export const FormationsSecondaryButton = ({
  className,
  variant = 'contained',
  color = 'secondary',
  rounded = false,
  ...buttonProps
}: Props) => (
  <Button
    variant={variant}
    color={color}
    className={rounded ? `roundedButton ${className ?? ''}`.trim() : className}
    {...buttonProps}
  />
);
