import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogActions, Grid, Typography } from '@mui/material';
import { FormationsPrimaryButton } from 'components/common/Buttons';
import { useTranslation } from 'react-i18next';
import { AlertSuccess } from 'components/FormationsAlert';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const UploadSuccessDialog = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      data-testid="upload-document-dialog"
    >
      <DialogTitle sx={{ p: 3 }}>
        {t('documentHub.uploadSuccess.title')}
      </DialogTitle>
      <DialogContent sx={{ p: 0, mx: 3, my: 2, width: 325 }}>
        <AlertSuccess
          text={
            <Grid container display="flex" flexDirection="column">
              <Typography
                variant="body2"
                display="inline"
                sx={{ color: 'success.main' }}
              >
                {t('documentHub.uploadSuccess.uploadSuccess')}
              </Typography>
              <Typography
                variant="body2"
                display="inline"
                sx={{ color: 'success.main' }}
              >
                {t('documentHub.uploadSuccess.csmReview')}
              </Typography>
            </Grid>
          }
        />
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Grid container justifyContent="flex-end" gap={1}>
          <FormationsPrimaryButton
            size="large"
            onClick={onClose}
            data-testid="form-cancel-btn"
          >
            {t('actions.close')}
          </FormationsPrimaryButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
