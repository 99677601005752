import { ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMediaBreakpoints } from 'hooks/useMediaBreakpoints';
import {
  FormationsForm,
  FormationsFormData,
  IFormField,
} from 'components/common/FormationsForm2';
import { UseFormMethods, UseFormOptions } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import { Breakpoint } from '@mui/material';

interface IFormDialog {
  isOpen: boolean;
  title: string;
  subTitle?: ReactNode | string;
  defaultValues?: FormationsFormData;
  onChange?: (data: FormationsFormData, formInstance: UseFormMethods) => void;
  onSubmit: (data: FormationsFormData) => Promise<void>;
  onClose: () => void;
  fields: IFormField[];
  validationSchema: AnyObjectSchema;
  'data-testid'?: string;
  useFormOptions?: UseFormOptions;
  loading?: boolean;
  maxWidth?: Breakpoint;
  footer?: ReactNode;
  saveButtonLabel?: string;
  cancelButtonLabel?: string;
  renderActions?: (formInstance: UseFormMethods) => ReactNode;
}
const emptyFn = () => null;
export const FormationsFormDialog = ({
  isOpen,
  onClose,
  title,
  subTitle = '',
  fields,
  defaultValues = {},
  onChange = emptyFn,
  onSubmit,
  validationSchema,
  'data-testid': dataTestId,
  useFormOptions = {},
  loading = false,
  maxWidth = 'xs',
  saveButtonLabel = 'Save',
  cancelButtonLabel = 'Cancel',
  footer,
  renderActions,
}: IFormDialog) => {
  const { isMobile } = useMediaBreakpoints();
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullScreen={isMobile}
        scroll="paper"
        fullWidth
        maxWidth={maxWidth}
        data-testid={dataTestId ?? 'form-dialog'}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{subTitle}</DialogContentText>
          <FormationsForm
            onChange={onChange}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            fields={fields}
            onCancel={onClose}
            validationSchema={validationSchema}
            useFormOptions={useFormOptions}
            loading={loading}
            saveButtonLabel={saveButtonLabel}
            cancelButtonLabel={cancelButtonLabel}
            renderActions={renderActions}
          />
          {footer}
        </DialogContent>
      </Dialog>
    </div>
  );
};
