import { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { Snackbar, Alert } from '@mui/material';
import { getErrorMessage } from 'helpers/error';
import { AlertTitle } from '@mui/lab';
import { styled } from '@mui/styles';

type ShowToastParams = {
  title?: string;
  message: string;
  type: 'error' | 'warning' | 'info' | 'success';
  duration?: number;
};
interface ToastProps extends ShowToastParams {
  onClose: () => void;
}

const FormationsAlert = styled(Alert)({
  '&.MuiAlert-outlined': {
    backgroundColor: `#fff !important`,
  },
});

const Toast = ({
  title = '',
  message,
  type,
  duration = 6000,
  onClose,
}: ToastProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  return (
    <Snackbar
      data-testid={`toast-${type}`}
      open={open}
      autoHideDuration={duration}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
    >
      <FormationsAlert
        onClose={handleClose}
        severity={type}
        variant="outlined"
        sx={{ maxWidth: '400px' }}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </FormationsAlert>
    </Snackbar>
  );
};
Toast.defaultProps = {
  title: '',
  duration: 6000,
};

const ToastContainer = ({
  container,
  ...props
}: ShowToastParams & { container: HTMLElement }) => {
  const handleClose = useCallback(() => {
    container.remove();
  }, [container]);
  return (
    <Toast
      {...props}
      data-testid={`toast-${props.type}`}
      onClose={handleClose}
    />
  );
};

export const showToast = (props: ShowToastParams) => {
  const container = document.createElement('div');
  ReactDOM.render(
    <ToastContainer container={container} {...props} />,
    container,
  );
  document.body.append(container);
};
export const showErrorToast = (message: string, title: string = '') => {
  showToast({ title, message, type: 'error' });
};
export const showSuccessToast = (message: string, title: string = '') => {
  showToast({ title, message, type: 'success' });
};
export const showInfoToast = (message: string, title: string = '') => {
  showToast({ title, message, type: 'info' });
};
export const showWarningToast = (message: string, title: string = '') => {
  showToast({ title, message, type: 'warning' });
};
export const showToastOnError = (e: unknown | Error) => {
  showErrorToast(`${getErrorMessage(e)}`);
};
