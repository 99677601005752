import { injectFeatureFlagInit } from 'helpers/featureFlag';
import { withSentryIdentitySetup } from 'helpers/sentry';
import { Alerts } from './components/common';
import {
  ChurnZeroPageTracking,
  LoadChurnScript,
  LoadHubspotScript,
  LoadUserPilotScript,
} from './components/LoadScripts';
import { Routing } from './Routing';

const AppBase = () => (
  <>
    <Routing />
    <Alerts />
    <LoadChurnScript />
    <LoadHubspotScript />
    <ChurnZeroPageTracking />
    <LoadUserPilotScript />
  </>
);

export const App = withSentryIdentitySetup(injectFeatureFlagInit(AppBase));
