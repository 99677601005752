import * as yup from 'yup';

export const homeAddressSchema = yup.object().shape({
  homeAddress1: yup.string().required('Please enter an address.'),
  homeAddress2: yup.string(),
  homeCity: yup.string().required('Please enter a city.'),
  homeState: yup.string().nullable().required('Please enter a state.'),
  homeZip: yup
    .string()
    .matches(/^\d{5}(?:-?\d{4})?$/, 'Please enter a valid ZIP code.')
    .required('Please enter a ZIP code.'),
});

export const homeAddressSchemaProfile = yup.object().shape({
  homeAddress1: yup.string().required('Please enter an address.'),
  homeAddress2: yup.string(),
  homeCity: yup.string().required('Please enter a city.'),
  homeState: yup
    .string()
    .required('Please enter a state.'),
  homeZip: yup
    .string()
    .matches(/^\d{5}(?:-?\d{4})?$/, 'Please enter a valid ZIP code.')
    .required('Please enter a ZIP code.'),
});