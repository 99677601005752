import { useMemo } from 'react';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseMutationOptions,
  MutateOptions,
} from 'react-query';
import { SortingDirection, SortOrderEnumParam } from 'enums';
import {
  ArrayParam,
  createEnumParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';
import {
  ApiListTransactions,
  getXeroCategories,
  getXeroTransactions,
  updateTransaction,
  ICategoryItem,
  UNKNOWN_TRANSACTION_CATEGORY_CODE,
  deleteAllTransactions,
  createTransaction,
  getXeroUnknownTransactionCount,
} from 'services/xero';
import { defaultPageInfo } from 'models/api';
import { useLocation } from 'react-router-dom';
import { Routes } from 'fnRoutes';
import { isNil, omitBy } from 'lodash';
import { isSamePathWithSpoof } from 'helpers/routes';
import { AxiosError } from 'axios';
import { defaultTablePageSize } from 'constants/common';

export { UNKNOWN_TRANSACTION_CATEGORY_CODE } from 'services/xero';

export enum ExpenseType {
  income = 'income',
  spend = 'spend',
}

export const emptyTransactionAllFilter: TransactionsFilters = {
  expenseType: undefined,
  category: undefined,
  page: undefined,
  from: undefined,
  to: undefined,
  description: undefined,
  amountFrom: undefined,
  amountTo: undefined,
  excludeCategory: undefined,
  order: undefined,
  orderBy: undefined,
  size: undefined,
};

export const emptyTransactionFilter: TransactionsFilters = {
  expenseType: undefined,
  category: undefined,
  page: undefined,
  from: undefined,
  to: undefined,
  description: undefined,
  amountFrom: undefined,
  amountTo: undefined,
};

export type TransactionsFilters = {
  from?: string | null;
  to?: string | null;
  page?: number;
  size?: number;
  order?: SortingDirection;
  orderBy?: string | null;
  category?: (string | null)[] | null;
  amountFrom?: number | null;
  amountTo?: number | null;
  description?: string | null;
  expenseType?: ExpenseType | null;
  excludeCategory?: string | null;
};

export interface QueryParams {
  page: number;
  order?: SortingDirection;
  orderBy?: string;
  category?: string[];
  amountFrom?: number;
  amountTo?: number;
  size?: number;
  description?: string;
  expenseType?: ExpenseType;
  excludeCategory?: string;
  from?: string;
  to?: string;
}

export const ExpenseTypeParam = createEnumParam([
  ExpenseType.spend,
  ExpenseType.income,
]);

export const transactionsQueryParams = {
  page: withDefault(NumberParam, 1),
  from: StringParam,
  to: StringParam,
  order: withDefault(SortOrderEnumParam, SortingDirection.Desc),
  orderBy: StringParam,
  size: withDefault(NumberParam, defaultTablePageSize),
  category: ArrayParam,
  amountFrom: NumberParam,
  amountTo: NumberParam,
  description: StringParam,
  expenseType: ExpenseTypeParam,
  excludeCategory: StringParam,
};

const toQueryParams = (filter: TransactionsFilters): QueryParams => {
  const params: QueryParams = {
    amountFrom: filter.amountFrom ?? undefined,
    amountTo: filter.amountTo ?? undefined,
    category:
      (filter.category?.filter((item) => item != null) as string[]) ??
      undefined,
    description: filter.description ?? undefined,
    expenseType: filter.expenseType ?? undefined,
    order:
      filter.order === SortingDirection.Asc
        ? SortingDirection.Asc
        : SortingDirection.Desc,
    orderBy: filter.orderBy ?? undefined,
    page: filter.page ?? 1,
    size: filter.size ?? undefined,
    excludeCategory: filter.excludeCategory ?? undefined,
    from: filter.from ? new Date(filter.from).toISOString() : undefined,
    to: filter.to ? new Date(filter.to).toISOString() : undefined,
  };
  return <QueryParams>omitBy<QueryParams>(params, isNil);
};

export const useTransactions = (
  accountID: string,
  params: TransactionsFilters,
  options: UseQueryOptions<ApiListTransactions> = {},
) => {
  const { data: transactions, ...rest } = useQuery<ApiListTransactions>(
    [
      'transactions',
      {
        accountID,
        ...params,
      },
    ],
    () => getXeroTransactions(accountID, toQueryParams(params)),
    {
      enabled: accountID != null, // only enable if accountid is available
      ...options,
      keepPreviousData: true,
    },
  );

  return {
    transactions: transactions ?? {
      data: [],
      pageInfo: defaultPageInfo,
    },
    ...rest,
  };
};

export const filterOutSystemAccountCategory = (
  list: ICategoryItem[],
): ICategoryItem[] => list.filter((item) => !item.systemAccount);

export const useTransactionCategories = (accountID: string | undefined) => {
  const { data: categories, ...rest } = useQuery<ICategoryItem[], unknown>(
    [
      'xeroCategories',
      {
        accountID,
      },
    ],
    () => getXeroCategories(accountID!),
  );
  const filteredCategories = filterOutSystemAccountCategory(
    categories || ([] as ICategoryItem[]),
  );
  const filterUnknown = filteredCategories.filter(
    (item) => item.code !== UNKNOWN_TRANSACTION_CATEGORY_CODE,
  );

  return {
    categories: filteredCategories,
    categoriesWithoutUnknown: filterUnknown,
    ...rest,
  };
};

interface TransactionUpdateData {
  transactionId: string;
  categoryCode?: string;
  note?: string;
}

export const useTransactionUpdate = (
  mutationProps?: UseMutationOptions<unknown, unknown, TransactionUpdateData>,
) =>
  useMutation(
    ({ transactionId, categoryCode, note }: TransactionUpdateData) =>
      updateTransaction(transactionId, { categoryCode, note }),
    mutationProps,
  );

export const useUncategorizedTransactionCount = (
  accountId: string | undefined,
) => {
  const { pathname } = useLocation();
  const { transactions, isFetching } = useTransactions(
    accountId!,
    {
      category: [UNKNOWN_TRANSACTION_CATEGORY_CODE],
      page: 1,
      order: SortingDirection.Desc,
    },
    {
      enabled:
        accountId != null &&
        isSamePathWithSpoof(pathname, Routes.ACCOUNTING_UNKNOWN_TRANSACTIONS), // optional improvement: make sure it is only called in unknown transactions page to save one query
    },
  );

  const count = useMemo(() => {
    if (transactions?.pageInfo) {
      return transactions?.pageInfo.totalCount || 0;
    }
    return 0;
  }, [transactions]);

  return {
    count,
    isLoading: isFetching,
  };
};

export const useCreateTransaction = (
  accountId: string | undefined,
  queryProps?: MutateOptions<unknown, AxiosError, FormData>,
) => {
  const {
    mutate: addTransaction,
    mutateAsync: addTransactionAsync,
    ...rest
  } = useMutation((formData: FormData) => {
    if (accountId) {
      return createTransaction(accountId, formData);
    }
    throw new Error('Account is not found');
  }, queryProps);
  return {
    addTransaction,
    addTransactionAsync,
    ...rest,
  };
};

export const useDeleteAllTransactions = (
  queryProps?: MutateOptions<unknown, unknown, string>,
) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    (accountId: string) => deleteAllTransactions(accountId),
    queryProps,
  );
  return {
    deleteAllTransactionsAsync: mutateAsync,
    deleteAllTransactions: mutate,
    ...rest,
  };
};

export const useXeroUnknownTransactionCount = (
  accountId: string,
  year: number,
  queryProps?: UseQueryOptions<{
    count: number;
  }>,
) => {
  const { data, ...rest } = useQuery<{
    count: number;
  }>(
    ['xeroUnknownTransactionCount', accountId, year],
    () => getXeroUnknownTransactionCount(accountId, year),
    {
      ...queryProps,
      enabled: !!accountId && !!year,
    },
  );
  return {
    count: data?.count || 0,
    ...rest,
  };
};
