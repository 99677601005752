import { Icon, IconProps } from '@mui/material';
import React, { CSSProperties } from 'react';

export interface FormationsIconProps extends IconProps {
    name: string;
    fill?: number;
    weight?: number;
    grade?: number;
    opticalSize?: number;
    size?: number;
  }
  
  export const FormationsIcon: React.FC<FormationsIconProps> = ({
    name,
    fill = 0,
    weight = 400,
    grade = 0,
    opticalSize = 24,
    size = 24,
    ...props
  }) => {

    const style: CSSProperties = {
      fontVariationSettings: `'FILL' ${fill}, 'wght' ${weight}, 'GRAD' ${grade}, 'opsz' ${opticalSize}`,
      display: 'flex',
      alignItems: 'center',
    };
  
    return (
      <Icon style={style} {...props}>
        <span className="material-symbols-rounded" style={{ fontSize: size }}>{name}</span>
      </Icon>
    );
  };