import { Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { FormationsTableDocument } from 'components/common/tables/DocumentsTable';
import { EDocumentDepartment } from 'constants/documents';

const TaxesText = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.others.mint,
  borderRadius: theme.spacing(2),
}));

const ProfileText = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.others.newLightBlue,
  borderRadius: theme.spacing(2),
}));

const AccountingText = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.others.yellow,
  borderRadius: theme.spacing(2),
}));

const PayrollText = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.primary.background,
  borderRadius: theme.spacing(2),
}));

const TaxesTextCell = ({ title } : { title: string }) => (
  <TaxesText
    data-testid="document-pillar-cell"
    variant="chip"
    color="text.primary"
    sx={{ pl: 2, pr: 2, pt: 1, pb: 1, whiteSpace: 'nowrap' }}
  >
    {title}
  </TaxesText>
);

const ProfileTextCell = ({ title } : { title: string }) => (
  <ProfileText
    data-testid="document-pillar-cell"
    variant="chip"
    color="text.primary"
    sx={{ pl: 2, pr: 2, pt: 1, pb: 1, whiteSpace: 'nowrap' }}
  >
    {title}
  </ProfileText>
);

const AccountingTextCell = ({ title } : { title: string }) => (
  <AccountingText
    data-testid="document-pillar-cell"
    variant="chip"
    color="text.primary"
    sx={{ pl: 2, pr: 2, pt: 1, pb: 1, whiteSpace: 'nowrap' }}
  >
    {title}
  </AccountingText>
);

const PayrollTextCell = ({ title } : { title: string }) => (
  <PayrollText
    data-testid="document-pillar-cell"
    variant="chip"
    color="text.primary"
    sx={{ pl: 2, pr: 2, pt: 1, pb: 1, whiteSpace: 'nowrap' }}
  >
    {title}
  </PayrollText>
);

interface IMoreActionsCell {
  document: FormationsTableDocument;
}

export const PillarCell = ({ document }: IMoreActionsCell) => {
  switch (document.department) {
    case EDocumentDepartment.Tax:
      return <TaxesTextCell title={document.department} />;
    case EDocumentDepartment['Profile & Account']:
      return <ProfileTextCell title={document.department} />;
    case EDocumentDepartment.Accounting:
      return <AccountingTextCell title={document.department} />;
    case EDocumentDepartment.Payroll:
      return <PayrollTextCell title={document.department} />;
    default:
      return (
        <Typography
          data-testid="document-pillar-cell"
          variant="chip"
          color="text.primary"
          sx={{ pl: 2, pr: 2, pt: 1, pb: 1, whiteSpace: 'nowrap' }}
        >
          {document.department}
        </Typography>
      );
  }
};
