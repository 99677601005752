import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions, Grid, Typography } from '@mui/material';
import { FormationsPrimaryButton } from 'components/common/Buttons';
import { useTranslation } from 'react-i18next';
import { AlertSuccess } from 'components/FormationsAlert';

interface Props {
  onClose: () => void;
  isCreateSuccess: boolean;
  isMobile?: boolean;
}

export const TaxesDirectPaymentSuccessDialog = ({
  onClose,
  isCreateSuccess,
  isMobile = false,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle sx={{ p: 3 }}>{t('taxes.successDialog.title')}</DialogTitle>
      <DialogContent sx={{ p: 0, mx: 3, my: 2 }}>
        <AlertSuccess
          text={
            <Typography
              variant="body2"
              display="inline"
              sx={{ color: 'success.main' }}
            >
              {isCreateSuccess
                ? t('taxes.successDialog.alertTextAdd')
                : t('taxes.successDialog.alertTextEdit')}
            </Typography>
          }
        />
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: isMobile ? 10 : 2 }}>
        <Grid container justifyContent="flex-end" gap={1}>
          <FormationsPrimaryButton
            size="large"
            onClick={onClose}
            data-testid="form-cancel-btn"
          >
            {t('actions.close')}
          </FormationsPrimaryButton>
        </Grid>
      </DialogActions>
    </>
  );
};
