import { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { IFormField } from './types';

export interface TFormationsCustomOptions {
  render: (field: IFormField, formInstance: UseFormMethods) => ReactElement;
}

export type TFormationsTextField = {
  field: IFormField<TFormationsCustomOptions>;
  formInstance: UseFormMethods;
};

const defaultValue = {
  render: () => <></>,
};

export const FormationsCustom = ({
  field,
  formInstance,
}: TFormationsTextField) => {
  const { options: { render } = defaultValue } = field;
  return render(field, formInstance);
};
