import { useAccountPreference, useUpdateAccountPreference } from 'hooks/api';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  TypographyProps,
} from '@mui/material';
import { FormationsPrimaryButton } from 'components/common/Buttons';
import React, { useMemo } from 'react';
import { AccountPreference } from 'models/account';
import * as yup from 'yup';
import { showSuccessToast, showToastOnError } from 'components/toast/showToast';
import { queryClient } from 'states/reactQueryClient';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateAccountPreferencePayload } from 'services/account';

const PreferenceTitle = ({ children, ...props }: TypographyProps<any>) => (
  <Typography variant="body2S" {...props}>
    {children}
  </Typography>
);

const maskEmail = (email: string) => {
  if (email == null) {
    return '';
  }
  const [name, domain] = email.split('@');
  return `${name.slice(0, 2)}****@${domain}`;
};

type RequestData = UpdateAccountPreferencePayload;

const dataValidation = yup.object({
  textNotify: yup
    .boolean()
    .required('Text notification preference is required'),
  phoneNumber: yup.string().when('textNotify', {
    is: true,
    then: yup
      .string()
      .required('Phone number is required')
      .matches(/^\d{10}$/, 'Phone number must be 10 digits')
      .min(1, 'Phone number cannot be empty'),
    otherwise: yup.string().notRequired(),
  }),
});

interface UIProps {
  preference: AccountPreference;
  email: string;
  title: string;
}

const AccountPreferenceUI = ({ email, preference, title }: UIProps) => {
  const maskedEmail = useMemo(() => maskEmail(email ?? ''), [email]);
  const { updateAccountPreferenceAsync, isLoading } =
    useUpdateAccountPreference(preference.accountId);

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty },
    errors,
    reset,
    trigger,
    setValue,
    getValues,
  } = useForm<RequestData>({
    defaultValues: {
      textNotify: preference.textNotify,
      phoneNumber: preference.phoneNumber,
    },
    reValidateMode: 'onBlur',
    resolver: yupResolver(dataValidation),
  });
  const textNotify = watch('textNotify');

  const submit = async (data: RequestData) => {
    try {
      await updateAccountPreferenceAsync(data);
      await queryClient.invalidateQueries([
        'accountPreference',
        preference.accountId,
      ]);
      reset(getValues())
      showSuccessToast('Account preference updated successfully');
    } catch (e) {
      showToastOnError(e);
    }
  };

  const noClickHandler = async () => {
    setValue('textNotify', false)
    const isValid = await trigger()
    if (isValid) {
      await handleSubmit(submit)()
    }
  }

  return (
    <>
      <Typography sx={{ mt: 2 }}>
        Get updates on your {title} status.
      </Typography>
      <Box sx={{ color: 'text.primary' }}>
        <form onSubmit={handleSubmit(submit)}>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <PreferenceTitle component="h4">
              We can send you progress update at {maskedEmail}
            </PreferenceTitle>
            <FormControlLabel
              sx={{ ml: 1, mt: 1, mb: 1 }}
              control={<Checkbox sx={{ pl: 0 }} checked disabled />}
              label="Send updates to my email"
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <PreferenceTitle component="h4">
              Do you want text updates when your {title} status changes?
            </PreferenceTitle>
            <Controller
              name="textNotify"
              control={control}
              render={(field) => (
                <RadioGroup
                  {...field}
                  onChange={(e) => field.onChange(e.target.value === 'true')}
                  value={String(field.value)}
                >
                  <Grid
                    container
                    flexDirection="row"
                    sx={{ ml: 1, mt: 1, mb: 1 }}
                  >
                    <FormControlLabel
                      label="Yes"
                      disabled={isLoading}
                      control={<Radio value="true" checked={field.value} />}
                    />
                    <FormControlLabel
                      label="No"
                      disabled={isLoading}
                      control={<Radio value="false" checked={!field.value} />}
                      onClick={noClickHandler}
                    />
                  </Grid>
                </RadioGroup>
              )}
            />
            {textNotify && (
              <Box sx={{ mt: 1 }}>
                <Grid container gap={1}>
                  <Grid item>
                    <Controller
                      control={control}
                      name="phoneNumber"
                      render={(field, { invalid }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +1
                              </InputAdornment>
                            ),
                          }}
                          label="Please enter mobile number"
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          error={invalid}
                          helperText={errors.phoneNumber?.message}
                          disabled={isLoading}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <FormationsPrimaryButton
                      size="large"
                      sx={{ height: '49px' }}
                      data-testid="submit-button"
                      disabled={!isDirty}
                      type="submit"
                    >
                      Save
                    </FormationsPrimaryButton>
                  </Grid>
                </Grid>
              </Box>
            )}
          </FormControl>
        </form>
      </Box>
    </>
  );
};

interface Props {
  accountId: string;
  email: string;
  title: string;
}

export const AccountPreferenceSetting = ({
  accountId,
  email,
  title,
}: Props) => {
  const { accountPreference } = useAccountPreference(accountId);

  if (accountPreference == null) {
    return null;
  }

  return (
    <AccountPreferenceUI
      email={email}
      preference={accountPreference}
      title={title}
    />
  );
};
