import { Typography } from '@mui/material';
import { FormationsPrimaryButton } from 'components/common/Buttons';
import { sendProgressTrackerEvent } from 'helpers/heap/progressTrackerEvent';
import { ProgressTrackerStages } from 'models/account';
import { useTranslation } from 'react-i18next';

interface TParams {
  accountId: string | undefined;
  accountEntity: string;
  completedSteps: number;
  rightSignatureURL?: string;
  hasBankAccount: boolean;
}

export const DocumentSigning = ({
  accountId,
  accountEntity,
  completedSteps,
  rightSignatureURL,
  hasBankAccount,
}: TParams) => {
  const { t } = useTranslation();

  const getVerbiage = (): string => {
    if (completedSteps === 2) {
      return t('progressTracker.bankSetup.documentApproved');
    }
    if (!hasBankAccount) {
      return t('progressTracker.bankSetup.completeBankSelection');
    }
    if (rightSignatureURL) {
      return t('progressTracker.bankSetup.documentPacketReady');
    }
    return t('progressTracker.bankSetup.documentSigningInReview');
  };

  return (
    <div>
      <Typography
        component="h5"
        variant="h5B"
        data-testid="document-signing-heading"
      >
        {completedSteps === 2
          ? t('progressTracker.bankSetup.documentSuccessfullyCompleted')
          : t('progressTracker.bankSetup.documentSigning')}
      </Typography>
      <Typography variant="body1" sx={{ color: 'text.secondary' }}>
        {getVerbiage()}
      </Typography>
      {completedSteps !== 2 && (
        <FormationsPrimaryButton
          size="large"
          href={rightSignatureURL}
          sx={{ marginTop: '24px' }}
          disabled={!rightSignatureURL}
          data-testid="document-signing-sign-btn"
          onClick={() =>
            sendProgressTrackerEvent({
              stage: ProgressTrackerStages.DocumentSigning,
              accountId,
              entityType: accountEntity,
            })
          }
        >
          {t('progressTracker.bankSetup.viewSignDocuments')}
        </FormationsPrimaryButton>
      )}
    </div>
  );
};

DocumentSigning.defaultProps = {
  rightSignatureURL: '',
};
