import { Alert, Box, Typography } from "@mui/material";
import { AccountPreferenceSetting } from "components/IncorporationStatus/AccountPreferenceSetting";
import { IAccount, ProgressTrackerStatus } from "models/account";
import { useGetRelayAccountApplication } from "hooks/api";
import { useEffect, useMemo } from "react";
import { FormationsPrimaryButton } from "components/common/Buttons";
import { RelayBankingStatusStepper } from "./RelayBankingStatusStepper";

interface Props {
  account: IAccount;
  handleComplete: (status: ProgressTrackerStatus, eventData: Object) => void;
  onContinueToNextSection: () => void;
}

export const RelayBankingApplicationStatus = ({
  account,
  handleComplete,
  onContinueToNextSection
}: Props) => {
  const { relayAccountApplication } = useGetRelayAccountApplication(account.id);

  const emailSent = useMemo(() => 
    relayAccountApplication?.relayApplicationStatus === 'application.submitted'
  , [relayAccountApplication]);

  const relayApplicationCompleted = useMemo(() =>
    relayAccountApplication?.relayApplicationStatus === 'application.approved'
  , [relayAccountApplication]);

  useEffect(() => {
    if (relayApplicationCompleted) {
      handleComplete(ProgressTrackerStatus.Completed, {});
    }
  }, [relayApplicationCompleted]);

  const message = emailSent
    ? 'An email was sent to you to complete your registration'
    : 'Your Relay Banking Application Progress';

  const emailAlert = emailSent && (
    <Alert severity="warning" icon={false} sx={{ mt: 2, mb: 2, p: 2 }}>
      Please follow the instructions in the email to complete your banking registration.
    </Alert>
  );

  return (
    <Box sx={{ mb: 3 }}>
      <Typography
        variant="subtitle1MB"
        color="primary.main"
        sx={{ mt: 2, mb: 2, textAlign: 'center' }}
        component="h2"
      >
        {message}
      </Typography>
      <Box sx={{ p: 3 }}>
        <RelayBankingStatusStepper relayAccountApplication={relayAccountApplication} />
        {emailAlert}
        {relayApplicationCompleted ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <FormationsPrimaryButton
              onClick={onContinueToNextSection}
            >
              Continue to Business Packet
            </FormationsPrimaryButton>
          </Box>
        ) : (
          <AccountPreferenceSetting email={account.ownerEmail ?? ''} accountId={account.id} title="Relay banking"/>
        )}
      </Box>
    </Box>
  );
};