import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material';
import { useController, UseFormMethods } from 'react-hook-form';
import { IFormField } from './types';

export interface TCheckboxOptions {
  label: string;
  value: any;
}

interface TFormationsCheckboxOptions {
  options: Array<TCheckboxOptions>;
}

type TFormationsCheckboxList = {
  field: IFormField<TFormationsCheckboxOptions>;
  formInstance: UseFormMethods;
};

export const FormationsCheckboxList = ({
  field,
  formInstance,
}: TFormationsCheckboxList) => {
  const { control, errors } = formInstance;
  const { options: { options = [] } = {} } = field;

  const { field: controlledField } = useController({
    name: field.name,
    control,
    defaultValue: field?.defaultValue || [],
  });

  const { value, onChange } = controlledField;

  return (
    <>
      <FormLabel component="legend" error={!!errors[field?.name]}>
        <Typography variant="body2B" color="text.primary" component="p" sx={{mb: 1}}>{field.label}</Typography>
      </FormLabel>
      {options?.map((option: TCheckboxOptions) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          label={<Typography variant="body3S" sx={{fontSize: '14px' }}>{option.label}</Typography>}
          sx={{ display: 'flex' }}
          control={
            <Checkbox
              checked={value?.some((val: string) => val === option.value)}
              onChange={(e) => {
                let valueCopy = [...value];
                if (e.target.checked) {
                  valueCopy = [...value, option.value]; // append to array
                } else {
                  const idx = valueCopy.findIndex(
                    (val: string) => val === option.value,
                  );
                  valueCopy.splice(idx, 1); // remove from array
                }
                onChange(valueCopy); // update form field with new array
              }}
              inputProps={{
                // @ts-ignore
                'data-testid': `field-${option.value}`,
              }}
              sx={{ ml: 2 }}
            />
          }
        />
      ))}
      <FormHelperText error={!!errors[field?.name]}>
        {errors[field?.name]?.message}
      </FormHelperText>
    </>
  );
};
