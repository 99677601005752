import makeStyles from '@mui/styles/makeStyles';
import { Button, Typography } from '@mui/material';
import { RenderPDF } from 'components/common/ViewPDFSDK';
import { downloadFileBlob } from 'helpers/documents';
import { IFormationsPreviewDocument } from './FilePreviewComponent';

const useStyles = makeStyles(() => ({
  imagePreview: {
    maxWidth: '100%',
    height: '80vh',
    objectFit: 'contain',
  },
}));

interface Props {
  fileType: string;
  fileBlob: Blob;
  file?: IFormationsPreviewDocument;
  unsupportedType: boolean;
}

interface IPreviewProps {
  fileBlob: Blob;
  file?: IFormationsPreviewDocument;
  fileType: string;
}

const PreviewComponent = ({ fileBlob, file, fileType }: IPreviewProps) => {
  const classes = useStyles();
  let component = (
    <img
      className={classes.imagePreview}
      alt="Document preview"
      src={fileBlob && window.URL.createObjectURL(fileBlob)}
    />
  );
  if (fileType === 'pdf') {
    component = <RenderPDF blob={fileBlob} filename={file?.title ?? 'File'} />;
  }
  if (fileType === 'mp4') {
    component = (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        className={classes.imagePreview}
        controls
        src={fileBlob && window.URL.createObjectURL(fileBlob)}
      >
        Your browser does not support the HTML5 Video element.
      </video>
    );
  }
  return component;
};

export const FilePreview = (props: Props) => {
  const { fileBlob, fileType, file, unsupportedType } = props;

  return unsupportedType ? (
    <Typography variant="body2">
      Preview of this document format is not supported.
      <Button
        onClick={(e) => {
          e.preventDefault();
          downloadFileBlob(fileBlob, file?.title ?? '');
        }}
      >
        Please download this file for review.
      </Button>
    </Typography>
  ) : (
    <PreviewComponent fileBlob={fileBlob} file={file} fileType={fileType} />
  );
};
