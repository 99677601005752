import { useMutation, useQuery } from "react-query";
import { RelayApplicationStatus, createRelayAccountApplication, getRelayAccountApplication } from "services/relay";


export const useCreateRelayAccountApplication = (accountId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(() =>
    createRelayAccountApplication(accountId),
  );

  return {
    createRelayAccountApplication: mutate,
    createRelayAccountApplicationAsync: mutateAsync,
    ...rest,
  };
};

export const useGetRelayAccountApplication = (accountId: string) => {
  const { data, ...rest } = useQuery<RelayApplicationStatus>(
    ['relayAccountApplication', accountId],
    () => getRelayAccountApplication(accountId),
    {
      enabled: !!accountId,
    },
  );

  return {
    relayAccountApplication: data,
    ...rest,
  };
}