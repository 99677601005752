import React, { useEffect, useState } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import { PaymentType, PaymentSource } from 'enums';
import { ArrowDropDownIcon } from 'components/common/Icon';
import { FilterPopover } from 'components/common/FormationsFilters/FilterPopover';
import { TaxesFilters, useTaxesQuery } from 'hooks/api/useTaxes';
import { styled } from '@mui/material/styles';

export const highlightCSS = {
  backgroundColor: 'others.newYellow',
  '&:hover': { backgroundColor: 'others.newYellow' },
};

const isHighlighted = (query: TaxesFilters): boolean =>
  (query.type !== undefined && query.type !== null) ||
  (query.source !== undefined && query.source !== null);

export const FilterButton = styled(FormationsGhostButton)({
  width: '24px',
  height: '24px',
});

export const TypeAndSourceFilterCell = () => {
  const [query, setQuery] = useTaxesQuery();
  const { type, source } = query;
  // popover state
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // radio group state
  const [radioValueSource, setRadioValueSource] = useState<PaymentSource | undefined>(
    undefined,
  );
  const [radioValueType, setRadioValueType] = useState<PaymentType | undefined>(
    undefined,
  );

  const openPopover = (btn: HTMLButtonElement | null) => {
    setAnchorEl(btn);
  };

  const confirmFilter = () => {
    setQuery({
      source: radioValueSource,
      type: radioValueType,
      page: undefined,
    });
    setAnchorEl(null);
  };

  const onRadioChangeSource = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValueSource((event.target as HTMLInputElement).value as PaymentSource);
  };

  const onRadioChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValueType((event.target as HTMLInputElement).value as PaymentType);
  };

  useEffect(() => {
    setRadioValueSource(source ?? undefined);
    setRadioValueType(type ?? undefined);
  }, [type, source]);

  return (
    <>
      <FilterButton
        rounded
        onClick={(e: { currentTarget: HTMLButtonElement | null }) =>
          openPopover(e.currentTarget)
        }
        data-testid="type-filter-btn"
        sx={isHighlighted(query) ? highlightCSS : {}}
      >
        <ArrowDropDownIcon />
      </FilterButton>
      <FilterPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClear={() => {
          setAnchorEl(null);
          setQuery({ source: undefined, type: undefined, page: undefined });
          setRadioValueType(undefined);
          setRadioValueSource(undefined);
        }}
        onClose={() => {
          setAnchorEl(null);
          setRadioValueType(type ?? undefined);
          setRadioValueSource(source ?? undefined);
        }}
        onConfirm={confirmFilter}
        saveDisabled={source === radioValueSource && type === radioValueType}
        clearDisabled={!radioValueSource && !radioValueType}
      >
        <Typography sx={{ m: 3 }} variant="body2B" component="h6">
          Source of Payment
        </Typography>
        <RadioGroup value={radioValueSource} onChange={onRadioChangeSource}>
          <FormControlLabel
            value={PaymentSource.Direct}
            control={<Radio />}
            label="Direct"
            componentsProps={{
              typography: {
                variant: 'body2S',
              },
            }}
          />
          <FormControlLabel
            value={PaymentSource.Payroll}
            control={<Radio />}
            label="Payroll"
            componentsProps={{
              typography: {
                variant: 'body2S',
              },
            }}
          />
        </RadioGroup>
        <Typography sx={{ m: 3 }} variant="body2B" component="h6">
          Type of Payment
        </Typography>
        <RadioGroup value={radioValueType} onChange={onRadioChangeType}>
          <FormControlLabel
            value={PaymentType.Federal}
            control={<Radio />}
            label="IRS"
            componentsProps={{
              typography: {
                variant: 'body2S',
              },
            }}
          />
          <FormControlLabel
            value={PaymentType.State}
            control={<Radio />}
            label="State"
            componentsProps={{
              typography: {
                variant: 'body2S',
              },
            }}
          />
        </RadioGroup>
      </FilterPopover>
    </>
  );
};
