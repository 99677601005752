import {
  Container,
  Stepper,
} from '@mui/material';
import { Step, StepLabel, StepIcon } from 'components/common';
import { useMemo } from 'react';
import { RelayApplicationStatus } from 'services/relay';

enum RelayBankingStatus {
  SUBMITTED = 0,
  EMAIL_SENT = 1,
  UNDER_REVIEW = 2,
  APPROVED = 3,
}

export const RelayBankingStatusStepper = ({
  relayAccountApplication,
}: {
  relayAccountApplication: RelayApplicationStatus | undefined;
}) => {

  const status = relayAccountApplication?.relayApplicationStatus ?? '';

  const stepNum = useMemo(() => {
    switch (status) {
      case 'IN_PROGRESS':
        return RelayBankingStatus.SUBMITTED;
      case 'application.submitted':
        return RelayBankingStatus.EMAIL_SENT;
      case 'application.inviteAccepted':
      case 'application.underReview':
      case 'application.awaitingDocuments':
        return RelayBankingStatus.UNDER_REVIEW;
      case 'application.approved':
        return RelayBankingStatus.APPROVED;
      default:
        return RelayBankingStatus.SUBMITTED;
    }
  }, [status]);

  return (
    <Container sx={{ width: 900 }}>
      <Stepper>
        {status === 'ACTION_NEEDED' ? (
          <Step data-testid="status-action-required">
            <StepLabel StepIconComponent={StepIcon} error>
              Action Required
            </StepLabel>
          </Step>
        ) : (
          <Step
            data-testid="status-submitted"
            completed={stepNum >= RelayBankingStatus.SUBMITTED}
          >
            <StepLabel StepIconComponent={StepIcon}>Submitted</StepLabel>
          </Step>
        )}
        <Step
          data-testid="status-email-sent"
          completed={stepNum >= RelayBankingStatus.EMAIL_SENT}
        >
          <StepLabel StepIconComponent={StepIcon}>
            Email Sent
          </StepLabel>
        </Step>
        <Step
          data-testid="status-under-review"
          completed={stepNum >= RelayBankingStatus.UNDER_REVIEW}
        >
          <StepLabel StepIconComponent={StepIcon}>
            Under Review
          </StepLabel>
        </Step>
        <Step
          data-testid="status-approved"
          completed={stepNum >= RelayBankingStatus.APPROVED}
        >
          <StepLabel StepIconComponent={StepIcon}>Approved</StepLabel>
        </Step>
      </Stepper>
    </Container>
  );
};