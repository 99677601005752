import { ENTITY_MAPPING } from 'constants/common';
import {
  IAccount,
  ProgressTrackerGroup,
  ProgressTrackerGroups,
  ProgressTrackerStages,
  ProgressTrackerStatus,
} from 'models/account';
import { findByGroupAndStage, getLatestStatus } from 'helpers/progressTracker';
import i18n from 'translations/i18n';

export const initialState = (entityType: string, isMultiMemberOwned: boolean, isBIB: boolean = false) => ({
  [ProgressTrackerStages.PersonalDetails]: {
    data: {},
    updated_at: null,
    status: null,
    label: i18n.t('progressTracker.incorporation.step.personalDetails'),
  },
  [ProgressTrackerStages.CompanyDetails]: {
    data: {},
    updated_at: null,
    status: null,
    label: i18n.t('progressTracker.incorporation.step.companyDetails'),
  },
  [ProgressTrackerStages.AddressDetails]: {
    data: {},
    updated_at: null,
    status: null,
    label: i18n.t('progressTracker.incorporation.step.businessAddress'),
  },
  ...(isMultiMemberOwned && {
    [ProgressTrackerStages.OwnershipDetails]: {
      data: {},
      updated_at: null,
      status: null,
      label: i18n.t('progressTracker.incorporation.step.ownershipDetails')
    }
  }),
  ...((entityType === ENTITY_MAPPING.sole_prop || isBIB) && {
    [ProgressTrackerStages.FilingDocuments]: {
      data: {},
      updated_at: null,
      status: null,
      label: i18n.t('progressTracker.incorporation.step.filingDocuments'),
    },
  }),
});

export const reducer = (
  state: any,
  action: { type: ProgressTrackerStages; value: ProgressTrackerGroup[] },
) => {
  switch (action.type) {
    case ProgressTrackerStages.PersonalDetails: {
      const data = findByGroupAndStage(action.value, {
        group: ProgressTrackerGroups.Incorporation,
        stage: ProgressTrackerStages.PersonalDetails,
      });

      return {
        ...state,
        [ProgressTrackerStages.PersonalDetails]: {
          ...state[ProgressTrackerStages.PersonalDetails],
          data,
          ...getLatestStatus(data?.status),
        },
      };
    }
    case ProgressTrackerStages.CompanyDetails: {
      const data = findByGroupAndStage(action.value, {
        group: ProgressTrackerGroups.Incorporation,
        stage: ProgressTrackerStages.CompanyDetails,
      });

      return {
        ...state,
        [ProgressTrackerStages.CompanyDetails]: {
          ...state[ProgressTrackerStages.CompanyDetails],
          data,
          ...getLatestStatus(data?.status),
        },
      };
    }
    case ProgressTrackerStages.AddressDetails: {
      const data = findByGroupAndStage(action.value, {
        group: ProgressTrackerGroups.Incorporation,
        stage: ProgressTrackerStages.AddressDetails,
      });

      return state[ProgressTrackerStages.AddressDetails]
        ? {
            ...state,
            [ProgressTrackerStages.AddressDetails]: {
              ...state[ProgressTrackerStages.AddressDetails],
              data,
              ...getLatestStatus(data?.status),
            },
          }
        : state;
    }
    case ProgressTrackerStages.OwnershipDetails: {
      const data = findByGroupAndStage(action.value, {
        group: ProgressTrackerGroups.Incorporation,
        stage: ProgressTrackerStages.OwnershipDetails,
      });
    
      const newState = {
        ...state,
        [ProgressTrackerStages.OwnershipDetails]: {
          ...(state[ProgressTrackerStages.OwnershipDetails] || {
            data: {},
            updated_at: null,
            status: null,
            label: i18n.t('progressTracker.incorporation.step.ownershipDetails')
          }),
          data,
          ...getLatestStatus(data?.status),
        },
      };
    
      if (!state[ProgressTrackerStages.OwnershipDetails]) {
        const orderedState: { [key: string]: any } = {};
        const keys = Object.keys(newState);
        const addressDetailsIndex = keys.indexOf(ProgressTrackerStages.AddressDetails);
      
        keys.forEach((key, index) => {
          orderedState[key] = newState[key];
          if (index === addressDetailsIndex) {
            orderedState[ProgressTrackerStages.OwnershipDetails] = newState[ProgressTrackerStages.OwnershipDetails];
          }
        });
      
        return orderedState;
      }
    
      return newState;
    }
    case ProgressTrackerStages.FilingDocuments: {
      const data = findByGroupAndStage(action.value, {
        group: ProgressTrackerGroups.Incorporation,
        stage: ProgressTrackerStages.FilingDocuments,
      });

      return state[ProgressTrackerStages.FilingDocuments]
        ? {
            ...state,
            [ProgressTrackerStages.FilingDocuments]: {
              ...state[ProgressTrackerStages.FilingDocuments],
              data,
              ...getLatestStatus(data?.status),
            },
          }
        : state;
    }
    default:
      return state;
  }
};

export const getProgressTrackerStageStatus = (
  account: IAccount | null,
  state: ProgressTrackerStages,
): ProgressTrackerStatus | null => {
  const data = account?.progress?.find((item) => item.stage === state);
  if (data) {
    return getLatestStatus(data.status)?.status ?? null;
  }
  return null;
};

export const isProgressTrackerStageCompleted = (
  account: IAccount | null,
  state: ProgressTrackerStages,
): boolean => {
  const data = account?.progress?.find((item) => item.stage === state);
  return data
    ? getLatestStatus(data.status)?.status === ProgressTrackerStatus.Completed
    : false;
};

// additional owner needed for community states logic 
const communityStateCodes = ['AZ', 'CA', 'ID', 'LA', 'NV', 'NM', 'TX', 'WA', 'WI'];

export const needAdditionalOwner = (
  state: string | undefined, 
  taxFiling: "SINGLE" | "MARRIED_FILING_JOINTLY" | "MARRIED_FILING_SEPARATELY" | "HEAD_OF_HOUSEHOLD" | "WIDOW_WITH_DEPENDENT_CHILD" | undefined
) => {
  if (taxFiling !== "MARRIED_FILING_JOINTLY" && taxFiling !== "MARRIED_FILING_SEPARATELY") {
    return false
  }
  if (state !== undefined) {
    if (communityStateCodes.includes(state)) {
      return true
    }
  }
  return false
}
