import React, { useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import { FormationsTableDocument } from 'components/common/tables/DocumentsTable';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { VisibilityPopup } from 'components/documentsV1/VisibilityPopup';
import { useUpdateDocument } from 'hooks/api';
import { showToastOnError } from 'components/toast/showToast';
import { queryClient } from 'states/reactQueryClient';

export const DocumentVisibilityCell = ({
  document,
}: {
  document: FormationsTableDocument;
}) => {
  const [isVisibilityModalOpen, setIsVisibilityModalOpen] = useState(false);

  const { mutateAsync: updateDocument } = useUpdateDocument({
    onSuccess: () =>
      queryClient.invalidateQueries([
        'documents',
        'accountId',
        document.accountId,
      ]),
  });

  const Icon = useMemo(() => {
    if (!document.visibleToCustomer) {
      return VisibilityOffIcon;
    }
    if (document.isVisibilityEditable) {
      return VisibilityIcon;
    }
    return VisibilityIcon;
  }, [document]);

  const handleToggleVisibility = () => {
    if (document.isVisibilityEditable) {
      setIsVisibilityModalOpen(true);
    }
  };
  const closeVisibilityPopup = () => {
    setIsVisibilityModalOpen(false);
  };
  const onVisibilityChange = async () => {
    try {
      await updateDocument({
        id: document.id,
        form: {
          visibleToCustomer: !document.visibleToCustomer,
        },
      });
    } catch (e) {
      showToastOnError(e);
    }
    closeVisibilityPopup();
  };
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <FormationsGhostButton
          rounded
          disabled={
            document.visibleToCustomer && !document.isVisibilityEditable
          }
          onClick={handleToggleVisibility}
          sx={{
            '&.MuiButton-root': {
              color: 'gray.100',
            },
            '&.MuiButton-root:hover': {
              color: '#FFFFFF',
            },
          }}
        >
          <Icon />
        </FormationsGhostButton>
      </Grid>
      <VisibilityPopup
        open={isVisibilityModalOpen}
        status={!document.visibleToCustomer || false}
        onSave={onVisibilityChange}
        onClose={closeVisibilityPopup}
      />
    </>
  );
};
