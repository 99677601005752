import {
  Step as MuiStep,
  stepClasses,
  StepLabel as MuiStepLabel,
  stepLabelClasses,
} from '@mui/material';
import { styled } from '@mui/styles';
import { StepIconProps } from '@mui/material/StepIcon';
import { CheckCircleIcon, WarningIcon } from 'components/common/Icon';

const StepIconRoot = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  height: 20,
  width: 20,
  alignItems: 'center',
  '& .customStepIcon-warningIcon': {
    color: theme.palette.warning.main,
  },
  '& .customStepIcon-completedIcon': {
    color: theme.palette.success.main,
  },
  '& .customStepIcon-circle': {
    borderRadius: '50%',
    backgroundColor: 'transparent',
    width: 14,
    height: 14,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export const Step = styled(MuiStep)(({ theme }) => ({
  [`&.${stepClasses.completed}`]: {
    [`& .${stepLabelClasses.label}`]: {
      color: `${theme.palette.text.primary}`,
    },
  },
}));

export const StepLabel = styled(MuiStepLabel)(({ theme }) => ({
  [`&.${stepLabelClasses.error}`]: {
    [`& .${stepLabelClasses.label}`]: {
      color: theme.palette.warning.main,
    },
  },
  [`& .${stepLabelClasses.label}`]: {
    fontSize: '12px',
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}));

export const StepIcon = (props: StepIconProps) => {
  const { completed, className, error } = props;

  let icon = <div className="customStepIcon-circle" />;
  if (completed) {
    icon = (
      <CheckCircleIcon
        size={18}
        fill={1}
        className="customStepIcon-completedIcon"
      />
    );
  } else if (error) {
    icon = (
      <WarningIcon size={18} fill={1} className="customStepIcon-warningIcon" />
    );
  }

  return <StepIconRoot className={className}>{icon}</StepIconRoot>;
};