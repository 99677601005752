import makeStyles from '@mui/styles/makeStyles';
import { IAction } from 'components/common/sidebar/constant';
import React, { PropsWithChildren, useState } from 'react';
import { Box, Fab, Modal } from '@mui/material';
import { MenuIcon } from 'components/common/Icon';
import { HeaderMobile } from './HeaderMobile';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: '#2a62ff',
    '&:hover': {
      backgroundColor: '#2a62ff',
    },
    zIndex: 1400,
  },
  modal: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(2),
    backgroundColor: 'white',
    border: 'none',
    outline: 'none',
    maxHeight: '80vh',
    overflowY: 'auto',
    boxShadow: '0px 2px 10px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
  },
  container: {
    overflowY: 'auto',
    position: 'relative',
  },
}));

interface Props {
  actions: IAction[];
}

export const HamburgerMenuMobile = ({
  children,
  actions,
}: PropsWithChildren<Props>) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Fab 
        color="primary" 
        aria-label="add" 
        onClick={handleOpen}
        className={classes.fab}
      >
        <MenuIcon />
      </Fab>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        BackdropProps={{ invisible: true }}
      >
        <Box className={classes.modal}>
          <HeaderMobile actions={actions} handleClose={handleClose} />
        </Box>
      </Modal>
      <Box sx={{ flexGrow: 1, mb: 3 }} className={classes.container}>
        {children}
      </Box>
    </>
  );
};
