import { StaticDatePicker } from '@mui/lab';
import { Controller, UseFormMethods } from 'react-hook-form';
import { TextField, Box, TextFieldProps } from '@mui/material';
import { get } from 'lodash';
import moment from 'moment';
import { IFormField } from './types';

interface Options {
  placeholder?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  format?: string;
  minDate?: string;
  maxDate?: string;
}

type TFormationsStaticDateField = {
  field: IFormField<Options>;
  formInstance: UseFormMethods;
};

export const FormationsStaticDatePicker = ({
  field,
  formInstance,
}: TFormationsStaticDateField) => {
  const { control, errors } = formInstance;
  const {
    disabled = false,
    options: {
      placeholder = '',
      disableFuture = false,
      disablePast = false,
      format = 'MM/DD/YYYY',
      minDate,
      maxDate,
    } = {},
  } = field;

  const errorMsg = get(errors, [field.name, 'message'], '');
  return (
    <Controller
      control={control}
      name={field.name}
      defaultValue={field.defaultValue || null}
      render={({ onChange, value }) => (
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          disablePast={disablePast}
          disableFuture={disableFuture}
          disabled={disabled}
          inputFormat={format}
          toolbarTitle="Select date"
          value={value}
          views={['day']}
          onChange={onChange}
          minDate={minDate ? moment(minDate) : minDate}
          maxDate={maxDate ? moment(maxDate) : maxDate}
          defaultCalendarMonth={minDate ? moment(minDate) : minDate}
          showDaysOutsideCurrentMonth
          renderInput={(props: TextFieldProps) => (
            <>
              <TextField
                {...props}
                name={field.name}
                label={field.label}
                placeholder={placeholder}
                fullWidth
                error={!!errorMsg}
                helperText={errorMsg}
                data-testid={`field-${field.name}`}
              />
              <Box sx={{ mx: 2 }}> to </Box>
            </>
          )}
        />
      )}
    />
  );
};
