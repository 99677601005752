import moment from 'moment';
import { AuthService } from 'services';
import { FormationsDocument } from 'services/documentTypes';
import { FormationsTableDocument } from 'components/common/tables/DocumentsTable';

export enum EDocumentStatus {
  'Approved' = 'Approved',
  'Submitted' = 'Submitted',
  'Rejected' = 'Rejected',
}

export type { FormationsDocument };

export const useDocumentsTableData = (
  documents: FormationsDocument[],
): FormationsTableDocument[] =>
  documents.map((item: FormationsDocument) => {
    const getName = () => {
      let result = '';
      if (item.uploader?.role === 'Admin') {
        if (!AuthService.isAdmin()) {
          return 'Formations';
        }
      }
      if (item.uploader?.preferredName) {
        result = item.uploader?.preferredName;
      } else if (item.uploader?.name?.first && item.uploader?.name?.last) {
        result = `${item.uploader?.name?.first} ${item.uploader?.name?.last}`;
      } else if (item.uploader?.email) {
        result = item.uploader?.email;
      }
      return result;
    };

    const canEditVisibility = () => {
      if (!AuthService.isAdmin()) return false;
      // allow handle super admin case
      return item.uploader.role !== 'Customer';
    };

    return {
      id: item.id,
      title: item.title,
      forYear: item.forYear,
      companyId: item.companyId,
      department: item.documentCategory?.department,
      category: item.documentCategory?.category,
      subcategory: item.documentCategory?.subcategory,
      visibleToCustomer: item.visibleToCustomer || false,
      isVisibilityEditable: canEditVisibility(),
      uploaded: `${moment
        .utc(item.uploadedAt)
        .local()
        .format('MMM DD, YYYY')} by ${getName()}`,
      uploadedAt: item.uploadedAt,
      role: item.uploader?.role,
      status: item.status,
      statusReason: item?.statusReason ?? '',
      metadata: item.metadata,
      hasNotes: item.hasNotes,
      adminUnread: item.adminUnread,
      customerUnread: item.customerUnread,
      accountId: item.accountId,
      uploader: item.uploader,
      customerReadCount: item.customerReadCount,
    };
  });
