import { useCallback, useMemo } from 'react';
import { Grid, Typography, styled } from '@mui/material';
import { IAccount } from 'models/account';
import { Company } from 'models/company';
import {
  FormationsTertiaryButton,
  FormationsPrimaryButton,
} from 'components/common/Buttons';
import { useNavigate } from 'hooks/api';
import { Routes } from 'fnRoutes';
import saveTimeAndMoneyImg from '../../../icons/Save_Time_And_Money_1.png';
import { isAccountBusinessInABox } from '../util';
import { getBIBCheckoutPageLink, getFullSuitePlanTableID } from './helpers';

const IncorporationButton = styled(FormationsTertiaryButton)(({ theme }) => ({
  color: theme.palette.blue.primary,
  border: 'none',
  '&:hover': {
    border: 'none',
  },
}));

interface ReviewAndPayViewProps {
  account: IAccount;
  company: Company;
}

export const ReviewAndPayView = ({
  account,
  company,
}: ReviewAndPayViewProps) => {
  const navigate = useNavigate();
  const subtitleText = useMemo(() => {
    if (company.entityType !== 'Sole-Prop') {
      return "We're ready to verify your incorporation details";
    }
    return "We're ready to submit your incorporation details";
  }, [company.entityType]);

  const bodyText = useMemo(() => {
    if (company.entityType !== 'Sole-Prop') {
      return "We have all the information we need to verify your incorporation information and connect you to business banking. All that's left is to complete your payment!";
    }
    return "We have all the information needed to submit your incorporation paperwork to the state and set up your business bank account. All that's left is to complete your payment!";
  }, [company.entityType]);

  const goToCheckoutPageText = useMemo(() => {
    if (isAccountBusinessInABox(account)) {
      return 'Pay for Incorporation';
    }
    return 'Start My Subscription';
  }, [account]);

  const incorporationState = useMemo(
    () => company?.stateOfIncorporation,
    [company?.stateOfIncorporation],
  );

  const checkoutPageLink = useMemo(
    () =>
      getBIBCheckoutPageLink(
        {
          email: account?.ownerEmail ?? '',
          accountID: account?.id,
        },
        incorporationState,
      ),
    [incorporationState, account],
  );

  const fullSuitePlanTableID = useMemo(
    () => getFullSuitePlanTableID(account?.planSelection),
    [account?.planSelection],
  );

  const handlePayForIncorporationNow = useCallback( async () => {
    const link = getBIBCheckoutPageLink(
      {
        email: account?.ownerEmail ?? '',
        accountID: account?.id,
      },
      incorporationState,
    );
    window.location.href = link;
  }, [account, incorporationState]);

  const handleGoToCheckoutPage = useCallback(() => {
    if (isAccountBusinessInABox(account)) {
      window.location.href = checkoutPageLink;
    } else {
      navigate(`${Routes.PRICING_TABLE}?planTableID=${fullSuitePlanTableID}`);
    }
  }, [account, checkoutPageLink, fullSuitePlanTableID, navigate]);

  return (
    <Grid container display="flex" flexDirection="column">
      <Grid item container>
        <Grid
          item
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          xs={4}
        >
          <img
            src={saveTimeAndMoneyImg}
            alt="Save Time and Money"
            style={{ width: '238px', height: 'auto', paddingBottom: '16px' }}
          />
        </Grid>
        <Grid
          item
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          xs={8}
          gap={2}
        >
          <Typography variant="subtitle1L" color="text.primary">
            {subtitleText}
          </Typography>
          <Typography variant="body2S" color="text.secondary">
            {bodyText}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="flex-end"
        alignItems="center"
        gap={2}
        style={{ marginTop: 'auto' }}
      >
        {!isAccountBusinessInABox(account) &&
          company?.entityType === 'Sole-Prop' && (
            <IncorporationButton
              size="large"
              onClick={handlePayForIncorporationNow}
            >
              Pay for Incorporation Now: Choose Subscription Later
            </IncorporationButton>
          )}
        <FormationsPrimaryButton size="large" onClick={handleGoToCheckoutPage}>
          {goToCheckoutPageText}
        </FormationsPrimaryButton>
      </Grid>
    </Grid>
  );
};
