import { useEffect, useState } from 'react';
import { CONFIG } from 'config';

interface Props {
  url: string;
  id: string;
  type: string;
  unmountCallback?: () => void;
}

export enum ScriptStatus {
  Loading = 'loading',
  Ready = 'ready',
  Error = 'error',
}

export const useScript = ({ url, id, type, unmountCallback }: Props) => {
  // Keep track of script status ("loading", "ready", "error")
  const [status, setStatus] = useState<ScriptStatus>(ScriptStatus.Loading);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.id = id;
    script.type = type;
    script.defer = true;
    document.body.appendChild(script);

    // Script event handler to update status in state
    const setStateFromEvent = (event: Event) => {
      setStatus(
        event.type === 'load' ? ScriptStatus.Ready : ScriptStatus.Error,
      );
    };
    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);
    return () => {
      script.removeEventListener('load', setStateFromEvent);
      script.removeEventListener('error', setStateFromEvent);
      document.body.removeChild(script);
      if (unmountCallback) {
        unmountCallback();
      }
    };
  }, []);

  return status;
};

export const useHubspotScript = () =>
  useScript({
    url: '//js.hs-scripts.com/6637631.js',
    id: 'hs-script-loader',
    type: 'text/javascript',
  });

export const useChurnZero = () =>
  useScript({
    url: `https://${CONFIG.churnDomain}.us2app.churnzero.net/churnzero.js`,
    id: 'ChurnZero-script-loader',
    type: 'text/javascript',
  });

export const useHurdlrScript = () =>
  useScript({
    url: '//cdn.hurdlr.com/sdk/stable/hurdlr.js',
    id: 'hurdlr-script-loader',
    type: 'text/javascript',
  });

export const useUserPilotScript = () => {
  (window as any).userpilotSettings = {
    token: CONFIG.userpilot.apiKey,
  };
  useScript({
    url: `https://js.userpilot.io/sdk/latest.js`,
    id: 'userpilot-script-loader',
    type: 'text/javascript',
  });
};

export const useWorkNetScript = (accountId: string) => {
  // eslint-disable-next-line no-underscore-dangle
  (window as any).__WorknetWidget = {
    buttonSide: 'right',
    isDrawer: true,
    headerText: 'Smart Assistance',
    groupingId: accountId,
    customContext: { account_id: accountId },
    styles: {
      primary: '#587AFF',
      secondary: '#FFFFFF',
      width: '600px',
      zIndex: 1000,
      display: 'none',
    },
  };
  useScript({
    url: `https://app.worknet.ai/widget/56fd4c99a9e522b176b24eade43a58de3a59d0fbda77e9654df8b6bf06e86037e544adc91dcf80d2a899314d105e5d0e29d3b950ec918df5f46b883ddf9135ab.js`,
    id: 'worknet-script-loader',
    type: 'text/javascript',
    unmountCallback: () => {
      document.querySelectorAll('iframe').forEach((iframe) => iframe.remove());
    },
  });
};
