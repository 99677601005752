import { ITodoItem } from 'views/dashboard/insight/todos/types';
import { api } from './axios';

export const getTodos = async (accountID: string): Promise<ITodoItem[]> => {
  const { data } = await api.get<ITodoItem[]>('todos', {
    params: {
      accountID,
    },
  });
  return data;
};
