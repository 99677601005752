import { useState } from 'react';
import {
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  Link,
  FormHelperText,
} from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import { Controller, useFormContext } from 'react-hook-form';
import { HelpIcon } from 'components/common/Icon';
import { ALLOWED_BANKS_OPTIONS } from 'enums';
import { useTranslation } from 'react-i18next';
import { YesNoModal } from '../../common/modals';

const RELAY_PRICING_LINK =
  'https://support.relayfi.com/hc/en-us/articles/360054705132-Relay-Pricing-';

export const BankLikeToUse = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [isModelOpen, setIsModelOpen] = useState(false);

  return (
    <>
      <YesNoModal
        open={isModelOpen}
        heading={t('progressTracker.bankSetup.bankLikeToUseHeading')}
        onClose={() => setIsModelOpen(false)}
      >
        <>
          <Typography variant="body1">
            {t('progressTracker.bankSetup.ourBankPartners')}
          </Typography>
          <Typography variant="body1" component="div">
            <ul>
              <li>
                {t('progressTracker.bankSetup.allowSetupBusinessBankAccount')}
              </li>
              <li>{t('progressTracker.bankSetup.integrateWithOurSoftware')}</li>
            </ul>
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {t('progressTracker.bankSetup.customerWhoDoPersonalBanking')}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {t('progressTracker.bankSetup.relayOnTechnology')}
          </Typography>
          <Typography variant="helperText">
            *Relay{' '}
            <Link href={RELAY_PRICING_LINK} target="_blank">
              does charge
            </Link>{' '}
            for premium features like bill pay and for outgoing wires.
          </Typography>
        </>
      </YesNoModal>
      <Typography variant="h5B" component="h5">
        {t('progressTracker.bankSetup.whichBankLikeToUse')}
      </Typography>

      <FormationsGhostButton
        onClick={() => setIsModelOpen(true)}
        startIcon={<HelpIcon size={20} sx={{ pl: 0.5, pb: 0.25 }} />}
        sx={{ mt: 2 }}
      >
        How do I decide?
      </FormationsGhostButton>
      <Controller
        name="bankLikeToUse"
        control={control}
        rules={{
          required: true,
        }}
        render={({ onChange, value }) => (
          <RadioGroup
            value={value || ''}
            onChange={onChange}
            sx={{ mt: 4, mb: 4 }}
          >
            {ALLOWED_BANKS_OPTIONS.map((option) => (
              <FormControlLabel
                value={option.value}
                control={<Radio checked={option.value === value} />}
                label={option.label}
                key={option.value}
              />
            ))}
            {errors?.bankLikeToUse && (
              <FormHelperText error={errors?.bankLikeToUse}>
                Please select an option.
              </FormHelperText>
            )}
          </RadioGroup>
        )}
      />
    </>
  );
};
