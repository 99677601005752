import { useCurrentUser } from './api';
import { CONSTANTS } from '../constants/common';

export const USER_PERMISSION: { [key: string]: number } = {
  [CONSTANTS.USER_ROLES.SUPER_ADMIN]: 100,
  [CONSTANTS.USER_ROLES.ADMIN]: 50,
  [CONSTANTS.USER_ROLES.CUSTOMER]: 0,
};

export const ActionPermissions = {
  DOCUMENT_RENAME: 'DOCUMENT_RENAME',
  DOCUMENT_DELETE: 'DOCUMENT_DELETE',
  OIL_LIST_DELETE: 'OIL_LIST_DELETE',
  APPROVED_TAX_PAYMENT_DELETE: 'APPROVED_TAX_PAYMENT_DELETE',
};

const Permissions = {
  [`${ActionPermissions.DOCUMENT_RENAME}`]:
    USER_PERMISSION[CONSTANTS.USER_ROLES.ADMIN],
  [`${ActionPermissions.DOCUMENT_DELETE}`]:
    USER_PERMISSION[CONSTANTS.USER_ROLES.SUPER_ADMIN],
  [`${ActionPermissions.OIL_LIST_DELETE}`]:
    USER_PERMISSION[CONSTANTS.USER_ROLES.SUPER_ADMIN],
  [`${ActionPermissions.APPROVED_TAX_PAYMENT_DELETE}`]:
    USER_PERMISSION[CONSTANTS.USER_ROLES.SUPER_ADMIN],
};

export const usePermissions = (action: keyof typeof Permissions): boolean => {
  const { currentUser } = useCurrentUser();
  const userRole = currentUser?.role || CONSTANTS.USER_ROLES.CUSTOMER;
  const userPermission = USER_PERMISSION[userRole];
  const actionPermission = Permissions[action];
  return userPermission >= actionPermission;
};
