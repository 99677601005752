import { Alert, Theme, Typography, AlertTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    backgroundColor: theme.palette.primary.contrastText,
    borderColor: theme.palette.info.main,
    borderWidth: 1,
    borderStyle: 'solid',
    '& .MuiSvgIcon-root': {
      height: '100%',
      color: theme.palette.info.main,
    },
    '& .MuiAlert-icon': {
      alignSelf: 'flex-start',
    },
  },
}));

export const AlertInfo = ({
  titleText,
  text,
}: {
  titleText: string | ReactNode;
  text: string | ReactNode;
}) => {
  const classes = useStyles();
  return (
    <Alert severity="info" className={classes.info}>
      <AlertTitle sx={{ color: 'info.textDark' }}>{titleText}</AlertTitle>
      <Typography
        variant="body2"
        display="inline"
        sx={{ color: 'info.textDark' }}
      >
        {text}
      </Typography>
    </Alert>
  );
};
