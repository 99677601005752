import { Controller, useFormContext } from 'react-hook-form';
import { Typography, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ProvideBankName = () => {
  const {t} = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Typography variant="h5B" component="h5">
        {t('progressTracker.bankSetup.enterBusinessBankName')}
      </Typography>
      <Typography variant="body1">
        {t('progressTracker.bankSetup.ourTeamWillContact')}
      </Typography>
      <Controller
        name="bankName"
        control={control}
        rules={{
          required: { value: true, message: t('progressTracker.error.enterBankName') },
        }}
        render={({ onChange, value }) => (
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={t('progressTracker.bankSetup.yourBusinessBankName')}
            onChange={onChange}
            value={value || ""}
            inputProps={{
              maxLength: 80,
            }}
            error={errors?.bankName}
            helperText={errors?.bankName?.message}
            sx={{ maxWidth: '442px', mt: 4, mb: 4 }}
          />
        )}
      />
    </>
  );
};
