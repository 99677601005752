import { useEffect, useMemo, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import { ArrowDropDownIcon } from 'components/common/Icon';
import { FilterPopover } from 'components/common/FormationsFilters/FilterPopover';
import { DocumentFilters, useDocumentQuery } from 'hooks/api';
import { styled } from '@mui/material/styles';

export const highlightCSS = {
  backgroundColor: 'others.newYellow',
  '&:hover': { backgroundColor: 'others.newYellow' },
};

export const isHighlighted = (
  column: string,
  query: DocumentFilters,
): boolean => {
  switch (column) {
    case 'documentHasNotes':
      return query.documentHasNotes !== undefined;
    default:
      return false;
  }
};

export const FilterButton = styled(FormationsGhostButton)({
  width: '24px',
  height: '24px',
});

export enum NoteFilterType {
  withNote = 'withNote',
  withoutNote = 'withoutNote',
}

const queryToNoteFilterBoolean = (
  filterType?: NoteFilterType,
): boolean | undefined => {
  if (filterType) {
    if (filterType === NoteFilterType.withNote) {
      return true;
    }
    return false;
  }
  return undefined;
};

const noteFilterBooleanToValue = (
  query?: boolean | null,
): NoteFilterType | undefined => {
  if (typeof query === 'boolean') {
    return query ? NoteFilterType.withNote : NoteFilterType.withoutNote;
  }
  return undefined;
};

export const DocumentNoteFilter = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = (btn: HTMLButtonElement | null) => {
    setAnchorEl(btn);
  };
  const [query, setQuery] = useDocumentQuery();
  const { documentHasNotes } = query;

  const [noteFilterType, setNoteFilterType] = useState<NoteFilterType>();
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const confirmFilter = () => {
    setQuery({
      documentHasNotes: queryToNoteFilterBoolean(noteFilterType),
      page: undefined,
    });
    setAnchorEl(null);
    setIsDirty(false);
  };

  const updateNoteFilterType = (newType: NoteFilterType) => {
    setNoteFilterType(newType);
    setIsDirty(queryToNoteFilterBoolean(newType) !== documentHasNotes);
  };

  const iconCSS = useMemo<object>(
    () => (isHighlighted('documentHasNotes', query) ? highlightCSS : {}),
    [documentHasNotes],
  );

  useEffect(() => {
    setNoteFilterType(noteFilterBooleanToValue(documentHasNotes));
  }, [documentHasNotes]);

  return (
    <>
      <FilterButton
        rounded
        onClick={(e: { currentTarget: HTMLButtonElement | null }) =>
          openPopover(e.currentTarget)
        }
        data-testid="type-filter-btn"
        sx={iconCSS}
      >
        <ArrowDropDownIcon />
      </FilterButton>
      <FilterPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClear={() => {
          setAnchorEl(null);
          setQuery({ documentHasNotes: undefined, page: undefined });
          setNoteFilterType(undefined);
          setIsDirty(false);
        }}
        onClose={() => {
          setAnchorEl(null);
          setNoteFilterType(noteFilterBooleanToValue(documentHasNotes));
          setIsDirty(false);
        }}
        onConfirm={confirmFilter}
        saveDisabled={!isDirty}
        clearDisabled={!noteFilterType}
      >
        <Typography sx={{ m: 3 }} variant="body2B" component="h6">
          Transaction type
        </Typography>
        <RadioGroup
          onChange={(event) =>
            updateNoteFilterType(event.target.value as NoteFilterType)
          }
        >
          <FormControlLabel
            value={NoteFilterType.withNote}
            checked={noteFilterType === NoteFilterType.withNote}
            control={<Radio />}
            label="only with note"
            componentsProps={{
              typography: {
                variant: 'body2S',
              },
            }}
          />
          <FormControlLabel
            value={NoteFilterType.withoutNote}
            checked={noteFilterType === NoteFilterType.withoutNote}
            control={<Radio />}
            label="only without note"
            componentsProps={{
              typography: {
                variant: 'body2S',
              },
            }}
          />
        </RadioGroup>
      </FilterPopover>
    </>
  );
};
