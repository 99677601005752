import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

interface TokenDecoded {
  permissions: string[];
}

export const PERMISSIONS = {
  DeleteGustoAuth: 'delete:gusto_auth:all',
  ReadGustoAuth: 'read:gusto_auth:all',
  UpdateGustoAuth: 'update:gusto_auth:all',
  WriteGustoAuth: 'write:gusto_auth:all',
};

export const usePermissions = () => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => jwtDecode<TokenDecoded>(token))
      .then((decodedToken) => setPermissions(decodedToken.permissions))
      .catch((error) => console.error(error));
  }, []);

  const hasPermission = useCallback(
    (permission: string) => permissions.includes(permission),
    [permissions],
  );

  return {
    permissions,
    hasPermission,
  };
};
