import { Controller, UseFormMethods } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { get } from 'lodash';
import { IFormField } from './types';

export interface TAutocompleteOptions {
  name: string;
  value: any;
}
interface TFormationsAutoCompleteOptions {
  placeholder?: string;
  options?: Array<TAutocompleteOptions>;
  groupBy?: (data: any) => string;
}

interface TFormationsAutoComplete {
  field: IFormField<TFormationsAutoCompleteOptions>;
  formInstance: UseFormMethods;
}

export const FormationsAutoComplete = ({
  field,
  formInstance,
}: TFormationsAutoComplete) => {
  const { control, errors } = formInstance;
  const { disabled = false, options: { placeholder = '', options = [], groupBy } = {} } = field;
  const errorMsg = get(errors, [field.name, 'message']);

  return (
    <Controller
      control={control}
      name={field.name}
      defaultValue={field.defaultValue}
      render={({ onChange, value }) => (
        <Autocomplete
          autoComplete
          autoHighlight
          clearOnBlur
          clearOnEscape
          handleHomeEndKeys
          openOnFocus
          {...groupBy && { groupBy } }
          onChange={(_event, item: null | TAutocompleteOptions) => {
            onChange(item?.value);
          }}
          value={options.find((item) => item.value === value) ?? null}
          options={options}
          disabled={disabled}
          getOptionLabel={(item) => item.name}
          data-testid={`autocomplete-${field.name}`}
          renderInput={(params) => (
            <TextField
              {...params}
              name={field.name}
              label={field.label}
              variant="outlined"
              placeholder={placeholder}
              error={!!errorMsg}
              helperText={errorMsg}
              data-testid={`field-${field.name}`}
              sx={{ minWidth: '256px'}}
            />
          )}
        />
      )}
    />
  );
};
