import { List, Box } from '@mui/material';
import { useFlags } from 'hooks/useFeatureFlag';
import { useCurrentUserFeatureList } from 'hooks/api/useFeatures';
import { IAction } from '../constant';
import { filterVisibleActions } from '../helpers';
import { MenuItem } from '../desktop/MenuItem';
import { AccountMenuItem } from '../desktop/AccountMenuItem';

interface Props {
  actions: IAction[];
  handleClose: () => void;
}

export const HeaderMobile = ({ actions, handleClose }: Props) => {
  // feature flags
  const flags = useFlags();
  const featureList = useCurrentUserFeatureList();
  const enabledActions = filterVisibleActions(actions, flags, featureList);

  const isAccountMenuItems = enabledActions.every(
    (item) => item.type === 'account',
  );

  return (
    <Box>
      {isAccountMenuItems ? (
        <List>
          {enabledActions.map((action) => (
            <AccountMenuItem
              key={action.id}
              {...action}
              active={action.id === 'welcome'}
              handleClose={handleClose}
            />
          ))}
        </List>
      ) : (
        <List>
          {enabledActions.map((action) => (
            <MenuItem
              key={action.id}
              {...action}
              active={action.id === 'welcome'}
              handleClose={handleClose}
            />
          ))}
        </List>
      )}
    </Box>
  );
};
