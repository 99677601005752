import { TextField } from '@mui/material';
import { ReactNode } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { IFormField } from './types';

export interface TFormationNumberOptions {
  placeholder?: string;
  maxLength?: number;
  autoFocus?: boolean;
  startAdornment?: ReactNode;
  readonly?: boolean;
  shrink?: boolean;
  autoComplete?: string;
}

export type TFormationsTextField = {
  field: IFormField<TFormationNumberOptions>;
  formInstance: UseFormMethods;
};

export const FormationsNumber = ({
  field,
  formInstance,
}: TFormationsTextField) => {
  const { control, errors } = formInstance;
  const {
    label = field.name,
    options: {
      readonly = field.disabled ?? false,
      placeholder = '',
      autoFocus = false,
      startAdornment = null,
      maxLength,
      shrink = undefined,
      autoComplete = undefined,
    } = {},
  } = field;

  return (
    <Controller
      name={field.name}
      control={control}
      defaultValue={field.defaultValue || null}
      render={({ onChange, value }) => (
        <TextField
          name={field.name}
          onChange={onChange}
          value={value}
          type="tel"
          variant="outlined"
          fullWidth
          disabled={readonly}
          label={label}
          placeholder={placeholder}
          error={!!errors[field?.name]}
          helperText={errors[field?.name]?.message}
          InputProps={{
            startAdornment,
            inputProps: {
              maxLength,
            },
          }}
          InputLabelProps={{ shrink }}
          data-testid={`field-${field.name}`}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
        />
      )}
    />
  );
};
