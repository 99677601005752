import {
  Typography,
  TableCell,
  TableRow,
  Grid,
  Card,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { EmptyState } from 'components/common/EmptyState';
import { queryClient } from 'states/reactQueryClient';
import {
  useGetDirectPayments,
  useUpdateDirectPayment,
  useTaxesQuery,
} from 'hooks/api/useTaxes';
import { Loading } from 'components/common';
import { showErrorToast, showSuccessToast } from 'components/toast/showToast';
import { FormationsFormDialog } from 'components/common/FormationsFormDialog';
import {
  FormationsFormFields,
  FormationsFormData,
} from 'components/common/FormationsForm2';
import {
  NOTE_MAX_LENGTH,
} from 'constants/common';
import * as yup from 'yup';
import { DirectPayment } from 'components/taxes/types';
import { YesNoModal } from 'components/common/modals';
import { PaymentStatus } from 'enums';
import { LoadFilePreviewDialog } from './LoadFilePreviewDialog';
import { TaxesCardCell } from './TaxesCardCell';

export type CellColumnProps = {
  row: DirectPayment;
  accountId: string | undefined;
  onViewClick: (row: DirectPayment) => void;
  onApproveClick: (row: DirectPayment) => void;
  onRejectClick: (row: DirectPayment) => void;
};

const TaxesCardContainer = styled('div')(() => ({
  flex: '1 1 auto',
  display: 'flex',
  flexFlow: 'column',
  minHeight: 100,
  width: '100%',
}));

const TaxesCardRow = styled(Card)(() => ({
  width: '100%',
  marginBottom: '30px',
  borderRadius: '10px',
  boxShadow: 'none',
  border: '1px solid lightgray',
}));

const EmptyRow = styled(TableCell)(() => ({
  borderBottom: 'none',
}));

const EmptyTable = () => (
  <TableRow data-testid="payment-empty-table">
    <EmptyRow>
      <EmptyState message="No payment information available!" />
    </EmptyRow>
  </TableRow>
);

type Props = {
  accountId: string | undefined;
  isLoadingUser?: boolean;
  setPaymentToEdit?: Function;
};

export const TaxesDirectPaymentCard = ({
  accountId,
  isLoadingUser = false,
  setPaymentToEdit = () => {},
}: Props) => {
  const { t } = useTranslation();
  // pagination and filter state
  const [query, ] = useTaxesQuery();
  // file preview dialog state
  const [selectedDocumentId, setSelectedDocumentId] = useState('');
  // admin add note and approve/reject payment state
  const [paymentToChangeStatus, setPaymentToChangeStatus] =
    useState<DirectPayment | null>(null);
  const [showAddNoteDialog, setShowAddNoteDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const {
    data: directPaymentGetData,
    isLoading: isLoadingData,
    isError: isGetError,
  } = useGetDirectPayments(accountId, query);
  const { data: directPaymentTableData } = directPaymentGetData ?? {};
  const pendingReviewData = directPaymentTableData?.filter(item => item.status === PaymentStatus.Submitted);
  const approvedData = directPaymentTableData?.filter(item => item.status === PaymentStatus.Approved);
  const rejectedData = directPaymentTableData?.filter(item => item.status === PaymentStatus.Rejected);

  const { mutateAsync: updateDirectPayment, isLoading: updatingDirectPayment } =
    useUpdateDirectPayment({
      onSuccess: async () => {
        await queryClient.invalidateQueries(['direct-payments']);
        await queryClient.invalidateQueries(['account', accountId]);
        setPaymentToChangeStatus(null);
        setShowConfirmationDialog(false);
        setShowAddNoteDialog(false);
        showSuccessToast('Successfully updated direct payment status');
      },
      onError: () => {
        showErrorToast(`Failed to update direct payment status`);
      },
    });

  useEffect(() => {
    if (isGetError) {
      showErrorToast('Failed to load direct payment information');
    }
  }, [isGetError]);

  const onYesClick = () => {
    const mutateVariables = {
      data: { status: PaymentStatus.Approved },
      accountId: accountId ?? '',
      paymentId: paymentToChangeStatus!.id,
    };
    return updateDirectPayment(mutateVariables);
  };

  const onNoteSubmit = (data: FormationsFormData) => {
    const mutateVariables = {
      data: { status: PaymentStatus.Rejected, note: data.note },
      accountId: accountId ?? '',
      paymentId: paymentToChangeStatus!.id,
    };
    return updateDirectPayment(mutateVariables);
  };

  const onViewClick = (card: DirectPayment) => {
    const { documentId } = card;
    setSelectedDocumentId(documentId);
  };

  const onCardClick = (card: DirectPayment) => {
    setPaymentToEdit(card);
  };

  if (isLoadingUser || isLoadingData) {
    return <Loading />;
  }

  return (
    <TaxesCardContainer>
      <Grid container gap={1}>
        {(!directPaymentTableData || directPaymentTableData.length === 0) && !isLoadingData ? (
          <EmptyTable />
        ) : null}
        {pendingReviewData && pendingReviewData.length > 0 && (
          <Grid container direction='column' spacing={2}>
            <Grid item>
              <Typography variant='body2B' color='primary.main'>
                Pending
              </Typography>
            </Grid>
            <Grid item>
              {pendingReviewData?.map((card: DirectPayment) => (
                <TaxesCardRow
                  key={card.id}
                  data-testid={`card-row-payment-${card.id}`}
                >
                  <TaxesCardCell
                    key={card.id}
                    card={card}
                    onCardClick={onCardClick}
                    onViewClick={onViewClick}
                  />
                </TaxesCardRow>
              ))}
            </Grid>
          </Grid>
        )}
        {rejectedData && rejectedData.length > 0 && (
          <Grid container direction='column' spacing={2}>
          <Grid item>
            <Typography variant='body2B' color='primary.main'>
              Rejected
            </Typography>
          </Grid>
          <Grid item>
            {rejectedData?.map((card: DirectPayment) => (
              <TaxesCardRow
                key={card.id}
                data-testid={`card-row-payment-${card.id}`}
              >
                <TaxesCardCell
                  key={card.id}
                  card={card}
                  onCardClick={onCardClick}
                  onViewClick={onViewClick}
                />
              </TaxesCardRow>
            ))}
          </Grid>
        </Grid>
        )}
        {approvedData && approvedData.length > 0 && (
          <Grid container direction='column' spacing={2}>
          <Grid item>
            <Typography variant='body2B' color='primary.main'>
              Paid
            </Typography>
          </Grid>
          <Grid item>
            {approvedData?.map((card: DirectPayment) => (
              <TaxesCardRow
                key={card.id}
                data-testid={`card-row-payment-${card.id}`}
              >
                <TaxesCardCell
                  key={card.id}
                  card={card}
                  onCardClick={onCardClick}
                  onViewClick={onViewClick}
                />
              </TaxesCardRow>
            ))}
          </Grid>
        </Grid>
        )}
      </Grid>
      {/* File Preview Dialog */}
      {selectedDocumentId && (
        <LoadFilePreviewDialog
          documentId={selectedDocumentId}
          setSelectedDocumentId={setSelectedDocumentId}
          accountId={accountId}
        />
      )}
      {/* Payment Rejection Add Note Dialog */}
      <FormationsFormDialog
        title={t('taxes.noteDialog.title')}
        isOpen={showAddNoteDialog}
        onClose={() => {
          setPaymentToChangeStatus(null);
          setShowAddNoteDialog(false);
        }}
        fields={[
          {
            type: FormationsFormFields.TextArea,
            name: 'note',
            label: 'Note',
            options: {
              placeholder: t('taxes.noteDialog.placeHolderText'),
              characterLimit: NOTE_MAX_LENGTH,
              autoFocus: true,
            },
          },
        ]}
        validationSchema={yup.object().shape({
          note: yup
            .string()
            .trim()
            .required('Please enter a note')
            .max(
              NOTE_MAX_LENGTH,
              `Please use less than ${NOTE_MAX_LENGTH} characters`,
            ),
        })}
        onSubmit={onNoteSubmit}
        loading={updatingDirectPayment}
      />
      {/* Confirmation Dialog */}
      <YesNoModal
        open={showConfirmationDialog}
        heading={t('taxes.confirmation.title')}
        onClose={() => {
          setPaymentToChangeStatus(null);
          setShowConfirmationDialog(false);
        }}
        onSave={onYesClick}
        isLoading={updatingDirectPayment}
      >
        <Typography variant="body1">{t('taxes.confirmationText')}</Typography>
      </YesNoModal>
    </TaxesCardContainer>
  );
};
