import { useMutation, useQuery } from 'react-query';
import {
  StripeCustomer,
  StripeSessionStatus,
  checkStripePaymentStatus,
  checkStripePlan,
  getStripeCustomer,
  linkStripeCustomer,
  listAllPlans,
  unlinkStripeCustomer,
} from 'services/stripe';
import { IAccount } from 'models/account';
import { useCurrentAccount } from './useAccounts';

export const useCheckStripePlan = (accountId: string) => {
  const { data: stripePlan, ...rest } = useQuery<
    StripeCustomer | null,
    unknown
  >(['stripePlan', accountId], () => checkStripePlan(accountId), {
    enabled: !!accountId,
  });

  return {
    stripePlan,
    ...rest,
  };
};

export const useCheckStripePaymentStatus = (
  accountId: string,
  sessionId: string,
) => {
  const { data: paymentStatus, ...rest } = useQuery<
    StripeSessionStatus,
    unknown
  >(
    ['stripePaymentStatus', accountId],
    () => checkStripePaymentStatus(accountId, sessionId),
    {
      enabled: !!sessionId && !!accountId,
    },
  );

  return {
    paymentStatus,
    ...rest,
  };
};

export const useStripePlans = () => {
  const { data: plans, ...rest } = useQuery<string[], unknown>(
    ['stripePlans'],
    () => listAllPlans(),
  );

  return {
    plans,
    ...rest,
  };
};

export const useStripeCustomer = (accountId?: string) => {
  const { data: stripeCustomers, ...rest } = useQuery<
    StripeCustomer[],
    unknown
  >(['stripeCustomers', accountId], () => getStripeCustomer(accountId), {
    enabled: !!accountId,
  });

  return {
    stripeCustomers,
    ...rest,
  };
};

export const useCurrentPaymentStatus = (account?: IAccount) => {
  const { currentAccount } = useCurrentAccount();
  const { stripePlan, ...rest } = useCheckStripePlan(
    account?.id ?? currentAccount?.id ?? '',
  );
  return {
    alreadyPaid: !!stripePlan,
    ...rest,
  };
};

export const useLinkStripeCustomer = (queryProps?: any) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    (params: { stripeId: string; accountId: string; isPrimary?: boolean }) =>
      linkStripeCustomer(params),
    queryProps,
  );
  return {
    linkStripeCustomer: mutate,
    linkStripeCustomerAsync: mutateAsync,
    ...rest,
  };
};

export const useUnlinkStripeCustomer = (queryProps?: any) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    (params: { stripeId: string; accountId: string }) =>
      unlinkStripeCustomer(params),
    queryProps,
  );
  return {
    unlinkStripeCustomer: mutate,
    unlinkStripeCustomerAsync: mutateAsync,
    ...rest,
  };
};
