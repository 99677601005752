import React, { useState } from 'react';
import {
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormHelperText,
} from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HelpIcon } from 'components/common/Icon';
import { YesNoModal } from '../../common/modals';

export const WillingToSwitch = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [isModelOpen, setIsModelOpen] = useState(false);

  return (
    <>
      <YesNoModal
        open={isModelOpen}
        heading={t('progressTracker.bankSetup.whoArePartnerBanks')}
        onClose={() => setIsModelOpen(false)}
      >
        <Typography variant="body1" component="div">
          <ul>
            <li>Chase</li>
            <li>Bank of America</li>
            <li>Relay</li>
            <li>Wells Fargo </li>
          </ul>
        </Typography>
      </YesNoModal>
      <Typography variant="h5B" component="h5">
        {t('progressTracker.bankSetup.wouldYouSwitchToPartnerBank')}
      </Typography>
      <Typography
        variant="body1"
        sx={{ mt: '8px', color: 'text.secondary' }}
      >
        {t('progressTracker.bankSetup.ourPartnerBankSeamlessIntegrate')}
      </Typography>

      <FormationsGhostButton
        startIcon={<HelpIcon size={20} sx={{ pl: 0.5, pb: 0.25 }} />}
        onClick={() => setIsModelOpen(true)}
        sx={{ mt: 2 }}
      >
        {t('progressTracker.bankSetup.whoArePartnerBanks')}
      </FormationsGhostButton>
      <Controller
        name="willingToSwitch"
        control={control}
        rules={{
          required: true,
        }}
        render={({ onChange, value }) => (
          <RadioGroup
            value={value || ''}
            onChange={onChange}
            sx={{ mt: 4, mb: 4 }}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
            {errors?.willingToSwitch && (
              <FormHelperText error={errors?.willingToSwitch}>
                {t('progressTracker.error.selectAnOption')}
              </FormHelperText>
            )}
          </RadioGroup>
        )}
      />
    </>
  );
};
