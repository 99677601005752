import { Typography } from '@mui/material';
import { SaveIcon } from 'components/common/Icon';
import { FormationsTertiaryButton } from 'components/common/Buttons';
import { queryClient } from 'states/reactQueryClient';
import { useMemo, useState } from 'react';
import moment from 'moment';
import { ReadOnlyForm } from 'components/common/ReadOnlyForm';
import { PersonalDetailsSchema } from 'schemas/personalDetailsSchema';
import {
  useCurrentAccount,
  useCurrentUser,
  UserIdentity,
  useUpdateAccountProgress,
  useUpdateUser,
  useUpdateUserIdentity,
  useUserIdentityById,
} from 'hooks/api';
import {
  FormationsFormActions,
  FormationsFormContainer,
  FormationsFormContent,
  FormationsFormFields,
  FormationsFormItem,
  IFormField,
  renderField,
} from 'components/common/FormationsForm2';
import { UIDateFormat } from 'helpers/dateTimeFormat';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserInfo } from 'services/users';
import { ReadOnlySSN } from 'components/common/ReadOnlySSN';
import { formatSsn } from 'helpers/text-transformer';
import { useTranslation } from 'react-i18next';
import {
  ProgressTrackerGroupRequest,
  ProgressTrackerGroups,
  ProgressTrackerStages,
  ProgressTrackerStatus,
} from 'models/account';
import { Company } from 'models/company';
import { isProgressTrackerStageCompleted } from 'components/ProgressTracker/Incorporation/state';

const formFields: IFormField[] = [
  {
    type: FormationsFormFields.Text,
    name: 'first',
    label: 'First Name',
    options: {
      placeholder: 'Mary',
      autoFocus: true,
      maxLength: 25,
    },
  },
  {
    type: FormationsFormFields.Text,
    name: 'middle',
    label: 'Middle Name',
    options: {
      placeholder: 'D.',
      maxLength: 25,
    },
  },
  {
    type: FormationsFormFields.Text,
    name: 'last',
    label: 'Last Name',
    options: {
      placeholder: 'Wang',
      maxLength: 25,
    },
  },
  {
    type: FormationsFormFields.DatePicker,
    name: 'dob',
    label: 'Date of Birth',
    options: {
      placeholder: 'MM/DD/YYYY',
    },
  },
  {
    type: FormationsFormFields.SecuredText,
    name: 'ssn',
    label: 'Social Security Number',
    readOnly: false,
    options: {
      placeholder: '',
      maxLength: 11,
      helperText: 'progressTracker.incorporation.ssnHelperText',
    },
  },
];

interface TParams {
  handleComplete: () => void;
  stageCompleted: boolean;
  user: UserInfo;
  userIdentity?: UserIdentity;
}

export const PersonalDetailsUI = ({
  handleComplete,
  stageCompleted,
  user,
  userIdentity,
}: TParams) => {
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  const { updateUserAsync: updateUser } = useUpdateUser({
    onSuccess: () => queryClient.invalidateQueries(['currentUser']),
  });
  const { updateUserIdentityAsync: updateSSN } = useUpdateUserIdentity({
    onSuccess: () =>
      queryClient.invalidateQueries(['user', 'identity', user.id]),
  });

  const onFormSubmit = async (formData: {
    dob: string;
    first: string;
    middle: string;
    last: string;
    ssn: string;
  }) => {
    setSubmitting(true);
    const data = {
      name: {
        first: formData.first,
        middle: formData.middle,
        last: formData.last,
      },
      dob: UIDateFormat(formData.dob),
    };
    await updateUser({ data });
    if (formData.ssn && user && !userIdentity?.ssn.last4) {
      await updateSSN({
        id: user.id,
        ssn: formatSsn(formData.ssn),
      });
    }
    handleComplete();
  };

  const formInstance = useForm({
    defaultValues: {
      first: user?.name?.first,
      middle: user?.name?.middle,
      last: user?.name?.last,
      dob: user?.dob ? moment(user.dob, 'M/D/YYYY', true) : null,
      ssn: userIdentity?.ssn.last4 ? `*****${userIdentity.ssn.last4}` : '',
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    },
    resolver: yupResolver(PersonalDetailsSchema),
    context: { isSSNRequired: !userIdentity?.ssn?.last4 },
  });

  const formFieldsRefined = formFields.map((field) => {
    const newField = { ...field };
    if (
      field.type === FormationsFormFields.SecuredText &&
      userIdentity?.ssn.last4
    ) {
      newField.readOnly = true;
    }
    return newField;
  });

  return (
    <div>
      <Typography variant="h5B" component="h5" data-test-id="personal-details">
        Personal Details
      </Typography>
      {stageCompleted ? (
        <ReadOnlyForm
          items={[
            {
              key: 'First Name',
              title: 'First Name',
              value: user?.name?.first,
            },
            {
              key: 'Middle Name',
              title: 'Middle Name',
              value: user?.name?.middle,
            },
            {
              key: 'Last Name',
              title: 'Last Name',
              value: user?.name?.last,
            },
            {
              key: 'Date of Birth',
              title: 'Date of Birth',
              value: user?.dob,
            },
            {
              key: 'Social Security Number',
              title: 'Social Security Number',
              value: (
                <ReadOnlySSN
                  value={
                    userIdentity?.ssn?.last4
                      ? `${userIdentity?.ssn?.first5}${userIdentity?.ssn?.last4}`
                      : ''
                  }
                />
              ),
            },
          ]}
        />
      ) : (
        <>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            {t('progressTracker.incorporation.knowYouBetter')}
          </Typography>
          <FormationsFormContainer>
            <form onSubmit={formInstance.handleSubmit(onFormSubmit)}>
              <FormationsFormContent>
                {formFieldsRefined.map((field) => (
                  <FormationsFormItem key={field.name}>
                    {renderField(field, formInstance)}
                  </FormationsFormItem>
                ))}
              </FormationsFormContent>
              <FormationsFormActions>
                <FormationsTertiaryButton
                  isLoading={submitting}
                  type="submit"
                  size="large"
                  data-testid="save-personal-details"
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                >
                  Save and Continue
                </FormationsTertiaryButton>
              </FormationsFormActions>
            </form>
          </FormationsFormContainer>
        </>
      )}
    </div>
  );
};

export const PersonalDetails = (
  props: Omit<TParams, 'user' | 'userIdentity'>,
) => {
  const { currentUser: user } = useCurrentUser();
  const { userIdentity } = useUserIdentityById(user?.id || '', {
    enabled: !!user?.id,
  });
  if (user != null && userIdentity != null) {
    return (
      <PersonalDetailsUI {...props} user={user} userIdentity={userIdentity} />
    );
  }
  return null;
};

interface WrapperProps {
  company?: Company;
}
export const PersonalDetailsContainer = ({
  company,
  ...props
}: WrapperProps) => {
  const { currentAccount } = useCurrentAccount();
  const { mutateAsync: updateAccountProgress } = useUpdateAccountProgress(
    currentAccount?.id,
    {
      onSuccess: () => queryClient.invalidateQueries(['progressTracker']),
    },
  );
  const isStageCompleted = useMemo(
    () =>
      isProgressTrackerStageCompleted(
        currentAccount ?? null,
        ProgressTrackerStages.PersonalDetails,
      ),
    [currentAccount],
  );

  const handleComplete = async () => {
    const requestProps: ProgressTrackerGroupRequest = {
      group: ProgressTrackerGroups.Incorporation,
      status: ProgressTrackerStatus.Completed,
      stage: ProgressTrackerStages.PersonalDetails,
    };

    await updateAccountProgress({
      progress: [requestProps],
      eventData: {
        stage: ProgressTrackerStages.PersonalDetails,
        stageStatus: ProgressTrackerStatus.Completed,
        entityType: (company?.entityType ?? company?.legacyEntityType) ?? '',
      },
    });
  };
  return (
    <PersonalDetails
      {...props}
      stageCompleted={isStageCompleted}
      handleComplete={handleComplete}
    />
  );
};
