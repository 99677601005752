import create from 'zustand';
import { SidebarMenuID } from 'enums';

type SideMenuArgs = {
  openMenuId: SidebarMenuID | null;
  setOpenMenuId: (id: SidebarMenuID | null) => void;
};

export const useMenuVisibleStore = create<SideMenuArgs>((set) => ({
  openMenuId: null,
  setOpenMenuId: (id: SidebarMenuID | null) => set({ openMenuId: id }),
}));
