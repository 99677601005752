import { ReactNode, useMemo } from 'react';
import { Box, Grid, Stepper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import i18n from 'translations/i18n';
import { useMediaBreakpoints } from 'hooks/useMediaBreakpoints';
import {
  IAccount,
  ProgressTrackerStages,
  ProgressTrackerStatus,
} from 'services/account';
import { FormationsStep } from 'components/FormationsStep';
import { ENTITY_MAPPING } from 'constants/common';
import {
  PT_DOC_INCORPORATION,
  PT_ID_PROOF,
  PT_TAX_STATEMENT,
} from 'constants/documents';
import { FormationsPrimaryButton } from 'components/common/Buttons';
import { useCurrentPaymentStatus } from 'hooks/api/useStripe';
import {
  isAccount1120sOnly,
  isAccountBusinessInABox,
  isAccountFullSuite,
} from '../util';
import { UploadGovID } from './UploadGovID';
import { DirectDepositInfo } from './DirectDepositInfo';
import { HomeAddress } from './HomeAddress';

const StepContentWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    '& > div': {
      maxWidth: '546px',
    },
  },
}));

const IconHeader = styled(Typography)(({ theme }) => ({
  marginTop: '16px',
  color: theme.palette.text.secondary,
}));

const getSubtitleWithList = ({
  header,
  listItems,
}: {
  header: string;
  listItems: string[];
}) => (
  // eslint-disable-next-line react/jsx-indent
  <Box>
    <p>{header}</p>
    <ul>
      {listItems.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  </Box>
);

const getDOIOptionsByEntityType = (entityType: string) => {
  const options = [
    i18n.t(
      'progressTracker.accountVerification.documentOfIncorporation.articleOfIncorporation',
    ),
    i18n.t(
      'progressTracker.accountVerification.documentOfIncorporation.einLetter',
    ),
    i18n.t(
      'progressTracker.accountVerification.documentOfIncorporation.operatingAgreement',
    ),
  ];
  if (entityType === ENTITY_MAPPING.s_corp) {
    options.push(
      i18n.t(
        'progressTracker.accountVerification.documentOfIncorporation.letterOfAcceptance',
      ),
    );
  }
  return options;
};

const getTitleAndSubtitle = (
  status: ProgressTrackerStatus,
  data: { title: string; subtitle: string | ReactNode },
) => {
  if (status === ProgressTrackerStatus.InProgress) {
    return {
      title: i18n.t(
        'progressTracker.accountVerification.documentOfIncorporation.titleInProgress',
      ),
      subtitle: i18n.t(
        'progressTracker.accountVerification.documentOfIncorporation.subTitleInProgress',
      ),
    };
  }

  if (status === ProgressTrackerStatus.Completed) {
    return {
      title: i18n.t(
        'progressTracker.accountVerification.documentOfIncorporation.titleCompleted',
      ),
      subtitle: i18n.t(
        'progressTracker.accountVerification.documentOfIncorporation.subTitleCompleted',
      ),
    };
  }

  return data;
};

const RenderStepContent = ({
  step,
  handleContinue,
  isLoading,
  account,
  state,
  entityType,
  onContinueToNextSection,
}: {
  step: number;
  handleContinue: (
    stage: ProgressTrackerStages,
    status: ProgressTrackerStatus,
  ) => void;
  isLoading: boolean;
  account: IAccount;
  state: any;
  entityType: string;
  onContinueToNextSection: () => void;
}) => {
  const { alreadyPaid } = useCurrentPaymentStatus(account);
  const steps = Object.keys(state);
  const currentStatus = state[steps[step]]?.status;
  const comment = state[steps[step]]?.comment;
  const is1120sOnly = isAccount1120sOnly(account);
  const canSkipLastYearTaxStatement = useMemo(
    () => isAccountBusinessInABox(account) || isAccountFullSuite(account),
    [account],
  );

  switch (steps[step]) {
    case ProgressTrackerStages.UploadIdentityProof: {
      const titleAndSubtitle = getTitleAndSubtitle(currentStatus, {
        title: i18n.t(
          'progressTracker.accountVerification.proofOfIdentity.title',
        ),
        subtitle: i18n.t(
          'progressTracker.accountVerification.proofOfIdentity.subTitle',
        ) as string,
      });
      return (
        <UploadGovID
          accountId={account.id}
          handleContinue={() =>
            handleContinue(
              ProgressTrackerStages.UploadIdentityProof,
              ProgressTrackerStatus.InProgress,
            )
          }
          isLoading={isLoading}
          currentStatus={currentStatus}
          {...titleAndSubtitle}
          categoryData={PT_ID_PROOF}
          defaultYear="Permanent"
          comment={comment}
          key="kycComponent"
          supportedFileHelperText={i18n.t(
            'progressTracker.accountVerification.supportedFileHelperText',
          )}
        />
      );
    }
    case ProgressTrackerStages.UploadLastYearTaxStatement: {
      const titleAndSubtitle = getTitleAndSubtitle(currentStatus, {
        title: i18n.t(
          'progressTracker.accountVerification.lastYearTaxStatement.title',
        ) as string,
        subtitle: i18n.t(
          is1120sOnly
            ? 'progressTracker.accountVerification.lastYearTaxStatement.subTitle1120s'
            : 'progressTracker.accountVerification.lastYearTaxStatement.subTitle',
        ) as string,
      });
      return (
        <UploadGovID
          accountId={account.id}
          handleContinue={(isSkipDocument) =>
            handleContinue(
              ProgressTrackerStages.UploadLastYearTaxStatement,
              isSkipDocument
                ? ProgressTrackerStatus.Rejected
                : ProgressTrackerStatus.InProgress,
            )
          }
          isLoading={isLoading}
          currentStatus={currentStatus}
          {...titleAndSubtitle}
          categoryData={PT_TAX_STATEMENT}
          defaultYear="Permanent"
          comment={comment}
          key="taxComponent"
          supportedFileHelperText={i18n.t(
            'progressTracker.accountVerification.supportedFileHelperText',
          )}
          optional={canSkipLastYearTaxStatement}
          skipLabel="Skip / I will add this later"
        />
      );
    }
    case ProgressTrackerStages.DocumentOfIncorporation: {
      const titleAndSubtitle = getTitleAndSubtitle(currentStatus, {
        title: i18n.t(
          'progressTracker.accountVerification.documentOfIncorporation.title',
        ),
        subtitle: getSubtitleWithList({
          header: i18n.t(
            'progressTracker.accountVerification.documentOfIncorporation.subTitle',
          ),
          listItems: getDOIOptionsByEntityType(entityType),
        }),
      });
      return (
        <UploadGovID
          accountId={account.id}
          handleContinue={(isSkipDocument) =>
            handleContinue(
              ProgressTrackerStages.DocumentOfIncorporation,
              isSkipDocument
                ? ProgressTrackerStatus.Completed
                : ProgressTrackerStatus.InProgress,
            )
          }
          isLoading={isLoading}
          currentStatus={currentStatus}
          {...titleAndSubtitle}
          categoryData={PT_DOC_INCORPORATION}
          defaultYear="Permanent"
          comment={comment}
          key="IncorporationComponent"
          supportedFileHelperText={i18n.t(
            'progressTracker.accountVerification.supportedFileHelperText',
          )}
          optional
        />
      );
    }
    case ProgressTrackerStages.DirectDepositInformation:
      return (
        <DirectDepositInfo
          handleContinue={() =>
            handleContinue(
              ProgressTrackerStages.DirectDepositInformation,
              ProgressTrackerStatus.Completed,
            )
          }
          account={account}
          currentStatus={currentStatus}
        />
      );
    case ProgressTrackerStages.HomeAddressDetails:
      return (
        <HomeAddress
          handleContinue={() =>
            handleContinue(
              ProgressTrackerStages.HomeAddressDetails,
              ProgressTrackerStatus.Completed,
            )
          }
          currentStatus={currentStatus}
          account={account}
        />
      );
    default:
      return (
        <>
          {(account.formationsPlan === 'FullSuite' ||
            account.formationsPlan === 'TaxOnly') && (
            <>
              <Typography component="h2" variant="h5B">
                {
                  i18n.t(
                    'progressTracker.accountVerification.directDepositInfo.weGotAccountInfo',
                  ) as string
                }
              </Typography>
              <IconHeader variant="body1">
                {
                  i18n.t(
                    'progressTracker.accountVerification.directDepositInfo.processingTime',
                  ) as string
                }
              </IconHeader>
              {account.formationsPlan === 'FullSuite' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 'auto',
                  }}
                >
                  <FormationsPrimaryButton
                    onClick={onContinueToNextSection}
                    data-testid="continue-to-next-section"
                  >
                    Continue to Next Section
                  </FormationsPrimaryButton>
                </div>
              )}
            </>
          )}
          {account.formationsPlan === 'BIB' && (
            <>
              <Typography component="h2" variant="h5B">
                {
                  i18n.t(
                    'progressTracker.accountVerification.processingTitleBIB',
                  ) as string
                }
              </Typography>
              <IconHeader variant="body1">
                {
                  i18n.t(
                    'progressTracker.accountVerification.processingMessageBIB',
                  ) as string
                }
                {alreadyPaid
                  ? (i18n.t(
                      'progressTracker.accountVerification.processingMessageContinueToIncorporationStatus',
                    ) as string)
                  : (i18n.t(
                      'progressTracker.accountVerification.processingMessageContinueToPayment',
                    ) as string)}
              </IconHeader>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 'auto',
                }}
              >
                <FormationsPrimaryButton
                  onClick={onContinueToNextSection}
                  data-testid="continue-to-next-section"
                >
                  {alreadyPaid
                    ? 'Continue to Incorporation Status'
                    : 'Continue to Payment'}
                </FormationsPrimaryButton>
              </div>
            </>
          )}
        </>
      );
  }
};

interface AccountVerificationProps {
  activeStep: number;
  setActiveStep: (step: number) => void;
  handleContinue: (
    stage: ProgressTrackerStages,
    status: ProgressTrackerStatus,
  ) => void;
  isLoading: boolean;
  state: any;
  entityType: string;
  account: IAccount;
  onContinueToNextSection: () => void;
}

export const AccountVerificationLayout = ({
  activeStep,
  setActiveStep,
  handleContinue,
  isLoading,
  account,
  state,
  entityType,
  onContinueToNextSection,
}: AccountVerificationProps) => {
  const { isDesktop } = useMediaBreakpoints();

  const steps = Object.keys(state);
  return (
    <Grid container spacing={{ md: 2 }}>
      <Grid item xs={12} md={5}>
        <Stepper
          activeStep={activeStep}
          nonLinear
          orientation={isDesktop ? 'vertical' : 'horizontal'}
          alternativeLabel={!isDesktop}
          data-test-id="account-verification-stages"
        >
          {steps.map((key, index) => (
            <FormationsStep
              key={key}
              index={index}
              setActiveStep={setActiveStep}
              status={state[key]?.status}
              label={state[key]?.label}
              isTrainer={false}
            />
          ))}
        </Stepper>
      </Grid>
      <StepContentWrapper item xs={12} md={7}>
        <Box
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <RenderStepContent
            step={activeStep}
            handleContinue={handleContinue}
            isLoading={isLoading}
            account={account}
            state={state}
            entityType={entityType}
            onContinueToNextSection={onContinueToNextSection}
          />
        </Box>
      </StepContentWrapper>
    </Grid>
  );
};
