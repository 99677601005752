import {
  Alert,
  Box,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import {
  nameFields,
  otherFields,
  ownershipDetailsSchema,
} from 'components/ProgressTracker/Incorporation/helpers';
import { renderField } from 'components/common/FormationsForm2';
import { FileUploadEditableRow, UploadFile } from 'components/common';
import { MAX_DOCUMENT_SIZE_BYTES } from 'constants/documents';
import { ALL_ALLOWED_FILES } from 'constants/common';
import { fileUploadErrorHandler, nameLengthValidator } from 'helpers/common';
import {
  FormationsGhostButton,
  FormationsPrimaryButton,
} from 'components/common/Buttons';
import { CloseIcon, RefreshIcon } from 'components/common/Icon';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TempDocument } from 'components/documentsV2/types';
import { CreateOwnerData, Owner } from 'models/company';
import { FileRejection } from 'react-dropzone';
import {
  useCreateDocument,
  useCreateOwner,
  useDeleteDocument,
  useDocumentCategories,
  useDocuments,
  useOwner,
  useUpdateOwner,
} from 'hooks/api';
import { FormationsDocument } from 'services/documentTypes';
import { showSuccessToast, showToastOnError } from 'components/toast/showToast';
import { queryClient } from 'states/reactQueryClient';

interface Props {
  accountId: string;
  open: boolean;
  onClose: () => void;
}

const transformToTempDoc = (file: File) => {
  const dotIndex = file.name.lastIndexOf('.');
  const displayName = file.name.slice(0, dotIndex);
  const extension = file.name.slice(dotIndex);

  return {
    displayName,
    extension: extension || '',
    file,
  };
};

interface OwnerFormData {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string;
  socialSecurityNumber: string;
  email: string;
  ownershipPercentage: number;
}

interface OwnershipFormDialogProps extends Props {
  owner: Owner | CreateOwnerData;
  documents: TempDocument[];
  onSubmit: (data: OwnerFormData & { proofOfIds: string[] }) => Promise<void>;
  title: string;
}

export const OwnershipFormDialog = ({
  open,
  onClose,
  accountId,
  owner,
  documents,
  onSubmit,
  title,
}: OwnershipFormDialogProps) => {
  const [files, setFiles] = useState<TempDocument[]>(documents);
  const [error, setError] = useState<Error | null>();
  const { mutateAsync: createDocument, isLoading: isCreatingDocument } =
    useCreateDocument();
  const { mutateAsync: deleteDocument, isLoading: isDeleting } =
    useDeleteDocument();
  const [isLoading, setIsLoading] = useState(false);

  const hasFile = useMemo(() => files.length > 0, [files]);

  const formInstance = useForm({
    defaultValues: {
      firstName: owner.name?.First ?? '',
      middleName: owner.name?.Middle ?? '',
      lastName: owner.name?.Last ?? '',
      dateOfBirth: owner.dob ?? '',
      socialSecurityNumber: owner.ssn ?? '',
      email: owner.email ?? '',
      ownershipPercentage: owner.ownershipPercentage ?? 0,
    },
    mode: 'onChange',
    resolver: yupResolver(ownershipDetailsSchema),
  });

  const { categories } = useDocumentCategories();
  const category = useMemo(
    () =>
      categories.find(
        (cat) =>
          cat.department === 'Profile & Account' &&
          cat.category === 'Miscellaneous' &&
          cat.subcategory === 'Additional Owner Information',
      ),
    [categories],
  );

  const { handleSubmit, formState, reset } = formInstance;
  const onDrop = useCallback(() => setError(null), [setError]);
  const onDropAccepted = (newFiles: File[]) => {
    setFiles((prevFiles) => [
      ...prevFiles,
      ...newFiles.map(transformToTempDoc),
    ]);
  };
  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    fileUploadErrorHandler(fileRejections, setError);
  }, []);
  const handleDeleteFile = useCallback((file: TempDocument, index: number) => {
    setFiles(
      files.filter(
        (f, i) => !(f.displayName === file.displayName && i === index),
      ),
    );
  }, []);
  const updateFileName = (file: TempDocument, index: number) => {
    setFiles([
      ...files.slice(0, index),
      file,
      ...files.slice(index + 1, files.length),
    ]);
  };
  const handleClearForm = async () => {
    try {
      reset({
        firstName: '',
        middleName: '',
        lastName: '',
        dateOfBirth: undefined,
        socialSecurityNumber: '',
        email: '',
        ownershipPercentage: 0,
      });
      await Promise.all(
        owner.proofOfIds?.map((id) => deleteDocument(id)) ?? [],
      );
      setFiles([]);
    } catch (e) {
      showToastOnError(e);
    }
  };

  const submitHandler = async (formData: OwnerFormData) => {
    try {
      setIsLoading(true);
      const oldProofOfIds = files
        .filter((file) => file.id != null)
        .map((file) => file.id!);
      const results = await Promise.all(
        files.map(async (file) => {
          if (!file.id) {
            return createDocument({
              form: {
                accountId,
                file: file.file,
                title: file.displayName + file.extension,
                year: 'Permanent',
                documentCategoryId: category?.id,
              },
            })
              .then((res) => res.data.id)
              .catch((e) => {
                console.log(e);
                return undefined;
              });
          }
          return Promise.resolve(undefined);
        }),
      );
      const newProofOfIds: string[] = results.filter(
        (result) => typeof result === 'string',
      ) as unknown as string[];

      const mergedProofOfIds: string[] = [...oldProofOfIds!, ...newProofOfIds];
      await onSubmit({ ...formData, proofOfIds: mergedProofOfIds });
    } catch (e) {
      showToastOnError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <form noValidate onSubmit={handleSubmit(submitHandler)}>
        <DialogTitle sx={{ pr: 4 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>{title}</Grid>
            <ButtonBase>
              <CloseIcon onClick={onClose} />
            </ButtonBase>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ pt: 2 }}>
            {nameFields.map((field) => (
              <Grid item key={field.name} xs={12} sm={6} md={4}>
                {renderField(field, formInstance)}
              </Grid>
            ))}
          </Grid>
          {otherFields.map((field) => (
            <Grid item key={field.name} xs={12} sx={{ pt: 2 }}>
              {renderField(field, formInstance)}
            </Grid>
          ))}
          <Grid item xs={12} sx={{ pt: 1 }}>
            <Typography variant="body3B" color="text.secondary">
              Please verify owner identity by uploading a front and back image
              of driver’s license or state-issued ID.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ pt: 1 }}>
            <UploadFile
              uploadProps={{
                onDrop,
                onDropAccepted,
                onDropRejected,
                maxSize: MAX_DOCUMENT_SIZE_BYTES,
                accept: ALL_ALLOWED_FILES,
                multiple: true,
                noClick: true,
                noKeyboard: true,
                validator: nameLengthValidator,
              }}
            />
          </Grid>
          {error && (
            <Grid item xs={12} sx={{ pt: 2 }}>
              <Alert severity="error">{error.message}</Alert>
            </Grid>
          )}
          {hasFile && (
            <Grid item xs={12} sx={{ pt: 2 }}>
              {files.map((file, index) => (
                <FileUploadEditableRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${file.displayName}-${index}`}
                  index={index}
                  file={file}
                  handleDeleteFile={handleDeleteFile}
                  updateFileName={updateFileName}
                  disabled={file.id != null}
                  disablePreview={file.id != null}
                />
              ))}
            </Grid>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            sx={{ pt: 2 }}
          >
            <FormationsGhostButton
              size="large"
              onClick={handleClearForm}
              disabled={isCreatingDocument || isDeleting || isLoading}
              startIcon={<RefreshIcon sx={{ pb: 0.75 }} />}
              data-testid="form-clear-btn"
              sx={{ mr: 2 }}
            >
              Clear
            </FormationsGhostButton>
            <FormationsPrimaryButton
              size="large"
              type="submit"
              data-testid="form-save-btn"
              disabled={!formState.isValid || files.length < 1 || isLoading}
            >
              Submit
            </FormationsPrimaryButton>
          </Box>
        </DialogContent>
      </form>
    </Dialog>
  );
};

interface OwnershipEditDialogProps extends Props {
  ownerId: string;
  companyId: string;
}

const transformToTempDocument = (doc: FormationsDocument): TempDocument => {
  const { id, file, title } = doc;
  const dotIndex = title.lastIndexOf('.');
  const displayName = title.slice(0, dotIndex);
  const extension = title.slice(dotIndex);

  return {
    id,
    referenceFileId: id,
    displayName,
    extension: extension || '',
    file,
  };
};

export const OwnershipEditDialog = ({
  ownerId,
  companyId,
  accountId,
  onClose,
  ...props
}: OwnershipEditDialogProps) => {
  const { owner, isFetched } = useOwner(ownerId, companyId, true);
  const { documents, isFetched: isDocumentsFetched } = useDocuments(
    owner?.proofOfIds || [],
    {
      enabled: isFetched && owner != null,
    },
  );

  const tempDocs = useMemo(
    () => (documents ?? []).map(transformToTempDocument),
    [documents],
  );

  const { mutateAsync: updateOwner } = useUpdateOwner({
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries(['companyOwners', companyId]),
        queryClient.invalidateQueries(['companyOwner', ownerId, companyId]),
      ]),
  });

  const onSubmit = async (
    formData: OwnerFormData & { proofOfIds: string[] },
  ) => {
    const submittedData: Owner = {
      companyId,
      accountId,
      isPrimary: owner?.isPrimary ?? false,
      id: ownerId,
      name: {
        First: formData.firstName,
        Middle: formData.middleName,
        Last: formData.lastName,
      },
      dob: formData.dateOfBirth,
      ssn: formData.socialSecurityNumber,
      email: formData.email,
      ownershipPercentage: formData.ownershipPercentage,
      proofOfIds: formData.proofOfIds,
    };
    await updateOwner({ ownerId, companyId, data: submittedData });
    showSuccessToast('Update owner successfully');
    onClose();
  };

  if (owner == null || !isDocumentsFetched || !isFetched) {
    return null;
  }

  return (
    <OwnershipFormDialog
      {...props}
      onClose={onClose}
      accountId={accountId}
      owner={owner}
      documents={tempDocs}
      onSubmit={onSubmit}
      title="Edit owner"
    />
  );
};

interface OwnershipCreateDialogProps extends Props {
  companyId: string;
}
export const OwnershipCreateDialog = ({
  companyId,
  accountId,
  onClose,
  ...props
}: OwnershipCreateDialogProps) => {
  const { mutateAsync: createOwner } = useCreateOwner({
    onSuccess: () =>
      queryClient.invalidateQueries(['companyOwners', companyId]),
  });

  const onSubmit = async (
    formData: OwnerFormData & { proofOfIds: string[] },
  ) => {
    const submittedData: CreateOwnerData = {
      companyId,
      accountId,
      isPrimary: false,
      name: {
        First: formData.firstName,
        Middle: formData.middleName,
        Last: formData.lastName,
      },
      dob: formData.dateOfBirth,
      ssn: formData.socialSecurityNumber,
      email: formData.email,
      ownershipPercentage: formData.ownershipPercentage,
      proofOfIds: formData.proofOfIds,
    };
    await createOwner({ companyId, data: submittedData });
    showSuccessToast('Additional owner created successfully');
    onClose();
  };

  return (
    <OwnershipFormDialog
      {...props}
      onClose={onClose}
      accountId={accountId}
      owner={
        {
          companyId,
          accountId,
          isPrimary: false,
        } as CreateOwnerData
      }
      documents={[]}
      onSubmit={onSubmit}
      title="Add another owner"
    />
  );
};
