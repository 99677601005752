import { CONFIG } from 'config';
import {
  useChurnZero,
  useHubspotScript,
  useUserPilotScript,
  useWorkNetScript,
} from 'hooks/importScript';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { sendChurnZeroEvent, setChurnZeroContact } from 'helpers/churnZero';
import { CHURN_ZERO_EVENT_NAMES, CONSTANTS } from 'constants/common';
import {
  useCompany,
  useCurrentAccount,
  useCurrentUser,
  useSpoof,
} from 'hooks/api';
import { useAuth0 } from '@auth0/auth0-react';
import { useStripeCustomer } from 'hooks/api/useStripe';
import useContacts from 'hooks/api/useContacts';
import { useLocation } from 'react-router-dom';
import { useDataSourcesFeatureFlag } from 'hooks/useFeatureFlag';
import { useBankAccounts } from 'hooks/api/bankAccounts';
import { getBankAccountDataSummary } from 'helpers/userpilot';

export const LoadHubspotScript = () => {
  useHubspotScript();
  return null;
};

export const LoadChurnScript = () => {
  const status = useChurnZero();
  useEffect(() => {
    if (status === 'ready') {
      const churnZero = (window as any).ChurnZero;
      churnZero.push(['setAppKey', CONFIG.churnAppKey]); // AppKey from ChurnZero
    }
  }, [status]);
  return null;
};

export const ChurnZeroPageTracking = () => {
  useEffect(() => {
    sendChurnZeroEvent(CHURN_ZERO_EVENT_NAMES.VISIT_PAGE, window.location.href);
  }, [window.location.href]);

  return null;
};

export const ChurnZeroSetupContact = ({ children }: PropsWithChildren<{}>) => {
  useEffect(() => {
    setChurnZeroContact().catch((err) => {
      console.error(err);
    });
  }, []);

  return <>{children}</>;
};

export const LoadUserpilotIdentify = () => {
  const { isAuthenticated } = useAuth0();
  const { currentUser } = useCurrentUser();
  const { currentAccount } = useCurrentAccount();
  const { isAdminSpoof } = useSpoof();
  const { bankAccounts } = useBankAccounts(currentAccount?.id);
  const { company } = useCompany(currentAccount?.companyId ?? '');
  const primaryStripeId = currentAccount?.primaryStripeId;
  const { stripeCustomers } = useStripeCustomer(currentAccount?.id);
  const { data: contactData } = useContacts.GetContact(
    currentUser?.contactId ?? '',
  );
  const dataSource = useDataSourcesFeatureFlag();
  const primaryStripeCustomer = useMemo(() => {
    if (stripeCustomers == null) return null;
    if (stripeCustomers.length === 1) return stripeCustomers[0];
    const primary = stripeCustomers.find((x) => x.stripeId === primaryStripeId);
    return primary ?? null;
  }, [stripeCustomers, primaryStripeId]);

  useEffect(() => {
    if (
      isAuthenticated &&
      (window as any).userpilot &&
      currentUser &&
      currentAccount
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { ein, ...stripDownCompany } = company ?? {};
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { routingNumber, bankAccountNumber, dob, ...stripDownUser } =
        currentUser;

      if (currentUser?.role === CONSTANTS.USER_ROLES.CUSTOMER) {
        if (isAdminSpoof) {
          // do not send info when spoofing as admin
          return;
        }
        (window as any).userpilot.identify(currentAccount.id, {
          ...currentAccount,
          ...stripDownUser,
          ...stripDownCompany,
          hubspotId: contactData?.hubspotId,
          dataSource,
          stripePlan: primaryStripeCustomer?.plan,
          csmEmail: currentAccount.csm?.email,
          ...getBankAccountDataSummary(bankAccounts),
        });
      } else {
        (window as any).userpilot.identify(currentAccount.id, {
          email: currentUser.email,
          role: currentUser.role,
        });
      }
    }
  }, [
    isAuthenticated,
    currentUser,
    company,
    currentAccount,
    stripeCustomers,
    contactData,
    dataSource,
    primaryStripeCustomer?.plan,
    isAdminSpoof,
    bankAccounts,
  ]);
  return null;
};

export const LoadWorknetScript = ({ accountId }: { accountId: string }) => {
  useWorkNetScript(accountId);
  useUserPilotScript();
  return null;
};

export const LoadUserPilotScript = () => {
  useUserPilotScript();
  const location = useLocation();
  useEffect(() => {
    if ((window as any).userpilot) {
      (window as any).userpilot.reload();
    }
  }, [location]);
  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated || !(window as any).userpilot) {
    return null;
  }
  return <LoadUserpilotIdentify />;
};
