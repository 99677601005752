import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Routes } from 'fnRoutes';
import { Loading } from 'components/common';
import { LandingPage1120s } from 'views/LandingPage1120s';
import { LandingPageBIB } from 'views/LandingPageBIB';
import { FormationsPlan } from 'models/account';

export const RedirectToHomeOrLandingPage = ({
  plan,
}: {
  plan: FormationsPlan;
}) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { pathname, search } = useLocation();

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    if (plan === FormationsPlan.BIB) {
      return <LandingPageBIB />;
    }
    if (plan === FormationsPlan.TaxOnly) {
      return <LandingPage1120s />;
    }
  }

  if (pathname === '/' && search) {
    return <Navigate to={`${Routes.HOME()}${search}`} replace />;
  }
  return <Navigate to={`${Routes.HOME()}`} replace />;
};
