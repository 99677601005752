import { Shadows } from '@mui/material/styles/shadows';

export const shadows = [
  'none',
  '0px 3px 5px -1px rgba(0, 0, 0, 0.05), 0px 6px 10px rgba(13, 34, 89, 0.05), 0px 1px 18px rgba(13, 34, 89, 0.05)',
  '0px 2px 1px -1px rgba(13, 34, 89, 0.1)',
  '0px 2px 1px -1px rgba(13, 34, 89, 0.1), 0px 1px 1px rgba(13, 34, 89, 0.14), 0px 1px 3px rgba(13, 34, 89, 0.12)',
  '0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(13, 34, 89, 0.14), 0px 1px 14px rgba(13, 34, 89, 0.12)',
  '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%)',
  '0px 3px 5px -1px rgba(0, 0, 0, 0.05), 0px 6px 10px rgba(13, 34, 89, 0.05), 0px 1px 18px rgba(13, 34, 89, 0.05)',
  '0px 3px 5px -1px rgba(0, 0, 0, 0.05), 0px 6px 10px rgba(13, 34, 89, 0.05), 0px 1px 18px rgba(13, 34, 89, 0.05)',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
] as Shadows;
