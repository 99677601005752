import React, { useCallback, useState } from 'react';
import { FormationsGhostButton } from 'components/common/Buttons';
import { ArrowDropDownIcon } from 'components/common/Icon';
import { TaxesFilters, useTaxesQuery } from 'hooks/api/useTaxes';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { MOMENT_DATE_FORMAT } from 'helpers/dateTimeFormat';
import {
  DateFilter,
  DateFilterOptions,
} from 'components/common/FormationsFilters/FormationsDateFilter';

const highlightCSS = {
  backgroundColor: 'others.newYellow',
  '&:hover': { backgroundColor: 'others.newYellow' },
};

const isHighlighted = (query: TaxesFilters): boolean =>
  (query.payment_to !== undefined && query.payment_to !== null) ||
  (query.payment_from !== undefined && query.payment_from !== null) ||
  (query.sort_by === 'payment_date' && query.sort !== null && query.sort !== undefined);

const FilterButton = styled(FormationsGhostButton)({
  width: '24px',
  height: '24px',
});

export const DateFilterCell = () => {
  const [query, setQuery] = useTaxesQuery();
  const {
    payment_from: paymentFrom,
    payment_to: paymentTo,
    sort,
    sort_by: sortBy,
  } = query;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openPopover = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleFilter = useCallback((options: DateFilterOptions) => {
    const newSortKey = options.order ? 'payment_date' : undefined;
    setQuery({
      sort: options.order || sortBy === 'payment_date' ? options.order : sort,
      sort_by: options.order || sortBy === 'payment_date' ? newSortKey : sortBy,
      payment_from: options.dates[0]
        ? options.dates[0]?.format(MOMENT_DATE_FORMAT)
        : undefined,
      payment_to: options.dates[1]
        ? options.dates[1]?.format(MOMENT_DATE_FORMAT)
        : undefined,
      page: 1,
    });
    setAnchorEl(null);
  }, [sort, sortBy]);

  const handleClear = useCallback(() => {
    setQuery({
      sort: sortBy === 'payment_date' ? undefined : sort,
      sort_by: sortBy === 'payment_date' ? undefined : sortBy,
      payment_from: undefined,
      payment_to: undefined,
    });
    setAnchorEl(null);
  }, [sort, sortBy]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <FilterButton
        rounded
        onClick={openPopover}
        data-testid="date-filter-btn"
        sx={isHighlighted(query) ? highlightCSS : {}}
      >
        <ArrowDropDownIcon />
      </FilterButton>
      <DateFilter
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        sortOrder={sortBy === 'payment_date' ? sort : null}
        onClear={handleClear}
        onClose={handleClose}
        defaultDate={[
          paymentFrom ? moment(paymentFrom) : null,
          paymentTo ? moment(paymentTo) : null,
        ]}
        onConfirm={handleFilter}
      />
    </>
  );
};
