import { CONFIG, Environment } from 'config';
import { PlanNames } from 'models/account';

export type StripePrefilledInfo = {
  email: string;
  accountID: string;
};

export const CheckoutLinkBIBTest: { [key: string]: string } = {};
export const CheckoutLinkBIBProd: { [key: string]: string } = {
  MA: 'https://checkout.formationscorp.com/b/eVag0Y8b892oaQgfZe',
  NV: 'https://checkout.formationscorp.com/b/5kAbKI1MK1zW9Mc3cu',
  TN: 'https://checkout.formationscorp.com/b/9AQ8ywfDA2E07E44gx',
  TX: 'https://checkout.formationscorp.com/b/bIY4ig3US1zW8I8fZd',
  AK: 'https://checkout.formationscorp.com/b/aEU1648b82E0e2sbJ2',
  AL: 'https://checkout.formationscorp.com/b/7sI9CA4YW1zW0bCfZh',
};

export const CheckoutLinkObjByEnv: {
  [key in Environment]: { [key: string]: string };
} = {
  [Environment.LOCALHOST]: CheckoutLinkBIBTest,
  [Environment.DEV]: CheckoutLinkBIBTest,
  [Environment.STAGING]: CheckoutLinkBIBTest,
  [Environment.PRODUCTION]: CheckoutLinkBIBProd,
};

export const DefaultCheckoutLinkByEnv: { [key in Environment]: string } = {
  [Environment.LOCALHOST]:
    'https://checkout.formationscorp.com/b/test_5kA3do9tMbgy4mcfZ0',
  [Environment.DEV]:
    'https://checkout.formationscorp.com/b/test_14k01c49sckCcSI28b',
  [Environment.STAGING]:
    'https://checkout.formationscorp.com/b/test_5kA8xIfSa98q2e45ko',
  [Environment.PRODUCTION]:
    'https://checkout.formationscorp.com/b/4gwg0Y3US3I4bUk7sG',
};

export const PlanTableIDBasedOnPlanLocalhost: { [Key in PlanNames]: string } = {
  [PlanNames.Platinum]: 'prctbl_1PlFkJDUhnX55bvLKOE8GQHh',
  [PlanNames.Standard]: 'prctbl_1PlFm0DUhnX55bvL7ZvMjAEi',
  [PlanNames.Digital]: 'prctbl_1PlFmUDUhnX55bvLxcyhg6aY',
  [PlanNames.PlatinumPlus]: '',
  [PlanNames.PlatinumPlusPlus]: '',
};

export const PlanTableIDBasedOnPlanDev: { [Key in PlanNames]: string } = {
  [PlanNames.Platinum]: 'prctbl_1PlFoFDUhnX55bvLTjZ1q2tW',
  [PlanNames.Standard]: 'prctbl_1PlFooDUhnX55bvLbsFFKJ5j',
  [PlanNames.Digital]: 'prctbl_1PlFpUDUhnX55bvLjzBIHvyd',
  [PlanNames.PlatinumPlus]: '',
  [PlanNames.PlatinumPlusPlus]: '',
};

export const PlanTableIDBasedOnPlanStaging: { [Key in PlanNames]: string } = {
  [PlanNames.Platinum]: 'prctbl_1PlFrwDUhnX55bvLlbInOYLb',
  [PlanNames.Standard]: 'prctbl_1PlFsMDUhnX55bvLzERttwYZ',
  [PlanNames.Digital]: 'prctbl_1PlFt1DUhnX55bvLELkxPE6z',
  [PlanNames.PlatinumPlus]: '',
  [PlanNames.PlatinumPlusPlus]: '',
};

export const PlanTableIDBasedOnPlanProd: { [Key in PlanNames]: string } = {
  [PlanNames.Platinum]: 'prctbl_1PYEqJDUhnX55bvLlsls9YhB',
  [PlanNames.Standard]: 'prctbl_1PYEikDUhnX55bvLghSK1xIK',
  [PlanNames.Digital]: 'prctbl_1PXvKKDUhnX55bvLZLtq4KnW',
  [PlanNames.PlatinumPlus]: '',
  [PlanNames.PlatinumPlusPlus]: '',
};

export const PlanTableObjByEnv: {
  [key in Environment]: { [key: string]: string };
} = {
  [Environment.LOCALHOST]: PlanTableIDBasedOnPlanLocalhost,
  [Environment.DEV]: PlanTableIDBasedOnPlanDev,
  [Environment.STAGING]: PlanTableIDBasedOnPlanStaging,
  [Environment.PRODUCTION]: PlanTableIDBasedOnPlanProd,
};

export const getBIBCheckoutPageLink = (
  params: StripePrefilledInfo,
  state: string | undefined,
  env: Environment = CONFIG.stripe.env ?? Environment.PRODUCTION,
) => {
  let link = DefaultCheckoutLinkByEnv[env];
  const checkoutLinksBasedOnStateBIB = CheckoutLinkObjByEnv[env];
  if (state) {
    link = checkoutLinksBasedOnStateBIB[state] ?? link;
  }
  return `${link}?prefilled_email=${params.email}&client_reference_id=${params.accountID}`;
};

export const getFullSuitePlanTableID = (
  plan?: PlanNames,
  env: Environment = CONFIG.stripe.env ?? Environment.PRODUCTION,
) => {
  const planTableIDBasedOnPlan = PlanTableObjByEnv[env];
  if (plan) {
    return (
      planTableIDBasedOnPlan[plan] ?? planTableIDBasedOnPlan[PlanNames.Digital]
    );
  }
  return planTableIDBasedOnPlan[PlanNames.Digital];
};
