import makeStyles from '@mui/styles/makeStyles';
import MuiDrawer from '@mui/material/Drawer';
import { FormationsGhostButton } from 'components/common/Buttons';
import { IAction } from 'components/common/sidebar/constant';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import { 
  ChevronRightIcon,
  CloseIcon
 } from 'components/common/Icon';
import { HeaderDesktop } from './HeaderDesktop';
import { FooterDesktop } from './FooterDesktop';
import {
  getSidebarContextProvider,
  setSidebarOpen,
  useSidebarOpen,
} from './sidebarContext';
import { AuthService } from '../../../../services';
import { YesNoModal } from '../../modals';
import { useCurrentUser } from '../../../../hooks/api';

const drawerWidth = 258;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(11)} + 1px)`,
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  spoofContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    border: `${theme.spacing(0.5)} solid ${theme.palette.error.light}`,
    boxSizing: 'border-box',
    height: '100vh',
    overflowY: 'auto',
    '& .MuiDrawer-paper': {
      top: 58,
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.others.background,
  },
  drawerPaper: {
    width: drawerWidth,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.others.background,
  },
  rightContainer: {
    // taking into account left Sidebar and padding
    width: 'calc(100% - 258px - 48px)',
    overflowY: 'auto',
    position: 'relative',
  },
  warningContainer: {
    width: '100%',
    textAlign: 'center',
    height: 58,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.error.light,
  },
  subtitle: {
    color: theme.palette.info.contrastText,
  },
  contrastText: {
    color: theme.palette.info.contrastText,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    "&:hover": {
      background: 'none',
    }
  },
}));

interface Props {
  actions: IAction[];
  isAdminSpoof?: boolean;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      justifyContent: 'space-between',
      borderRight: 0,
      backgroundColor: '#EBECF2',
      opacity: 0.8,
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      justifyContent: 'space-between',
      borderRight: 0,
    },
  }),
}));
const ExpandButton = styled(FormationsGhostButton)(({ theme }) => ({
  position: 'fixed',
  top: '50px',
  transform: 'translate(-50%, 0)',
  background: '#fff',
  zIndex: 1300,
  boxShadow: theme.shadows[3],
  width: theme.spacing(4),
  height: theme.spacing(4),
  transition: theme.transitions.create('left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
const ExpandIcon = styled(ChevronRightIcon)(({ theme }) => ({
  fontSize: '2rem',
  transition: theme.transitions.create('transform', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const SideBarDesktop = ({
  children,
  actions,
  isAdminSpoof = false,
}: PropsWithChildren<Props>) => {
  const classes = useStyles();
  const { currentUser } = useCurrentUser();
  const isAdmin = isAdminSpoof ? false : AuthService.isAdmin(); // we set expand button for admin only as customer has more complex menu items
  const defaultSidebarOpen = useSidebarOpen();
  const [open, setOpen] = useState<boolean>(defaultSidebarOpen);
  const [showSpoofModeWarning, setShowSpoofModeWarning] =
    useState<boolean>(false);
  const Provider = getSidebarContextProvider();
  const toggleSidebar = () => {
    setOpen(!open);
    setSidebarOpen(!open);
  };
  const spoofUserName = useMemo(
    () => currentUser?.preferredName || currentUser?.name?.first || '',
    [currentUser],
  );

  return (
    <Provider value={open}>
      <div
        className={isAdminSpoof ? classes.spoofContainer : classes.container}
      >
        <YesNoModal
          open={showSpoofModeWarning}
          heading="Exit spoof mode"
          okButtonStyle="primary"
          okText="Exit spoof mode"
          onClose={() => setShowSpoofModeWarning(false)}
          onSave={() => window.close()}
        >
          <Typography variant="body1" component="h5">
            Are you sure you want to exit the spoof mode?
          </Typography>
        </YesNoModal>
        {isAdminSpoof && (
          <Box className={classes.warningContainer}>
            <Typography
              variant="body2B"
              component="h5"
              className={classes.subtitle}
            >
              ⚠️ In Spoof mode
            </Typography>
            <Typography
              variant="body3S"
              component="h5"
              className={classes.contrastText}
              sx={{ ml: 1 }}
            >
              {`You’re logged in as customer '${spoofUserName}' 🔍‍`}
            </Typography>
            <FormationsGhostButton
              rounded
              aria-label="close"
              className={classes.closeButton}
              onClick={() => setShowSpoofModeWarning(true)}
              size="large"
            >
              <CloseIcon />
            </FormationsGhostButton>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            overflowX: 'hidden',
          }}
        >
          {isAdmin && (
            <Tooltip title={open ? 'Collapse' : 'Expand'}>
              <ExpandButton
                rounded
                aria-label={open ? 'Collapse' : 'Expand'}
                sx={{
                  left: open ? '240px' : '88px',
                }}
                onClick={toggleSidebar}
              >
                <ExpandIcon
                  sx={{
                    transform: open ? 'rotate(180deg)' : 'none',
                    pl: 0.25,
                  }}
                  size={32}
                />
              </ExpandButton>
            </Tooltip>
          )}
          <Drawer variant="permanent" open={open}>
            <HeaderDesktop actions={actions} />
            <FooterDesktop />
          </Drawer>
          <Box sx={{ flexGrow: 1, mb: 3 }} className={classes.rightContainer}>
            {children}
          </Box>
        </Box>
      </div>
    </Provider>
  );
};
