import * as yup from 'yup';
import moment from 'moment';

const validateCompanyNames = (val: string[] | undefined) => {
  if (!val || val.length === 0) return true;
  // company name shouldn't start with 0
  return !val.some((name: string) => name.trim().startsWith('0'));
};

const checkDuplicates = (val: string[] | undefined) => {
  const arr = val?.filter(Boolean);
  if (!arr || arr.length < 2) return true;

  const hasDuplicates = new Set(arr.map((i) => i.trim())).size !== arr.length;
  return !hasDuplicates;
};

const validateSuggested = (val: string[] | undefined) => {
  if (!val || val.length < 3) return false;
  return val.filter((item) => !!item).length >= 3;
};

export const BUSINESS_DESCRIPTION_LIMIT = 800;

const companySolePropDetailsStep1Schema = {
  suggested: yup
    .string()
    .when('$isSoleProp', (isSoleProp: boolean, schema) =>
      isSoleProp
        ? yup
            .array()
            .test(
              'invalidCompanyName',
              "Names starting with '0' are not accepted by the IRS. Please choose a different name.",
              validateCompanyNames,
            )
            .test(
              'suggested',
              'Three company name options are required.',
              validateSuggested,
            )
            .test(
              'suggestedDuplicates',
              'Company name options cannot be duplicates.',
              checkDuplicates,
            )
        : schema.notRequired(),
    ),
};

const companyBIBStep1Schema = {
  suggested: yup
    .array()
    .test(
      'invalidCompanyName',
      "Names starting with '0' are not accepted by the IRS. Please choose a different name.",
      validateCompanyNames,
    )
    .test(
      'suggested',
      'Three company name options are required.',
      validateSuggested,
    )
    .test(
      'suggestedDuplicates',
      'Company name options cannot be duplicates.',
      checkDuplicates,
    ),
};

const companyDetailsStep1Schema = {
  ...companySolePropDetailsStep1Schema,
  name: yup
    .string()
    .when('$isSoleProp', (isSoleProp: boolean, schema) =>
      isSoleProp
        ? schema.notRequired()
        : yup.string().required('Please enter a company name.'),
    ),
  incorporationDate: yup
    .date()
    .when('$isSoleProp', (isSoleProp: boolean, schema) =>
      isSoleProp
        ? schema.notRequired()
        : yup
            .date()
            .transform((_value, originalValue) => {
              const parsedValue = moment(originalValue, 'M/D/YYYY', true);

              // if it's valid return the date object, otherwise return an `InvalidDate`
              return parsedValue.isValid()
                ? parsedValue.toDate()
                : new Date('');
            })
            .max(new Date(), 'Future date is not allowed.')
            .typeError('Please enter a date of incorporation in MM/DD/YYYY.'),
    ),
  ein: yup.string().when(['$isScorp', 'hasEinNo'], (isScorp: boolean) =>
    isScorp
      ? yup
          .string()
          .required('Please enter a EIN.')
          .matches(/^\d{2}-?\d{7}$/, 'Please enter a valid EIN.')
      : yup.string().when('hasEinNo', (hasEinNo: boolean | undefined) =>
          !hasEinNo && !isScorp
            ? yup
                .string()
                .required('Please enter a EIN.')
                .matches(/^\d{2}-?\d{7}$/, 'Please enter a valid EIN.')
            : yup.string(),
        ),
  ),
  hasEinNo: yup.bool(),
};

const companyDetailsStep2Schema = {
  stateOfIncorporation: yup
    .mixed()
    .required('Please enter a state of incorporation.'),
  industry: yup.mixed().required('Please enter a business industry.'),
  description: yup
    .string()
    .nullable()
    .required('Please enter business description.')
    .max(
      BUSINESS_DESCRIPTION_LIMIT,
      `Please use less than ${BUSINESS_DESCRIPTION_LIMIT} characters`,
    ),
};

export const companyDetailSolePropCustomerStep1 = yup
  .object()
  .shape(companySolePropDetailsStep1Schema);

export const companyDetailsBIBCustomerStep1 = yup
  .object()
  .shape(companyBIBStep1Schema);

export const companyDetailsCustomerStep1 = yup
  .object()
  .shape(companyDetailsStep1Schema);

export const companyDetailsCustomerStep2 = yup
  .object()
  .shape(companyDetailsStep2Schema);

export const adminCompanyDetailSchema = yup.object().shape({
  ...companyDetailsStep2Schema,
  ...companyDetailsStep1Schema,
  ...companySolePropDetailsStep1Schema,
  ein: yup.string().when(['$isSoleProp'], (isSoleProp: boolean) =>
    !isSoleProp
      ? yup
          .string()
          .required('Please enter a EIN.')
          .matches(/^\d{2}-?\d{7}$/, 'Please enter a valid EIN.')
      : yup.string(),
  ),
});

export const companyDetails1120OnlyStep1Schema = yup
  .object()
  .shape({
    name: yup.string().required('Please enter a company name.'),
    date: yup.date()
      .transform((_value, originalValue) => {
        const parsedValue = moment(originalValue, 'M/D/YYYY', true);

        // if it's valid return the date object, otherwise return an `InvalidDate`
        return parsedValue.isValid()
          ? parsedValue.toDate()
          : new Date('');
      })
      .max(new Date(), 'Future date is not allowed.')
      .typeError('Please enter a date of incorporation in MM/DD/YYYY.'),
    ein: yup.string().when('noEin', (noEin: boolean) =>
      noEin
        ? yup.string().notRequired()
        : yup
          .string()
          .required('Please enter a EIN.')
          .matches(/^\d{2}-?\d{7}$/, 'Please enter a valid EIN.'),
    ),
  })