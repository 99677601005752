import { ApiListResp } from 'models/api';
import { ActiveTaxYearActivityLog } from 'components/ActiveTaxYears/types';
import { api } from './axios';

export type ActiveTaxYear = {
  id: string;
  year: number;
  active: boolean;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
};

export const listActiveTaxYears = async (): Promise<ActiveTaxYear[]> => {
  const { data } = await api.get<ActiveTaxYear[]>('tax-years');
  return data;
};

export const listAllTaxYears = async (): Promise<ActiveTaxYear[]> => {
  const { data } = await api.get<ActiveTaxYear[]>('tax-years?activeOnly=false');
  return data;
};
export const createActiveTaxYears = async (params: Partial<ActiveTaxYear>) => {
  await api.post(`tax-years`, params);
};

export const updateActiveTaxYears = async (params: Partial<ActiveTaxYear>) => {
  await api.put(`tax-years/${params.id}`, params);
};

export const getActiveTaxYearsActivity = async (page: number = 1): Promise<
  ApiListResp<ActiveTaxYearActivityLog[]>
> => {
  const { data } = await api.get<ApiListResp<ActiveTaxYearActivityLog[]>>(
    'tax-years/op-logs',
    {
      params: { page },
    },
  );
  return data;
}
