import React, { useState, useCallback, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  FormationsFormData,
  IFormField,
} from 'components/common/FormationsForm2';
import { queryClient } from 'states/reactQueryClient';
import { useTranslation } from 'react-i18next';
import { fileUploadErrorHandler, nameLengthValidator } from 'helpers/common';
import { ALL_ALLOWED_FILES, CHURN_ZERO_EVENT_NAMES } from 'constants/common';
import { MAX_DOCUMENT_SIZE_BYTES } from 'constants/documents';
import { UseFormMethods, UseFormOptions } from 'react-hook-form';
import moment from 'moment';
import { AnyObjectSchema } from 'yup';
import {
  Breakpoint,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Grid,
  Box,
  Alert,
} from '@mui/material';
import { Spacer } from 'components/Spacer';
import { UploadFile, Loading, FileUploadEditableRow } from 'components/common';
import { sendChurnZeroEvent } from 'helpers/churnZero';
import { FileRejection } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { showErrorToast } from 'components/toast/showToast';
import { formatDate } from 'helpers/dateTimeFormat';
import {
  FormationsPrimaryButton,
  FormationsGhostButton,
} from 'components/common/Buttons';
import {
  useCreateDocument,
  useCurrentAccount,
  useDocumentCategories,
} from 'hooks/api';
import {
  useCreateDirectPayment,
  useUpdateDirectPayment,
} from 'hooks/api/useTaxes';
import { getCategoryId } from 'components/documentsV1/DocumentForm';
import {
  useDocumentForDirectPayment,
  useDownloadDocumentForDirectPayment,
} from 'hooks/api/useDocuments';
import {
  DirectPayment,
  DirectPaymentCreateMutateVariables,
  DirectPaymentUpdateMutateVariables,
  DirectPaymentFormData,
} from 'components/taxes/types';
import { TempDocument } from 'components/documentsV2/types';
import { PaymentType, PaymentStatus } from 'enums';
import { TaxesDirectPaymentSuccessDialog } from 'components/taxes/dialogs/TaxesDirectPaymentSuccessDialog';
import { TaxesDirectPaymentForm } from './TaxesDirectPaymentForm';

const Container = styled(Grid)(() => ({
  maxHeight: 256,
  overflowY: 'auto',
  flexWrap: 'nowrap',
}));

const transformData = ({
  amount,
  paymentDate,
  year,
}: FormationsFormData): DirectPaymentFormData => ({
  amount: parseFloat(amount),
  paymentDate: formatDate(paymentDate),
  year: parseInt(year, 10),
});

interface IFormDialog {
  isOpen: boolean;
  onChange?: (data: FormationsFormData, formInstance: UseFormMethods) => void;
  onClose: () => void;
  fields: IFormField[];
  validationSchema: AnyObjectSchema;
  'data-testid'?: string;
  useFormOptions?: UseFormOptions;
  maxWidth?: Breakpoint;
  accountId: string | undefined;
  resetValues?: boolean;
  paymentToEdit?: DirectPayment | null;
  onSurveySubmitRedirect?: () => void;
  isMobile?: boolean;
}

const emptyFn = () => null;

export const TaxesDirectPaymentFormDialog = ({
  isOpen,
  onClose,
  fields,
  onChange = emptyFn,
  validationSchema,
  'data-testid': dataTestId,
  useFormOptions = {},
  maxWidth = 'xs',
  accountId,
  resetValues = true,
  paymentToEdit = null,
  onSurveySubmitRedirect,
  isMobile = false,
}: IFormDialog) => {
  const { t } = useTranslation();
  const { currentAccount } = useCurrentAccount();
  const companyId = currentAccount?.companyId || '';
  const { categories } = useDocumentCategories();
  const {
    type: typeDefaultValue,
    documentId: fileDefaultValue,
    note,
    id: paymentId,
    status,
    amount,
    year,
    paymentDate,
  } = paymentToEdit ?? {};
  // file download hooks
  const {
    document,
    isLoading: isLoadingDocumentInfo,
    isError: isDocumentError,
  } = useDocumentForDirectPayment(fileDefaultValue);
  const {
    blob,
    isLoading: isLoadingDocument,
    isError: isDownloadError,
  } = useDownloadDocumentForDirectPayment(fileDefaultValue);
  // radio group state
  const [radioValue, setRadioValue] = useState<PaymentType | null>(null);
  const [initialRadioValue, setInitialRadioValue] =
    useState<PaymentType | null>(null);
  // file upload state
  const [file, setFile] = useState<TempDocument | null>(null);
  const [error, setError] = useState<Error | null>(null);
  // state to track whether new document needs to be created when editing
  const [isEditFile, setIsEditFile] = useState(false);
  // success dialog state
  const [showSuccessDialogContent, setShowSuccessDialogContent] =
    useState(false);
  const [isCreateSuccess, setIsCreateSuccess] = useState(false);

  useEffect(() => {
    if (isDocumentError || isDownloadError) {
      setFile(null);
      showErrorToast(
        'Failed to load payment document associated with direct payment',
      );
    }
  }, [isDocumentError, isDownloadError]);

  // creates uploaded document to display in edit dialog
  useEffect(() => {
    if (blob && document) {
      const uploadedFile = new File([blob], document.metadata.filename, {
        type: document.metadata.mimeType,
      });
      const dotIndex = document.title.lastIndexOf('.');
      const displayName = document.title.slice(0, dotIndex);
      const extension = document.title.slice(dotIndex);
      setFile({ file: uploadedFile, displayName, extension });
    } else {
      setFile(null);
    }
  }, [blob, document]);

  useEffect(() => {
    // if upload dialog reset values and file
    if (isOpen && resetValues) {
      setRadioValue(null);
      setFile(null);
      setError(null);
    }
    // if edit dialog fill with default values
    if (isOpen && !resetValues) {
      setRadioValue(typeDefaultValue ?? null);
      setIsEditFile(false);
      setInitialRadioValue(typeDefaultValue ?? null);
      setError(null);
    }
  }, [isOpen]);

  const { mutateAsync: createDirectPayment, isLoading: creatingDirectPayment } =
    useCreateDirectPayment({
      onSuccess: async () => {
        await queryClient.invalidateQueries(['direct-payments']);
        setIsCreateSuccess(paymentToEdit == null);
        if (onSurveySubmitRedirect) {
          onSurveySubmitRedirect();
        } else {
          setShowSuccessDialogContent(true);
        }
      },
      onError: () => {
        showErrorToast(`Failed to create direct payment`);
      },
    });

  const callCreateDirectPaymentMutate = (
    sendData: DirectPaymentFormData,
    documentId: string,
  ) => {
    const mutateVariables: DirectPaymentCreateMutateVariables = {
      data: { ...sendData, type: radioValue ?? undefined, documentId },
      accountId: accountId ?? '',
    };
    return createDirectPayment(mutateVariables);
  };

  const { mutateAsync: updateDirectPayment, isLoading: updatingDirectPayment } =
    useUpdateDirectPayment({
      onSuccess: async () => {
        await queryClient.invalidateQueries(['direct-payments']);
        setIsCreateSuccess(paymentToEdit == null);
        setShowSuccessDialogContent(true);
      },
      onError: () => {
        showErrorToast(`Failed to update direct payment`);
      },
    });

  const callUpdateDirectPaymentMutate = (
    sendData: DirectPaymentFormData,
    documentId: string,
  ) => {
    const mutateVariables: DirectPaymentUpdateMutateVariables = {
      data: {
        ...sendData,
        type: radioValue ?? undefined,
        documentId,
        status: PaymentStatus.Submitted,
      },
      accountId: accountId ?? '',
      paymentId: paymentId!,
    };
    return updateDirectPayment(mutateVariables);
  };

  const { mutateAsync: createOrUpdateDocument, isLoading: creatingDocument } =
    useCreateDocument({
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['documents', 'accountId', accountId]);
        sendChurnZeroEvent(CHURN_ZERO_EVENT_NAMES.DOCUMENT_UPLOAD);
        const { data: responseData } = data;
        const { sendData } = variables;
        if (paymentToEdit !== null) {
          return callUpdateDirectPaymentMutate(sendData!, responseData.id);
        }
        return callCreateDirectPaymentMutate(sendData!, responseData.id);
      },
      onError: () => {
        showErrorToast(`Error in processing direct payment`);
      },
    });

  const findCategoryId = () =>
    radioValue === PaymentType.Federal
      ? getCategoryId(
          {
            department: 'Tax',
            category: 'Business',
            subcategory: 'Supporting Docs',
          },
          categories,
        )
      : getCategoryId(
          {
            department: 'Tax',
            category: 'State and Local',
            subcategory: 'State Tax',
          },
          categories,
        );

  const onSubmit = async (data: FormationsFormData) => {
    if (!file) {
      setError(new Error('A file must be uploaded in order to submit'));
      return;
    }

    const sendData = transformData(data);
    const categoryId = findCategoryId();
    await createOrUpdateDocument({
      form: {
        accountId,
        file: file.file,
        title: file.displayName + file.extension,
        year: new Date().getFullYear(),
        companyId,
        documentCategoryId: categoryId ?? '',
        visibleToCustomer: true,
      },
      sendData,
    });
  };

  const onEditSubmit = async (data: FormationsFormData) => {
    if (!file) {
      setError(new Error('A file must be uploaded in order to submit'));
      return;
    }

    const sendData = transformData(data);

    if (!isEditFile) {
      await callUpdateDirectPaymentMutate(sendData, fileDefaultValue ?? '');
      return;
    }

    const categoryId = findCategoryId();
    await createOrUpdateDocument({
      form: {
        accountId,
        file: file.file,
        title: file.displayName + file.extension,
        year: new Date().getFullYear(),
        companyId,
        documentCategoryId: categoryId ?? '',
        visibleToCustomer: true,
      },
      sendData,
    });
  };

  const onDrop = useCallback(() => {
    setError(null);
  }, []);

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    setIsEditFile(true);
    const dotIndex = acceptedFiles[0].name.lastIndexOf('.');
    const displayName = acceptedFiles[0].name.slice(0, dotIndex);
    const extension = acceptedFiles[0].name.slice(dotIndex);
    setFile({ file: acceptedFiles[0], displayName, extension });
  }, []);

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    fileUploadErrorHandler(fileRejections, setError);
  }, []);

  const handleDeleteFile = () => {
    setIsEditFile(true);
    setFile(null);
  };

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value as PaymentType);
  };

  const loading =
    creatingDirectPayment || creatingDocument || updatingDirectPayment;

  if (isLoadingDocument || isLoadingDocumentInfo) {
    return <Loading />;
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => {
          setShowSuccessDialogContent(false);
          onClose();
        }}
        fullScreen={isMobile}
        scroll="paper"
        fullWidth
        maxWidth={maxWidth}
        data-testid={dataTestId ?? 'form-dialog'}
        // to prevent flash of new content, maybe can adjust this by milliseconds
        transitionDuration={0}
      >
        {!showSuccessDialogContent ? (
          <>
            <DialogTitle>{t('taxes.dialog.title')}</DialogTitle>
            <DialogContent sx={{ pb: isMobile ? 10 : undefined }}>
              <DialogContentText>
                {t('taxes.dialog.subtitle')}
              </DialogContentText>
              {/* note alert for edit form */}
              {note && status === PaymentStatus.Rejected ? (
                <Alert
                  severity="error"
                  sx={{ mt: 1, overflowWrap: 'anywhere' }}
                >
                  {note}
                </Alert>
              ) : null}
              {/* radio group */}
              <Grid container sx={{ my: 2 }}>
                <Typography variant="body1B">
                  {t('taxes.dialog.radioQuestion')}
                </Typography>
                <RadioGroup value={radioValue} onChange={onRadioChange}>
                  <FormControlLabel
                    value={PaymentType.Federal}
                    control={<Radio data-testid="radio-federal" />}
                    label={t('taxes.dialog.federal')}
                    disabled={status === PaymentStatus.Approved}
                  />
                  <FormControlLabel
                    value={PaymentType.State}
                    control={<Radio data-testid="radio-state" />}
                    label={t('taxes.dialog.state')}
                    disabled={status === PaymentStatus.Approved}
                  />
                </RadioGroup>
              </Grid>
              {/* dialog form */}
              {radioValue && (
                <Grid container>
                  <Typography variant="body1B">
                    {radioValue === PaymentType.Federal
                      ? t('taxes.dialog.federal')
                      : t('taxes.dialog.state')}
                  </Typography>
                  <TaxesDirectPaymentForm
                    onChange={onChange}
                    onSubmit={paymentToEdit !== null ? onEditSubmit : onSubmit}
                    defaultValues={
                      paymentToEdit !== null
                        ? {
                            amount: amount?.toFixed(2),
                            year: year?.toString(),
                            paymentDate: formatDate(moment.utc(paymentDate)),
                          }
                        : {}
                    }
                    fields={fields}
                    onCancel={onClose}
                    validationSchema={validationSchema}
                    useFormOptions={useFormOptions}
                    loading={loading}
                    radioValue={radioValue}
                    setFile={setFile}
                    resetValues={resetValues}
                    renderActions={(formInstance) => (
                      <Grid container>
                        <Box sx={{ width: '100%' }}>
                          <Typography
                            variant="body2S"
                            color="text.secondary"
                            sx={{ mb: 2, pb: 2 }}
                          >
                            {t('taxes.dialog.uploadText')}
                          </Typography>
                          <Spacer height={2} />
                          <UploadFile
                            uploadProps={{
                              onDrop,
                              onDropAccepted,
                              onDropRejected,
                              accept: ALL_ALLOWED_FILES,
                              maxSize: MAX_DOCUMENT_SIZE_BYTES,
                              multiple: false,
                              noClick: true,
                              noKeyboard: true,
                              validator: nameLengthValidator,
                            }}
                            disabled={
                              file !== null || status === PaymentStatus.Approved
                            }
                          />
                          <Spacer height={1} />
                        </Box>
                        {error && (
                          <Box mt={1}>
                            <Alert severity="warning">{error.message}</Alert>
                          </Box>
                        )}
                        <Spacer height={3} />
                        <Container
                          container
                          flexDirection="column"
                          data-testid="documents-list"
                        >
                          {file && (
                            <FileUploadEditableRow
                              disabled={
                                status === PaymentStatus.Approved || loading
                              }
                              file={file}
                              handleDeleteFile={handleDeleteFile}
                              updateFileName={(editFile: TempDocument) => {
                                setFile({
                                  file: file.file,
                                  displayName: editFile.displayName,
                                  extension: file.extension,
                                });
                              }}
                              allowNameEdit={isEditFile}
                              isMobile={isMobile}
                            />
                          )}
                        </Container>
                        <Grid container justifyContent="flex-end">
                          <FormationsGhostButton
                            size="large"
                            onClick={onClose}
                            data-testid="form-cancel-btn"
                            disabled={loading}
                            sx={{ mr: 2 }}
                          >
                            {t('actions.cancel')}
                          </FormationsGhostButton>
                          <FormationsPrimaryButton
                            isLoading={loading}
                            size="large"
                            type="submit"
                            data-testid="form-save-btn"
                            disabled={
                              status === PaymentStatus.Approved ||
                              (!formInstance?.formState?.isDirty &&
                                !isEditFile &&
                                initialRadioValue === radioValue)
                            }
                          >
                            {paymentToEdit !== null
                              ? 'Update'
                              : t('actions.submit')}
                          </FormationsPrimaryButton>
                        </Grid>
                      </Grid>
                    )}
                  />
                </Grid>
              )}
            </DialogContent>
          </>
        ) : (
          <TaxesDirectPaymentSuccessDialog
            onClose={() => {
              setShowSuccessDialogContent(false);
              onClose();
            }}
            isCreateSuccess={isCreateSuccess}
            isMobile={isMobile}
          />
        )}
      </Dialog>
    </div>
  );
};
