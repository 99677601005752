import { ReactNode } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';

const useStyles = makeStyles(() => ({
  fullHeightContainer: {
    height: '100%',
  },
}));

interface Props {
  disabled?: boolean;
  icon: ReactNode;
  onClick: () => void;
}

export const ClickableArrow = (props: Props) => {
  const { disabled = false, icon, onClick } = props;
  const classes = useStyles();

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      xs={1}
      className={classes.fullHeightContainer}
    >
      <FormationsGhostButton
        rounded
        aria-label="prevFile"
        onClick={onClick}
        disabled={disabled}
      >
        {icon}
      </FormationsGhostButton>
    </Grid>
  );
};
