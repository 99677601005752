import { Controller, UseFormMethods } from 'react-hook-form';
import DatePicker from '@mui/lab/DatePicker';
import { TextField, TextFieldProps } from '@mui/material';
import { get } from 'lodash';
import { theme } from 'theme';
import { IFormField } from './types';

interface Options {
  placeholder?: string;
  disableFuture?: boolean;
  format?: string;
  shrink?: boolean;
  maxDate?: Date;
  minDate?: Date;
  defaultValue?: Date;
}

type TFormationsDateField = {
  field: IFormField<Options>;
  formInstance: UseFormMethods;
};

export const FormationsDate = ({
  field,
  formInstance,
}: TFormationsDateField) => {
  const { control, errors } = formInstance;
  const {
    disabled = false,
    options: {
      placeholder = '',
      disableFuture = false,
      format = 'MM/DD/YYYY',
      shrink = undefined,
      maxDate = undefined,
      minDate = undefined,
    } = {},
  } = field;
  const errorMsg = get(errors, [field.name, 'message'], '');
  return (
    <Controller
      control={control}
      name={field.name}
      defaultValue={field.defaultValue || null}
      render={({ onChange, value }) => (
        <DatePicker
          disabled={disabled}
          disableFuture={disableFuture}
          inputFormat={format}
          onChange={onChange}
          value={value}
          maxDate={maxDate}
          minDate={minDate}
          renderInput={(props: TextFieldProps) => (
            <TextField
              sx={{ svg: { color: theme.palette.text.secondary } }}
              {...props}
              name={field.name}
              placeholder={placeholder}
              label={field.label}
              variant="outlined"
              fullWidth
              error={!!errorMsg}
              helperText={errorMsg}
              data-testid={`field-${field.name}`}
              InputLabelProps={{ shrink }}
            />
          )}
        />
      )}
    />
  );
};
