import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as yup from 'yup';
import {
  FormationsFormActions,
  FormationsFormFields,
  IFormField,
  renderField,
} from 'components/common/FormationsForm2';
import { useForm } from 'react-hook-form';
import { DialogActions, Grid, Popover } from '@mui/material';
import {
  FormationsGhostButton,
  FormationsPrimaryButton,
} from 'components/common/Buttons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useUpdateDocument } from 'hooks/api';
import { queryClient } from 'states/reactQueryClient';
import { ActionPermissions, usePermissions } from 'hooks/usePermissions';
import { CONSTANTS } from 'constants/common';
import { showErrorToast } from 'components/toast/showToast';
import { AlertWarning } from '../../FormationsAlert';
import { IFormationsPreviewDocument } from '../FilePreviewComponent/FilePreviewComponent';

const schema = yup.object().shape({
  documentName: yup.string().nullable().required('Please enter a new name'),
});

const documentNameField: IFormField = {
  name: 'documentName',
  type: FormationsFormFields.Text,
  label: 'File Name',
  options: {
    characterLimit: 300,
    autoFocus: true,
    width: '100%',
  },
};

interface IEditNameDialog {
  anchorEl: Element | null;
  document: IFormationsPreviewDocument;
  open: boolean;
  onClose: () => void;
  onSuccess?: (newName: string) => void;
}

export const EditNameDialog = ({
  anchorEl,
  document,
  open,
  onClose,
  onSuccess,
}: IEditNameDialog) => {
  const { t } = useTranslation();
  const overrideRename = usePermissions(ActionPermissions.DOCUMENT_RENAME);

  const allowRename = useMemo(
    () => overrideRename || document.role === CONSTANTS.USER_ROLES.CUSTOMER,
    [document.role],
  );
  const { documentName, documentExtension } = useMemo(() => {
    const dotIndex = document.title.lastIndexOf('.');
    const displayName = document.title.slice(0, dotIndex);
    const extension = document.title.slice(dotIndex);
    return { documentName: displayName, documentExtension: extension };
  }, [document]);

  const { mutateAsync: updateDocument, isLoading: isDocumentUpdating } =
    useUpdateDocument({
      onSuccess: () => {
        queryClient.invalidateQueries([
          'documents',
          'accountId',
          document.accountId,
        ]);
        queryClient.invalidateQueries(['documents', document.id]);
      },
    });

  const formInstance = useForm({
    defaultValues: {
      documentName,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { handleSubmit, formState } = formInstance;

  const handleSubmitClick = async (data: { documentName: string }) => {
    try {
      await updateDocument({
        id: document.id,
        form: {
          title: data.documentName + documentExtension,
        },
      });
      if (onSuccess) {
        onSuccess(data.documentName + documentExtension);
      } else {
        onClose();
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        showErrorToast(e.message);
      } else {
        showErrorToast(t('documentHub.rename.renameError'));
      }
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
      open={open}
    >
      <form noValidate onSubmit={handleSubmit(handleSubmitClick)}>
        <DialogTitle sx={{ p: 3 }}>{t('documentHub.rename.title')}</DialogTitle>
        <DialogContent sx={{ px: 3, py: 0, width: 360 }}>
          {allowRename ? (
            <Grid item key="documentName" xs={12} sx={{ my: 0, pt: 1 }}>
              {renderField(documentNameField, formInstance)}
            </Grid>
          ) : (
            <AlertWarning text={t('documentHub.rename.renameReject')} />
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <FormationsFormActions sx={{ m: 0 }}>
            {!allowRename ? (
              <FormationsPrimaryButton
                size="large"
                onClick={onClose}
                data-testid="form-cancel-btn"
              >
                {t('actions.close')}
              </FormationsPrimaryButton>
            ) : (
              <>
                <FormationsGhostButton
                  size="large"
                  onClick={onClose}
                  data-testid="form-cancel-btn"
                  disabled={isDocumentUpdating}
                  sx={{ mr: 2 }}
                >
                  {t('actions.cancel')}
                </FormationsGhostButton>
                <FormationsPrimaryButton
                  isLoading={isDocumentUpdating}
                  size="large"
                  type="submit"
                  data-testid="form-save-btn"
                  disabled={!formState.isValid}
                >
                  {document.title ? t('actions.update') : t('actions.save')}
                </FormationsPrimaryButton>
              </>
            )}
          </FormationsFormActions>
        </DialogActions>
      </form>
    </Popover>
  );
};
