import * as yup from 'yup';

export const SetupYourGoalsSchema = yup.object().shape({
  projectedGrossIncome: yup.string().nullable().required('Please enter Projected Gross Income'),
  projectedBusinessExpenses: yup.string().nullable().required('Please enter Projected Business Expenses'),
  businessGoals: yup.array()
  .of(yup.string())
  .min(1, "Must choose at least one option."),
  personalFinancialGoals: yup.array()
  .of(yup.string())
  .min(1, "Must choose at least one option."),
  goalsWorkingWithFormations: yup.array()
  .of(yup.string())
  .min(1, "Must choose at least one option."),
});


