import React, { useEffect, useState } from 'react';
import { SaveIcon } from 'components/common/Icon';
import { FormationsTertiaryButton } from 'components/common/Buttons';
import { Typography } from '@mui/material';
import { homeAddressSchema } from 'schemas/homeAddressSchema';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormationsFormActions,
  FormationsFormContainer,
  FormationsFormContent,
  FormationsFormFields,
  FormationsFormItem,
  IFormField,
  renderField,
} from 'components/common/FormationsForm2';
import { formatZipCode } from 'components/common/FormationsForm2/FormationsTextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { 
  useCurrentCompany,
  useUpdateAccount, 
} from 'hooks/api';
import { Address } from 'models/company';
import {
  IAccount,
  ProgressTrackerStatus,
} from 'models/account';
import { ReadOnlyForm } from 'components/common/ReadOnlyForm';
import { isPhysicalSameAsMailing } from 'components/AdminProgressTracker/Incorporation/BusinessAddress';
import { STATES } from '../../../enums';

interface TParams {
  handleContinue: () => void;
  currentStatus: ProgressTrackerStatus;
  account: IAccount;
}

interface IFormData {
  homeAddress1: string;
  homeAddress2: string;
  homeCity: string;
  homeState: string;
  homeZip: string;
}

const defaultAddress = {
  homeSameAsBusiness: false,
  homeSameAsMailing: false,
  homeAddress1: '',
  homeAddress2: '',
  homeCity: '',
  homeState: '',
  homeZip: '',
};

export const HomeAddress = ({
  handleContinue,
  currentStatus,
  account,
}: TParams) => {
  const { t } = useTranslation();
  const { currentCompany } = useCurrentCompany();

  const mapState = (state: { name: string; code: string }) => ({
    name: state.name,
    value: state.code,
  });

  const isPhysicalAddressSameAsMailing = isPhysicalSameAsMailing(currentCompany?.contactDetails?.mailingAddress, currentCompany?.contactDetails?.physicalAddress)
  
  const formFields: IFormField[] = [
    ...(isPhysicalAddressSameAsMailing ? [
      {
        type: FormationsFormFields.CheckboxField,
        name: 'homeSameAsBoth',
        label: t('progressTracker.incorporation.homeSameAsBoth'),
        options: {
          placeholder: '',
        },
        disabled: !currentCompany?.contactDetails?.physicalAddress?.street1,
      }
    ] : [
      {
        type: FormationsFormFields.CheckboxField,
        name: 'homeSameAsBusiness',
        label: t('progressTracker.incorporation.homeSameAsBusiness'),
        options: {
          placeholder: '',
        },
        disabled: !currentCompany?.contactDetails?.physicalAddress?.street1,
      },
      {
        type: FormationsFormFields.CheckboxField,
        name: 'homeSameAsMailing',
        label: t('progressTracker.incorporation.homeSameAsMailing'),
        options: {
          placeholder: '',
        },
        disabled: !currentCompany?.contactDetails?.mailingAddress?.street1,
      },
    ]),
    {
      type: FormationsFormFields.Text,
      name: 'homeAddress1',
      label: 'Address Line 1',
      options: {
        placeholder: '1234 Ave E',
        maxLength: 25,
      },
    },
    {
      type: FormationsFormFields.Text,
      name: 'homeAddress2',
      label: 'Address Line 2',
      options: {
        placeholder: '',
        maxLength: 250,
      },
    },
    {
      type: FormationsFormFields.Text,
      name: 'homeCity',
      label: 'City',
      options: {
        placeholder: 'Seattle',
        maxLength: 50,
      },
    },
    {
      type: FormationsFormFields.AutoComplete,
      name: 'homeState',
      label: 'State',
      options: {
        placeholder: 'Washington',
        options: STATES.map(mapState),
      },
    },
    {
      type: FormationsFormFields.Text,
      name: 'homeZip',
      label: 'Zip Code',
      options: {
        placeholder: '12345',
        formatInput: formatZipCode,
      },
    },
  ];

  const formInstance = useForm({
    ...defaultAddress,
    resolver: yupResolver(homeAddressSchema),
  });

  const homeSameAsBusiness = formInstance.watch('homeSameAsBusiness');
  const homeSameAsMailing = formInstance.watch('homeSameAsMailing');
  const homeSameAsBoth = formInstance.watch('homeSameAsBoth');
  const homeAddress1 = formInstance.watch('homeAddress1');
  const homeAddress2 = formInstance.watch('homeAddress2');
  const homeCity = formInstance.watch('homeCity');
  const homeState = formInstance.watch('homeState');
  const homeZip = formInstance.watch('homeZip');
  const [userHasTypedAfterCheckingMailingBox, setUserHasTypedAfterCheckingMailingBox] = useState(false);
  const [userHasTypedAfterCheckingBusinessBox, setUserHasTypedAfterCheckingBusinessBox] = useState(false);
  const [userHasTypedAfterCheckingBothBox, setUserHasTypedAfterCheckingBothBox] = useState(false);

  useEffect(() => {
    if (homeSameAsBoth === true) {
      formInstance.reset({
        homeSameAsBoth: true,
        homeAddress1: currentCompany?.contactDetails?.physicalAddress?.street1,
        homeAddress2: currentCompany?.contactDetails?.physicalAddress?.street2,
        homeCity: currentCompany?.contactDetails?.physicalAddress?.city,
        homeState: currentCompany?.contactDetails?.physicalAddress?.state,
        homeZip: currentCompany?.contactDetails?.physicalAddress?.zip,
      });
    }
  }, [homeSameAsBoth]);

  useEffect(() => {
    if (homeSameAsBusiness === true) {
      formInstance.setValue('homeSameAsMailing', false);
      formInstance.reset({
        homeSameAsBusiness: true,
        homeSameAsMailing: false,
        homeAddress1: currentCompany?.contactDetails?.physicalAddress?.street1,
        homeAddress2: currentCompany?.contactDetails?.physicalAddress?.street2,
        homeCity: currentCompany?.contactDetails?.physicalAddress?.city,
        homeState: currentCompany?.contactDetails?.physicalAddress?.state,
        homeZip: currentCompany?.contactDetails?.physicalAddress?.zip,
      });
    }
  }, [homeSameAsBusiness]);

  useEffect(() => {
    if (homeSameAsMailing === true) {
      formInstance.setValue('homeSameAsBusiness', false);
      formInstance.reset({
        homeSameAsBusiness: false,
        homeSameAsMailing: true,
        homeAddress1: currentCompany?.contactDetails?.mailingAddress?.street1,
        homeAddress2: currentCompany?.contactDetails?.mailingAddress?.street2,
        homeCity: currentCompany?.contactDetails?.mailingAddress?.city,
        homeState: currentCompany?.contactDetails?.mailingAddress?.state,
        homeZip: currentCompany?.contactDetails?.mailingAddress?.zip,
      });
    } 
  }, [homeSameAsMailing]);

  useEffect(() => {
    if (homeSameAsBoth === false && userHasTypedAfterCheckingBothBox === false) {
      formInstance.reset({
        ...defaultAddress
      });
    }
  }, [homeSameAsBoth]);

  useEffect(() => {
    if (homeSameAsBusiness === false && homeSameAsMailing === false && (userHasTypedAfterCheckingMailingBox === false || userHasTypedAfterCheckingBusinessBox === false)) {
      formInstance.reset({
        ...defaultAddress
      });
    }
  }, [homeSameAsBusiness, homeSameAsMailing]);

  const checkAddress = (address: Address | undefined, setUserHasTyped: React.Dispatch<React.SetStateAction<boolean>>, setSameAs: string) => {
    if (homeAddress1 !== address?.street1 ||
        homeAddress2 !== address?.street2 ||
        homeCity !== address?.city ||
        homeState !== address?.state ||
        homeZip !== address?.zip) {
      setUserHasTyped(true);
      formInstance.setValue(setSameAs, false);
    } else if (homeAddress1 === address?.street1 &&
        homeAddress2 === address?.street2 &&
        homeCity === address?.city &&
        homeState === address?.state &&
        homeZip === address?.zip) {
      setUserHasTyped(false);
      formInstance.setValue(setSameAs, true);
    }
  }
  
  useEffect(() => {
    checkAddress(currentCompany?.contactDetails?.physicalAddress, setUserHasTypedAfterCheckingBothBox, 'homeSameAsBoth');
    checkAddress(currentCompany?.contactDetails?.physicalAddress, setUserHasTypedAfterCheckingBusinessBox, 'homeSameAsBusiness');
    checkAddress(currentCompany?.contactDetails?.mailingAddress, setUserHasTypedAfterCheckingMailingBox, 'homeSameAsMailing');
  }, [homeAddress1, homeAddress2, homeCity, homeState, homeZip]);

  const { mutateAsync: updateAccount, isLoading } = useUpdateAccount(
    account.id,
  );

  const onFormSubmit = async (formData: IFormData) => {
    const homeAddress: Address = {
      street1: formData.homeAddress1,
      street2: formData.homeAddress2,
      city: formData.homeCity,
      state: formData.homeState,
      zip: formData.homeZip,
    };
    await updateAccount({
      homeAddress
    });
    handleContinue();
  }

  return (
    <div>
      <Typography variant="h5B" component="h5" data-test-id="address-details">
        Home Address
      </Typography>
      {currentStatus === ProgressTrackerStatus.Completed && (
        <ReadOnlyForm
          items = {[
            {
              key: 'Street',
              title: 'Street',
              value: account?.homeAddress?.street1 ? `${account?.homeAddress?.street1} ${account?.homeAddress?.street2}` : 'N/A',
            },
            {
              key: 'City',
              title: 'City',
              value: account?.homeAddress?.city ?? 'N/A',
            },
            {
              key: 'State',
              title: 'State',
              value: account?.homeAddress?.state ?? 'N/A',
            },
            {
              key: 'Zip Code',
              title: 'Zip Code',
              value: account?.homeAddress?.zip ?? 'N/A',
            },
          ]}        
        />
      )}
      {currentStatus !== ProgressTrackerStatus.Completed && (
        <>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            {t('progressTracker.incorporation.homeAddressSubtitle')}
          </Typography>
          <FormationsFormContainer>
            <form onSubmit={formInstance.handleSubmit(onFormSubmit)}>
              <FormationsFormContent>
                {formFields.map((field) => (
                  <FormationsFormItem key={field.name}>
                    {renderField(field, formInstance)}
                  </FormationsFormItem>
                ))}
              </FormationsFormContent>
              <FormationsFormActions>
                <FormationsTertiaryButton
                  isLoading={isLoading}
                  type="submit"
                  size="large"
                  data-testid="save-address"
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                >
                  Save and Continue
                </FormationsTertiaryButton>
              </FormationsFormActions>
            </form>
          </FormationsFormContainer>
        </>
      )}
    </div>
  );
};