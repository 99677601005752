import { Box, Typography } from '@mui/material';
import { IncorporationBusinessInABox } from 'components/ProgressTracker/Incorporation/IncorporationBusinessInABox';
import { AccountVerificationComponentBIB } from 'components/ProgressTracker/AccountVerification/AccountVerificationComponentBIB';
import { useMediaBreakpoints } from 'hooks';
import { IAccount } from 'models/account';
import React, { useMemo, useState } from 'react';
import { useCurrentCompany, useCurrentUser } from 'hooks/api';
import { IncorporationStatus } from 'components/IncorporationStatus/IncorporationStatus';
import { useSignedDocument } from 'hooks/api/useDropboxSign';
import { DropboxSignTemplates } from 'services/dropboxSign';
import { useCurrentPaymentStatus } from 'hooks/api/useStripe';
import { ReviewAndSignComponentBIB } from './ReviewAndSign/ReviewAndSignComponentBIB';
import { BankSetupBIB } from './BankSetup/BankSetupBIB';
import { BusinessPackageComponent } from './BusinessPackage/BusinessPackageComponent';
import { ReviewAndPayComponent } from './ReviewAndPay/ReviewAndPayComponent';

interface Props {
  account: IAccount;
}

export const ProgressTrackerBusinessInABox = ({ account }: Props) => {
  const { isMobile, isDesktop } = useMediaBreakpoints();
  const { currentCompany: companyData } = useCurrentCompany();
  const { currentUser } = useCurrentUser();
  const { alreadyPaid } = useCurrentPaymentStatus(account);
  const incorporationFinished = useMemo(
    () => companyData?.bibHubspot?.ticketStatus === 'COMPLETED',
    [companyData?.bibHubspot?.ticketStatus],
  );
  const { signedDocuments } = useSignedDocument({
    accountId: account.id,
    template: DropboxSignTemplates.LLCOnlySignature,
  });

  const firstTwoSectionsCompleted = useMemo(() => {
    if (
      !currentUser?.dob ||
      !companyData?.stateOfIncorporation ||
      !companyData?.contactDetails ||
      !account?.homeAddress
    ) {
      return false;
    }
    return true;
  }, [currentUser, companyData, account]);

  const reviewAndSignCompleted = useMemo(
    () => signedDocuments && signedDocuments.length > 0,
    [signedDocuments],
  );

  const bankSetupCompleted = useMemo(
    () =>
      account?.progress.some(
        (progressItem) =>
          progressItem.stage === 'bankInformation' &&
          progressItem.status.some(
            (statusItem) => statusItem.status === 'completed',
          ),
      ),
    [account?.progress],
  );

  const [expandedIncorporationSection, setExpandedIncorporationSection] =
    useState<string | false>(false);
  const [
    expandedAccountVerificationSection,
    setExpandedAccountVerificationSection,
  ] = useState<string | false>(false);
  const [expandedReviewAndPaySection, setExpandedReviewAndPaySection] =
    useState<string | false>(false);
  const [expandedReviewAndSignSection, setExpandedReviewAndSignSection] =
    useState<string | false>(false);
  const [expandedBankSetupSection, setExpandedBankSetupSection] = useState<
    string | false
  >(false);
  const [expandedBusinessPackageSection, setExpandedBusinessPackageSection] =
    useState<string | false>(false);

  const handleExpandIncorporationSection =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedIncorporationSection(isExpanded ? panel : false);
    };
  const handleExpandAccountVerificationSection =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedAccountVerificationSection(isExpanded ? panel : false);
    };
  const handleExpandReviewAndPaySection =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedReviewAndPaySection(isExpanded ? panel : false);
    };
  const handleExpandedReviewAndSignSection =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedReviewAndSignSection(isExpanded ? panel : false);
    };
  const handleExpandedBankSetupSection =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedBankSetupSection(isExpanded ? panel : false);
    };
  const handleExpandedBusinessPackageSection =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedBusinessPackageSection(isExpanded ? panel : false);
    };

  const onCollapseAllSections = () => {
    setExpandedIncorporationSection(false);
    setExpandedAccountVerificationSection(false);
    setExpandedReviewAndPaySection(false);
    setExpandedReviewAndSignSection(false);
    setExpandedBankSetupSection(false);
    setExpandedBusinessPackageSection(false);
  };

  const onContinueToAccountVerification = () => {
    onCollapseAllSections();
    setExpandedAccountVerificationSection('account-verification');
  };

  const onContinueToReviewAndPay = () => {
    onCollapseAllSections();
    setExpandedReviewAndPaySection('review-and-pay');
  };

  const onContinueToBankSetup = () => {
    onCollapseAllSections();
    setExpandedBankSetupSection('bank-setup');
  };

  const onContinueToBusinessPackage = () => {
    onCollapseAllSections();
    setExpandedBusinessPackageSection('business-package');
  };

  const handleAccountVerificationOnContinue = () => {
    if (!alreadyPaid && firstTwoSectionsCompleted) {
      onContinueToReviewAndPay();
    }
    onCollapseAllSections();
  };

  return (
    <>
      <Typography variant="h4" align="center" sx={{ pt: 8 }} color="#0D2259">
        Let’s Get Your Business Incorporated
      </Typography>
      <Box
        sx={{
          width: 'auto',
          px: isMobile ? 2 : 9,
          py: 2,
          mt: 2,
        }}
      >
        <IncorporationBusinessInABox
          isDesktop={isDesktop}
          handleExpand={handleExpandIncorporationSection}
          expanded={expandedIncorporationSection}
          onContinueToAccountVerification={onContinueToAccountVerification}
        />
        <AccountVerificationComponentBIB
          handleExpand={handleExpandAccountVerificationSection}
          expanded={expandedAccountVerificationSection}
          onContinueToNextSection={handleAccountVerificationOnContinue}
        />
        {!alreadyPaid && (
          <ReviewAndPayComponent
            handleExpand={handleExpandReviewAndPaySection}
            expanded={expandedReviewAndPaySection}
            disabled={!firstTwoSectionsCompleted}
          />
        )}
        {!incorporationFinished && alreadyPaid && (
          <IncorporationStatus account={account} />
        )}
        <ReviewAndSignComponentBIB
          handleExpand={handleExpandedReviewAndSignSection}
          expanded={expandedReviewAndSignSection}
          onContinueToNextSection={onContinueToBankSetup}
          disabled={!incorporationFinished}
        />
        <BankSetupBIB
          isDesktop={isDesktop}
          handleExpand={handleExpandedBankSetupSection}
          expanded={expandedBankSetupSection}
          onContinueToNextSection={onContinueToBusinessPackage}
          disabled={!reviewAndSignCompleted}
        />
        <BusinessPackageComponent
          handleExpand={handleExpandedBusinessPackageSection}
          expanded={expandedBusinessPackageSection}
          disabled={!bankSetupCompleted}
        />
      </Box>
    </>
  );
};
