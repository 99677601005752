export const BANK_ACCOUNT_TYPES = new Map([
  ['checking', 'Checking'],
  ['savings', 'Savings'],
]);

export const BANK_ACCOUNT_OPTIONS = [
  {
    label: 'Checking',
    value: 'checking',
  },
  {
    label: 'Savings',
    value: 'savings',
  },
];