import {
  COMPLETION_STATUS,
  HEALTH_INSURANCE_OPTION,
  UPDATED_HEALTH_INSURANCE_OPTION,
  RETIREMENT_PLAN_LIST,
  RETIREMENT_PROGRAM_OPTION,
  VENDOR_1099_OPTION,
  ANNUAL_INCOME_LIST,
  FILING_STATUS_OPTION,
} from 'enums/taxSurveyOptions';
import { isEmpty } from 'lodash';
import { getISOFormatTime } from 'helpers/dateTimeFormat';
import { api, AxiosResponse } from './axios';

interface TaxSurveyResult {
  data: TaxSurveyData;
}

export interface TaxSurveyData {
  id: string;
  completionStatus: COMPLETION_STATUS;
  filingStatus?: string;
  additionalIncome?: string;
  annualHouseholdIncome?: string;
  taxesPaidForCurrentYear?: boolean;
  currentYearFederalTaxAmount?: string;
  currentYearStateTaxAmount?: string;
  healthInsuranceOption?: HEALTH_INSURANCE_OPTION;
  paidHealthInsuranceThroughBusiness?: boolean;
  totalInsurancePaymentAmount?: string;
  vendorsFor1099s?: VENDOR_1099_OPTION;
  businessRetirementProgramExists?: RETIREMENT_PROGRAM_OPTION;
  currentBusinessRetirementPlans?: {
    selections: RETIREMENT_PLAN_LIST[];
    other: string;
  };
  totalRetirementContributions?: string;
  targetRetirementContributions?: string;
  year: number;
}

export interface AdditionalIncome {
  incomeType: string;
  amount: number;
  otherAmount?: number;
  description?: string;
  documentIds?: string[];
}

type RetirementPlan = {
  selections: RETIREMENT_PLAN_LIST[];
  other: string;
};

type Address = {
  Street1: string;
  Street2: string;
  City: string;
  State: string;
  Country: string;
  Zip: string;
};

export interface CurrentYearSurveyData {
  // Important fields
  id?: string;
  accountId: string;
  year: number;
  month: number;
  completionStatus?: COMPLETION_STATUS;

  // Address fields
  currentHomeAddress?: Address;
  homeAddressUnchanged?: boolean;
  newHomeAddress?: Address;
  moveDate?: string;
  previousResidencyPlan?: string;

  // Filing status fields
  currentFilingStatus?: FILING_STATUS_OPTION;
  filingStatusUnchanged?: boolean;
  newFilingStatus?: FILING_STATUS_OPTION;
  filingStatusChangeReason?: string;
  filingStatusChangeDate?: string;

  // Dependents fields
  currentDependents?: number;
  dependentsUnchanged?: boolean;
  newDependents?: number;
  dependentsChangeReason?: string;
  dependentsChangeDate?: string;

  // HIP fields
  currentHIPStatus?: UPDATED_HEALTH_INSURANCE_OPTION;
  currentHIPMonthlyPremium?: number;
  hipStatusUnchanged?: boolean;
  newHIPStatus?: UPDATED_HEALTH_INSURANCE_OPTION;
  newHIPMonthlyPremium?: number;
  hipStatusChangeDate?: string;

  // Retirement plan fields
  currentRetirementProgram?: RETIREMENT_PROGRAM_OPTION;
  currentRetirementPlan?: RetirementPlan;
  retirementPlanUnchanged?: boolean;
  newRetirementProgram?: RETIREMENT_PROGRAM_OPTION;
  newRetirementPlan?: RetirementPlan;
  retirementPlanYTDContribution?: number;
  retirementCYContributionPlan?: string;

  // Projected gross income fields
  currentProjectedGrossIncome?: number;
  projectedGrossIncomeUnchanged?: boolean;
  newProjectedGrossIncome?: number;

  // Misc fields
  additionalNonBusinessIncomes?: AdditionalIncome[];
  additionalItems?: string;
  paidWithBusinessAccount?: boolean;
  estimatedYTDDirectTaxPayments?: number;

  // Timestamp fields
  completedAt?: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
}

export type TaxSurveyFormData = {
  completionStatusFormField?: COMPLETION_STATUS;
  homeAddressUnchangedFormField?: boolean;
  mailingAddress1FormField?: string;
  mailingAddress2FormField?: string;
  mailingCityFormField?: string;
  mailingStateFormField: string;
  mailingZipFormField?: string;
  moveDateFormField?: string | null;
  previousResidencyPlanFormField?: string;
  filingStatusUnchangedFormField?: boolean;
  newFilingStatusFormField?: string;
  filingStatusChangeReasonFormField?: string;
  filingStatusChangeDateFormField?: string | null;
  dependentsUnchangedFormField?: boolean;
  newDependentsFormField?: string;
  dependentsChangeReasonFormField?: string;
  dependentsChangeDateFormField?: string | null;
  hipStatusUnchangedFormField?: boolean;
  updateHipMonthlyPremiumFormField?: string;
  newHipStatusFormField?: string;
  newHipMonthlyPremiumFormField?: string;
  hipStatusChangeDateFormField?: string;
  retirementPlanUnchangedFormField?: boolean;
  updateRetirementPlanYTDContributionFormField?: string;
  newRetirementProgramFormField?: string;
  newRetirementPlanFormField?: string[];
  otherRetirementPlanFormField?: string;
  retirementPlanYTDContributionFormField?: string;
  retirementCYContributionPlanFormField?: string;
  projectedGrossIncomeUnchangedFormField?: boolean;
  newProjectedGrossIncomeFormField?: string;
  incomeTypeFormField?: string[];
  otherWagesTotalAnnualAmountFormField?: string;
  otherWagesTotalAnnualOtherAmountFormField?: string;
  otherWagesTotalAnnualFilesFormField?: File[];
  otherWagesTotalAnnualDocumentIds?: string[];
  otherBusinessesTotalAnnualAmountFormField?: string;
  otherBusinessesTotalAnnualFilesFormField?: File[];
  otherBusinessesTotalAnnualDocumentIds?: string[];
  retirementOrSocialSecurityTotalAnnualAmountFormField?: string;
  rentTotalAnnualAmountFormField?: string;
  rentTotalAnnualOtherAmountFormField?: string;
  rentTotalAnnualFilesFormField?: File[];
  rentTotalAnnualDocumentIds?: string[];
  spousalTotalAnnualAmountFormField?: string;
  spousalTotalAnnualOtherAmountFormField?: string;
  spousalTotalAnnualFilesFormField?: File[];
  spousalTotalAnnualDocumentIds?: string[];
  stocksYTDAmountFormField?: string;
  stocksYTDFilesFormField?: File[];
  stocksYTDDocumentIds?: string[];
  stocksDescriptionFormField?: string;
  supplementalIncomeYTDAmountFormField?: string;
  supplementalIncomeFiles?: File[];
  supplementalIncomeDocumentIds?: string[];
  supplementalIncomeDescriptionFormField?: string;
  additionalItemsFormField?: string;
  estimatedYTDDirectTaxPaymentsFormField?: string;
};

export const createAddressOrNull = (data: any): Address | null => {
  const address = {
    Street1: data.mailingAddress1FormField,
    Street2: data.mailingAddress2FormField,
    City: data.mailingCityFormField,
    State: data.mailingStateFormField,
    Country: '',
    Zip: data.mailingZipFormField,
  };

  return Object.values(address).every(
    (field) => field === null || field === undefined || field === '',
  )
    ? null
    : address;
};

export const createAdditionalIncomes = (
  data: any,
): AdditionalIncome[] | null => {
  const additionalIncomes: AdditionalIncome[] = [];

  data.incomeTypeFormField?.forEach((type: string) => {
    const additionalIncome: AdditionalIncome = { incomeType: type, amount: 0 };

    switch (type) {
      case ANNUAL_INCOME_LIST.OTHER_JOB_WAGES:
        additionalIncome.amount = parseFloat(
          data.otherWagesTotalAnnualAmountFormField,
        ); // Wage
        additionalIncome.otherAmount = parseFloat(
          data.otherWagesTotalAnnualOtherAmountFormField,
        ); // Withholding
        additionalIncome.documentIds = data.otherWagesTotalAnnualDocumentIds; // Document IDs
        break;
      case ANNUAL_INCOME_LIST.SPOUSAL:
        additionalIncome.amount = parseFloat(
          data.spousalTotalAnnualAmountFormField,
        ); // Wage
        additionalIncome.otherAmount = parseFloat(
          data.spousalTotalAnnualOtherAmountFormField,
        ); // Withholding
        additionalIncome.documentIds = data.spousalTotalAnnualDocumentIds; // Document IDs
        break;
      case ANNUAL_INCOME_LIST.OTHER_BUSINESS:
        additionalIncome.amount = parseFloat(
          data.otherBusinessesTotalAnnualAmountFormField,
        ); // Net Income
        additionalIncome.documentIds =
          data.otherBusinessesTotalAnnualDocumentIds; // Document IDs
        break;
      case ANNUAL_INCOME_LIST.STOCKS:
        additionalIncome.amount = parseFloat(data.stocksYTDAmountFormField); // Capital Gains estimate
        additionalIncome.description = data.stocksDescriptionFormField; // Description of capital gains
        additionalIncome.documentIds = data.stocksYTDDocumentIds; // Document IDs
        break;
      case ANNUAL_INCOME_LIST.RENT:
        additionalIncome.amount = parseFloat(
          data.rentTotalAnnualAmountFormField,
        ); // Total Income
        additionalIncome.otherAmount = parseFloat(
          data.rentTotalAnnualOtherAmountFormField,
        ); // Total Expenses
        additionalIncome.documentIds = data.rentTotalAnnualDocumentIds; // Document IDs
        break;
      case ANNUAL_INCOME_LIST.RETIREMENT_OR_SOCIAL_SECURITY:
        additionalIncome.amount = parseFloat(
          data.retirementOrSocialSecurityTotalAnnualAmountFormField,
        ); // Distribution Estimate
        break;
      case ANNUAL_INCOME_LIST.SUPPLEMENTAL:
        additionalIncome.amount = parseFloat(
          data.supplementalIncomeYTDAmountFormField,
        ); // Amount
        additionalIncome.description =
          data.supplementalIncomeDescriptionFormField; // Description
        additionalIncome.documentIds = data.supplementalIncomeDocumentIds; // Document IDs
        break;
      default:
        break;
    }

    additionalIncomes.push(additionalIncome);
  });

  return additionalIncomes;
};

export const createRetirementPlanOrNull = (
  data: any,
): RetirementPlan | null => {
  const retirementPlan = {
    selections: data.newRetirementPlanFormField
      ? data.newRetirementPlanFormField
      : null,
    other: data.otherRetirementPlanFormField || null,
  };
  return Array.isArray(retirementPlan.selections) &&
    retirementPlan.selections.length
    ? retirementPlan
    : null;
};

export const transformEmptyDataToNulls = (params: any): any => {
  const data: any = {};
  if (params == null) {
    return data;
  }
  Object.entries(params).forEach(([key, value]) => {
    if (
      typeof value === 'object' ||
      typeof value === 'boolean' ||
      (typeof value === 'number' && !Number.isNaN(value)) ||
      Array.isArray(value) ||
      !isEmpty(value)
    ) {
      data[key] = value;
    } else {
      data[key] = null;
    }
  });

  return data;
};

const populateData = (
  params: FormData,
  accountId: string,
  taxSurveyData: CurrentYearSurveyData,
): any => {
  const data = transformEmptyDataToNulls(params);

  const sendData = {
    // status will be mark as completed on every submit
    completionStatus: COMPLETION_STATUS.COMPLETED,

    // Important fields
    accountID: accountId,
    year: taxSurveyData.year,
    month: taxSurveyData.month,

    // Address fields
    currentHomeAddress: taxSurveyData.currentHomeAddress,
    homeAddressUnchanged:
      typeof data.homeAddressUnchangedFormField === 'boolean'
        ? data.homeAddressUnchangedFormField
        : false,
    newHomeAddress: createAddressOrNull(data),
    moveDate: data.moveDateFormField
      ? getISOFormatTime(data.moveDateFormField)
      : null,
    previousResidencyPlan: data.previousResidencyPlanFormField,

    // Filing status fields
    currentFilingStatus: taxSurveyData.currentFilingStatus,
    filingStatusUnchanged:
      typeof data.filingStatusUnchangedFormField === 'boolean'
        ? data.filingStatusUnchangedFormField
        : false,
    newFilingStatus: data.newFilingStatusFormField,
    filingStatusChangeReason: data.filingStatusChangeReasonFormField,
    filingStatusChangeDate: data.filingStatusChangeDateFormField
      ? getISOFormatTime(data.filingStatusChangeDateFormField)
      : null,

    // Dependents fields
    currentDependents: taxSurveyData.currentDependents,
    dependentsUnchanged:
      typeof data.dependentsUnchangedFormField === 'boolean'
        ? data.dependentsUnchangedFormField
        : false,
    newDependents: parseInt(data.newDependentsFormField, 10),
    dependentsChangeReason: data.dependentsChangeReasonFormField,
    dependentsChangeDate: data.dependentsChangeDateFormField
      ? getISOFormatTime(data.dependentsChangeDateFormField)
      : null,

    // HIP fields
    currentHIPStatus: taxSurveyData.currentHIPStatus,
    currentHIPMonthlyPremium: taxSurveyData.currentHIPMonthlyPremium,
    hipStatusUnchanged:
      typeof data.hipStatusUnchangedFormField === 'boolean'
        ? data.hipStatusUnchangedFormField
        : false,
    newHIPStatus: data.newHipStatusFormField,
    newHIPMonthlyPremium: data.updateHipMonthlyPremiumFormField
      ? parseFloat(data.updateHipMonthlyPremiumFormField)
      : parseFloat(data.newHipMonthlyPremiumFormField),
    hipStatusChangeDate: data.hipStatusChangeDateFormField,

    // Retirement plan fields
    currentRetirementProgram: taxSurveyData.currentRetirementProgram,
    currentRetirementPlan: taxSurveyData.currentRetirementPlan,
    retirementPlanUnchanged:
      typeof data.retirementPlanUnchangedFormField === 'boolean'
        ? data.retirementPlanUnchangedFormField
        : false,
    newRetirementProgram: data.newRetirementProgramFormField,
    newRetirementPlan: createRetirementPlanOrNull(data),
    retirementPlanYTDContribution: data.retirementPlanUnchangedFormField
      ? parseFloat(data.updateRetirementPlanYTDContributionFormField)
      : parseFloat(data.retirementPlanYTDContributionFormField),
    retirementCYContributionPlan: data.retirementCYContributionPlanFormField,

    // Projected gross income fields
    currentProjectedGrossIncome: taxSurveyData.currentProjectedGrossIncome,
    projectedGrossIncomeUnchanged:
      typeof data.projectedGrossIncomeUnchangedFormField === 'boolean'
        ? data.projectedGrossIncomeUnchangedFormField
        : false,
    newProjectedGrossIncome: parseFloat(data.newProjectedGrossIncomeFormField),

    // Misc fields
    additionalNonBusinessIncomes: createAdditionalIncomes(data),
    additionalItems: data.additionalItemsFormField,
  };

  const nulledSendData = transformEmptyDataToNulls(sendData);
  return nulledSendData;
};

export const getTaxSurveyDataByUserId = async (
  userId: string,
): Promise<TaxSurveyData> => {
  const { data }: AxiosResponse<TaxSurveyResult> = await api.get(
    `tax-liability/${userId}`,
  );
  return data.data;
};

export const upsertTaxSurveyDataForUser = async (
  userId: string,
  accountId: string,
  params: TaxSurveyData,
) => {
  const reqBody: any = {};
  Object.entries(params).forEach(([key, value]) => {
    if (typeof value === 'boolean' || !isEmpty(value)) {
      reqBody[key] = value;
    } else {
      reqBody[key] = null;
    }
  });
  reqBody.userId = userId;
  reqBody.accountId = accountId;
  await api.post('tax-liability', reqBody);
};

export const getMidYearSurveyDataByUserId = async (
  accountId: string,
  year?: number,
  month?: number,
): Promise<CurrentYearSurveyData> => {
  const { data } = await api.get<CurrentYearSurveyData>('checkin-survey', {
    params: {
      accountID: accountId,
      year,
      month,
    },
  });
  return data;
};

export const upsertMidYearSurveyDataForUser = async (
  accountId: string,
  params: FormData,
  taxSurveyData: CurrentYearSurveyData,
) => {
  const reqBody = populateData(params, accountId, taxSurveyData);
  await api.post<CurrentYearSurveyData>('checkin-survey', reqBody);
};

export const updateSupportedDocument = (
  surveyId: string,
  incomeType: string,
  documentIds: string[],
) =>
  api.patch(`checkin-survey/${surveyId}`, {
    incomeType,
    documentIds,
  });
