import { PropsWithChildren, ReactNode, useCallback } from 'react';
import { Box } from '@mui/material';
import { TopNavBar } from '../TopNavBar';
import { SideBarDesktop } from './desktop';
import { useMediaBreakpoints, useLogout } from '../../../hooks';
import { useMenuItems } from './useMenuItems';
import { IAction } from './constant';
import { HamburgerMenuMobile } from './mobile/HamburgerMenuMobile';

interface Props {
  children: ReactNode;
  isAdminSpoof?: boolean;
}

export const SideBarLayout = ({ children, isAdminSpoof = false }: Props) => {
  const { isMobile } = useMediaBreakpoints();
  const ACTIONS = useMenuItems();
  const { logout } = useLogout();

  // useCallback to cache the component
  // it will generate new component whenever Sidebar rerender
  // which affect performance
  const Component = useCallback(
    (
      props: PropsWithChildren<{
        actions: IAction[];
        logoutUser: () => void;
      }>,
    ) =>
      isMobile ? (
        <HamburgerMenuMobile {...props} />
      ) : (
        <SideBarDesktop {...props} isAdminSpoof={isAdminSpoof} />
      ),
    [isMobile],
  );

  return (
    <Component actions={ACTIONS} logoutUser={logout}>
      <TopNavBar />
      <Box sx={{ mt: 8 }}>{children}</Box>
    </Component>
  );
};
