import {
  useMutation,
  useQuery,
  MutateOptions,
  UseQueryOptions,
} from 'react-query';
import {
  IndustryTableData,
  IndustryOptionsActivityLog,
} from 'components/IndustryOptions/types';
import {
  getIndustryOptions,
  updateIndustryOptions,
  createIndustryOptions,
  getIndustryOptionsActivity,
} from 'services/industryOptions';
import { ApiListResp, defaultPageInfo } from 'models/api';

export const useIndustryOptions = () => {
  const { data, ...rest } = useQuery<IndustryTableData[]>(['industries'], () =>
    getIndustryOptions(),
  );
  return {
    data,
    ...rest,
  };
};

export const useIndustryOptionsActivity = (
  page: number = 1,
  options: UseQueryOptions<ApiListResp<IndustryOptionsActivityLog[]>> = {},
) => {
  const { data, ...rest } = useQuery<ApiListResp<IndustryOptionsActivityLog[]>>(
    ['industriesActivity', page],
    () => getIndustryOptionsActivity(page),
    options,
  );
  return {
    data: data?.data ?? [],
    pageInfo: data?.pageInfo ?? defaultPageInfo,
    ...rest,
  };
};

export const useUpdateIndustryOptions = (
  queryProps?: MutateOptions<unknown, unknown, Partial<IndustryTableData>>,
) =>
  useMutation(
    (industryTableData: IndustryTableData) =>
      updateIndustryOptions(industryTableData),
    queryProps,
  );

export const useCreateIndustryOptions = (
  queryProps?: MutateOptions<unknown, unknown, Partial<IndustryTableData>>,
) =>
  useMutation(
    (industryTableData: IndustryTableData) =>
      createIndustryOptions(industryTableData),
    queryProps,
  );
