import { 
  ContentCopyIcon,
  DownloadIcon
} from 'components/common/Icon';
import { Grid, TextField } from '@mui/material';
import { FormationsTertiaryButton } from 'components/common/Buttons';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { showErrorToast, showSuccessToast } from '../../toast/showToast';

interface Props {
  isLoading: boolean;
  showCopyUrlButtons?: boolean;
  deeplinkUrl: string;
  handleDocumentDownload: () => void;
}

const DocumentURLContainer = styled(TextField)(({ theme }) => ({
  width: 450,
  marginRight: theme.spacing(2),
}));

const CopyLinkIcon = styled(ContentCopyIcon)(() => ({
  cursor: 'pointer',
}));

export const PreviewHeaderAction = (props: Props) => {
  const { t } = useTranslation();
  const {
    isLoading,
    showCopyUrlButtons = false,
    deeplinkUrl,
    handleDocumentDownload,
  } = props;

  const handleCopyLinkForCustomer = async () => {
    try {
      await navigator.clipboard.writeText(deeplinkUrl);
      showSuccessToast(t('documentHub.preview.copySuccessToast'));
    } catch (e) {
      if (e instanceof Error) {
        showErrorToast(e.message);
      } else {
        showErrorToast(String(e));
      }
    }
  };

  return (
    <Grid item>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        {showCopyUrlButtons && (
          <DocumentURLContainer
            variant="outlined"
            InputProps={{
              endAdornment: (
                <CopyLinkIcon onClick={handleCopyLinkForCustomer} />
              ),
            }}
            value={deeplinkUrl}
            disabled
          />
        )}
        {showCopyUrlButtons && (
          <FormationsTertiaryButton
            size="large"
            onClick={handleCopyLinkForCustomer}
            startIcon={<ContentCopyIcon />}
            sx={{
              mr: 2,
            }}
          >
            Copy Deep Link
          </FormationsTertiaryButton>
        )}
        <FormationsTertiaryButton
          size="large"
          onClick={handleDocumentDownload}
          startIcon={<DownloadIcon />}
          sx={{
            mr: 2,
          }}
          disabled={isLoading}
        >
          Download
        </FormationsTertiaryButton>
      </Grid>
    </Grid>
  );
};
