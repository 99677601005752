import React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {
  Typography,
  Divider,
  Link,
  Autocomplete,
  TextField,
  SvgIconProps,
} from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import { sendChurnZeroEvent } from 'helpers/churnZero';
import { CHURN_ZERO_EVENT_NAMES } from 'constants/common';
import { IAccount } from 'models/account';
import { AutoCompleteOptions } from './HelpCenterMenu';
import { CustomIconComponent } from '../Icon';
import { MenuListItem } from './types';

interface NavMenuItemProps {
  tooltipTitle: string;
  menuName: string;
  menuList: MenuListItem[];
  mainIcon: React.FC<SvgIconProps> | CustomIconComponent;
  mainIconText?: string;
  action?: MenuListItem;
  search?: {
    options: AutoCompleteOptions[];
    onSearch: (newValue: AutoCompleteOptions | null) => void;
  };
  customerStatus?: string;
  account?: IAccount;
}

export const NavMenuItem = ({
  tooltipTitle,
  menuName,
  menuList,
  mainIcon,
  mainIconText,
  action,
  search,
  customerStatus = '',
  account,
}: NavMenuItemProps) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    search?.onSearch(null);
  };

  const menuItem = (item: MenuListItem) => (
    <MenuItem
      sx={{
        display: item.hidden ? 'none' : 'flex',
        mt: 1,
        px: 2,
      }}
      key={item.name}
      disabled={item?.shouldDisable?.(account)}
      data-testid="menu-item"
      hidden={item.hidden}
      onClick={() => {
        if (item?.shouldDisable?.(account)) {
          return;
        }
        if (item.clickHandler) item.clickHandler();
        setAnchorElUser(null);
      }}
    >
      <Typography variant="body3S" mr={1}>
        {item.name}
      </Typography>
      {item.icon ? <item.icon /> : null}
    </MenuItem>
  );

  const getMenuItems = () =>
    menuList.map((item) => {
      if (item?.hiddenForStatus?.includes(customerStatus)) {
        return null;
      }
      return item.navigation && !item?.shouldDisable?.(account) ? (
        <Link
          key={`${item.name}_link`}
          href={item.navigation.href}
          style={{ textDecoration: 'none' }}
          target="_blank"
          onClick={() => {
            if (
              item?.navigation?.href ===
              'https://learn.formationscorp.com/using-formations-platform'
            ) {
              sendChurnZeroEvent(CHURN_ZERO_EVENT_NAMES.VISITED_KNOWLEDGE_BASE);
            }
          }}
        >
          {menuItem(item)}
        </Link>
      ) : (
        menuItem(item)
      );
    });

  const MainIcon = mainIcon;

  return (
    <Box>
      <Tooltip title={tooltipTitle}>
        <FormationsGhostButton
          rounded
          onClick={handleOpenUserMenu}
          sx={{
            '& .profileIcon': { backgroundColor: 'gray.100' },
            '&:hover': {
              '& .profileIcon': {
                color: 'blue.hover',
                backgroundColor: '#FFFFFF',
              },
            },
          }}
        >
          <MainIcon
            data-testid="menu-main-icon"
            className={menuName === 'Profile' ? 'profileIcon' : ''}
          >
            <Typography
              data-testid="menu-icon-text"
              sx={{
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '20px',
                mt: 0.5,
              }}
            >
              {mainIconText ?? ''}
            </Typography>
          </MainIcon>
        </FormationsGhostButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ style: { minWidth: '242px', paddingTop: 0 } }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Typography variant="h6" sx={{ opacity: 0.5, p: 2 }}>
          {menuName}
        </Typography>
        <Divider />
        {search && (
          <Autocomplete
            disablePortal
            id="autocomplete-menu"
            onChange={(_event, newValue: AutoCompleteOptions | null) => {
              search.onSearch(newValue);
            }}
            options={search.options}
            disabled
            sx={{ my: 2, px: 1 }}
            renderInput={(params) => (
              <TextField {...params} label="" placeholder="Search" />
            )}
          />
        )}
        {menuList?.length ? getMenuItems() : null}
        {action && menuList.length > 0 && <Divider />}
        {action && menuItem(action)}
      </Menu>
    </Box>
  );
};
