import { alpha, Components } from '@mui/material';

export const button: Components = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'inherit',
        fontWeight: 700,
      },
    },
    variants: [
      {
        props: { size: 'large' },
        style: {
          paddingTop: '10px',
          paddingBottom: '6px',
        },
      },
      {
        props: { size: 'medium' },
        style: {
          paddingTop: '6px',
          paddingBottom: '4px',
        },
      },
      {
        props: {
          variant: 'outlined',
          color: 'secondary',
        },
        style: {
          '&:hover': {
            backgroundColor: alpha('#79BD7B', 0.18),
          },
        },
      },
      {
        props: {
          variant: 'contained',
          disabled: true,
        },
        style: {
          backgroundColor: 'none',
          background:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #FFFFFF',
        },
      },
      {
        props: {
          variant: 'contained',
          color: 'primary',
        },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.blue.primary,
          color: '#FFFFFF',
          fontWeight: 500,
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: theme.palette.blue.hover,
          },
          '&:active': {
            backgroundColor: theme.palette.blue.active,
          },
          '&.roundedButton': {
            borderRadius: '50vh',
          },
          ':disabled': {
            color: '#FFFFFF',
            backgroundColor: theme.palette.blue.primary,
            opacity: 0.5,
          },
        }),
      },
      {
        props: {
          variant: 'contained',
          color: 'secondary',
        },
        style: ({ theme }) => ({
          color: theme.palette.gray[100],
          backgroundColor: theme.palette.gray[20],
          fontWeight: 500,
          borderRadius: '8px',
          '&:hover': {
            color: '#FFFFFF',
            backgroundColor: theme.palette.blue.hover,
          },
          '&:active': {
            color: '#FFFFFF',
            backgroundColor: theme.palette.blue.active,
          },
          '&:disabled': {
            color: theme.palette.gray[100],
            backgroundColor: theme.palette.gray[20],
            backgroundImage: 'none',
            opacity: 0.5,
          },
          '&.roundedButton': {
            borderRadius: '50vh',
          },
        }),
      },
      {
        props: {
          variant: 'contained',
          color: 'error',
        },
        style: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.red.primary,
          fontWeight: 500,
          borderRadius: '8px',
          '&:hover': {
            color: '#FFFFFF',
            backgroundColor: theme.palette.red.hover,
          },
          '&:active': {
            color: '#FFFFFF',
            backgroundColor: theme.palette.red.active,
          },
          '&:disabled': {
            color: '#FFFFFF',
            backgroundColor: theme.palette.red.primary,
            backgroundImage: 'none',
            opacity: 0.5,
          },
          '&.roundedButton': {
            borderRadius: '50vh',
          },
        }),
      },
      {
        props: {
          variant: 'outlined',
          color: 'tertiary',
        },
        style: ({ theme }) => ({
          color: theme.palette.gray[100],
          fontWeight: 500,
          border: `1px solid ${theme.palette.gray[100]}`,
          borderRadius: '8px',
          '&:hover': {
            color: '#FFFFFF',
            backgroundColor: theme.palette.blue.hover,
            borderColor: 'transparent',
          },
          '&:active': {
            color: '#FFFFFF',
            backgroundColor: theme.palette.blue.active,
            borderColor: 'transparent',
          },
          '&:disabled': {
            color: theme.palette.gray[100],
            opacity: 0.5,
          },
          '&.roundedButton': {
            borderRadius: '50vh',
          },
        }),
      },
      {
        props: {
          variant: 'text',
          color: 'ghost',
        },
        style: ({ theme }) => ({
          color: theme.palette.gray[100],
          fontWeight: 500,
          borderRadius: '8px',
          '&:hover': {
            color: '#FFFFFF',
            backgroundColor: theme.palette.blue.hover,
          },
          '&:active': {
            color: '#FFFFFF',
            backgroundColor: theme.palette.blue.active,
          },
          '&:disabled': {
            color: theme.palette.gray[100],
            opacity: 0.5,
          },
          '&.roundedButton': {
            borderRadius: '50vh',
            padding: '8px',
            minWidth: 0,
          },
        }),
      },
    ],
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        textTransform: 'inherit',
        fontWeight: 'inherit',
        fontFamily: 'inherit',
        fontSize: 'inherit',
      },
    },
  },
};
