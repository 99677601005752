import * as Sentry from '@sentry/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { LogProps } from './types';

export const log = (props: LogProps) => {
  Sentry.addBreadcrumb(props);
};

export const logInfo = ({ category, message, data }: Omit<LogProps, 'level'>) => {
  Sentry.addBreadcrumb({
    category,
    message: `Info: ${message}`,
    data,
    level: "info",
  });
};

export const logError = ({ category, message, data }: Omit<LogProps, 'level'>) => {
  Sentry.addBreadcrumb({
    category,
    data,
    message: `Error: ${message}`,
    level: "error",
  });
};

export const useSentryUser = () => {
  const { user, isAuthenticated } = useAuth0();
  useEffect(() => {
    if (user != null && isAuthenticated) {
      Sentry.setUser({
        email: user.email,
        name: user.name,
        "First Name": user.given_name,
        "Last Name": user.family_name,
        "Nick Name": user.nickname
      });
    }
  }, [user?.email, isAuthenticated]);
};
