import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import 'material-symbols';
import { QueryClientProvider } from 'react-query';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { CONFIG } from 'config';
import { queryClient } from 'states/reactQueryClient';
import * as Sentry from '@sentry/react';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { LocalizationProvider } from '@mui/lab';
import { theme } from 'theme';
import { Auth0ProviderWithHistory } from 'auth/Auth0ProviderWithHistory';
import { QueryParamProvider } from 'use-query-params';
import { parse, stringify } from 'query-string';
import TagManager from 'react-gtm-module';
import { HurdlrProvider } from 'components/hurdlr/HurdlrProvider';
import { App } from './App';

import './index.css';
import './translations/i18n';

if (
  ['development', 'staging', 'production'].includes(CONFIG.sentry.env) &&
  CONFIG.sentry.enabled
) {
  Sentry.init({
    dsn: 'https://7d6fd1913d1e434482526374615af779@o509044.ingest.sentry.io/5602647',
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: CONFIG.sentry.env === 'production',
        blockAllMedia: CONFIG.sentry.env === 'production',
        maskAllInputs: CONFIG.sentry.env === 'production',
      }),
    ],
    environment: CONFIG.sentry.env,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    release: `formations@${process.env.REACT_APP_GITHUB_REF_NAME}`,
  });
}

const tagManagerArgs = {
  gtmId: 'GTM-M7ZMBF7',
};
if (CONFIG.environment === 'production') {
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: parse,
          objectToSearchString: stringify,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <StyledEngineProvider injectFirst>
                <HurdlrProvider>
                  <Routes>
                    <Route path="/*" element={<App />} />
                  </Routes>
                </HurdlrProvider>
              </StyledEngineProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </QueryParamProvider>
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById('root'),
);
