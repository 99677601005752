import { Checkbox, TableCell, Grid, Box } from '@mui/material';
import React, { useMemo } from 'react';
import moment from 'moment';
import { MOMENT_DATE_FORMAT } from 'helpers/dateTimeFormat';
import { FormationsTableDocument } from 'components/common/tables/DocumentsTable';
import { useCurrentUserRole } from 'hooks/api';
import { DocumentStatusCell } from './DocumentStatusCell';
import { PillarCell } from './PillarCell';
import { MoreActionsCell } from './MoreActionsCell';
import { NoteIconCell } from './NoteIconCell';
import { DocumentNameCell } from './DocumentNameCell';
import { FormationsTableHeaderKeys } from '../header/DocumentHeader';
import { DocumentVisibilityCell } from './DocumentVisibilityCell';

interface IHeaderItem {
  title: string;
  key: FormationsTableHeaderKeys;
}

const TableCellValue = (props: {
  accountId: string;
  item: IHeaderItem;
  document: FormationsTableDocument;
  openDocumentPreview: (document: FormationsTableDocument) => void;
  checked: boolean;
  onCheckboxClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {

  const { accountId, item, document, openDocumentPreview, checked } = props;
  const { isAdmin } = useCurrentUserRole();

  let cellValue;
  switch (item.key) {
    case 'title':
      cellValue = (
        <DocumentNameCell
          document={document}
          isFavourite={false}
          openDocumentPreview={openDocumentPreview}
          checked={checked}
          onCheckboxClick={props.onCheckboxClick}
        />
      );
      break;
    case 'status':
      cellValue = <DocumentStatusCell document={document} />;
      break;
    case 'uploadedAt':
      cellValue = (
        <span data-testid="document-date-cell">
          {moment.utc(document.uploadedAt).local().format(MOMENT_DATE_FORMAT)}
        </span>
      );
      break;
    case 'department':
      cellValue = (
        <Box sx={{ py: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <PillarCell document={document} />
        </Box>
      );
      break;
    case 'adminActions':
      cellValue = (
        <MoreActionsCell
          accountId={accountId}
          document={document}
          isFavourite={false}
          onReviewFile={openDocumentPreview}
          isAdmin
        />
      );
      break;
    case 'actions':
      cellValue = (
        <MoreActionsCell
          accountId={accountId}
          document={document}
          isFavourite={false}
          onReviewFile={openDocumentPreview}
          isAdmin={false}
        />
      );
      break;
    case 'visibleToCustomer':
      cellValue = <DocumentVisibilityCell document={document} />;
      break;
    case 'hasNotes':
      cellValue = document.hasNotes ? (
        <NoteIconCell document={document} isAdmin={isAdmin}/>
      ) : null;
      break;
    case 'checkbox':
      cellValue = (
        <Grid container justifyContent="center">
          <Checkbox
            color="primary"
            checked={checked}
            inputProps={{
              'aria-labelledby': document.id,
            }}
            onChange={props.onCheckboxClick}
          />
        </Grid>
      );
      break;
    case 'customerReadCount':
      if (!document.isVisibilityEditable) {
        cellValue = <span data-testid="document-cell-customerReadCount">-</span>;
      } else {
        cellValue = (
          <span data-testid="document-cell-customerReadCount">{`${
            document.customerReadCount ?? ''
          }`}</span>
        );
      }
      break;
    default:
      cellValue = (
        <span data-testid="document-cell-default">{`${
          document[item.key] ?? ''
        }`}</span>
      );
      break;
  }
  return cellValue;
};

export const DocumentTableCell = (props: {
  accountId: string;
  item: IHeaderItem;
  document: FormationsTableDocument;
  openDocumentPreview: (document: FormationsTableDocument) => void;
  checked: boolean;
  onCheckboxClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { accountId, item, document, openDocumentPreview, checked, onCheckboxClick } = props;
  const cellStyle = useMemo(() => {
    switch (item.key) {
      case 'title':
        return { width: 'auto', flex: 1 };
      default:
        return { maxWidth: '15%' };
    }
  }, [item.key]);

  const cellAlign = useMemo(() => {
    switch (item.key) {
      case 'actions':
      case 'adminActions':
      case 'status':
      case 'hasNotes':
      case 'customerReadCount': 
        return 'center';
      default:
        return 'left';
    }
  }, [item.key]);

  return (
    <TableCell
      key={item.key}
      component="td"
      scope="row"
      style={cellStyle}
      align={cellAlign}
      sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
    >
      <TableCellValue
        accountId={accountId}
        item={item}
        document={document}
        openDocumentPreview={openDocumentPreview}
        checked={checked}
        onCheckboxClick={onCheckboxClick}
      />
    </TableCell>
  );
};
