import { Table } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormationsTable = styled(Table)(({ theme }) => ({
  '& th': {
    borderColor: theme.palette.gray[20],
    color: theme.palette.gray[60],
  },
  '& th button': {
    color: theme.palette.gray[60],
  },
  '& td': {
    borderColor: theme.palette.gray[20],
    color: theme.palette.gray[60],
  },
  '& td button': {
    color: theme.palette.gray[60],
  },
}));
