import { Typography, Grid } from '@mui/material';
import { useState } from 'react';
import { Layout } from 'views/navigation';
import { AddIcon } from 'components/common/Icon';
import i18n from 'translations/i18n';
import { useTranslation } from 'react-i18next';
import { TaxesDirectPaymentFormDialog } from 'components/taxes/dialogs/TaxesDirectPaymentFormDialog';
import { TaxesFilterAndTable } from 'components/taxes/TaxesFilterAndTable';
import {
  FormationsFormFields,
  IFormField,
} from 'components/common/FormationsForm2';
import { useCurrentUserRole } from 'hooks/api';
import { FormationsGhostButton } from 'components/common/Buttons';
import { directPaymentSchema } from 'schemas/directPaymentSchema';
import { cloneDeep } from 'lodash';
import { PaymentStatus } from 'enums';
import { useActiveTaxYears } from 'hooks/api/useTaxYears';
import { ActiveTaxYear } from 'services/taxYears';
import { DirectPayment } from 'components/taxes/types';
import { useMediaBreakpoints } from 'hooks';

export const fields: IFormField[] = [
  {
    type: FormationsFormFields.Currency,
    name: 'amount',
    label: i18n.t('taxes.dialog.amount') as string,
    options: {
      placeholder: '$0.00',
      maxLength: 16,
      width: '100%',
    },
  },
  {
    type: FormationsFormFields.AutoComplete,
    name: 'year',
    label: i18n.t('taxes.dialog.taxYear') as string,
    options: {
      options: [],
    },
  },
  {
    type: FormationsFormFields.DatePicker,
    name: 'paymentDate',
    label: i18n.t('taxes.dialog.paymentDate') as string,
    options: {
      placeholder: 'MM/DD/YYYY',
    },
  },
];

const createActiveTaxYears = (taxYears: ActiveTaxYear[] | undefined) =>
  taxYears
    ? taxYears.map(({ year }) => ({
        label: year.toString(),
        name: year.toString(),
        value: year.toString(),
      }))
    : [];

export const useTaxYearsOptions = () => {
  const { activeTaxYears } = useActiveTaxYears();
  // set tax years options field with options
  const taxYears = createActiveTaxYears(activeTaxYears);
  const fieldsWithYearOptions = cloneDeep(fields);
  fieldsWithYearOptions[1].options.options = taxYears;
  return fieldsWithYearOptions;
};

export const TaxesUI = ({
  accountId,
  isLoadingUser = false,
}: {
  accountId: string | undefined;
  isLoadingUser?: boolean;
}) => {
  const { isAdmin } = useCurrentUserRole();
  const { t } = useTranslation();
  const { isMobile } = useMediaBreakpoints();
  const fieldsWithYearOptions = useTaxYearsOptions();
  // upload form dialog state
  const [showFormDialog, setShowFormDialog] = useState(false);
  // edit form dialog state
  const [paymentToEdit, setPaymentToEdit] = useState<DirectPayment | null>(
    null,
  );

  // disable form fields if payment status is approved
  let formFields: IFormField[] = [];
  if (paymentToEdit && paymentToEdit.status === PaymentStatus.Approved) {
    formFields = fieldsWithYearOptions.map((field) => ({
      ...field,
      disabled: true,
    }));
  } else {
    formFields = fieldsWithYearOptions;
  }

  return (
    <Layout
      title={
        <Grid
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body1B" color="primary">
            {t('taxes.heading')}
          </Typography>
          {!isLoadingUser && !isAdmin && !isMobile && (
            <FormationsGhostButton
              aria-label="edit"
              startIcon={<AddIcon sx={{ pb: 0.25 }}/>}
              onClick={() => setShowFormDialog(true)}
              data-testid="upload-btn"
            >
              {t('taxes.uploadButton')}
            </FormationsGhostButton>
          )}
        </Grid>
      }
      gridSx={isAdmin ? { px: 0 } : undefined}
    >
      <Grid container flexDirection="column">
        <TaxesFilterAndTable
          accountId={accountId}
          isLoadingUser={isLoadingUser}
          setPaymentToEdit={setPaymentToEdit}
          setShowFormDialog={setShowFormDialog}
          isMobile={isMobile}
        />
      </Grid>
      {/* Upload Direct Payment Dialog */}
      <TaxesDirectPaymentFormDialog
        isOpen={showFormDialog}
        onClose={() => setShowFormDialog(false)}
        fields={formFields}
        data-testid="upload-tax-payment-dialog"
        validationSchema={directPaymentSchema}
        accountId={accountId}
        isMobile={isMobile}
      />
      {/* Edit Direct Payment Dialog */}
      <TaxesDirectPaymentFormDialog
        isOpen={paymentToEdit !== null}
        onClose={() => {
          setPaymentToEdit(null);
        }}
        fields={formFields}
        validationSchema={directPaymentSchema}
        accountId={accountId}
        resetValues={false}
        paymentToEdit={paymentToEdit}
        isMobile={isMobile}
      />
    </Layout>
  );
};
