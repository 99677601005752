import { Checkbox, FormControlLabel, Grid, Tooltip, Typography, styled } from "@mui/material";
import { FormationsGhostButton, FormationsPrimaryButton } from "components/common/Buttons";
import { useCreateRelayAccountApplication, useUpdateCompany } from "hooks/api";
import { queryClient } from 'states/reactQueryClient';
import { Company } from "services";
import { ProgressTrackerStatus } from "models/account";
import { useTranslation } from "react-i18next";
import { InfoIcon } from "components/common/Icon";
import { useCallback, useState } from "react";
import { showErrorToast, showSuccessToast } from "components/toast/showToast";

const InfoIconButton = styled(FormationsGhostButton)(() => ({
  width: '20px',
  height: '20px',
  fontSize: '20px',
  marginTop: -1,
}));

const TypographyContainer = styled(Typography)(() => ({
  cursor: 'default',
}));

const TypographyLink = styled(Typography)(() => ({
  cursor: 'pointer',
  textDecoration: 'underline',
  display: 'inline',
}));

interface RelayTermsOfServiceProps {
  company: Company;
  handleShowRelayBankCreation: () => void;
  handleComplete: (status: ProgressTrackerStatus, eventData: Object) => void;
}

export const RelayTermsOfService = (
  {
    company,
    handleShowRelayBankCreation,
    handleComplete,
  }: RelayTermsOfServiceProps
) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  const { mutateAsync: updateCompany, isLoading: isUpdatingCompany } =
    useUpdateCompany({
      onSuccess: () => {
        queryClient.invalidateQueries(['getCompany', company?.id]);
      },
    });

  const { createRelayAccountApplicationAsync, isLoading: isCreateRelayApplicationLoading } = useCreateRelayAccountApplication(company?.accountId ?? '');

  const handleUpdateCompanyAndHandleCompleteStage = async () => {
    if (company?.id) {
      const data = {
        bankName: 'relay',
        useOtherBank: false,
        useExistingBank: true,
        hasBankAccount: false,
      };
      await updateCompany({ id: company.id, data });
    
      handleComplete(ProgressTrackerStatus.Completed, {
        bankName: 'relay',
      });
    }
  }

  const handleCreateAccountApplicationWithRelay = async () => {
    if (company?.id && company?.accountId && isChecked) {
      try {
        await createRelayAccountApplicationAsync();
        showSuccessToast('Relay account application submitted successfully.');
        handleUpdateCompanyAndHandleCompleteStage();
      } catch (error: any) {
        if (error.response?.data?.message === 'the customer already has a relay banking application') { // there may have been a timeout error but the relay account application was still created so update the ui to reflect the correct state
          showSuccessToast('Relay account application submitted successfully.');
          handleUpdateCompanyAndHandleCompleteStage();
        } else {
          showErrorToast('An error occurred while submitting relay account application.');
        }
      }
    }
    if (!isChecked) {
      showErrorToast('Please agree to the Relay Privacy Policy & Terms of Service, Deposit and Cardholder Terms & Conditions');
    }
  }

  const openRelayInfo = useCallback(() => {
    const link =
      'https://learn.formationscorp.com/what-is-relay-bank';
      const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
  }, []);

  const openRelayTermsOfService = useCallback(() => {
    const link =
      'https://relayfi.com/u-terms';
      const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
  }, []);

  const openDepositTermsOfService = useCallback(() => {
    const link =
      'https://relayfi.com/u-deposit';
      const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
  }, []);

  const openCardHolderTermsOfService = useCallback(() => {
    const link =
      'https://relayfi.com/u-cardholder';
      const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
  }, []);

  return (
    <>
      <Grid item >
        <Grid container direction="column" gap={1}>
          <Grid item sx={{ pl: 18, pr: 18 }}>
            <Typography variant='subtitle1L'>{t('progressTracker.bankSetup.relayTermsTitle')}</Typography>
            <Tooltip
              title={
                <Typography
                  variant="tooltip"
                  sx={{ color: 'primary.contrastText' }}
                >
                  Relay is a banking partner of Formations. <TypographyLink variant="tooltip" onClick={openRelayInfo}>Read here</TypographyLink> for more details.
                </Typography>
              }
              sx={{ ml: 1, mb: 0.25 }}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: 'primary.main',
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: 'primary.main',
                    ml: 1,
                    mr: 1,
                  },
                },
              }}
            >
              <InfoIconButton rounded>
                <InfoIcon 
                  fontSize="inherit" 
                  size={20}
                  sx={{ 
                    color: 'primary.main', 
                    ':hover': {
                      color: 'white',
                      transition: 'color 0.1s',
                    },
                  }}
                />
              </InfoIconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ pl: 18, pr: 18 }}>
            <Typography variant='body2S'>
              {t('progressTracker.bankSetup.relayTermsSubTitle')}
            </Typography>
          </Grid>
          <Grid item sx={{ pl: 18, pr: 18, pt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={(event) => setIsChecked(event.target.checked)}
                  name="termsAndConditions"
                />
              }
              label={
                <TypographyContainer variant='body1' onClick={(event) => event.preventDefault()}>
                  I agree to the{' '}
                  <TypographyLink variant="body1" onClick={openRelayTermsOfService}>Relay Privacy Policy & Terms of Service</TypographyLink>
                  ,{' '}
                  <TypographyLink variant="body1" onClick={openDepositTermsOfService}>Deposit</TypographyLink>
                  {' and '}
                  <TypographyLink variant="body1" onClick={openCardHolderTermsOfService}>Cardholder Terms & Conditions</TypographyLink>
                </TypographyContainer>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justifyContent='space-between' display="flex" sx={{ mt: 4 }}>
        <FormationsGhostButton
          size='large'
          onClick={handleShowRelayBankCreation}
        >
          I Want To Use A Different Bank
        </FormationsGhostButton>
        <FormationsPrimaryButton
          isLoading={isUpdatingCompany || isCreateRelayApplicationLoading}
          size='large'
          onClick={handleCreateAccountApplicationWithRelay}
          loadingPosition="end"
          sx={{ paddingRight: (isUpdatingCompany || isCreateRelayApplicationLoading) ? 5 : 2.75 }}
        >
          Create My Business Account
        </FormationsPrimaryButton>
      </Grid>
    </>
  )
}