import { IAccount } from 'models/account';

const initData: IAccount = {
  id: '',
  status: {
    label: 'NEW',
    updatedBy: '',
    updatedAt: '',
  },
  createdAt: '',
  progress: [],
  retirementContributionGoal: 0,
  personalFinancialGoals: [],
  goalsWorkingWithFormations: [],
  xeroTransactionsError: [],
  payroll: {
    estimatedSalary: 0,
    ytdOfficerWages: 0,
    lyOfficerWages: 0,
    ytdNonOfficerWages: 0,
    lyNonOfficerWages: 0,
  },
  companyName: '',
  incomeExpense: {
    income: 0,
    associatedWithBrokerage: false,
    categoryExpense: {},
  },
  taxes: {
    annualEstimated: 0,
    ytdTotalPaid: 0,
    updatedAt: undefined,
  },
  generalTaxInfo: {
    taxFilingStatus: undefined,
    dependents: undefined,
    healthInsuranceStatus: undefined,
    healthInsuranceMonthlyPremium: undefined,
    retirementProgram: undefined,
    retirementPlan: undefined,
  },
  yearlyTaxInfo: {
    [new Date().getFullYear()]: {
      year: new Date().getFullYear(),
      spousalW2Income: 0,
      spousalFederalWithholdings: 0,
      spousalStateWithholdings: 0,
      spousalRetirement: 0,
      otherW2Income: 0,
      otherFederalWithholdings: 0,
      otherStateWithholdings: 0,
      otherRetirement: 0,
      other1099Income: 0,
      itemizedDeductions: 0,
    }
  },
  retirementPlan: 'todo',
  hurdlrConnected: false
};

export const useAccountDataForAdminPage = (
  accountData: IAccount | undefined,
): IAccount => {
  if (!accountData) {
    return initData;
  }
  return { ...initData, ...accountData };
};
