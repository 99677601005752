import { Company } from 'services/companies';

const initData: Company = {
  id: '',
  name: '',
  suggested: [],
  bankName: '',
  entityType: '',
  ein: '',
  state: '',
  contactDetails: {
    workPhone: '',
    mailingAddress: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      country: 'USA',
    },
    physicalAddress: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      country: 'USA',
    },
  },
};

export const useCompanyDataForAdminPage = (
  companyData: Company | undefined,
): Company => {
  if (!companyData) {
    return initData;
  }

  const result = { ...initData, ...companyData };

  result.suggested = (result?.suggested ?? []).map((item: any) => item.name);

  return result;
};
