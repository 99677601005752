import { IDocumentCategory } from '../components/FilePreview/FilePreviewComponent';

export const MAX_DOCUMENT_SIZE_MB = 50;
export const MAX_DOCUMENT_SIZE_BYTES = MAX_DOCUMENT_SIZE_MB * 1024 * 1024;

export const KYC_DOCUMENT: IDocumentCategory = {
  category: 'Miscellaneous',
  subcategory: 'Biographical Information',
  department: 'Profile & Account',
};

export const BOOK_OF_BUSINESS: IDocumentCategory = {
  category: 'Reports',
  subcategory: 'Monthly Financial Report',
  department: 'Accounting',
};

export const ACCOUNT_YTD: IDocumentCategory = {
  category: 'Financials',
  subcategory: 'Miscellaneous',
  department: 'Accounting',
};

export const PT_ID_PROOF: IDocumentCategory = {
  category: 'Miscellaneous',
  subcategory: 'Biographical Information',
  department: 'Profile & Account',
};

export const PT_TAX_STATEMENT: IDocumentCategory = {
  category: 'Business',
  subcategory: 'Tax Return',
  department: 'Tax',
};

export const PT_DOC_INCORPORATION: IDocumentCategory = {
  category: 'Organizational Docs',
  subcategory: 'Miscellaneous',
  department: 'Profile & Account',
};

export const PT_SIGNING_DOCUMENT: IDocumentCategory = {
  category: 'Organizational Docs',
  subcategory: 'Signed Agreement',
  department: 'Profile & Account',
};

export enum EDocumentDepartment {
  'Tax' = 'Tax',
  'Profile & Account' = 'Profile & Account',
  'Accounting' = 'Accounting',
  'Payroll' = 'Payroll',
}
