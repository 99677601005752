import {
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormHelperText,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';


export const HaveAccount = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Typography variant="h5B" component="h5">
        Do you have a business bank account?
      </Typography>
      <Controller
        name="haveAccount"
        control={control}
        rules={{
          required: true,
        }}
        render={({ onChange, value, name }) => (
          <RadioGroup name={name} value={value || ""} onChange={onChange} sx={{ mt: 4, mb: 4 }}>
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
            {errors?.haveAccount && (
              <FormHelperText error>
                Please select an option.
              </FormHelperText>
            )}
          </RadioGroup>
        )}
      />
    </>
  );
};
