import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, UseFormMethods } from 'react-hook-form';
import { get } from 'lodash';
import { Fragment } from 'react';
import { IFormField } from './types';

export interface TRadioOptions {
  label: string;
  value: any;
}
interface FieldOptions {
  maxLength?: number;
  options?: Array<TRadioOptions>;
  otherValueName?: string;
}

type TFormationsRadioField = {
  field: IFormField<FieldOptions>;
  formInstance: UseFormMethods;
};

function renderOption(
  option: TRadioOptions,
  field: IFormField<FieldOptions>,
  formInstance: UseFormMethods,
) {
  const {
    options: { maxLength = 12, otherValueName = `${field.name}Other` } = {},
  } = field;
  const { errors, control } = formInstance;
  const errorMsg = get(errors, [otherValueName, 'message'], '');
  if (option.value === 'other') {
    return (
      <FormControlLabel
        value={option.value}
        control={<Radio />}
        key={option.value}
        label={
          <Controller
            name={otherValueName}
            control={control}
            render={({ onChange, value }) => (
              <TextField
                value={value}
                label={option.label}
                onChange={onChange}
                variant="outlined"
                fullWidth
                inputProps={{
                  maxLength,
                }}
                error={!!errorMsg}
                helperText={errorMsg}
                data-testid="field-value-other"
                sx={{ ml: 2 }}
              />
            )}
          />
        }
      />
    );
  }
  return (
    <FormControlLabel
      value={option.value}
      label={
        <Typography variant="body3S" sx={{ fontSize: '14px' }}>
          {option.label}
        </Typography>
      }
      control={<Radio sx={{ ml: 2 }}/>}
      key={option.value}
      data-testid={`field-value-${option.value}`}
    />
  );
}

export const FormationsRadioField = ({
  field,
  formInstance,
}: TFormationsRadioField) => {
  const { control, errors } = formInstance;
  const { options: { options = [] } = {} } = field;
  const errorMsg = get(errors, [field.name, 'message'], '');

  return (
    <Controller
      name={field.name}
      control={control}
      defaultValue={field.defaultValue || null}
      render={({ onChange, value }) => (
        <FormControl
          component="fieldset"
          error={!!errorMsg}
          data-testid={`field-${field.name}`}
        >
          <FormLabel component="legend">
            <Typography variant="body2B" color="text.primary" component="p" sx={{ mb: 1 }}>
              {field.label}
            </Typography>
          </FormLabel>
          <RadioGroup value={value} onChange={onChange}>
            {options.map((option) => (
              <Fragment key={option.value}>
                {renderOption(option, field, formInstance)}
              </Fragment>
            ))}
          </RadioGroup>
          <FormHelperText>{errorMsg}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
