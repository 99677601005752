import { Components } from '@mui/material';
import { MAIN_COLOR } from 'theme/constant';

export const dialog: Components = {
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        color: MAIN_COLOR,
        padding: '32px 0 8px 32px',
        fontFamily: 'Spartan',
        fontSize: '16px',
        lineHeight: '150%',
        fontWeight: 700,
        fontStyle: 'normal',
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '.MuiBackdrop-root': {
          backgroundColor: 'rgba(23,36,71,0.8)',
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '24px 32px',
      },
    },
  },
};
