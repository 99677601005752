import { Box, Grid, Popover, PopoverProps } from '@mui/material';
import { styled, SxProps } from '@mui/material/styles';
import {
  FormationsPrimaryButton,
  FormationsGhostButton,
} from 'components/common/Buttons';
import { ReactNode, useState } from 'react';

export const useFilterPopover = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  return {
    open,
    anchorEl,
    setAnchorEl,
  };
};

const PopoverUI = styled(Popover)(
  ({ theme }) => `
    .MuiPopover-paper {
      min-width: 330px;
      padding: ${theme.spacing(2)};
      background: ${theme.palette.others.background};
      border-radius: 4px;
      box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(13, 34, 89, 0.14), 0px 6px 30px 5px rgba(13, 34, 89, 0.12);
    }
  `,
);

interface FilterPopoverProps extends PopoverProps {
  clearDisabled?: boolean;
  saveDisabled?: boolean;
  onClear: () => void;
  onConfirm: () => void;
}

const FilterPopover = ({
  anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
  transformOrigin = { vertical: 'top', horizontal: 'center' },
  saveDisabled = false,
  clearDisabled = false,
  children,
  onConfirm,
  onClear,
  ...props
}: FilterPopoverProps) => (
  <PopoverUI
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
    {...props}
  >
    {children}
    <Grid
      container
      alignItems="flex-end"
      justifyContent="flex-end"
      sx={{ pt: 2 }}
      gap={1}
    >
      {onClear && (
        <Grid item>
          <FormationsGhostButton
            size="large"
            disabled={clearDisabled}
            data-testid="filter-popover-clear"
            onClick={onClear}
          >
            Clear
          </FormationsGhostButton>
        </Grid>
      )}
      {onConfirm && (
        <Grid item>
          <FormationsPrimaryButton
            size="large"
            disabled={saveDisabled}
            data-testid="filter-popover-confirm"
            onClick={onConfirm}
          >
            Confirm
          </FormationsPrimaryButton>
        </Grid>
      )}
    </Grid>
  </PopoverUI>
);

FilterPopover.Header = ({ children }: { children: ReactNode }) => (
  <Box>{children}</Box>
);
FilterPopover.Content = ({
  sx = {},
  children,
}: {
  sx?: SxProps;
  children: ReactNode;
}) => (
  <Box className="content" sx={sx}>
    {children}
  </Box>
);

export { FilterPopover };
