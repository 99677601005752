import { ENTITY_MAPPING } from 'constants/common';
import {
  BUSINESS_GOALS,
  BUSINESS_OWNERSHIP,
  FILING_STATUS_OPTION,
  HEALTH_INSURANCE_OPTION,
  PERSONAL_FINANCIAL_GOALS,
  RETIREMENT_PROGRAM_OPTION,
  VENDOR_1099_OPTION,
  WORKING_WITH_FORMATIONS_GOALS,
  ANNUAL_INCOME,
  W2EMPLOYEES,
  SALES_TAX,
  PHYSICAL_INVENTORY,
  RENTAL_PROPERTIES,
  OTHER_BUSINESSES,
  EXPERT_HELP,
} from 'enums';
import { Address } from './company';

export enum ProgressTrackerStatus {
  Unknown = 'Unknown',
  Started = 'Started',
  NotStarted = 'notStarted',
  InProgress = 'inProgress',
  Completed = 'completed',
  Rejected = 'rejected',
}

export enum ProgressTrackerStages {
  Unsupported = 'unsupported',
  PersonalDetails = 'personalDetails',
  CompanyDetails = 'companyDetails',
  AddressDetails = 'addressDetails',
  HomeAddressDetails = 'homeAddressDetails',
  FilingDocuments = 'filingDocuments',
  UploadIdentityProof = 'uploadIdentityProof',
  UploadLastYearTaxStatement = 'uploadLastYearTaxStatement',
  DocumentOfIncorporation = 'documentOfIncorporation',
  DirectDepositInformation = 'directDepositInformation',
  BankSelection = 'bankSelection',
  BankInformation = 'bankInformation',
  DocumentSigning = 'documentSigning',
  OwnershipDetails = 'ownershipDetails'
}

export enum ProgressTrackerGroups {
  Unsupported = 'unsupported',
  Incorporation = 'incorporation',
  AccountVerification = 'accountVerification',
  FormCompletion = 'formCompletion',
}

export type AccountStatus = 'NEW' | 'ARCHIVED' | 'ACTIVE';

export type Status = {
  status: ProgressTrackerStatus;
  updatedAt: Date;
  updatedBy: string;
  comment: string;
};

export type ProgressTrackerGroup = {
  stage: string;
  group: string;
  status: Status[];
};

export type ProgressTrackerGroupRequest = {
  stage: string;
  group: string;
  status?: ProgressTrackerStatus;
  comment?: string;
};

export type CustomerDiscussionStatus = 'yes' | 'decline' | 'todo';

export type BankSummary = {
  cashReceived: number;
  cashSpent: number;
  closingBalance: number;
  openingBalance: number;
};

export type Insights = {
  ytdExpense: number;
  ytdGrossProfit: number;
  ytdNetProfit: number;
  ytdTotalIncome: number;
  lyExpense: number;
  ytdBankSummary: BankSummary;
  lmBankSummary: BankSummary;
  lmBankSummaryLastUpdatedAt?: string;
  ytdWages: number;
  lyWages: number;
  lyNetProfit: number;
  lyTotalIncome: number;
};

export type HurdlrProfitLoss = {
  beginDate: string;
  endDate: string;
  netIncome: number;
  income: number;
  expenses: number;
  grossProfit: number;
};

export type HurdlrCashFlow = {
  beginDate: string;
  endDate: string;
  netCashChange: number;
  beginCashBalance: number;
  endCashBalance: number;
};

export type HurdlrInsights = {
  lyProfitLoss: HurdlrProfitLoss;
  ytdProfitLoss: HurdlrProfitLoss;
  lyOfficerWages?: number;
  ytdOfficerWages?: number;
  lmCashflow?: HurdlrCashFlow;
  ytdCashflow?: HurdlrCashFlow;
};

interface GustoFields {
  companyUUID: string;
  companyName: string;
  credentialsUserId?: string;
  acceptedToSDate: string | null;
  disabledPayroll: boolean;
}

interface CustomerSuccessManager {
  name: string;
  email: string;
  meetLink: string;
}

type EntityKey = keyof typeof ENTITY_MAPPING;
export type EntityType = (typeof ENTITY_MAPPING)[EntityKey];

export type Taxes = {
  annualEstimated: number;
  ytdTotalPaid: number;
  updatedAt?: string;
};

export type IncomeExpense = {
  income: number;
  categoryExpense: {
    [key: string]: number;
  };
  associatedWithBrokerage: boolean;
  updatedAt?: string;
  completed?: boolean;
};

export type Payroll = {
  estimatedSalary: number;
  ytdOfficerWages: number;
  lyOfficerWages: number;
  ytdNonOfficerWages: number;
  lyNonOfficerWages: number;
};

// base type for Account data
export interface IAccountBase {
  id: string;
  rightSignatureURL?: string;
  status: {
    label: AccountStatus;
    updatedAt?: string;
    updatedBy?: string;
  };
  incomeExpense: IncomeExpense;
  createdAt: string;
  ownerId?: string;
  ownerName?: string;
  ownerEmail?: string;
  entityType?: EntityType;
  companyId?: string;
  health?: number;
  bookStatus?: number;
  payrollEnabled?: boolean;
  payrollRunNumber?: number;
  csm?: CustomerSuccessManager;
  gusto?: GustoFields;
  companyName?: string;
  insights?: Insights;
  payroll?: Payroll;
  taxes: Taxes;
  xeroLastUpdatedAt?: Date;
  xeroLastUpdatedAtTransactions?: Date;
  retirementContributionGoal?: number;
  generalTaxInfo?: GeneralTaxInfo;
  healthInsurance?: CustomerDiscussionStatus;
  retirementPlan?: CustomerDiscussionStatus;
  hurdlrConnected?: boolean;
  formationsPlan?: FormationsPlan;
  homeAddress?: Address;
  planRecommendation?: PlanRecommendation;
  planSelection?: PlanNames;
}

export type RetirementPlan = {
  selections: string[];
  other: string;
};

export type GeneralTaxInfo = {
  taxFilingStatus?: TFilingStatusOption;
  dependents?: number;
  healthInsuranceStatus?: THealthInsuranceOption;
  healthInsuranceMonthlyPremium?: number;
  retirementProgram?: TRetirementProgramOption;
  retirementPlan?: RetirementPlan;
  updatedAt?: string;
};

export type YearlyTaxInfoObject = {
  [key: number]: YearlyTaxInfo;
};

export type YearlyTaxInfo = {
  year?: number;
  retirementPlanYTDContribution?: number;
  estimatedYTDDirectTaxPayments?: number;
  rent?: number;
  stocks?: number;
  spousalW2Income?: number;
  spousalFederalWithholdings?: number;
  spousalStateWithholdings?: number;
  spousalRetirement?: number;
  otherW2Income?: number;
  otherFederalWithholdings?: number;
  otherStateWithholdings?: number;
  otherRetirement?: number;
  other1099Income?: number;
  itemizedDeductions?: number;
  projectedBusinessExpenses?: number;
  requires1099?: TVendor1099Option;
  updatedAt?: string;

  estimatedFederalTaxLiability?: number;
  estimatedFederalTaxUpdatedAt?: string;
  federalTaxPaid?: number;
  federalTaxDirectPaid?: number;
  estimatedStateTaxLiability?: number;
  estimatedStateTaxUpdatedAt?: string;
  stateTaxPaid?: number;
  stateTaxDirectPaid?: number;
};

export type XeroLastError = {
  code: string;
  message: string;
};

export type Bookkeeper = {
  id: string;
  name: string;
};

export enum FormationsPlan {
  FullSuite = 'FullSuite',
  TaxOnly = 'TaxOnly',
  BIB = 'BIB',
}

export enum PlanNames {
  Digital = 'Digital',
  Standard = 'Standard',
  Platinum = 'Platinum',
  PlatinumPlus = 'Platinum+',
  PlatinumPlusPlus = 'Platinum++',
}

export type PlanRecommendationInfo = {
  annualIncome?: ANNUAL_INCOME;
  w2Employees?: W2EMPLOYEES;
  salesTax?: SALES_TAX;
  salesTaxAdditionalField?: string;
  physicalInventory?: PHYSICAL_INVENTORY;
  rentalProperties?: RENTAL_PROPERTIES;
  whereRentalProperties?: string[];
  otherBusinesses?: OTHER_BUSINESSES;
  expertHelp?: EXPERT_HELP;
};

export type PlanRecommendation = {
  planRecommendationInfo?: PlanRecommendationInfo;
  recommendedPlan?: PlanNames;
};

export type TBusinessOwnership = keyof typeof BUSINESS_OWNERSHIP;
export type THealthInsuranceOption = keyof typeof HEALTH_INSURANCE_OPTION;
export type TRetirementProgramOption = keyof typeof RETIREMENT_PROGRAM_OPTION;
export type TVendor1099Option = keyof typeof VENDOR_1099_OPTION;
export type TFilingStatusOption = keyof typeof FILING_STATUS_OPTION;
export type TBusinessGoals = keyof typeof BUSINESS_GOALS;
export type TPersonalFinancialGoals = keyof typeof PERSONAL_FINANCIAL_GOALS;
export type TWorkingWithFormationsGoals =
  keyof typeof WORKING_WITH_FORMATIONS_GOALS;
export type TIncomeForCalculations = '0.00' | '0';
export interface IAccount extends IAccountBase {
  progress: ProgressTrackerGroup[];
  companyName: string;
  payroll: Payroll;
  businessOwnerShip?: TBusinessOwnership;
  retirementContributionGoal?: number;
  projectedGrossIncome?: number;
  businessGoals?: TBusinessGoals[];
  personalFinancialGoals?: TPersonalFinancialGoals[];
  goalsWorkingWithFormations?: TWorkingWithFormationsGoals[];
  financialLiteracy?: string;
  xeroTransactionsError: XeroLastError[];
  xeroCompanyName?: string;
  yearlyTaxInfo?: YearlyTaxInfoObject;
  hurdlrConnected?: boolean;
  primaryStripeId?: string;
  oilUnreadCustomerNoteItemsCount?: number;
  oilPendingReviewItemsCount?: number;
  hurdlrInsights?: HurdlrInsights;
  bookkeeper?: Bookkeeper;
}

export interface IAccountXeroInfoResp {
  // eslint-disable-next-line camelcase
  client_id: string;
  // eslint-disable-next-line camelcase
  is_connected: boolean;
}

export interface IAccountXeroInfo {
  clientId: string;
  isConnected: boolean;
}

export interface LegacyAccountRequest {
  businessOwnerShip?: keyof typeof BUSINESS_OWNERSHIP;
  taxFilingStatus?: TFilingStatusOption;
  dependents?: number;
  healthInsuranceStatus?: THealthInsuranceOption;
  healthInsuranceMonthlyPremium?: number;
  retirementProgram?: TRetirementProgramOption;
  retirementPlan?: RetirementPlan;
  requires1099?: TVendor1099Option;
}

export interface IAccountRequest extends Omit<IAccountBase, 'taxes'> {
  taxes: Pick<Taxes, 'annualEstimated'>;
  progress: ProgressTrackerGroupRequest[];
  estimatedSalary: number;
  businessOwnerShip?: keyof typeof BUSINESS_OWNERSHIP;
  projectedGrossIncome?: number;
  projectedBusinessExpenses?: number;
  businessGoals?: keyof (typeof BUSINESS_GOALS)[];
  personalFinancialGoals?: TPersonalFinancialGoals[];
  goalsWorkingWithFormations?: TWorkingWithFormationsGoals[];
  financialLiteracy?: string;
  yearlyTaxInfo?: YearlyTaxInfo;
}

export interface IInsights {
  ytdExpense: number;
}

export interface IAccountStatus {
  label: AccountStatus;
  updatedAt: string;
  updatedBy: string;
}

export interface IAccountListItem {
  companyId: string;
  companyName: string;
  entityType: string;
  id: string;
  ownerEmail: string;
  ownerId: string;
  ownerName: string;
  insights: IInsights;
  progress: ProgressTrackerGroup[];
  status: IAccountStatus;
  createdAt: string;
  rightSignatureURL?: string;
}

export type ProgressTrackerEvent = {
  stage: ProgressTrackerStages | 'EntitySelection' | 'IncorporationStatus';
  incorporationExists?: string;
  stageStatus?: ProgressTrackerStatus;
  accountId?: string;
  entityType: string;
  status?: 'success' | 'error';
  bankName?: string;
  bankRequired?: string;
};

export type xeroConnectError = {
  Code: number;
  Message: string;
  OccurredAt: string;
};

export interface IAccountXeroConnectionHistoryResp {
  id: string;
  // eslint-disable-next-line camelcase
  xero_account_id: string;
  // eslint-disable-next-line camelcase
  operation: string;
  // eslint-disable-next-line camelcase
  created_at: string;
  // eslint-disable-next-line camelcase
  created_by: string;
  // eslint-disable-next-line camelcase
  xero_errors: xeroConnectError[] | null;
  // eslint-disable-next-line camelcase
  xero_company_name: string;
}

export interface IAccountXeroConnectionHistory {
  id: string;
  operation: string;
  createdAt: string;
  createdBy: string;
  xeroAccountId: string;
  xeroErrors: xeroConnectError[] | null;
  xeroCompanyName: string;
}

export interface NotificationPreference {
  productCommunication: boolean;
  incorporationUpdate: boolean;
  bankingUpdate: boolean;
}
export interface AccountPreference {
  id: string;
  accountId: string;
  textNotify: boolean;
  phoneNumber: string;
  emailNotification: NotificationPreference;
}
