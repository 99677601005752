import { TableCell, Menu, MenuItem, Typography } from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { 
  MoreVertIcon, 
  CloudDownloadIcon, 
  DeleteIcon, 
  FileOpenIcon 
} from 'components/common/Icon';
import { downloadFileBlob } from 'helpers/documents';
import { CHURN_ZERO_EVENT_NAMES } from 'constants/common';
import { sendChurnZeroEvent } from 'helpers/churnZero';
import { showErrorToast } from 'components/toast/showToast';
import { DirectPayment } from 'components/taxes/types';
import { downloadDocument, getDocumentById } from 'services/document';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'states/reactQueryClient';
import { DeletePopover } from './DeletePopover';

const ActionsMenu = styled(Menu)(({ theme }) => ({
  '& .MuiMenu-list': {
    padding: 0,
    '& .MuiMenuItem-root:hover': {
      '& .actionIcon': {
        color: theme.palette.primary.main,
      },
      '& .actionText': {
        color: theme.palette.primary.main,
      },
    },
  },
}));

const ActionsMenuItem = styled(MenuItem)(() => ({
  display: 'flex',
  paddingRight: '8px',
  paddingLeft: '8px',
  minWidth: 175,
}));

const IconTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const ActionsCell = ({
  accountId,
  row,
  onViewClick,
}: {
  accountId: string | undefined;
  row: DirectPayment;
  onViewClick: (row: DirectPayment) => void;
}) => {
  const { t } = useTranslation();
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const onMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsMenuVisible(true);
    setAnchorElement(event.currentTarget);
  };

  const onDeletePopoverClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsPopoverVisible(true);
    setIsMenuVisible(false);
  };

  const onDeletePopoverClose = (shouldRefresh: boolean) => {
    setIsPopoverVisible(false);
    setIsMenuVisible(false);
    if (shouldRefresh) {
      queryClient.invalidateQueries(['documents', 'accountId', accountId]);
      queryClient.invalidateQueries(['direct-payments']);
    }
  };

  const onMenuClose = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setIsMenuVisible(false);
  };

  const onDownload = async () => {
    try {
      const document = await getDocumentById(row.documentId);
      const blob = await downloadDocument(row.documentId);
      downloadFileBlob(blob, document.title);
      sendChurnZeroEvent(CHURN_ZERO_EVENT_NAMES.DOCUMENT_DOWNLOAD);
      setIsMenuVisible(false);
    } catch (error) {
      showErrorToast('Error when downloading file');
    }
  };

  return (
    <TableCell align="center">
      <FormationsGhostButton
        rounded
        data-testid="actions-cell"
        onClick={onMenuClick}
      >
        <MoreVertIcon />
      </FormationsGhostButton>
      <ActionsMenu
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={onMenuClose}
        open={Boolean(anchorElement) && isMenuVisible}
      >
        {/* View and download payment document actions */}
        {row.documentId ? (
          <ActionsMenuItem
            data-testkey="view-menu-item"
            onClick={(event) => {
              event.stopPropagation();
              onViewClick(row);
            }}
          >
            <FileOpenIcon
              sx={{ color: (theme) => theme.palette.text.secondary }}
              className="actionIcon"
            />
            <IconTitle className="actionText" variant="body3S" ml={1}>
              {t('taxes.menu.view')}
            </IconTitle>
          </ActionsMenuItem>
        ) : null}
        {row.documentId ? (
          <ActionsMenuItem
            data-testkey="download-menu-item"
            onClick={(event) => {
              event.stopPropagation();
              onDownload();
            }}
          >
            <CloudDownloadIcon
              sx={{ color: (theme) => theme.palette.text.secondary }}
              className="actionIcon"
            />
            <IconTitle className="actionText" variant="body3S" ml={1}>
              {t('taxes.menu.download')}
            </IconTitle>
          </ActionsMenuItem>
        ) : null}
        {/* Delete payment action */}
        <ActionsMenuItem
          data-testkey="delete-menu-item"
          onClick={onDeletePopoverClick}
        >
          <DeleteIcon
            className="actionIcon"
            sx={{ color: (theme) => theme.palette.text.secondary }}
          />
          <IconTitle className="actionText" variant="body3S" ml={1}>
            {t('taxes.menu.delete')}
          </IconTitle>
        </ActionsMenuItem>
      </ActionsMenu>
      <DeletePopover
        open={Boolean(anchorElement) && isPopoverVisible}
        anchorEl={anchorElement}
        row={row}
        accountId={accountId}
        onClose={onDeletePopoverClose}
      />
    </TableCell>
  );
};
