/* eslint-disable */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stepper,
  Typography,
} from '@mui/material';
import { AccordionCTA } from 'components/ProgressTracker/AccordionCTA';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircle } from '@mui/icons-material';
import { useCurrentAccount, useCurrentCompany } from 'hooks/api';
import { StageSkeleton } from 'components/ProgressTracker/StageSkeleton';
import {
  IAccount,
  ProgressTrackerGroup,
  ProgressTrackerStages,
  ProgressTrackerStatus,
} from 'models/account';
import { Company } from 'models/company';
import { ExpandMoreIcon } from 'components/common/Icon';
import { getLatestStatus } from 'helpers/progressTracker';
import { FormationsStep } from 'components/FormationsStep';
import { PersonalDetailsContainer as PersonalDetails } from 'components/ProgressTracker/Incorporation/PersonalDetails';
import { AddressInformationContainer as AddressInformation } from 'components/ProgressTracker/Incorporation/AddressInformation';
import { CompanyDetails1120sOnlyContainer as CompanyDetails } from 'components/ProgressTracker/Incorporation/CompanyDetails1120s';
import { OwnershipDetailsContainer as OwnershipDetails } from './OwnershipDetails'
import { BUSINESS_OWNERSHIP } from 'enums';

interface Props {
  company: Company;
  account: IAccount;
  isDesktop: boolean;
  isExpanded: boolean; 
  expandHandler: () => void;
  onContinueToAccountVerification: () => void;
}

const getStageStatus = (
  group: ProgressTrackerGroup[],
  name: string,
): ProgressTrackerStatus => {
  const stage = group.find((g) => g.stage == name);
  if (stage) {
    const status = getLatestStatus(stage.status);
    return status?.status ?? ProgressTrackerStatus.NotStarted;
  }
  return ProgressTrackerStatus.NotStarted;
};

const firstStateNotComplete = (account: IAccount, multiMember: boolean) => {
  if (getStageStatus(account.progress, ProgressTrackerStages.PersonalDetails) !== ProgressTrackerStatus.Completed) {
    return 0;
  }
  if (getStageStatus(account.progress, ProgressTrackerStages.CompanyDetails) !== ProgressTrackerStatus.Completed) {
    return 1;
  }
  if (getStageStatus(account.progress, ProgressTrackerStages.AddressDetails) !== ProgressTrackerStatus.Completed) {
    return 2;
  }
  if ((multiMember) && getStageStatus(account.progress, ProgressTrackerStages.OwnershipDetails) !== ProgressTrackerStatus.Completed) {
    return 3;
  }
  return 0;
}

export const Incorporate1120sOnlyUI = ({
  account,
  company,
  isDesktop,
  isExpanded,
  expandHandler,
  onContinueToAccountVerification,
}: Props) => {
  const { t } = useTranslation();
  const isMultiMemberOwnedBusiness = account?.businessOwnerShip === BUSINESS_OWNERSHIP.PARTNERSHIP;
  // const addressState = company.contactDetails?.mailingAddress?.state
  // const taxFilingStatus = account.generalTaxInfo?.taxFilingStatus
  // const isAdditionalOwnerNeeded = useMemo(() => needAdditionalOwner(addressState, taxFilingStatus), [addressState, taxFilingStatus]);

  const [currentStep, setCurrentStep] = React.useState(firstStateNotComplete(account, isMultiMemberOwnedBusiness));

  const stageMap = useMemo(
    () => ({
      [ProgressTrackerStages.PersonalDetails]: getStageStatus(
        account.progress,
        ProgressTrackerStages.PersonalDetails,
      ),
      [ProgressTrackerStages.CompanyDetails]: getStageStatus(
        account.progress,
        ProgressTrackerStages.CompanyDetails,
      ),
      [ProgressTrackerStages.AddressDetails]: getStageStatus(
        account.progress,
        ProgressTrackerStages.AddressDetails,
      ),
      [ProgressTrackerStages.OwnershipDetails]: getStageStatus(
        account.progress,
        ProgressTrackerStages.OwnershipDetails,
      )
    }),
    [account.progress],
  );

  const steps = useMemo(() => {
    const baseSteps = [
      {
        key: ProgressTrackerStages.PersonalDetails,
        index: 0,
        label: t('progressTracker.incorporation.step.personalDetails'),
        status: stageMap[ProgressTrackerStages.PersonalDetails],
      },
      {
        key: ProgressTrackerStages.CompanyDetails,
        index: 1,
        label: t('progressTracker.incorporation.step.companyDetails'),
        status: stageMap[ProgressTrackerStages.CompanyDetails],
      },
      {
        key: ProgressTrackerStages.AddressDetails,
        index: 2,
        label: t('progressTracker.incorporation.step.businessAddress'),
        status: stageMap[ProgressTrackerStages.AddressDetails],
      },
    ];
    // In future, if needed to implement the additional owner needed for community states, 
      // add the isAdditionalOwnerNeeded boolean here and wherever else you see the isMultiMemberOwnedBusiness boolean in this file
      // it should triggor this ownership details section to be added if the criteria is met once they fill out their business address information
    if (isMultiMemberOwnedBusiness) {
      baseSteps.push({
        key: ProgressTrackerStages.OwnershipDetails,
        index: 3,
        label: t('progressTracker.incorporation.step.ownershipDetails'),
        status: stageMap[ProgressTrackerStages.OwnershipDetails],
      });
    }
  
    return baseSteps;
  }, [stageMap, isMultiMemberOwnedBusiness]);

  const isStageCompleted = useMemo(() => {
    const baseConditions = 
      stageMap[ProgressTrackerStages.PersonalDetails] === ProgressTrackerStatus.Completed &&
      stageMap[ProgressTrackerStages.CompanyDetails] === ProgressTrackerStatus.Completed &&
      stageMap[ProgressTrackerStages.AddressDetails] === ProgressTrackerStatus.Completed;
  
    const ownershipCondition = 
      (!isMultiMemberOwnedBusiness) ||
      stageMap[ProgressTrackerStages.OwnershipDetails] === ProgressTrackerStatus.Completed;
  
    return baseConditions && ownershipCondition;
  }, [stageMap, isMultiMemberOwnedBusiness]);

  // jump to the first incomplete step
  useEffect(() => {
    const nextAvailableStep = firstStateNotComplete(account, isMultiMemberOwnedBusiness);
    if (nextAvailableStep != currentStep) {
      setCurrentStep(nextAvailableStep)
    }
  }, [account.progress])

  const completedSteps = useMemo(() => {
    return steps.filter(
      (step) => step.status === ProgressTrackerStatus.Completed,
    ).length;
  }, [steps]);
  const totalSteps = steps.length;

  return (
    <Accordion expanded={isExpanded} onChange={expandHandler}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6B">Create Your Account</Typography>
              <Typography variant="subtitle1" color="primary">
                {t('progressTracker.incorporation.subTitle')}
              </Typography>
            </Grid>
            <Grid item>
              {!isStageCompleted && !isExpanded && (
                <Typography variant="h8B">
                  {completedSteps}/{totalSteps}
                </Typography>
              )}
              {isStageCompleted && !isExpanded && (
                <Typography variant="h8B" sx={{ display: 'flex' }}>
                  <CheckCircle color="secondary" />
                  &nbsp; {t('progressTracker.complete')}
                </Typography>
              )}
            </Grid>
          </Grid>
          <AccordionCTA
            expanded={isExpanded}
            handleExpand={expandHandler}
            completed={completedSteps}
            totalStep={steps.length}
            testId="start-incorporation"
            accordionId="incorporation"
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container gap={2}>
          <Grid item xs={12} md={(currentStep === 3 && (isMultiMemberOwnedBusiness)) ? 3 : 5}>
            <Stepper
              activeStep={currentStep}
              nonLinear
              orientation={isDesktop ? 'vertical' : 'horizontal'}
              alternativeLabel={!isDesktop}
              data-testid="incorporation-stages"
            >
              {steps.map(({ key, status, label }, index) => (
                <FormationsStep
                  key={key}
                  index={index}
                  setActiveStep={setCurrentStep}
                  status={status}
                  label={label}
                  isTrainer={false}
                />
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12} md={(currentStep === 3 && (isMultiMemberOwnedBusiness)) ? 8 : 6}>
            {currentStep === 0 && <PersonalDetails company={company} />}
            {currentStep === 1 && <CompanyDetails company={company} account={account} />}
            {currentStep === 2 && (
              <AddressInformation 
                account={account} 
                company={company}
                hasOwnershipSection={isMultiMemberOwnedBusiness} 
                onContinueToAccountVerification={onContinueToAccountVerification} />
            )}
            {currentStep === 3 && (
              <OwnershipDetails
                account={account}
                company={company}
                onContinueToAccountVerification={onContinueToAccountVerification}
              />
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export const Incorporation1120sOnly = (props: 
  { 
    isDesktop: boolean, 
    isExpanded: boolean, 
    expandHandler: () => void,
    onContinueToAccountVerification: () => void 
  }) => {
  const {
    currentCompany: companyData,
    isLoading: companyDataLoading,
  } = useCurrentCompany();
  const { currentAccount, isLoading } = useCurrentAccount();

  if (companyDataLoading && isLoading) {
    return <StageSkeleton />;
  }
  if (companyData && currentAccount) {
    return (
      <Incorporate1120sOnlyUI
        {...props}
        account={currentAccount}
        company={companyData}
      />
    );
  }
  return null;
};
