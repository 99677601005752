import { TextField } from '@mui/material';
import { ReactNode } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { isString } from 'lodash';
import { IFormField } from './types';

export const formatZipCode = (value: string): string => {
  const isAllowedPattern = /(\d*)-?(\d*)/.test(value);
  if (isAllowedPattern) {
    let zipCodeValue: string;
    const newValue = value.replace('-', '');
    if (newValue.length > 5) {
      zipCodeValue = `${newValue.substring(0, 5)}-${newValue.substring(5, 9)}`;
    } else {
      zipCodeValue = newValue;
    }
    return zipCodeValue;
  }
  return value;
};

export const formatEinInput = (
  value: string | undefined | null,
): string | undefined | null => {
  if (!isString(value)) return value;
  const newValue = value.replace(/-/g, '');
  if (newValue.length > 2) {
    return `${newValue.substring(0, 2)}-${newValue.substring(2)}`;
  }
  return newValue;
};

export interface TFormationTextFieldOptions {
  placeholder?: string;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  maxLength?: number;
  autoCapitalize?: boolean;
  autoFocus?: boolean;
  startAdornment?: ReactNode;
  formatInput?: (value: string | undefined | null) => string | undefined | null;
  defaulValue?: string
}

export type TFormationsTextField<T = any> = {
  field: IFormField<TFormationTextFieldOptions>;
  formInstance: UseFormMethods<T>;
};

export const FormationsTextField = ({
  field,
  formInstance,
}: TFormationsTextField) => {
  const { control, errors } = formInstance;
  const {
    label = field.name,
    disabled = false,
    defaultValue = '',
    options: {
      placeholder = '',
      inputMode = 'text',
      autoCapitalize = false,
      autoFocus = false,
      startAdornment = null,
      maxLength,
      formatInput = (value: string) => value,
    } = {},
  } = field;

  const onChangeFormatted = (onChange: (...event: any[]) => void) => (e: any) =>
    onChange(formatInput(e.target.value));

  return (
    <Controller
      name={field.name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value = '' }) => (
        <TextField
          name={field.name}
          onChange={onChangeFormatted(onChange)}
          value={value}
          variant="outlined"
          fullWidth
          disabled={disabled}
          label={label}
          placeholder={placeholder}
          error={!!errors[field?.name]}
          helperText={errors[field?.name]?.message}
          InputProps={{
            startAdornment,
            inputProps: {
              inputMode,
              maxLength,
              style: {
                textTransform: autoCapitalize ? 'capitalize' : 'none',
              },
              autoCapitalize: autoCapitalize ? 'on' : 'off',
            },
          }}
          data-testid={`field-${field.name}`}
          autoFocus={autoFocus}
        />
      )}
    />
  );
};
