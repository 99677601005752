import { QueryParams } from 'hooks/api/useTransactions';
import { ApiListResp } from 'models/api';
import queryString from 'query-string';
import { defaultPageInfo } from './account';
import { api } from './axios';

export const UNKNOWN_TRANSACTION_CATEGORY_CODE = '6999';
export const UNKNOWN_TRANSACTION_CATEGORY = 'uncategorized'; // Specifically for the unknown transactions page query

export interface IProfitLossGraphData {
  id: string;
  accountId: string;
  year: number;
  month: number;
  grossProfit: number;
  netProfit: number;
  expense: number;
  wages: number;
  updatedAt: Date;
  // eslint-disable-next-line camelcase
  total_income: number;
}

export interface ISaveXeroCodeProps {
  code: string;
  // eslint-disable-next-line camelcase
  redirect_url: string;
}

export interface ICategoryItem {
  accountID: string;
  addToWatchlist: boolean;
  bankAccountNumber: string;
  bankAccountType: string;
  class: string;
  code: string;
  systemAccount: string;
  currencyCode: string;
  enablePaymentsToAccount: boolean;
  formAccountID: string;
  hasAttachments: boolean;
  id: string;
  name: string;
  reportingCode: string;
  reportingCodeName: string;
  showInExpenseClaims: boolean;
  status: string;
  taxType: string;
  type: string;
  updatedDateUTC: Date;
}

export const saveXeroCodeToServer = async (
  accountId: string,
  params: ISaveXeroCodeProps,
) => api.post(`accounts/${accountId}/xero`, params);

export interface IXeroTransactionNote {
  note?: string;
  lastUpdatedAt: string;
}

export interface IXeroTransactionItem {
  id: string;
  accountID: string;
  type: string;
  amount: number;
  currency: string;
  contact: string;
  description: string;
  date: string;
  source: string;
  categoryCode: string;
  note: IXeroTransactionNote;
  // eslint-disable-next-line camelcase
  is_reconciled: boolean;
}

export interface ITransactionListItemResp {
  data: IXeroTransactionItem[];
}

export type ApiListTransactions = ApiListResp<IXeroTransactionItem[]>;
export const getXeroTransactions = async (
  accountID: string,
  params: Partial<QueryParams>,
): Promise<ApiListTransactions> => {
  const { data } = await api.get<ApiListResp<IXeroTransactionItem[]>>(
    `transactions?${queryString.stringify({ ...params, accountID })}`,
  );
  return {
    data: data?.data || [],
    pageInfo: data?.pageInfo || defaultPageInfo,
  };
};

export const getXeroCategories = async (
  accountID: string,
): Promise<ICategoryItem[]> => {
  const { data } = await api.get<ICategoryItem[]>('xeroaccounts', {
    params: {
      accountID,
    },
  });
  return data;
};

export const updateTransaction = async (
  transactionId: string,
  params: {
    categoryCode?: string;
    note?: string;
  },
): Promise<any> => {
  const { data } = await api.patch(`transactions/${transactionId}`, {
    ...params,
  });
  return data;
};

export const disconnectXero = async (
  accountId: string,
  force: boolean = false,
) => {
  const params = force ? { force } : {};
  const { data } = await api.delete(`accounts/${accountId}/xero`, {
    params,
  });
  return data;
};

export const createTransaction = async (
  accountId: string,
  formData: FormData,
) => {
  const { data } = await api.post<FormData>(
    `/accounts/${accountId}/bankTransactions`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 120000,
    },
  );
  return data;
};

export const getProfitLossGraphData = async (accountID: string) => {
  const { data } = await api.get<IProfitLossGraphData[]>('xeromonthlypl', {
    params: {
      accountID,
    },
  });
  // due to different country setting in XERO, sometime expense would be return as negative number, use Math.abs to unify the data
  return data.map((item) => ({
    ...item,
    expense: Math.abs(item.expense),
  }));
};

export const deleteAllTransactions = (accountId: string) =>
  api.delete(`/accounts/${accountId}/xerodata`);

export const getXeroUnknownTransactionCount = async (
  accountID: string,
  year: number,
): Promise<{ count: number }> => {
  const { data } = await api.get<{ count: number }>(
    `/transactions/${accountID}/count-unknown`,
    {
      params: {
        year,
      },
    },
  );
  return data;
};
