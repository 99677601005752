import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { Controller, UseFormMethods } from 'react-hook-form';
import { 
  useEffect, 
  useState 
} from 'react';
import { IFormField } from './types';

export interface TFormationSelectOptions {
  placeholder?: string;
  options?: Array<{ label: string; value: string }>;
}

type TFormationsSelect = {
  field: IFormField<TFormationSelectOptions>;
  formInstance: UseFormMethods;
};

export const FormationsMultiSelect = ({
  field,
  formInstance,
}: TFormationsSelect) => {
  const { disabled = false } = field;
  const { errors, control, watch } = formInstance;
  const { options: { placeholder, options = [] } = {} } = field;
  const [selections, setSelections] = useState<string[]>([]);

  const currentFieldValue = watch(field.name);
  useEffect(() => {
    // reset the selections if the field value gets set to an empty array
    if (Array.isArray(currentFieldValue) && currentFieldValue.length === 0) {
      setSelections([]);
    }
    // set the selections if there is a default value and it sets the field value
    if (Array.isArray(currentFieldValue) && currentFieldValue.length > 0) {
      setSelections(currentFieldValue)
    }
  }, [currentFieldValue]);

  const handleMenuItemClick = (value: string) => {
    if (selections.includes(value)) {
      setSelections(selections.filter((selection) => selection !== value));
    } else {
      setSelections([...selections, value]);
    }
  };

  const labelId = `${field.name}-label`;

  return (
    <FormControl
      variant="outlined"
      fullWidth
      data-testid={`field-${field.name}`}
      error={!!errors[field?.name]}
    >
      <InputLabel id={labelId}>{field.label}</InputLabel>
      <Controller
        as={
          <Select
            labelId={labelId}
            label={field.label}
            multiple
            value={selections || []}
            defaultValue={field.defaultValue || ''}
            sx={{ minWidth: '258px' }}
            disabled={disabled}
            renderValue={(selected) => (
              options.filter(option => selected.includes(option.value))
                     .map(option => option.label)
                     .join(', ')
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: '30vh',
                  overflow: 'auto',
                },
              },
            }}
          >
            <MenuItem disabled>
              <ListItemText primary={placeholder} />
            </MenuItem>
            {options.map(({ label, value }) => (
              <MenuItem 
                key={value} 
                value={value}
                onClick={() => handleMenuItemClick(value)}
                >
                <Checkbox checked={selections.includes(value)} />
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Select>
        }
        defaultValue={[]}
        name={field.name}
        control={control}
      />
      <FormHelperText>{errors[field?.name]?.message || ''}</FormHelperText>
    </FormControl>
  );
};