import { Box } from '@mui/material';
import { 
  ArrowBackIcon, 
  SaveIcon 
} from 'components/common/Icon';
import { HubspotService } from 'services';
import { ProgressTrackerStatus } from 'services/account';
import { UserInfo } from 'services/users';
import { FormProvider, useForm } from 'react-hook-form';
import { FormationsTertiaryButton, FormationsGhostButton } from 'components/common/Buttons';
import { ALLOWED_BANKS } from 'enums';
import { Company } from 'models/company';
import { useUpdateCompany } from 'hooks/api';
import { useUpdateTimeContactProperties } from 'hooks';
import { queryClient } from 'states/reactQueryClient';
import { HaveAccountWithBIB } from './HaveAccountWithBIB';
import { SelectedBank } from './SelectedBank';

type TFormData = {
  haveAccountWith: string;
};

const toFormData = (company: Company) => ({
  haveAccountWith: !company?.useExistingBank ? company?.bankName ?? '' : '',
});

interface TParams {
  company: Partial<Company>;
  handleShowRelayBankCreation: () => void;
  handleComplete: (status: ProgressTrackerStatus, eventData: Object) => void;
  user: Partial<UserInfo>;
}

export const BankSelectionBIB = ({ company, handleShowRelayBankCreation, handleComplete, user }: TParams) => {

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: toFormData(company),
    shouldUnregister: false,
  });

  const { mutateAsync: updateCompany, isLoading: isCompanyUpdating } =
    useUpdateCompany({
      onSuccess: () => {
        queryClient.invalidateQueries(['getCompany', company?.id]);
      },
    });

  const { mutate: updateTimeContactProperties, isLoading: isContactUpdating } =
    useUpdateTimeContactProperties();

  const updateBankSelection = async (formData: TFormData) => {
    if (company?.id && user?.contactId) {
      const {
        haveAccountWith,
      } = formData;
      const bank = haveAccountWith;
      const isExistingBank = !!ALLOWED_BANKS[bank];
      const companyData = {
        bankName: bank,
        useOtherBank: !isExistingBank,
        useExistingBank: isExistingBank,
        hasBankAccount: true,
      };
      await updateCompany({ id: company.id, data: companyData });
      updateTimeContactProperties({
        contactId: user.contactId,
        property: HubspotService.TimestampAccountType.BANKPREFERENCE,
      });
      handleComplete(ProgressTrackerStatus.Completed, {
        bankName: bank,
      });
    }
  };

  const handleNext = async (data: any) => {
    await updateBankSelection(data);
  };

  return (
    <div data-testid="step-bank-selection">
      {company?.bankName ? (
        <SelectedBank company={company} isBIB user={user} />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleNext)} noValidate>
            <HaveAccountWithBIB />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '48px',
              }}
            >
              <FormationsGhostButton
                onClick={handleShowRelayBankCreation}
                startIcon={<ArrowBackIcon />}
                data-testid="back-btn"
              >
                Back
              </FormationsGhostButton>
              <FormationsTertiaryButton
                isLoading={isCompanyUpdating || isContactUpdating}
                type="submit"
                size="large"
                data-testid="continue"
                loadingPosition="end"
                endIcon={<SaveIcon />}
                sx={{ ml: 'auto' }}
              >
                Save and Continue
              </FormationsTertiaryButton>
            </Box>
          </form>
        </FormProvider>
      )}
    </div>
  );
};
