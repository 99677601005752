import { ApiResponse } from 'models/api';
import { api } from './axios';

export enum DropboxSignTemplates {
  'LLCOnlySignature' = 'LLCOnlySignature',
}

export type HelloSignTemplateSignature = {
  signature_id: string;
  signer_email_address: string;
  signer_name: string;
  signer_role: string;
  order: number;
  status_code: string;
  signed_at: string;
  last_viewed_at: string;
  last_reminded_at: string;
  has_pin: boolean;
  has_sms_auth: boolean;
};

export type HelloSignSignature = {
  sign_url: string;
  expires_at: string;
};

export type HelloSignTemplate = {
  signature_request_id: string;
  title: string;
  original_title: string;
  subject: string;
  metadata: any;
  message: string;
  is_complete: boolean;
  is_declined: boolean;
  has_error: boolean;
  custom_fields: any[];
  response_data: any[];
  signing_url: string;
  signing_redirect_url: string;
  details_url: string;
  requester_email_address: string;
  signatures: HelloSignTemplateSignature[];
};

export type HelloSignSignedDocument = {
  id: string;
  signatureRequestTemplate: DropboxSignTemplates;
  signatureRequestId: string;
  accountId: string;
  documentId?: string;
  signedAt: string;
};

export const createHelloSignTemplate = async (
  accountId: string,
  templateName: DropboxSignTemplates,
): Promise<HelloSignTemplate> => {
  const { data } = await api.post<
    {
      templateName: DropboxSignTemplates;
    },
    ApiResponse<HelloSignTemplate>
  >(`dropbox-sign/${accountId}/create-embedded-with-template`, {
    templateName,
  });
  return data;
};

export const getHelloSignSignatureURL = async (
  accountId: string,
  signatureId: string,
): Promise<HelloSignSignature> => {
  const { data } = await api.get<HelloSignSignature>(
    `dropbox-sign/${accountId}/embedded-signature-url/${signatureId}`,
  );
  return data;
};

export const getSignedDocument = async (
  template: DropboxSignTemplates,
  accountId?: string,
): Promise<HelloSignSignedDocument[]> => {
  const { data } = await api.get<HelloSignSignedDocument[]>(
    `/dropbox-sign/${accountId}/signed-documents?template=${template}`,
  );
  return data;
};

export const saveHelloSignDocument = async (
  accountId: string,
  signatureRequestId: string,
): Promise<HelloSignSignedDocument> => {
  const { data } = await api.post<HelloSignSignedDocument>(
    `/dropbox-sign/${accountId}/save-signed-document/${signatureRequestId}`,
  );
  return data;
};
