import { ClickAwayListener, Tooltip, Typography, styled } from '@mui/material';
import { useCallback, useState } from 'react';
import { InfoIcon as InfoOutlinedIcon } from 'components/common/Icon';
import { FormationsGhostButton } from './Buttons';

const InfoIconButton = styled(FormationsGhostButton)(() => ({
  width: '20px',
  height: '20px',
  fontSize: '20px',
  marginTop: -1,
}));

const StyledLink = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  cursor: 'pointer',
  textDecoration: 'underline',
}));

export const FormationsInfoTooltipMobileAdaptable = ({
  tooltipContent,
  link,
  linkText, // the link and linkText will be placed at the end of the tooltip content
  isMobile = false,
}: {
  tooltipContent: string;
  link?: string;
  linkText?: string;
  isMobile: boolean;
}) => {
  const openLink = useCallback(() => {
    window.open(link, '_blank');
  }, [link]);

  const [openTooltip, setOpenTooltip] = useState(false);

  // Toggle the visibility state for mobile
  const handleTooltipToggle = () => {
    if (isMobile) {
      setOpenTooltip(!openTooltip);
    }
  };

  // Open tooltip for desktop
  const handleMouseEnter = () => {
    if (!isMobile) {
      setOpenTooltip(true);
    }
  };

  // Close tooltip for desktop
  const handleMouseLeave = () => {
    if (!isMobile) {
      setOpenTooltip(false);
    }
  };

  // Close the tooltip when clicking away on mobile
  const handleClickAwayFromTooltip = () => {
    setOpenTooltip(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAwayFromTooltip}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        role="button"
        tabIndex={0}
      >
        <Tooltip
          disableFocusListener
          disableHoverListener
          disableTouchListener
          open={openTooltip}
          title={
            <Typography
              variant="tooltip"
              sx={{ whiteSpace: 'pre-wrap', color: 'primary.contrastText' }}
            >
              {tooltipContent}
              {linkText && link && (
                <StyledLink variant="tooltip" onClick={openLink}>
                  {linkText}
                </StyledLink>
              )}
            </Typography>
          }
          placement={isMobile ? 'bottom' : 'right'}
          arrow
          componentsProps={{
            arrow: {
              sx: { color: 'primary.main' },
            },
            tooltip: {
              sx: { backgroundColor: 'primary.main', ml: 1, mr: 1 },
            },
          }}
        >
          <InfoIconButton rounded onClick={handleTooltipToggle}>
            <InfoOutlinedIcon fontSize="inherit" size={20} />
          </InfoIconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};
