import { Typography, Grid } from '@mui/material';
import { directDepositSchema } from 'schemas';
import { BANK_ACCOUNT_OPTIONS } from 'enums';
import { useCurrentUser, useUpdateUser } from 'hooks/api';
import { queryClient } from 'states/reactQueryClient';
import { FormationsTertiaryButton } from 'components/common/Buttons';
import { SaveIcon } from 'components/common/Icon';
import { IAccount, ProgressTrackerStatus } from 'services/account';
import { ReadOnlyForm } from 'components/common/ReadOnlyForm';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormationsForm,
  FormationsFormFields,
} from 'components/common/FormationsForm2';
import { isAccount1120sOnly } from 'components/ProgressTracker/util';

export const DirectDepositInfo = ({
  handleContinue,
  currentStatus,
  account,
}: {
  handleContinue: () => void;
  currentStatus: ProgressTrackerStatus;
  account: IAccount;
}) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const { currentUser: userData } = useCurrentUser();

  const { updateUser } = useUpdateUser({
    onSuccess: () => {
      queryClient.invalidateQueries(['currentUser']);
      handleContinue();
    },
  });

  const handleSubmit = async (data: any) => {
    setSubmitting(true);
    updateUser({ data });
  };

  const is1120sOnly = useMemo(() => isAccount1120sOnly(account), [account]);
  const subtitle = useMemo(() => {
    if (currentStatus === ProgressTrackerStatus.Completed) {
      return t(
        'progressTracker.accountVerification.directDepositInfo.subTitleCompleted',
      );
    }
    return is1120sOnly
      ? t('progressTracker.accountVerification.directDepositInfo.subTitle1120s')
      : t('progressTracker.accountVerification.directDepositInfo.subTitle');
  }, [currentStatus, is1120sOnly, t]);

  return (
    <>
      <Typography variant="h5B" component="h5">
        {t('progressTracker.accountVerification.directDepositInfo.title')}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: 'text.secondary',
          my: 2,
          mx: 0,
        }}
      >
        {subtitle}
      </Typography>
      <Grid container sx={{ mb: '36px' }} spacing={2}>
        {currentStatus === ProgressTrackerStatus.Completed ? (
          <Grid item xs={12}>
            <ReadOnlyForm
              items={[
                {
                  key: 'bank-name',
                  title: 'Bank Name',
                  value: userData?.bankName || 'N/A',
                },
                {
                  key: 'routing-number',
                  title: 'Routing Number',
                  value: userData?.routingNumber || 'N/A',
                },
                {
                  key: 'account-number',
                  title: 'Account Number',
                  value: userData?.bankAccountNumber || 'N/A',
                },
                {
                  key: 'account-type',
                  title: 'Account Type',
                  value: userData?.bankAccountType || 'N/A',
                },
              ]}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <FormationsForm
              onSubmit={handleSubmit}
              validationSchema={directDepositSchema}
              fields={[
                {
                  type: FormationsFormFields.Text,
                  name: 'bankName',
                  label: 'Bank Name',
                  options: {
                    placeholder: 'Bank Name',
                    autoFocus: true,
                    maxLength: 80,
                  },
                },
                {
                  type: FormationsFormFields.Text,
                  name: 'routingNumber',
                  label: 'Routing Number',
                  options: {
                    placeholder: '123456789',
                    inputMode: 'tel',
                    maxLength: 9,
                  },
                },
                {
                  type: FormationsFormFields.Text,
                  name: 'bankAccountNumber',
                  label: 'Account Number',
                  options: {
                    placeholder: 'Account Number',
                    inputMode: 'tel',
                    maxLength: 15,
                  },
                },
                {
                  type: FormationsFormFields.Select,
                  name: 'bankAccountType',
                  label: 'Account Type',
                  options: {
                    placeholder: 'Choose an account type',
                    options: BANK_ACCOUNT_OPTIONS,
                  },
                },
              ]}
              defaultValues={{
                bankName: userData?.bankName,
                routingNumber: userData?.routingNumber,
                bankAccountNumber: userData?.bankAccountNumber,
                bankAccountType: userData?.bankAccountType,
                mode: 'onChange',
                reValidateMode: 'onChange',
              }}
              renderActions={() => (
                <FormationsTertiaryButton
                  isLoading={submitting}
                  type="submit"
                  size="large"
                  data-testid="save-direct-deposit"
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                >
                  Save and Continue
                </FormationsTertiaryButton>
              )}
              loading={false}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
