import { Chip, Badge } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PrimaryChip = styled(Chip)(({ theme, icon }) => ({
  borderRadius: theme.spacing(3),
  borderColor: icon ? 'transparent' : theme.palette.gray[100],
  backgroundColor: icon ? theme.palette.gray[10] : 'transparent',
  '& .MuiChip-label': {
    color: icon ? theme.palette.gray[50] : theme.palette.gray[100],
  },
  '&.MuiChip-filled': {
    backgroundColor: theme.palette.blue.active,
    '& .MuiChip-label, & .MuiChip-icon': {
      color: '#FFFFFF',
    },
    borderColor: 'transparent',
  },
  '&.MuiChip-clickable:hover': {
    backgroundColor: theme.palette.blue.hover,
    '& .MuiChip-label, & .MuiChip-icon': {
      color: '#FFFFFF',
    },
    borderColor: 'transparent',
  },
  '&.Mui-disabled': {
    opacity: '50%',
  },
  '& .MuiChip-icon': {
    color: theme.palette.gray[50],
  },
}));

export const PrimaryChipBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.red.primary,
    color: '#FFFFFF'
  },
}));
