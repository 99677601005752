import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { IFormField } from './types';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  prefix: string;
  decimalScale: number;
}

export const NumberFormatCustom = React.forwardRef<NumberFormat, CustomProps>(
  (props, ref) => {
    const { onChange, prefix = '$', decimalScale = 2, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix={prefix}
        decimalScale={decimalScale}
        fixedDecimalScale
        allowNegative={false}
      />
    );
  },
);

const useStyles = makeStyles(() => ({
  readOnly: {
    '& fieldset': {
      border: 0,
    },
    '& input': {
      textAlign: 'right',
    },
  },
}));

interface CurrencyFieldOptions {
  placeholder?: string;
  maxLength?: number;
  width?: string;
  autoFocus?: boolean;
  shrink?: boolean;
}

interface IFormationsCurrencyField {
  field: IFormField<CurrencyFieldOptions>;
  formInstance: UseFormMethods;
}

export const FormationsCurrencyField = ({
  field,
  formInstance,
}: IFormationsCurrencyField) => {
  const classes = useStyles();
  const { control, errors } = formInstance;
  const {
    label,
    defaultValue,
    name,
    disabled = false,
    readOnly,
    options: {
      placeholder = '',
      maxLength,
      width,
      autoFocus = false,
      shrink = undefined,
    } = {},
  } = field;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <TextField
          sx={width ? { width } : {}}
          {...(label ? { label } : {})}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          name={name}
          id={name}
          disabled={disabled}
          placeholder={placeholder}
          data-testid={`field-${field.name}`}
          inputProps={{
            maxLength,
            readOnly: readOnly && 'readonly',
            autoFocus,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
          InputLabelProps={{ shrink }}
          variant="outlined"
          error={!!errors[name]}
          helperText={errors[name]?.message || ''}
          className={readOnly ? classes.readOnly : ''}
        />
      )}
    />
  );
};
