import { PlanNames } from 'models/account';
import i18n from 'translations/i18n';

export enum ANNUAL_INCOME {
  NO_INCOME = '0',
  LESS_THAN_50K = '0-50k',
  BETWEEN_50K_AND_100K = '50-100k',
  BETWEEN_100K_AND_200K = '100-200k',
  MORE_THAN_200K = '200k+',
}

export const AnnualIncomeOptionToLabel = {
  [ANNUAL_INCOME.NO_INCOME]: i18n.t('firstExperienceSurvey.ANNUAL_INCOME.NO_INCOME'),
  [ANNUAL_INCOME.LESS_THAN_50K]: i18n.t('firstExperienceSurvey.ANNUAL_INCOME.LESS_THAN_50K'),
  [ANNUAL_INCOME.BETWEEN_50K_AND_100K]: i18n.t('firstExperienceSurvey.ANNUAL_INCOME.BETWEEN_50K_AND_100K'),
  [ANNUAL_INCOME.BETWEEN_100K_AND_200K]: i18n.t('firstExperienceSurvey.ANNUAL_INCOME.BETWEEN_100K_AND_200K'),
  [ANNUAL_INCOME.MORE_THAN_200K]: i18n.t('firstExperienceSurvey.ANNUAL_INCOME.MORE_THAN_200K'),
};

export enum W2EMPLOYEES {
  NO = 'no',
  ONE_TO_TWO = '1-2',
  THREE_TO_FOUR = '3-4',
  FIVE_OR_MORE = '5+',
}

export const W2EmployeesOptionToLabel = {
  [W2EMPLOYEES.NO]: i18n.t('firstExperienceSurvey.W2EMPLOYEES.NO'),
  [W2EMPLOYEES.ONE_TO_TWO]: i18n.t('firstExperienceSurvey.W2EMPLOYEES.ONE_TO_TWO'),
  [W2EMPLOYEES.THREE_TO_FOUR]: i18n.t('firstExperienceSurvey.W2EMPLOYEES.THREE_TO_FOUR'),
  [W2EMPLOYEES.FIVE_OR_MORE]: i18n.t('firstExperienceSurvey.W2EMPLOYEES.FIVE_OR_MORE'),
};

export enum SALES_TAX {
  NO = 'no',
  YES = 'yes',
  UNSURE = 'unsure',
}

export const SalesTaxOptionToLabel = {
  [SALES_TAX.NO]: i18n.t('firstExperienceSurvey.SALES_TAX.NO'),
  [SALES_TAX.YES]: i18n.t('firstExperienceSurvey.SALES_TAX.YES'),
  [SALES_TAX.UNSURE]: i18n.t('firstExperienceSurvey.SALES_TAX.UNSURE'),
};

export enum PHYSICAL_INVENTORY {
  NO = 'no',
  YES = 'yes',
}

export const PhysicalInventoryOptionToLabel = {
  [PHYSICAL_INVENTORY.NO]: i18n.t('firstExperienceSurvey.PHYSICAL_INVENTORY.NO'),
  [PHYSICAL_INVENTORY.YES]: i18n.t('firstExperienceSurvey.PHYSICAL_INVENTORY.YES'),
};

export enum RENTAL_PROPERTIES {
  NO = 'no',
  ONE = '1',
  TWO = '2',
  MORE_THAN_TWO = '3+',
}

export const RentalPropertiesOptionToLabel = {
  [RENTAL_PROPERTIES.NO]: i18n.t('firstExperienceSurvey.RENTAL_PROPERTIES.NO'),
  [RENTAL_PROPERTIES.ONE]: i18n.t('firstExperienceSurvey.RENTAL_PROPERTIES.ONE'),
  [RENTAL_PROPERTIES.TWO]: i18n.t('firstExperienceSurvey.RENTAL_PROPERTIES.TWO'),
  [RENTAL_PROPERTIES.MORE_THAN_TWO]: i18n.t('firstExperienceSurvey.RENTAL_PROPERTIES.MORE_THAN_TWO'),
};

export enum OTHER_BUSINESSES {
  NO = 'no',
  ONE = '1',
  TWO_OR_MORE = '2+',
}

export const OtherBusinessesOptionToLabel = {
  [OTHER_BUSINESSES.NO]: i18n.t('firstExperienceSurvey.OTHER_BUSINESSES.NO'),
  [OTHER_BUSINESSES.ONE]: i18n.t('firstExperienceSurvey.OTHER_BUSINESSES.ONE'),
  [OTHER_BUSINESSES.TWO_OR_MORE]: i18n.t('firstExperienceSurvey.OTHER_BUSINESSES.TWO_OR_MORE'),
};

export enum EXPERT_HELP {
  NO = 'no',
  EMAIL_SUPPORT = 'emailSupport',
  TEXT_OR_CALL_SUPPORT = 'textOrCall',
  STRATEGY_AND_ACCOUNTING_MEETINGS = "strategyAndAccountingMeetings"
}

export const ExpertHelpOptionToLabel = {
  [EXPERT_HELP.NO]: i18n.t('firstExperienceSurvey.EXPERT_HELP.NO'),
  [EXPERT_HELP.EMAIL_SUPPORT]: i18n.t('firstExperienceSurvey.EXPERT_HELP.EMAIL_SUPPORT'),
  [EXPERT_HELP.TEXT_OR_CALL_SUPPORT]: i18n.t('firstExperienceSurvey.EXPERT_HELP.TEXT_OR_CALL_SUPPORT'),
  [EXPERT_HELP.STRATEGY_AND_ACCOUNTING_MEETINGS]: i18n.t('firstExperienceSurvey.EXPERT_HELP.STRATEGY_AND_ACCOUNTING_MEETINGS')
};

export const PlanNamesOptionToLabel = {
  [PlanNames.Digital]: 'Digital',
  [PlanNames.Standard]: 'Standard',
  [PlanNames.Platinum]: 'Platinum',
}
