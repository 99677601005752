import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Grid,
  Typography,
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { ExpandMoreIcon, DescriptionIcon } from 'components/common/Icon';
import { useCurrentAccount } from 'hooks/api/useAccounts';
import { useCurrentCompany } from 'hooks/api/useCompanies';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import { IAccount } from 'services/account';
import { FormationsPrimaryButton } from 'components/common/Buttons';
import { BlobPreviewDialog } from 'components/FilePreview/BlobPreviewDialog';
import { TempDocument } from 'components/documentsV2/types';
import { HelloSignDialog } from 'components/documentSigning/HelloSignDialog';
import { DropboxSignTemplates } from 'services/dropboxSign';
import {
  useSaveHelloSignDocument,
  useSignedDocument,
} from 'hooks/api/useDropboxSign';
import { LoadFilePreviewDialog } from 'components/taxes/LoadFilePreviewDialog';
import { showToastOnError } from 'components/toast/showToast';
import { queryClient } from 'states/reactQueryClient';
import { StageSkeleton } from '../StageSkeleton';
import { AccordionCTA } from '../AccordionCTA';

const ReviewDocument = ({
  title,
  description,
  onClick,
  testId,
}: {
  title: string;
  description: string;
  onClick: (e: React.MouseEvent) => void;
  testId: string;
}) => {
  const handleClick = (e: React.MouseEvent) => {
    onClick(e);
  };
  return (
    <Grid
      container
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="center"
      style={{
        cursor: 'pointer',
        boxSizing: 'border-box',
        fontWeight: 'bold',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#0D22593B',
        borderRadius: '4px',
        padding: 16,
        marginTop: 24,
        marginBottom: 24,
      }}
      onClick={handleClick}
      data-testid={testId}
    >
      <Grid item xs={1}>
        <DescriptionIcon />
      </Grid>
      <Grid item xs={11}>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="body2">{description}</Typography>
      </Grid>
    </Grid>
  );
};

interface ReviewAndSignComponentProps {
  account: IAccount;
  expanded: boolean | string;
  handleExpand: (
    panel: string,
  ) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  onContinueToNextSection: () => void;
  disabled: boolean;
}

const fileURL =
  'https://formations-public-docs.s3.us-west-2.amazonaws.com/SMLLC+Signature+Packet.pdf';

export const ReviewAndSignBIB = ({
  account: accData,
  expanded,
  handleExpand,
  onContinueToNextSection,
  disabled,
}: ReviewAndSignComponentProps) => {
  const { t } = useTranslation();
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] =
    useState<boolean>(false);
  const [file, setFile] = useState<TempDocument>();
  const [showSignDialog, setShowSignDialog] = useState<boolean>(false);
  const [isPreviewSignedDialogOpen, setIsPreviewSignedDialogOpen] =
    useState<boolean>(false);

  const { signedDocuments } = useSignedDocument({
    accountId: accData.id,
    template: DropboxSignTemplates.LLCOnlySignature,
  });

  const loadingSignedDocument: boolean = useMemo(() => {
    if (signedDocuments.length > 0) {
      return !signedDocuments[0].documentId;
    }
    return false;
  }, [signedDocuments]);

  const showSignDialogHandler = () => {
    setShowSignDialog(true);
  };

  const previewPackage = () => {
    setIsPreviewDialogOpen(true);
  };

  const previewSignedPackage = () => {
    if (loadingSignedDocument) {
      return;
    }
    setIsPreviewSignedDialogOpen(true);
  };

  const continueToNext = () => {
    onContinueToNextSection();
  };

  const fetchFile = async () => {
    await fetch(fileURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    }).then((response) => {
      response.blob().then((blob) => {
        const fBlob = new File([blob], 'SMLLC Signature Packet.pdf', {
          type: 'application/pdf',
        });
        setFile({
          file: fBlob,
          displayName: 'SMLLC Signature Packet.pdf',
          extension: 'pdf',
        });
      });
    });
  };

  useEffect(() => {
    fetchFile();
    return () => {
      setFile(undefined);
    };
  }, []);

  const completed: boolean = useMemo(
    () => (signedDocuments?.length > 0),
    [signedDocuments],
  );

  const handleClose = () => {
    setIsPreviewDialogOpen(false);
  };

  const { saveHelloSignDocumentAsync } = useSaveHelloSignDocument({
    onSuccess: () =>
      queryClient.invalidateQueries([
        'dropboxSignedDocuments',
        accData?.id || '',
        DropboxSignTemplates.LLCOnlySignature,
      ]),
  });

  const saveSignature = async (signatureRequestID: string) => {
    setShowSignDialog(false);
    try {
      await saveHelloSignDocumentAsync({
        accountId: accData?.id || '',
        signatureRequestId: signatureRequestID,
      });
    } catch (error) {
      showToastOnError(error);
    }
  };

  return (
    <Accordion
      expanded={expanded === 'review-and-sign'}
      onChange={handleExpand('review-and-sign')}
      disabled={disabled}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="review-and-sign-content"
        id="review-and-sign-header"
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h6B" data-testid="review-and-sign-title">
              {t('progressTracker.reviewAndSign.title')}
            </Typography>
            <Typography
              variant="subtitle1"
              data-testid="review-and-sign-subtitle"
            >
              {t('progressTracker.reviewAndSign.subtitle')}
            </Typography>
            <Typography
              variant="body2"
              color={theme.palette.text.secondary}
              data-testid="review-and-sign-processingTime"
            >
              {t('progressTracker.reviewAndSign.processingTime')}
            </Typography>
            <AccordionCTA
              expanded={expanded}
              handleExpand={handleExpand}
              completed={completed ? 1 : 0}
              totalStep={1}
              testId="start-review-and-sign"
              accordionId="review-and-sign"
            />
          </Grid>
          {!expanded && (
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
              {!completed && !expanded && (
                <Typography variant="h8B">
                  {0}/{1}
                </Typography>
              )}
              {completed && !expanded && (
                <Typography variant="h8B" sx={{ display: 'flex' }}>
                  <CheckCircle color="secondary" />
                  &nbsp; {t('progressTracker.complete')}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ pl: 4, pr: 2 }}
        >
          {completed ? (
            <ReviewDocument
              title="Signed SMLLC Signature Packet"
              description={
                loadingSignedDocument
                  ? 'We are preparing the signed document'
                  : 'View Signed Documents'
              }
              onClick={previewSignedPackage}
              testId="view-signed-documents"
            />
          ) : (
            <ReviewDocument
              title="SMLLC Signature Packet"
              description="This PDF contains three important documents you must review and complete so that Formations can continue the incorporation process for your business."
              onClick={previewPackage}
              testId="review-smlc-signature-packet"
            />
          )}
          {completed ? (
            <FormationsPrimaryButton
              onClick={continueToNext}
              data-testid="continue-to-next"
            >
              {t('progressTracker.reviewAndSign.continue')}
            </FormationsPrimaryButton>
          ) : (
            <FormationsPrimaryButton
              onClick={showSignDialogHandler}
              data-testid="review-and-sign"
            >
              {t('progressTracker.reviewAndSign.cta')}
            </FormationsPrimaryButton>
          )}
          <BlobPreviewDialog
            open={isPreviewDialogOpen}
            file={file}
            onClose={handleClose}
          />
          {isPreviewSignedDialogOpen && signedDocuments[0]?.documentId && (
            <LoadFilePreviewDialog
              documentId={signedDocuments[0].documentId}
              setSelectedDocumentId={() => setIsPreviewSignedDialogOpen(false)}
              accountId={accData.id}
            />
          )}
          {showSignDialog && (
            <HelloSignDialog
              open
              onClose={() => setShowSignDialog(false)}
              handleSuccess={saveSignature}
              accountId={accData?.id || ''}
              templateName={DropboxSignTemplates.LLCOnlySignature}
            />
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

interface ReviewAndSignComponentBIBProps {
  expanded: boolean | string;
  handleExpand: (
    panel: string,
  ) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  onContinueToNextSection: () => void;
  disabled: boolean;
}

export const ReviewAndSignComponentBIB: React.FC<
  ReviewAndSignComponentBIBProps
> = ({ expanded, handleExpand, onContinueToNextSection, disabled }) => {
  const {
    currentCompany: companyData,
    isLoading: companyDataLoading,
    isError: isCompanyDataError,
  } = useCurrentCompany();
  const {
    currentAccount,
    isLoading: fetchingCurrentAccount,
    isError: isAccountError,
  } = useCurrentAccount();

  const { isLoading: loadingSignedDocument } = useSignedDocument({
    accountId: currentAccount?.id,
    template: DropboxSignTemplates.LLCOnlySignature,
  });

  if (fetchingCurrentAccount || companyDataLoading || loadingSignedDocument) {
    return <StageSkeleton />;
  }
  if (companyData != null && currentAccount != null) {
    return (
      <ReviewAndSignBIB
        account={currentAccount}
        handleExpand={handleExpand}
        expanded={expanded}
        onContinueToNextSection={onContinueToNextSection}
        disabled={disabled}
      />
    );
  }
  if (isCompanyDataError && isAccountError) {
    return (
      <Alert severity="error">
        Error fetching data. Please try to refresh or contact for support
      </Alert>
    );
  }
  return null;
};
