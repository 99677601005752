import { SortingDirection } from 'enums';
import React, { useCallback, useEffect, useState } from 'react';
import { ArrowDropDownIcon } from 'components/common/Icon';
import { QueryParamConfigMap, SetQuery } from 'use-query-params';
import { DocumentFilters } from 'hooks/api';
import { FilterButton, highlightCSS, isHighlighted } from './common';
import { FilterPopover, Sort } from './FilterPopover';

type SortOptions = {
  order?: SortingDirection;
};

type SearchProps = {
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  onClear: () => void;
  onClose: () => void;
  onConfirm: (options: SortOptions) => void;
  sortOrder?: SortingDirection | null;
};

export const SortFilter = ({
  open,
  anchorEl,
  onClear,
  onClose,
  onConfirm,
  sortOrder = null,
}: SearchProps) => {
  const [order, setOrder] = useState<SortingDirection | null>(sortOrder);

  const resetToDefault = () => {
    setOrder(sortOrder);
  };

  const handleClear = useCallback(() => {
    setOrder(null);
    onClear();
  }, [onClear]);

  const handleClose = useCallback(() => {
    resetToDefault();
    onClose();
  }, [sortOrder]);

  const handleConfirm = useCallback(
    () =>
      onConfirm({
        order: order ?? undefined,
      }),
    [onConfirm, order],
  );

  useEffect(() => {
    setOrder(sortOrder);
  }, [sortOrder]);

  return (
    <FilterPopover
      open={open}
      anchorEl={anchorEl}
      onClear={handleClear}
      onClose={handleClose}
      onConfirm={handleConfirm}
      saveDisabled={order === sortOrder}
      clearDisabled={!order}
    >
      <Sort sortOrder={order} onSort={setOrder} />
    </FilterPopover>
  );
};

type TProps = {
  query: DocumentFilters;
  setQuery: SetQuery<QueryParamConfigMap>;
};

export const FormationsSortFilter = ({
  query,
  setQuery,
}: TProps) => {
  const { order, orderBy } = query;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleFilter = useCallback((options: SortOptions) => {
    const newSortKey = options.order ? 'keyword' : undefined;
    setQuery({
      order: options.order || orderBy === 'keyword' ? options.order : order,
      orderBy: options.order || orderBy === 'keyword' ? newSortKey : orderBy,
      page: 1,
    });
    setAnchorEl(null);
  }, [order, orderBy]);

  const handleClear = useCallback(() => {
    setQuery({
      order: orderBy === 'keyword' ? undefined : order,
      orderBy: orderBy === 'keyword' ? undefined : orderBy,
    });
    setAnchorEl(null);
  }, [order, orderBy]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <FilterButton
        rounded
        onClick={openPopover}
        data-testid="name-filter-btn"
        sx={isHighlighted('keyword', query) ? highlightCSS : {}}
      >
        <ArrowDropDownIcon />
      </FilterButton>
      <SortFilter
        sortOrder={orderBy === 'keyword' ? order : null}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClear={handleClear}
        onClose={handleClose}
        onConfirm={handleFilter}
      />
    </>
  );
};
