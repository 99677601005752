import { useMutation, useQuery, MutateOptions } from 'react-query';
import {
  DirectPaymentCreateMutateVariables,
  DirectPaymentUpdateMutateVariables,
  DirectPaymentDeleteMutateVariables,
  DirectPaymentGetResult,
} from 'components/taxes/types';
import {
  getDirectPayments,
  createDirectPayment,
  updateDirectPayment,
  deleteDirectPayment,
  getPayrollTaxes,
  setPayrollStateTaxes,
} from 'services/taxes';
import {
  NumberParam,
  QueryParamConfigMap,
  SetQuery,
  useQueryParams,
  withDefault,
  ArrayParam,
  StringParam,
  createEnumParam,
  createEnumArrayParam,
} from 'use-query-params';
import { SortingDirection, SortOrderEnumParam, PaymentType, PaymentSource, PaymentStatus } from 'enums';
import { defaultTablePageSize } from 'constants/common';
import queryClient from 'states/reactQueryClient';

export type TaxesFilters = {
  page?: number;
  size?: number;
  years?: (string | null)[] | null;
  type?: PaymentType | null;
  source?: PaymentSource | null;
  status?: (PaymentStatus | null)[] | null;
  payment_from?: string | null;
  payment_to?: string | null;
  sort?: SortingDirection;
  sort_by?: string | null;
};

export const taxesQueryParams = {
  page: withDefault(NumberParam, 1),
  size: withDefault(NumberParam, defaultTablePageSize),
  years: ArrayParam,
  type: createEnumParam([PaymentType.Federal, PaymentType.State]),
  source: createEnumParam([PaymentSource.Direct, PaymentSource.Payroll]),
  status: createEnumArrayParam([PaymentStatus.Approved, PaymentStatus.Rejected, PaymentStatus.Submitted]),
  payment_from: StringParam,
  payment_to: StringParam,
  sort: withDefault(SortOrderEnumParam, undefined),
  sort_by: StringParam,
};

export const useTaxesQuery = (): Readonly<
  [query: TaxesFilters, setQuery: SetQuery<QueryParamConfigMap>]
> => {
  const [query, setQuery] = useQueryParams(taxesQueryParams);
  return [query, setQuery];
};

export const useGetDirectPayments = (
  accountId: string | undefined,
  params: TaxesFilters,
) => {
  const { data, ...rest } = useQuery<DirectPaymentGetResult>(
    ['direct-payments', accountId, { ...params }],
    () => getDirectPayments(accountId, params),
  );
  return {
    data,
    ...rest,
  };
};

export const useDeleteDirectPayment = (
  queryProps?: MutateOptions<
    unknown,
    unknown,
    DirectPaymentDeleteMutateVariables
  >,
) =>
  useMutation(
    (mutateVariables) => deleteDirectPayment(mutateVariables),
    queryProps,
  );

export const useUpdateDirectPayment = (
  queryProps?: MutateOptions<
    unknown,
    unknown,
    DirectPaymentUpdateMutateVariables
  >,
) =>
  useMutation(
    (mutateVariables) => updateDirectPayment(mutateVariables),
    queryProps,
  );

export const useCreateDirectPayment = (
  queryProps?: MutateOptions<
    unknown,
    unknown,
    DirectPaymentCreateMutateVariables
  >,
) =>
  useMutation(
    (mutateVariables) => createDirectPayment(mutateVariables),
    queryProps,
  );

export const usePayrollTaxName = () => {
  const { data, ...rest } = useQuery(['payroll-taxes'], () =>
    getPayrollTaxes(),
  );
  return {
    taxNames: data ?? [],
    ...rest,
  };
};

export const useSetPayrollStateTaxes = () => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    ['set-payroll-state-taxes'],
    (taxNames: string[]) => setPayrollStateTaxes(taxNames),
    {
      onSuccess: () => queryClient.invalidateQueries(['payroll-taxes']),
    },
  );
  return {
    setPayrollStateTaxes: mutate,
    setPayrollStateTaxesAsync: mutateAsync,
    ...rest,
  };
};
