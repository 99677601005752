import { SortingDirection } from 'enums';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FilterPopover,
  Sort,
} from 'components/common/FormationsFilters/FilterPopover';
import { DateRange, DateRangePicker } from '@mui/lab';
import { TextField, TextFieldProps, Typography } from '@mui/material';
import { ArrowDropDownIcon } from 'components/common/Icon';
import { QueryParamConfigMap, SetQuery } from 'use-query-params';
import moment, { Moment } from 'moment';
import { DocumentFilters } from 'hooks/api';
import _ from 'lodash';
import { FilterButton, highlightCSS, isHighlighted } from './common';

export interface DateFilterOptions {
  order?: SortingDirection;
  dates: DateRange<Moment>;
}

interface DateFilterProps {
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  sortOrder?: SortingDirection | null;
  onClear: () => void;
  onClose: () => void;
  onConfirm: (options: DateFilterOptions) => void;
  defaultDate?: DateRange<Moment>;
}

export const DateFilter = ({
  open,
  anchorEl,
  onClear,
  onClose,
  onConfirm,
  sortOrder = null,
  defaultDate = [null, null],
}: DateFilterProps) => {
  const [order, setOrder] = useState<SortingDirection | null>(sortOrder);
  const [error, setError] = useState(false);
  const [value, setValue] = useState<DateRange<Moment>>(defaultDate);

  const resetToDefault = () => {
    setOrder(sortOrder);
    setValue(defaultDate);
  };

  const handleClear = useCallback(() => {
    setOrder(null);
    setValue([null, null]);
    onClear();
  }, [onClear]);

  const handleClose = useCallback(() => {
    resetToDefault();
    onClose();
  }, [sortOrder, defaultDate]);

  const handleConfirm = useCallback(() => {
    onConfirm({
      order: order ?? undefined,
      dates: value ?? undefined,
    });
  }, [value, order, onConfirm]);

  const handleDateChange = useCallback((date: DateRange<Moment>) => {
    setValue(date);
  }, []);

  const handleError = useCallback((reason: unknown[]) => {
    setError(reason?.some((v) => v === 'invalidDate' || v === 'invalidRange'));
  }, []);

  const renderDateInput = useCallback(
    (startProps: TextFieldProps, endProps: TextFieldProps) => (
      <>
        <TextField
          {...startProps}
          sx={{ maxWidth: '160px' }}
          placeholder="MM/DD/YYYY"
          data-testid="date-start"
        />
        <TextField
          {...endProps}
          sx={{ maxWidth: '160px', ml: 1 }}
          placeholder="MM/DD/YYYY"
          data-testid="date-end"
        />
      </>
    ),
    [],
  );

  useEffect(() => {
    setOrder(sortOrder);
  }, [sortOrder]);

  return (
    <FilterPopover
      open={open}
      anchorEl={anchorEl}
      onClear={handleClear}
      onClose={handleClose}
      onConfirm={handleConfirm}
      saveDisabled={
        (order === sortOrder &&
          _.isEqual(value.toString(), defaultDate.toString())) ||
        error
      }
      clearDisabled={!order && !value[0] && !value[1]}
    >
      <Sort sortOrder={order} onSort={setOrder} />
      <Typography sx={{ m: 3 }} variant="body2B" component="h6">
        Date within
      </Typography>
      <DateRangePicker
        calendars={1}
        value={value}
        onChange={handleDateChange}
        onError={handleError}
        renderInput={renderDateInput}
      />
    </FilterPopover>
  );
};

type TProps = {
  queryKey?: string;
  query: DocumentFilters;
  setQuery: SetQuery<QueryParamConfigMap>;
};

export const FormationsDateFilter = ({ query, setQuery, queryKey = 'date' }: TProps) => {
  const { from, to, order, orderBy } = query;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const isActive = useMemo(() => orderBy === queryKey, [orderBy, queryKey]);

  const handleFilter = useCallback((options: DateFilterOptions) => {
    const newSortKey = options.order ? queryKey : undefined;
    setQuery({
      order: options.order || orderBy === queryKey ? options.order : order,
      orderBy: options.order || orderBy === queryKey ? newSortKey : orderBy,
      from: options.dates[0]
        ? options.dates[0]?.format('YYYY-MM-DD')
        : undefined,
      to: options.dates[1] ? options.dates[1]?.format('YYYY-MM-DD') : undefined,
      page: 1,
    });
    setAnchorEl(null);
  }, [queryKey, order, orderBy]);

  const handleClear = useCallback(() => {
    setQuery({
      order: isActive ? undefined : order,
      orderBy: isActive ? undefined : orderBy,
      from: undefined,
      to: undefined,
    });
    setAnchorEl(null);
  }, [isActive, order, orderBy]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <FilterButton
        rounded
        onClick={openPopover}
        data-testid="date-filter-btn"
        sx={isHighlighted('date', query) ? highlightCSS : {}}
      >
        <ArrowDropDownIcon />
      </FilterButton>
      <DateFilter
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        sortOrder={isActive ? order : null}
        onClear={handleClear}
        onClose={handleClose}
        defaultDate={[from ? moment(from) : null, to ? moment(to) : null]}
        onConfirm={handleFilter}
      />
    </>
  );
};
