import makeStyles from '@mui/styles/makeStyles';
import {
  FormationsGhostButton,
  FormationsPrimaryButton,
} from 'components/common/Buttons';
import { ErrorOutline } from '@mui/icons-material';
import {
  Dialog,
  Button,
  Typography,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  DialogTitle as MuiDialogTitle,
} from '@mui/material';
import { styled } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  icon: {
    width: '68px',
    height: '68px',
    color: '#FF9800',
  },
  dialog: {
    [theme.breakpoints.down('md')]: {
      minWidth: 250,
      maxWidth: 250,
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 430,
      maxWidth: 430,
    },
    padding: theme.spacing(2, 4, 4, 4),
  },
  secondaryButton: {
    marginLeft: `0 !important`,
  },
}));

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  padding: theme.spacing(0, 0, 0, 0),
}));

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(0, 0, 4, 0),
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0),
}));

interface Props {
  showIcon?: boolean;
  open: boolean;
  title?: string;
  question: string;
  yesText?: string;
  noText?: string;
  onSave: () => void;
  onClose: () => void;
  isYesAsLink?: boolean;
  yesHref?: string;
}

export function ConfirmModal({
  showIcon = true,
  open,
  title = '',
  question,
  yesText = 'Yes',
  noText = 'No',
  onSave,
  onClose,
  isYesAsLink = false,
  yesHref = '',
}: Props) {
  const classes = useStyles();
  const LinkButton = FormationsPrimaryButton as typeof Button;

  const handleSave = () => {
    onSave();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        onClose={handleClose}
        open={open}
      >
        {showIcon && (
          <div className={classes.iconContainer}>
            <ErrorOutline className={classes.icon} />
          </div>
        )}
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <Typography variant="body1" component="div">
            {question}
          </Typography>
        </DialogContent>

        <DialogActions>
          {isYesAsLink ? (
            <LinkButton
              size="large"
              fullWidth
              href={yesHref}
              onClick={handleClose}
              disabled={!yesHref}
              target="_blank"
            >
              {yesText}
            </LinkButton>
          ) : (
            <FormationsPrimaryButton
              size="large"
              fullWidth
              onClick={handleSave}
            >
              {yesText}
            </FormationsPrimaryButton>
          )}
          <br />
          <FormationsGhostButton
            className={classes.secondaryButton}
            sx={{ mt: 1 }}
            size="large"
            fullWidth
            onClick={handleClose}
          >
            {noText}
          </FormationsGhostButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
