import i18n from 'translations/i18n';

export enum BUSINESS_OWNERSHIP {
  INDIVIDUAL = 'INDIVIDUAL',
  PARTNERSHIP = 'PARTNERSHIP',
  DONT_KNOW = 'DONT_KNOW',
}

export enum FILING_STATUS_OPTION {
  SINGLE = 'SINGLE',
  MARRIED_FILING_JOINTLY = 'MARRIED_FILING_JOINTLY',
  MARRIED_FILING_SEPARATELY = 'MARRIED_FILING_SEPARATELY',
  HEAD_OF_HOUSEHOLD = 'HEAD_OF_HOUSEHOLD',
  WIDOW_WITH_DEPENDENT_CHILD = 'WIDOW_WITH_DEPENDENT_CHILD',
}

export enum HEALTH_INSURANCE_OPTION {
  INDEPENDENT_PURCHASE = 'INDEPENDENT_PURCHASE',
  SPOUSE_EMPLOYER = 'SPOUSE_EMPLOYER',
  NOT_COVERED = 'NOT_COVERED',
}

export enum UPDATED_HEALTH_INSURANCE_OPTION {
  INDEPENDENT_PURCHASE = 'INDEPENDENT_PURCHASE',
  SPOUSE_EMPLOYER = 'SPOUSE_EMPLOYER',
  DIFFERENT_EMPLOYER = 'DIFFERENT_EMPLOYER',
  STATE_MARKETPLACE = 'STATE_MARKETPLACE',
  NOT_COVERED = 'NOT_COVERED',
}

export enum RETIREMENT_PROGRAM_OPTION {
  RETIREMENT_PLAN_EXISTS = 'RETIREMENT_PLAN_EXISTS',
  RETIREMENT_PLAN_DESIRED = 'RETIREMENT_PLAN_DESIRED',
  RETIREMENT_PLAN_DECLINED = 'RETIREMENT_PLAN_DECLINED',
}

export enum VENDOR_1099_OPTION {
  YES = 'YES',
  NO = 'NO',
  DONT_KNOW = 'DONT_KNOW',
}

export enum FINANCIAL_LITERACY_OPTION {
  NOVICE = 'NOVICE',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED',
  EXPERT = 'EXPERT',
}

export enum BUSINESS_GOALS {
  TAX_SAVING = 'TAX_SAVING',
  MEETING_CASHFLOW = 'MEETING_CASHFLOW',
  ENSURE_TAX_FILING_ON_TIME = 'ENSURE_TAX_FILING_ON_TIME',
  MONITOR_BUSINESS_PROFITS = 'MONITOR_BUSINESS_PROFITS',
  MAXIMIZE_BUSINESS_PROFITS = 'MAXIMIZE_BUSINESS_PROFITS',
}

export enum PERSONAL_FINANCIAL_GOALS {
  USE_TIME_EFFICIENTLY = 'USE_TIME_EFFICIENTLY',
  SAVE_TO_BUY_PROPERTY = 'SAVE_TO_BUY_PROPERTY',
  SAVE_FOR_RETIREMENT_PLAN = 'SAVE_FOR_RETIREMENT_PLAN',
  OPTIMIZE_SAVING_FOR_EMERGENCY_FUND = 'OPTIMIZE_SAVING_FOR_EMERGENCY_FUND',
}

export enum WORKING_WITH_FORMATIONS_GOALS {
  PROFESSIONAL_TAKE_CARE_OF_TAXES_AND_FINANCES = 'PROFESSIONAL_TAKE_CARE_OF_TAXES_AND_FINANCES',
  ALL_SUPPORT_FOR_TAXES_AVAILABLE_ONLINE = 'ALL_SUPPORT_FOR_TAXES_AVAILABLE_ONLINE',
  LEARN_MORE_ABOUT_ACCOUNTING_AND_BUSINESS = 'LEARN_MORE_ABOUT_ACCOUNTING_AND_BUSINESS',
  BETTER_COMMUNICATION = 'BETTER_COMMUNICATION',
  PLAN_TOWARD_RETIREMENT = 'PLAN_TOWARD_RETIREMENT',
}

export enum RETIREMENT_PLAN_LIST {
  SOLO_INDIVIDUAL_401K = 'SOLO_INDIVIDUAL_401K',
  SEP = 'SEP',
  SIMPLE = 'SIMPLE',
  SMALL_EMPLOYER_401K = 'SMALL_EMPLOYER_401K',
  DEFINED_BENEFIT_CASH_BALANCE = 'DEFINED_BENEFIT_CASH_BALANCE',
  OTHER = 'OTHER',
}

export enum ANNUAL_INCOME_LIST {
  OTHER_JOB_WAGES = 'OTHER_JOB_WAGES',
  SPOUSAL = 'SPOUSAL',
  OTHER_BUSINESS = 'OTHER_BUSINESS',
  RENT = 'RENT',
  RETIREMENT_OR_SOCIAL_SECURITY = 'RETIREMENT_OR_SOCIAL_SECURITY',
  STOCKS = 'STOCKS',
  SUPPLEMENTAL = 'SUPPLEMENTAL',
  NONE = 'NONE',
}

export enum COMPLETION_STATUS {
  NO_INFORMATION = 'NO_INFORMATION',
  COMPLETED = 'COMPLETED',
}

export enum TARGET_RETIREMENT_CONTRIBUTION {
  UNDER_5000 = 'UNDER_5000',
  BETWEEN_5000_10000 = 'BETWEEN_5000_10000',
  BETWEEN_10000_20000 = 'BETWEEN_10000_20000',
  BETWEEN_20000_40000 = 'BETWEEN_20000_40000',
  ABOVE_40000 = 'ABOVE_40000',
  OTHER = 'OTHER',
}

export enum YES_OR_NO_OPTION {
  YES = 'YES',
  NO = 'NO',
}

export const BusinessOwnershipOptionToLabel = {
  [BUSINESS_OWNERSHIP.INDIVIDUAL]: i18n.t(
    'firstExperienceSurvey.BUSINESS_OWNERSHIP.INDIVIDUAL',
  ),
  [BUSINESS_OWNERSHIP.PARTNERSHIP]: i18n.t(
    'firstExperienceSurvey.BUSINESS_OWNERSHIP.PARTNERSHIP',
  ),
  [BUSINESS_OWNERSHIP.DONT_KNOW]: i18n.t(
    'firstExperienceSurvey.BUSINESS_OWNERSHIP.DONT_KNOW',
  ),
};

// Filing Status is used at multiple places, DONT_KNOW option needs to be removed
export const FilingStatusOptionToLabel = {
  [FILING_STATUS_OPTION.SINGLE]: i18n.t(
    'firstExperienceSurvey.FILING_STATUS_OPTION.SINGLE',
  ),
  [FILING_STATUS_OPTION.MARRIED_FILING_JOINTLY]: i18n.t(
    'firstExperienceSurvey.FILING_STATUS_OPTION.MARRIED_FILING_JOINTLY',
  ),
  [FILING_STATUS_OPTION.MARRIED_FILING_SEPARATELY]: i18n.t(
    'firstExperienceSurvey.FILING_STATUS_OPTION.MARRIED_FILING_SEPARATELY',
  ),
  [FILING_STATUS_OPTION.HEAD_OF_HOUSEHOLD]: i18n.t(
    'firstExperienceSurvey.FILING_STATUS_OPTION.HEAD_OF_HOUSEHOLD',
  ),
  [FILING_STATUS_OPTION.WIDOW_WITH_DEPENDENT_CHILD]: i18n.t(
    'firstExperienceSurvey.FILING_STATUS_OPTION.WIDOW_WITH_DEPENDENT_CHILD',
  ),
};

export const UpdatedFilingStatusOptionToLabel = {
  [FILING_STATUS_OPTION.SINGLE]: i18n.t(
    'firstExperienceSurvey.FILING_STATUS_OPTION.SINGLE',
  ),
  [FILING_STATUS_OPTION.MARRIED_FILING_JOINTLY]: i18n.t(
    'firstExperienceSurvey.FILING_STATUS_OPTION.MARRIED_FILING_JOINTLY',
  ),
  [FILING_STATUS_OPTION.MARRIED_FILING_SEPARATELY]: i18n.t(
    'firstExperienceSurvey.FILING_STATUS_OPTION.MARRIED_FILING_SEPARATELY',
  ),
  [FILING_STATUS_OPTION.HEAD_OF_HOUSEHOLD]: i18n.t(
    'firstExperienceSurvey.FILING_STATUS_OPTION.HEAD_OF_HOUSEHOLD',
  ),
  [FILING_STATUS_OPTION.WIDOW_WITH_DEPENDENT_CHILD]: i18n.t(
    'firstExperienceSurvey.FILING_STATUS_OPTION.WIDOW_WITH_DEPENDENT_CHILD',
  ),
};

export const HealthInsuranceOptionToLabel = {
  [HEALTH_INSURANCE_OPTION.INDEPENDENT_PURCHASE]: i18n.t(
    'firstExperienceSurvey.HEALTH_INSURANCE_OPTION.INDEPENDENT_PURCHASE',
  ),
  [HEALTH_INSURANCE_OPTION.SPOUSE_EMPLOYER]: i18n.t(
    'firstExperienceSurvey.HEALTH_INSURANCE_OPTION.SPOUSE_EMPLOYER',
  ),
  [HEALTH_INSURANCE_OPTION.NOT_COVERED]: i18n.t(
    'firstExperienceSurvey.HEALTH_INSURANCE_OPTION.NOT_COVERED',
  ),
};

export const UpdatedHealthInsuranceOptionToLabel = {
  [UPDATED_HEALTH_INSURANCE_OPTION.INDEPENDENT_PURCHASE]: i18n.t(
    'firstExperienceSurvey.HEALTH_INSURANCE_OPTION.INDEPENDENT_PURCHASE',
  ),
  [UPDATED_HEALTH_INSURANCE_OPTION.SPOUSE_EMPLOYER]: i18n.t(
    'firstExperienceSurvey.HEALTH_INSURANCE_OPTION.SPOUSE_EMPLOYER',
  ),
  [UPDATED_HEALTH_INSURANCE_OPTION.DIFFERENT_EMPLOYER]: i18n.t(
    'firstExperienceSurvey.HEALTH_INSURANCE_OPTION.DIFFERENT_EMPLOYER',
  ),
  [UPDATED_HEALTH_INSURANCE_OPTION.STATE_MARKETPLACE]: i18n.t(
    'firstExperienceSurvey.HEALTH_INSURANCE_OPTION.STATE_MARKETPLACE',
  ),
  [UPDATED_HEALTH_INSURANCE_OPTION.NOT_COVERED]: i18n.t(
    'firstExperienceSurvey.HEALTH_INSURANCE_OPTION.NOT_COVERED',
  ),
};

export const RetirementProgramOptionToLabel = {
  [RETIREMENT_PROGRAM_OPTION.RETIREMENT_PLAN_EXISTS]: i18n.t(
    'firstExperienceSurvey.RETIREMENT_PROGRAM_OPTION.RETIREMENT_PLAN_EXISTS',
  ),
  [RETIREMENT_PROGRAM_OPTION.RETIREMENT_PLAN_DESIRED]: i18n.t(
    'firstExperienceSurvey.RETIREMENT_PROGRAM_OPTION.RETIREMENT_PLAN_DESIRED',
  ),
  [RETIREMENT_PROGRAM_OPTION.RETIREMENT_PLAN_DECLINED]: i18n.t(
    'firstExperienceSurvey.RETIREMENT_PROGRAM_OPTION.RETIREMENT_PLAN_DECLINED',
  ),
};

export const UpdatedRetirementProgramOptionToLabel = {
  [RETIREMENT_PROGRAM_OPTION.RETIREMENT_PLAN_EXISTS]: i18n.t(
    'firstExperienceSurvey.RETIREMENT_PROGRAM_OPTION.RETIREMENT_PLAN_EXISTS',
  ),
  [RETIREMENT_PROGRAM_OPTION.RETIREMENT_PLAN_DESIRED]: i18n.t(
    'firstExperienceSurvey.RETIREMENT_PROGRAM_OPTION.RETIREMENT_PLAN_DESIRED',
  ),
  [RETIREMENT_PROGRAM_OPTION.RETIREMENT_PLAN_DECLINED]: i18n.t(
    'firstExperienceSurvey.RETIREMENT_PROGRAM_OPTION.RETIREMENT_PLAN_DECLINED',
  ),
};

export const Vendor1099OptionToLabel = {
  [VENDOR_1099_OPTION.YES]: i18n.t(
    'firstExperienceSurvey.VENDOR_1099_OPTION.YES',
  ),
  [VENDOR_1099_OPTION.NO]: i18n.t(
    'firstExperienceSurvey.VENDOR_1099_OPTION.NO',
  ),
  [VENDOR_1099_OPTION.DONT_KNOW]: i18n.t(
    'firstExperienceSurvey.VENDOR_1099_OPTION.DONT_KNOW',
  ),
};

export const FinancialLiteracyOptionsToLabel = {
  [FINANCIAL_LITERACY_OPTION.NOVICE]: i18n.t(
    'firstExperienceSurvey.FINANCIAL_LITERACY_OPTION.NOVICE',
  ),
  [FINANCIAL_LITERACY_OPTION.INTERMEDIATE]: i18n.t(
    'firstExperienceSurvey.FINANCIAL_LITERACY_OPTION.INTERMEDIATE',
  ),
  [FINANCIAL_LITERACY_OPTION.ADVANCED]: i18n.t(
    'firstExperienceSurvey.FINANCIAL_LITERACY_OPTION.ADVANCED',
  ),
  [FINANCIAL_LITERACY_OPTION.EXPERT]: i18n.t(
    'firstExperienceSurvey.FINANCIAL_LITERACY_OPTION.EXPERT',
  ),
};

export const BusinessGoalsOptionsToLabel = {
  [BUSINESS_GOALS.TAX_SAVING]: i18n.t(
    'firstExperienceSurvey.BUSINESS_GOALS.TAX_SAVING',
  ),
  [BUSINESS_GOALS.MEETING_CASHFLOW]: i18n.t(
    'firstExperienceSurvey.BUSINESS_GOALS.MEETING_CASHFLOW',
  ),
  [BUSINESS_GOALS.ENSURE_TAX_FILING_ON_TIME]: i18n.t(
    'firstExperienceSurvey.BUSINESS_GOALS.ENSURE_TAX_FILING_ON_TIME',
  ),
  [BUSINESS_GOALS.MONITOR_BUSINESS_PROFITS]: i18n.t(
    'firstExperienceSurvey.BUSINESS_GOALS.MONITOR_BUSINESS_PROFITS',
  ),
  [BUSINESS_GOALS.MAXIMIZE_BUSINESS_PROFITS]: i18n.t(
    'firstExperienceSurvey.BUSINESS_GOALS.MAXIMIZE_BUSINESS_PROFITS',
  ),
};

export const PersonalFinancialGoalsOptionsToLabel = {
  [PERSONAL_FINANCIAL_GOALS.USE_TIME_EFFICIENTLY]: i18n.t(
    'firstExperienceSurvey.PERSONAL_FINANCIAL_GOALS.USE_TIME_EFFICIENTLY',
  ),
  [PERSONAL_FINANCIAL_GOALS.SAVE_TO_BUY_PROPERTY]: i18n.t(
    'firstExperienceSurvey.PERSONAL_FINANCIAL_GOALS.SAVE_TO_BUY_PROPERTY',
  ),
  [PERSONAL_FINANCIAL_GOALS.SAVE_FOR_RETIREMENT_PLAN]: i18n.t(
    'firstExperienceSurvey.PERSONAL_FINANCIAL_GOALS.SAVE_FOR_RETIREMENT_PLAN',
  ),
  [PERSONAL_FINANCIAL_GOALS.OPTIMIZE_SAVING_FOR_EMERGENCY_FUND]: i18n.t(
    'firstExperienceSurvey.PERSONAL_FINANCIAL_GOALS.OPTIMIZE_SAVING_FOR_EMERGENCY_FUND',
  ),
};

export const WorkingWithFormationsGoalsOptionsToLabel = {
  [WORKING_WITH_FORMATIONS_GOALS.PROFESSIONAL_TAKE_CARE_OF_TAXES_AND_FINANCES]:
    i18n.t(
      'firstExperienceSurvey.WORKING_WITH_FORMATIONS_GOALS.PROFESSIONAL_TAKE_CARE_OF_TAXES_AND_FINANCES',
    ),
  [WORKING_WITH_FORMATIONS_GOALS.ALL_SUPPORT_FOR_TAXES_AVAILABLE_ONLINE]:
    i18n.t(
      'firstExperienceSurvey.WORKING_WITH_FORMATIONS_GOALS.ALL_SUPPORT_FOR_TAXES_AVAILABLE_ONLINE',
    ),
  [WORKING_WITH_FORMATIONS_GOALS.LEARN_MORE_ABOUT_ACCOUNTING_AND_BUSINESS]:
    i18n.t(
      'firstExperienceSurvey.WORKING_WITH_FORMATIONS_GOALS.LEARN_MORE_ABOUT_ACCOUNTING_AND_BUSINESS',
    ),
  [WORKING_WITH_FORMATIONS_GOALS.BETTER_COMMUNICATION]: i18n.t(
    'firstExperienceSurvey.WORKING_WITH_FORMATIONS_GOALS.BETTER_COMMUNICATION',
  ),
  [WORKING_WITH_FORMATIONS_GOALS.PLAN_TOWARD_RETIREMENT]: i18n.t(
    'firstExperienceSurvey.WORKING_WITH_FORMATIONS_GOALS.PLAN_TOWARD_RETIREMENT',
  ),
};

export const RetirementPlanListToLabel = {
  [RETIREMENT_PLAN_LIST.SOLO_INDIVIDUAL_401K]: i18n.t(
    'firstExperienceSurvey.RETIREMENT_PLAN_LIST.SOLO_INDIVIDUAL_401K',
  ),
  [RETIREMENT_PLAN_LIST.SEP]: i18n.t(
    'firstExperienceSurvey.RETIREMENT_PLAN_LIST.SEP',
  ),
  [RETIREMENT_PLAN_LIST.SIMPLE]: i18n.t(
    'firstExperienceSurvey.RETIREMENT_PLAN_LIST.SIMPLE',
  ),
  [RETIREMENT_PLAN_LIST.SMALL_EMPLOYER_401K]: i18n.t(
    'firstExperienceSurvey.RETIREMENT_PLAN_LIST.SMALL_EMPLOYER_401K',
  ),
  [RETIREMENT_PLAN_LIST.DEFINED_BENEFIT_CASH_BALANCE]: i18n.t(
    'firstExperienceSurvey.RETIREMENT_PLAN_LIST.DEFINED_BENEFIT_CASH_BALANCE',
  ),
  [RETIREMENT_PLAN_LIST.OTHER]: i18n.t(
    'firstExperienceSurvey.RETIREMENT_PLAN_LIST.OTHER',
  ),
};

export const AnnualIncomeListToLabel = {
  [ANNUAL_INCOME_LIST.OTHER_JOB_WAGES]: 'I will have wages from another job.',
  [ANNUAL_INCOME_LIST.SPOUSAL]: 'My spouse has wages from outside the business.',
  [ANNUAL_INCOME_LIST.OTHER_BUSINESS]: 'I will have income from other business activities not included in Formations.',
  [ANNUAL_INCOME_LIST.STOCKS]: 'I will have capital gains from selling stocks/properties/cryptocurrency/etc.',
  [ANNUAL_INCOME_LIST.RENT]: 'I own rental properties.',
  [ANNUAL_INCOME_LIST.RETIREMENT_OR_SOCIAL_SECURITY]: 'I expect to have retirement or Social Security distributions.',
  [ANNUAL_INCOME_LIST.SUPPLEMENTAL]: 'I will have over $5,000 worth of income from an option not listed here.',
  [ANNUAL_INCOME_LIST.NONE]: 'None of above',
};

export const CompletionStatusToLabel = {
  [COMPLETION_STATUS.NO_INFORMATION]: i18n.t(
    'firstExperienceSurvey.COMPLETION_STATUS.NO_INFORMATION',
  ),
  [COMPLETION_STATUS.COMPLETED]: i18n.t(
    'firstExperienceSurvey.COMPLETION_STATUS.COMPLETED',
  ),
};

// years are hardcoded here as it's just a quick survey and next year we can change this.
export const CompletionStatusYear = ['2021', '2022'];

export const TargetRetirementContributionToLabel = {
  [TARGET_RETIREMENT_CONTRIBUTION.UNDER_5000]: i18n.t(
    'firstExperienceSurvey.TARGET_RETIREMENT_CONTRIBUTION.UNDER_5000',
  ),
  [TARGET_RETIREMENT_CONTRIBUTION.BETWEEN_5000_10000]: i18n.t(
    'firstExperienceSurvey.TARGET_RETIREMENT_CONTRIBUTION.BETWEEN_5000_10000',
  ),
  [TARGET_RETIREMENT_CONTRIBUTION.BETWEEN_10000_20000]: i18n.t(
    'firstExperienceSurvey.TARGET_RETIREMENT_CONTRIBUTION.BETWEEN_10000_20000',
  ),
  [TARGET_RETIREMENT_CONTRIBUTION.BETWEEN_20000_40000]: i18n.t(
    'firstExperienceSurvey.TARGET_RETIREMENT_CONTRIBUTION.BETWEEN_20000_40000',
  ),
  [TARGET_RETIREMENT_CONTRIBUTION.ABOVE_40000]: i18n.t(
    'firstExperienceSurvey.TARGET_RETIREMENT_CONTRIBUTION.ABOVE_40000',
  ),
  [TARGET_RETIREMENT_CONTRIBUTION.OTHER]: i18n.t(
    'firstExperienceSurvey.TARGET_RETIREMENT_CONTRIBUTION.OTHER',
  ),
};

export const retirementContributionIsOther = (val: string) => {
  if (
    val === TARGET_RETIREMENT_CONTRIBUTION.UNDER_5000 ||
    val === TARGET_RETIREMENT_CONTRIBUTION.BETWEEN_5000_10000 ||
    val === TARGET_RETIREMENT_CONTRIBUTION.BETWEEN_10000_20000 ||
    val === TARGET_RETIREMENT_CONTRIBUTION.BETWEEN_20000_40000 ||
    val === TARGET_RETIREMENT_CONTRIBUTION.ABOVE_40000
  ) {
    return false;
  }
  return true;
};

export const YesOrNoOptionToLabel = {
  [YES_OR_NO_OPTION.YES]: i18n.t('midYearSurvey.YES_OR_NO_OPTION.YES'),
  [YES_OR_NO_OPTION.NO]: i18n.t('midYearSurvey.YES_OR_NO_OPTION.NO'),
};

export enum surveyFieldType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  CURRENCY = 'CURRENCY',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  GROUP = 'GROUP',
  SECTION = 'SECTION',
  DATE = 'DATE',
  COMBINATIONCHECKBOX = 'COMBINATIONCHECKBOX',
  DROPDOWN = 'DROPDOWN',
  FILES = 'FILES',
  CUSTOM = 'CUSTOM',
}
