import React, { useEffect, useState } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { UseFormMethods } from 'react-hook-form';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { IFormField } from './types';
import './RichTextEditor.css'

interface TRichTextAreaOptions {
  placeholder?: string;
  characterLimit?: number;
  maxRow?: number;
  minRow?: number;
  InputProps?: any;
}

type TFormationsTextAreaProps = {
  field: IFormField<TRichTextAreaOptions>;
  formInstance: UseFormMethods;
};

export const RichTextEditor = ({
  field,
  formInstance,
}: TFormationsTextAreaProps) => {

  useEffect(() => {
    formInstance.register({ name: field.name });
  }, [formInstance, field.name]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isFocused, setIsFocused] = useState(false);

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    // Convert the new editor state to HTML
    let newValue = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    // Add the underline style to the links (so the underline is visible when displayed as a submitted note)
    newValue = newValue.replace(/<a href="/g, '<a style="text-decoration: underline;" href="');
    // Update the field's value in the form
    formInstance.setValue(field.name, newValue);
  };

  const clearEditor = () => {
    setEditorState(EditorState.createEmpty());
  };

  return (
    <>
      <label htmlFor={field.name} style={{ fontSize: '13px' }}>
        {field.label}</label>
      <div 
        className={`wrapper-class ${isFocused ? 'focused' : ''}`}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        tabIndex={0}
        role="textbox"
      >
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: ['link', 'inline'],
            inline: {
              options: ['bold'],
            },
            link: {
              showOpenOptionOnHover: false,
            },
          }}
          placeholder={field.options?.placeholder}
          editorStyle={{ height: '250px' }}
        />
          {field.options?.InputProps?.endAdornment && React.cloneElement(field.options.InputProps.endAdornment, { onClick: clearEditor })}
      </div>
    </>
  );
}