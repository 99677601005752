import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { theme } from 'theme';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';

const SpartanSemiBold = require('fonts/LeagueSpartan-SemiBold.ttf');
const SpartanRegular = require('fonts/LeagueSpartan-Regular.ttf');

// Register font
Font.register({
  family: 'Spartan',
  src: SpartanRegular,
  fontWeight: 400,
});
Font.register({
  family: 'Spartan',
  src: SpartanSemiBold,
  fontWeight: 700,
});

// Create styles
const styles = StyleSheet.create({
  document: {},
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: 'Spartan',
    marginBottom: 10,
    color: '#000000',
  },
  subtitles: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Spartan',
    marginBottom: 10,
    color: '#000000',
  },
});

// Create Document Component
export const MyBusinessPackageDocument = ({
  title,
  description,
  bankInfo,
}: {
  title: string;
  description: string;
  bankInfo: string;
}) => (
  <Document style={styles.document}>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.section}>
        <Text wrap={false} style={styles.title}>
          {title}
        </Text>
        <Text style={styles.subtitles} wrap={false}>
          {description}
        </Text>
        <Text style={styles.subtitles} wrap={false}>
          {bankInfo}
        </Text>
      </View>
    </Page>
  </Document>
);

const defaultWidth = 600;
const defaultHeight = defaultWidth;

export const BusinessPackageView = ({
  header,
  content,
  bankInfo,
  setHeader,
  setContent,
  setBankInfo,
  width = defaultWidth,
  height = defaultHeight,
}: {
  header: string;
  content: string;
  bankInfo: string;
  setHeader: (header: string) => void;
  setContent: (content: string) => void;
  setBankInfo: (bankInfo: string) => void;
  width?: number;
  height?: number;
}) => {
  const { t } = useTranslation();
  return (
    <Grid container display="flex" flexDirection="row" alignItems="flex-start">
      <Grid
        item
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        xs={4}
      >
        <Typography variant="body2B" sx={{ mb: 2 }}>
          {t('progressTracker.businessPackage.headerTitle')}
        </Typography>
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          sx={{ mb: 2, mt: 2 }}
        >
          {t('progressTracker.businessPackage.header')}
        </Typography>
        <TextField
          id="outlined-multiline-flexible"
          label="Header"
          multiline
          value={header}
          onChange={(e) => setHeader(e.target.value)}
          minRows={10}
          maxRows={10}
        />
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          sx={{ mb: 2, mt: 2 }}
        >
          {t('progressTracker.businessPackage.content')}
        </Typography>
        <TextField
          id="outlined-multiline-flexible"
          label="Content"
          multiline
          value={content}
          onChange={(e) => setContent(e.target.value)}
          minRows={10}
          maxRows={10}
        />
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          sx={{ mb: 2, mt: 2 }}
        >
          {t('progressTracker.businessPackage.banking_info')}
        </Typography>
        <TextField
          id="outlined-multiline-flexible"
          label="Banking info"
          multiline
          value={bankInfo}
          onChange={(e) => setBankInfo(e.target.value)}
          minRows={10}
          maxRows={10}
        />
      </Grid>
      <Grid
        item
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: 'red',
          height: '100%',
          minHeight: '100%',
          flexGrow: 1,
        }}
      >
        <Typography variant="body2B" sx={{ mb: 2 }}>
          {t('progressTracker.businessPackage.sample')}
        </Typography>
        <PDFViewer width={width} height={height} showToolbar={false}>
          <MyBusinessPackageDocument
            title={header}
            description={content}
            bankInfo={bankInfo}
          />
        </PDFViewer>
      </Grid>
    </Grid>
  );
};
