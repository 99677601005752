import { ScriptStatus, useHurdlrScript } from 'hooks/importScript';
import { PropsWithChildren, useEffect } from 'react';
import { CONFIG } from 'config';
import { useHurdlrAccessToken } from 'components/hurdlr/hooks';

export const HurdlrProvider = ({ children }: PropsWithChildren<any>) => {
  const status = useHurdlrScript();
  const accessToken = useHurdlrAccessToken((state) => state.accessToken);

  useEffect(() => {
    if (status === ScriptStatus.Ready && accessToken !== undefined) {
      window.Hurdlr.init({
        clientId: CONFIG.hurdlr.clientId,
        accessToken,
        environment: CONFIG.hurdlr.environment,
      });
    }
  }, [status, accessToken]);
  return children;
};
