import React, { useMemo, useRef, useEffect, createRef, useState } from 'react';
import {
  Box,
  Checkbox,
  Grid,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  FormationsDateFilter,
  FormationsSortFilter,
} from 'components/common/FormationsFilters';
import { FormationsTableDocument } from 'components/common/tables/DocumentsTable';
import { useDocumentQuery, useCurrentUserRole } from 'hooks/api';
import {
  DOCUMENT_HUB_NOTE_FLAG,
  DOCUMENT_HUB_STATUS_FLAG,
  useFeatureFlag,
} from 'hooks/useFeatureFlag';
import {
  DocumentCategoryFilter,
  DocumentPillarFilter,
  DocumentSubCategoryFilter,
  DocumentYearFilter,
} from './DocumentFilters';
import { DocumentNoteFilter } from './DocumentNoteFilter';
import { SelectedFile } from '../DocumentsTable';

const ResizeBar = styled(Box)(() => ({
  width: '5px',
  height: '100%',
  cursor: 'col-resize',
  position: 'absolute',
  top: 0,
  right: 0,
  '&:hover': {
    borderRight: '2px solid #BDBDBD',
  },
  '&:active': {
    borderRight: '2px solid #BDBDBD',
  },
}));

const NormalTableCell = styled(TableCell)(() => ({
  paddingTop: '16px',
  paddingBottom: '16px',
  '&:hover': {
    '.hoverCheckbox': {
      display: 'block',
    },
    '.activeCheckbox': {
      display: 'none',
    },
    '.activeIcon': {
      display: 'none',
    },
  },
  '.hoverCheckbox': {
    display: 'none',
  },
}));

const ResizeTableCell = styled(TableCell)(() => ({
  paddingTop: '16px',
  paddingBottom: '16px',
  position: 'relative',
  '&:hover': {
    '& .resizeBar': {
      borderRight: '2px solid #BDBDBD',
    },
  },
}));

export type FormationsTableHeaderKeys =
  | keyof FormationsTableDocument
  | 'checkbox'
  | 'actions'
  | 'adminActions';

type Header = {
  title: string;
  key: FormationsTableHeaderKeys;
  sort: boolean;
};

export const adminDocumentsHeaders: Array<Header> = [
  { title: '', key: 'checkbox', sort: false },
  { title: 'Name', key: 'title', sort: true },
  { title: 'Year', key: 'forYear', sort: true },
  { title: 'Pillar', key: 'department', sort: true },
  { title: 'Category', key: 'category', sort: false },
  { title: 'Subcategory', key: 'subcategory', sort: false },
  { title: 'Uploaded', key: 'uploaded', sort: false },
  { title: 'Visibility', key: 'visibleToCustomer', sort: false },
  { title: 'Views', key: 'customerReadCount', sort: false },
  { title: 'Notes', key: 'hasNotes', sort: false },
  { title: 'Actions', key: 'adminActions', sort: false },
];

export const documentsHeaders: Array<Header> = [
  { title: 'Status', key: 'status', sort: true },
  { title: 'Name', key: 'title', sort: true },
  { title: 'Year', key: 'forYear', sort: true },
  { title: 'Upload Date', key: 'uploadedAt', sort: false },
  { title: 'Pillar', key: 'department', sort: true },
  { title: 'Category', key: 'category', sort: false },
  { title: 'Subcategory', key: 'subcategory', sort: false },
  { title: 'Notes', key: 'hasNotes', sort: false },
  { title: 'Actions', key: 'actions', sort: false },
];

const ColumnFilter = ({
  filterKey,
  onSelectAllClick,
  selectedRows,
  currentRows,
}: {
  filterKey: string;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedRows: readonly SelectedFile[];
  currentRows: FormationsTableDocument[];
}) => {
  const [query, setQuery] = useDocumentQuery();
  const checked = useMemo(() => {
    const selectedIds = selectedRows.map((s) => s.id);
    const currentIds = currentRows.map((c) => c.id);
    return currentIds.every((id) => selectedIds.includes(id));
  }, [currentRows, selectedRows]);
  const indeterminate = useMemo(
    () => selectedRows.length > 0 && !checked,
    [selectedRows, checked],
  );

  switch (filterKey) {
    case 'title':
      return <FormationsSortFilter query={query} setQuery={setQuery} />;
    case 'forYear':
      return <DocumentYearFilter/>;
    case 'uploadedAt':
    case 'uploaded':
      return <FormationsDateFilter query={query} setQuery={setQuery} />;
    case 'department':
      return <DocumentPillarFilter />;
    case 'category':
      return <DocumentCategoryFilter />;
    case 'subcategory':
      return <DocumentSubCategoryFilter />;
    case 'hasNotes':
      return <DocumentNoteFilter />;
    case 'checkbox':
      return (
        <Checkbox
          color="primary"
          indeterminate={indeterminate}
          checked={checked}
          onChange={onSelectAllClick}
          inputProps={{
            'aria-label': 'select all desserts',
          }}
        />
      );
    default:
      return null;
  }
};

const headerUndefinedWidth = 80;
const paddingOffset = 32;

type HeaderWidths = {
  [key in FormationsTableHeaderKeys]: number;
};

const headerDefaultMinimumWidths: Partial<HeaderWidths> = {
  checkbox: 50,
  title: 200,
  forYear: 50,
  department: 150,
  category: 100,
  subcategory: 120,
  uploaded: 140,
  visibleToCustomer: 70,
  customerReadCount: 50,
  hasNotes: 70,
  adminActions: 50,
};

type Props = {
  item: Header;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedRows: readonly SelectedFile[];
  currentRows: FormationsTableDocument[];
  onPointerDown: Function;
  refMap: React.MutableRefObject<{
    [k: string]: React.RefObject<HTMLTableCellElement>;
  }>;
  isAdmin: boolean;
};

const HeaderCell = ({
  item,
  onSelectAllClick,
  selectedRows,
  currentRows,
  onPointerDown,
  refMap,
  isAdmin,
}: Props) => {
  const defaultCellWidth = `${
    headerDefaultMinimumWidths[item.key] ?? headerUndefinedWidth
  }px`;
  const checked = useMemo(() => {
    const selectedIds = selectedRows.map((s) => s.id);
    const currentIds = currentRows.map((c) => c.id);
    return currentIds.every((id) => selectedIds.includes(id));
  }, [currentRows, selectedRows]);
  const indeterminate = useMemo(
    () => selectedRows.length > 0 && !checked,
    [selectedRows, checked],
  );

  return isAdmin ? (
    <ResizeTableCell
      sx={{ width: item.key === 'title' ? 'auto' : defaultCellWidth }}
      component="th"
      ref={refMap.current[item.key]}
      scope="row"
    >
      <Grid
        container
        gap={item.key === 'checkbox' ? 0 : 1}
        justifyContent={
          [
            'checkbox',
            'adminActions',
            'hasNotes',
            'visibleToCustomer',
          ].includes(item.key)
            ? 'center'
            : 'flex-start'
        }
        wrap="nowrap"
      >
        <Box sx={{ width: 'max-content' }}>{item.title}</Box>
        <Box>
          <ColumnFilter
            filterKey={item.key}
            onSelectAllClick={onSelectAllClick}
            selectedRows={selectedRows}
            currentRows={currentRows}
          />
        </Box>
      </Grid>
      <ResizeBar
        onPointerDown={() => onPointerDown(item.key)}
        className="resizeBar"
      />
    </ResizeTableCell>
  ) : (
    <NormalTableCell component="th" ref={refMap.current[item.key]} scope="row">
      <Grid
        container
        gap={1}
        alignItems="center"
        justifyContent={
          ['actions', 'status', 'hasNotes'].includes(item.key)
            ? 'center'
            : 'flex-start'
        }
        wrap="nowrap"
      >
        {item.key === 'title' && (
          <>
            {(checked || indeterminate) && (
              <Checkbox
                color="primary"
                className="activeCheckbox"
                indeterminate={indeterminate}
                checked={checked}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            )}

            <Checkbox
              className="hoverCheckbox"
              color="primary"
              indeterminate={indeterminate}
              checked={checked}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
              onChange={onSelectAllClick}
              sx={{ height: 42 }}
            />
          </>
        )}
        <Box sx={{ width: 'max-content' }}>{item.title}</Box>
        <Box>
          <ColumnFilter
            filterKey={item.key}
            onSelectAllClick={onSelectAllClick}
            selectedRows={selectedRows}
            currentRows={currentRows}
          />
        </Box>
      </Grid>
    </NormalTableCell>
  );
};

export const DocumentHeader = ({
  onSelectAllClick,
  selectedRows,
  currentRows,
}: {
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedRows: readonly SelectedFile[];
  currentRows: FormationsTableDocument[];
}) => {
  const showNoteColumn = useFeatureFlag(DOCUMENT_HUB_NOTE_FLAG);
  const showStatusColumn = useFeatureFlag(DOCUMENT_HUB_STATUS_FLAG);
  const { isAdmin } = useCurrentUserRole();
  const [isResizingTable, setIsResizingTable] = useState(false);
  const autoSet = useRef(false);

  const headers = useMemo(() => {
    if (isAdmin) {
      return [...adminDocumentsHeaders];
    }
    let res = [...documentsHeaders];
    if (!showStatusColumn) {
      res = res.filter((h) => h.key !== 'status');
    }
    if (!showNoteColumn) {
      res = res.filter((h) => h.key !== 'hasNotes');
    }
    return res;
  }, [showNoteColumn, showStatusColumn, isAdmin]);

  const refMap = useRef(
    Object.fromEntries(
      headers.map((header) => [header.key, createRef<HTMLTableCellElement>()]),
    ),
  );
  const resizing = useRef('');

  const onPointerMove = (event: PointerEvent) => {
    if (isAdmin && Object.keys(refMap.current).includes(resizing.current)) {
      const headerRef = refMap.current[resizing.current].current!;
      const headerMinimumWidth =
        headerDefaultMinimumWidths[
          resizing.current as FormationsTableHeaderKeys
        ] ?? headerUndefinedWidth;
      const calculatedWidth =
        event.clientX - headerRef.getBoundingClientRect().left!;
      const resizeWidth =
        calculatedWidth < headerMinimumWidth + paddingOffset
          ? headerMinimumWidth + paddingOffset
          : calculatedWidth;
      headerRef.style.width = `${resizeWidth - paddingOffset}px`;
    }
  };

  const styleCursorResizing = (isResizing: boolean) => {
    document.body.style.cursor = isResizing ? 'col-resize' : 'auto';
  };

  const setHoverStyling = (isStyle: boolean) => {
    Object.values(refMap.current).forEach((ref) => {
      const headerRef = ref;
      headerRef.current!.style.pointerEvents = isStyle ? 'auto' : 'none';
    });
  };

  const onPointerDown = (key: string) => {
    resizing.current = key;
    setHoverStyling(false);
    styleCursorResizing(true);
  };

  const onPointerUp = () => {
    resizing.current = '';
    setHoverStyling(true);
    styleCursorResizing(false);
  };

  useEffect(() => {
    document.addEventListener('pointerup', onPointerUp);
    return () => document.removeEventListener('pointerup', onPointerUp);
  }, []);

  useEffect(() => {
    document.addEventListener('pointermove', onPointerMove);
    return () => document.removeEventListener('pointermove', onPointerMove);
  }, []);

  const titleColumnCell = isAdmin ? refMap.current.title?.current : null;
  const adminActionsColumnCell = refMap.current.adminActions?.current;
  useEffect(() => {
    if (!adminActionsColumnCell) {
      return () => {};
    }
    const columnResizeObservor = new ResizeObserver((entries) => {
      requestAnimationFrame(() => {
        entries.forEach(() => {
          setIsResizingTable((prev) => !prev);
        });
      });
    });
    columnResizeObservor.observe(adminActionsColumnCell);
    return () => columnResizeObservor.disconnect();
  }, [adminActionsColumnCell]);

  useEffect(() => {
    if (!titleColumnCell || !adminActionsColumnCell) {
      return;
    }
    if (
      adminActionsColumnCell.clientWidth <=
      headerDefaultMinimumWidths.adminActions! + paddingOffset
    ) {
      adminActionsColumnCell.style.width = `${headerDefaultMinimumWidths.adminActions}px`;
    } else if (autoSet.current) {
      adminActionsColumnCell.style.width = 'auto';
    }
    if (!autoSet.current) {
      if (
        titleColumnCell.clientWidth <=
        headerDefaultMinimumWidths.title! + paddingOffset
      ) {
        titleColumnCell.style.width = `${headerDefaultMinimumWidths.title}px`;
      }
      titleColumnCell.style.width = `${titleColumnCell.clientWidth}px`;
      adminActionsColumnCell.style.width = 'auto';
      autoSet.current = true;
    }
  }, [isResizingTable]);

  return (
    <TableHead>
      <TableRow>
        {headers.map((item) => (
          <HeaderCell
            item={item}
            key={item.key}
            onSelectAllClick={onSelectAllClick}
            selectedRows={selectedRows}
            currentRows={currentRows}
            onPointerDown={onPointerDown}
            refMap={refMap}
            isAdmin={isAdmin}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};
