import React, { useState } from 'react';
import { EditIcon } from 'components/common/Icon';
import { FormationsGhostButton } from 'components/common/Buttons';
import { Company } from 'models/company';
import { FormationsFormFields } from 'components/common/FormationsForm2';
import { FormationsFormDialog } from 'components/common/FormationsFormDialog';
import * as yup from 'yup';
import { useUpdateCompany } from 'hooks/api';
import { showErrorToast, showToastOnError } from 'components/toast/showToast';
import { queryClient } from 'states/reactQueryClient';
import { formatEinInput } from 'components/common/FormationsForm2/FormationsTextField';
import i18n from 'translations/i18n';

interface Props {
  companyData: Company;
  label: string;
  onSubmitted: () => void;
}

const validateSchema = yup.object().shape({
  ein: yup
    .string()
    .required('Please enter a EIN.')
    .matches(/^\d{2}-?\d{7}$/, 'Please enter a valid EIN.'),
});

export const EinFieldTitle = ({ companyData, label, onSubmitted }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const { mutateAsync: updateCompany, isLoading } = useUpdateCompany();

  const handleSave = async (data: { ein: string }) => {
    try {
      if (companyData.id) {
        const ein = data.ein.replace(/-/g, '');
        await updateCompany({ id: companyData.id, data: { ein } });
        await queryClient.invalidateQueries(['getCompany', companyData.id]);
        setOpen(false);
        onSubmitted();
      } else {
        showErrorToast(i18n.t('progressTracker.incorporation.companyNotFound'));
        setOpen(false);
      }
    } catch (e) {
      showToastOnError(e);
    }
  };

  return (
    <>
      <div>
        <span data-testid="field-label-ein">{label}</span>
        <FormationsGhostButton
          aria-label="delete"
          sx={{ marginLeft: '24px' }}
          startIcon={<EditIcon size={22} sx={{ pb: 0.25 }} />}
          data-testid="company-ein-edit-btn"
          onClick={() => setOpen(true)}
        >
          Edit
        </FormationsGhostButton>
      </div>
      <FormationsFormDialog
        title="Update EIN"
        isOpen={open}
        onClose={() => setOpen(false)}
        onSubmit={handleSave}
        validationSchema={validateSchema}
        data-testid="update-ein-dialog"
        loading={isLoading}
        fields={[
          {
            type: FormationsFormFields.Text,
            name: 'ein',
            label,
            options: {
              placeholder: 'EIN',
              autoFocus: true,
              maxLength: 10,
              formatInput: formatEinInput,
            },
          },
        ]}
        defaultValues={{
          ein: '',
          mode: 'onChange',
          reValidateMode: 'onChange',
        }}
      />
    </>
  );
};
