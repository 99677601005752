import { Components } from '@mui/material';

export const form: Components = {
  MuiInputLabel: {
    styleOverrides: {
      asterisk: {
        color: 'red',
      },
    },
  },
};
