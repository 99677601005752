import React, { useEffect, useMemo, useState } from 'react';
import { FormationsDialog } from 'components/FormationsDialog';
import { TempDocument } from 'components/documentsV2/types';
import { styled } from '@mui/styles';
import { Grid } from '@mui/material';
import { EDocumentStatus } from 'hooks/dataFormatters/useDocumentsTableData';
import { FilePreview } from './FilePreviewComponent/FilePreview';
import {
  IDocumentBlob,
  IFormationsPreviewDocument,
} from './FilePreviewComponent/FilePreviewComponent';

interface IBlobPreviewDialogProps {
  open: boolean;
  onClose: () => void;
  file?: TempDocument;
}

const FullHeightContainer = styled(Grid)(() => ({
  height: '100%',
}));

const PreviewContainer = styled(Grid)(() => ({
  height: '100%',
}));

const FileContainer = styled(Grid)(({ theme }) => ({
  boxShadow: theme.shadows[4],
}));

interface Props {
  file: TempDocument;
}

const initDocumentBlobState: IDocumentBlob = {
  unsupportedType: false,
  fileType: '',
  fileBlob: undefined,
};

const BlobPreviewComponent: React.FC<Props> = ({ file }) => {
  const [documentBlob, setDocumentBlob] = useState<IDocumentBlob>(
    initDocumentBlobState,
  );
  const downloadDocumentBlob = useMemo(
    () => new Blob([file.file], { type: file.file.type }),
    [file.file],
  );
  const tempFileObj: IFormationsPreviewDocument = useMemo(
    () => ({
      id: 'tempFileID',
      title: file.displayName,
      forYear: new Date().getFullYear(),
      companyId: 'tempCompanyID',
      status: EDocumentStatus.Submitted,
      metadata: {
        mimeType: file.file.type,
        filename: file.displayName,
      },
      accountId: 'tempAccountID',
      uploader: {
        accountId: 'tempUploaderAccountID',
      },
      customerReadCount: 0,
    }),
    [file],
  );

  useEffect(() => {
    if (downloadDocumentBlob) {
      const docType = downloadDocumentBlob?.type;
      const newFile = {
        ...initDocumentBlobState,
        fileBlob: downloadDocumentBlob,
      };
      switch (docType) {
        case 'application/pdf':
          newFile.fileType = 'pdf';
          newFile.unsupportedType = false;
          break;
        case 'image/png':
        case 'image/jpeg':
        case 'image/jpg':
          newFile.fileType = 'image';
          newFile.unsupportedType = false;
          break;
        case 'video/mp4':
          newFile.fileType = 'mp4';
          newFile.unsupportedType = false;
          break;
        default:
          newFile.unsupportedType = true;
          break;
      }
      setDocumentBlob(newFile);
    }
  }, [downloadDocumentBlob]);

  const { unsupportedType, fileType, fileBlob } = documentBlob;
  return (
    <PreviewContainer container direction="row" alignItems="flex-start">
      <FullHeightContainer
        container
        item
        justifyContent="center"
        alignItems="flex-start"
        xs={12}
      >
        <FileContainer item xs={12}>
          <FilePreview
            file={tempFileObj}
            fileBlob={fileBlob}
            fileType={fileType}
            unsupportedType={unsupportedType}
          />
        </FileContainer>
      </FullHeightContainer>
    </PreviewContainer>
  );
};

export const BlobPreviewDialog: React.FC<IBlobPreviewDialogProps> = ({
  open,
  onClose,
  file,
}) => (
  <FormationsDialog
    open={open}
    onClose={onClose}
    backButton
    fullScreen
    excludeFooter
  >
    {file && <BlobPreviewComponent file={file} />}
  </FormationsDialog>
);
