import { useEffect, useState } from 'react';
import { ReactComponent as FormationsDotsGrid } from 'icons/formations-dots-grid.svg';
import { Box, Grid, Typography } from '@mui/material';
import { FormationsPrimaryButton } from 'components/common/Buttons';
import { makeStyles } from '@mui/styles';
import { useMediaBreakpoints } from 'hooks';
import { EntitySelectionRadio } from 'components/common/EntitySelectionRadio';
import { useCurrentCompany } from 'hooks/api/useCompanies';
import { queryClient } from 'states/reactQueryClient';
import { Routes } from 'fnRoutes';
import { OnboardingService } from 'services';
import { ENTITY_MAPPING } from 'constants/common';
import { Loading } from 'components/common';
import { sendProgressTrackerEvent } from 'helpers/heap/progressTrackerEvent';
import { useCurrentUser, useUpdateUser, useNavigate } from 'hooks/api';
import { useBusinessTypeRegister } from 'components/OnboardingV1/state';
import { DisplayName } from './DisplayName';

type ITypographyVariant =
  | 'subtitle1LT'
  | 'body2'
  | 'body2B'
  | 'h6B'
  | 'subtitle1L'
  | 'h5B';

const data = [
  {
    value: 'not-incorporated',
    label: 'Not Incorporated',
    secondaryText:
      'Business owned and run by one individual that is not yet incorporated',
    icon: 'not-incorporated',
  },
  {
    value: 'incorporated',
    label: 'Incorporated',
    secondaryText:
      'An entity that is already incorporated and is an actively running business',
    icon: 'incorporated',
  },
];

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  subTitle: {
    marginTop: theme.spacing(2),
  },
  question: {
    marginTop: theme.spacing(2),
  },
  options: {},
  navigationControls: {
    marginTop: theme.spacing(4),
  },
  [theme.breakpoints.up('md')]: {
    title: {
      marginTop: theme.spacing(15),
    },
    subTitle: {
      marginTop: theme.spacing(6),
      maxWidth: `396px`,
    },
    question: {
      marginTop: theme.spacing(6),
    },
    dots: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(5),
      zIndex: '-1',
    },
    options: {
      marginTop: theme.spacing(15),
    },
    navigationControls: {
      position: 'absolute',
      marginTop: '0',
      top: '640px',
      right: 0,
    },
    continue: {
      float: 'right',
    },
  },
}));

const getTypograhyVariants = (isDesktop: boolean) => {
  let titleVariant: ITypographyVariant = 'subtitle1LT';
  let subTitleVariant: ITypographyVariant = 'body2';
  let questionVariant: ITypographyVariant = 'body2B';
  if (isDesktop) {
    titleVariant = 'h6B';
    subTitleVariant = 'subtitle1L';
    questionVariant = 'h5B';
  }
  return {
    titleVariant,
    subTitleVariant,
    questionVariant,
  };
};

export const BusinessType = () => {
  const classes = useStyles();
  const { isDesktop } = useMediaBreakpoints();
  const { businessType, setBusinessType } = useBusinessTypeRegister(
    (state) => state,
  );
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const [selected, setSelected] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { currentCompany, status } = useCurrentCompany();
  const { titleVariant, subTitleVariant, questionVariant } =
    getTypograhyVariants(isDesktop);
  const [displayName, setDisplayName] = useState(
    currentUser?.preferredName ?? '',
  );

  const { updateUserAsync: updateUser } = useUpdateUser({
    onSuccess: async () => {
      await queryClient.invalidateQueries(['currentUser']);
    },
  });

  const handleContinue = async () => {
    sendProgressTrackerEvent({
      stage: 'IncorporationStatus',
      incorporationExists: selected === 'incorporated' ? 'Yes' : 'No',
      accountId: currentCompany?.accountId,
      entityType: selected === 'incorporated' ? ENTITY_MAPPING.sole_prop : '',
    });
    setIsLoading(true);
    await updateUser({
      id: currentUser?.id,
      data: {
        preferredName: displayName,
      },
    });
    if (selected === 'incorporated') {
      setBusinessType(selected);
      navigate('/onboarding/incorporation-status');
      setIsLoading(false);
    } else {
      try {
        await OnboardingService.createAndUpdateCompany({
          entityType: ENTITY_MAPPING.sole_prop,
          legacyEntityType: ENTITY_MAPPING.sole_prop,
        });
        sendProgressTrackerEvent({
          stage: 'EntitySelection',
          accountId: currentCompany?.accountId,
          entityType: ENTITY_MAPPING.sole_prop,
        });
        queryClient
          .invalidateQueries('currentUser')
          .then(() => navigate(Routes.PROGRESS_TRACKER));
      } catch (errorSavingData) {
        sendProgressTrackerEvent({
          stage: 'EntitySelection',
          accountId: currentCompany?.accountId,
          entityType: ENTITY_MAPPING.sole_prop,
          status: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (currentCompany?.entityType) {
      navigate(Routes.PROGRESS_TRACKER);
    }
  }, [currentCompany, navigate]);

  useEffect(() => {
    const cachedBusinessType = businessType;
    if (cachedBusinessType) {
      setSelected(cachedBusinessType);
    }
  }, []);

  useEffect(() => {
    if (currentUser?.preferredName) {
      setDisplayName(currentUser?.preferredName);
    }
  }, [currentUser]);

  return ['success', 'error'].includes(status) ? (
    <Grid container spacing={2}>
      {isLoading && <Loading />}
      <Grid item xs={12} md={6} className="left">
        <Grid container spacing={1} direction="row" className={classes.title}>
          <Typography component="h4" variant={titleVariant} color="primary">
            Welcome aboard,&nbsp;
          </Typography>
          <DisplayName
            displayName={displayName}
            setDisplayName={setDisplayName}
          />
        </Grid>
        <Typography
          component="p"
          variant={subTitleVariant}
          color="primary"
          className={classes.subTitle}
          data-test-id="business-type"
        >
          We&apos;re happy you&apos;re here. Before we kick off the onboarding
          process, tell us about your current business structure.
        </Typography>
        <Typography
          component="p"
          variant={questionVariant}
          color="primary"
          className={classes.question}
        >
          Is your business incorporated?
        </Typography>
        {isDesktop && <FormationsDotsGrid className={classes.dots} />}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          position: 'relative',
        }}
      >
        <Box className={classes.options}>
          <Grid container spacing={3}>
            {data.map((option) => (
              <Grid item md={6} key={option.value} sx={{ width: '100%' }}>
                <EntitySelectionRadio
                  {...option}
                  onChange={setSelected}
                  selectedValue={selected}
                  isDesktop={isDesktop}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box className={classes.navigationControls}>
          <FormationsPrimaryButton
            size="large"
            data-test-id="continue"
            className={classes.continue}
            fullWidth={!isDesktop}
            disabled={!selected || !displayName}
            onClick={handleContinue}
          >
            Continue
          </FormationsPrimaryButton>
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Loading />
  );
};
