import create from 'zustand';

interface IBusinessTypeRegister {
  businessType: string;
  setBusinessType: (type: string) => void;
}
export const useBusinessTypeRegister = create<IBusinessTypeRegister>((set) => ({
  businessType: '',
  setBusinessType: (type: string) => set(() => ({ businessType: type })),
}));
