import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { 
  ArrowBackIcon as ArrowBack, 
  SaveIcon 
} from 'components/common/Icon';
import {
  FormationsTertiaryButton,
  FormationsGhostButton,
} from 'components/common/Buttons';
import { queryClient } from 'states/reactQueryClient';
import { ReadOnlyForm } from 'components/common/ReadOnlyForm';
import {
  BUSINESS_DESCRIPTION_LIMIT,
  companyDetailsBIBCustomerStep1,
  companyDetailsCustomerStep2,
} from 'schemas/companyDetailsSchema';
import {
  FormationsForm,
  FormationsFormActions,
  FormationsFormContainer,
  FormationsFormContent,
  FormationsFormData,
  FormationsFormFields,
  FormationsFormItem,
  IFormField,
  renderField,
} from 'components/common/FormationsForm2';
import {
  ProgressTrackerGroupRequest,
  ProgressTrackerGroups,
  ProgressTrackerStages,
  ProgressTrackerStatus,
} from 'services/account';
import { Company } from 'models/company';
import { ENTITY_MAPPING } from 'constants/common';
import {
  useUpdateCompany,
  useIndustryOptions,
  useCurrentAccount,
  useUpdateAccountProgress,
} from 'hooks/api';
import {
  findIndustryTitleFromCode,
  findIndustryDescriptionFromCode,
} from 'components/IndustryOptions/helpers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getProgressTrackerStageStatus,
  isProgressTrackerStageCompleted,
} from 'components/ProgressTracker/Incorporation/state';
import { STATES } from '../../../enums';

const HeadingText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

interface Step1FormProps {
  companyData: Company;
  onSubmit: (data: FormationsFormData) => Promise<void>;
}
const Step1Form = ({ onSubmit, companyData }: Step1FormProps) => {
  const isScorp = companyData.entityType === 'S-CORP';
  const isSoleProp = companyData.entityType === ENTITY_MAPPING.sole_prop;
  const solePropStep1Fields = [
    {
      type: FormationsFormFields.Suggested,
      name: 'suggested',
      placeholder: 'Company Name',
      label: 'Company Name',
      maxLength: 80,
    },
  ];
  const fields: IFormField[] = solePropStep1Fields;

  return (
    <FormationsForm
      onSubmit={onSubmit}
      fields={fields}
      defaultValues={{
        suggested: companyData?.suggested
          ? companyData.suggested.map((item: any) => item?.name ?? item)
          : ['', '', ''],
      }}
      validationSchema={companyDetailsBIBCustomerStep1}
      useFormOptions={{
        context: { isSoleProp, isScorp },
      }}
      renderActions={(formInstance) => (
        <FormationsTertiaryButton
          isLoading={formInstance.formState.isSubmitting}
          type="submit"
          size="large"
          data-testid="save-step-1"
          loadingPosition="end"
          endIcon={<SaveIcon />}
        >
          Save and Continue
        </FormationsTertiaryButton>
      )}
    />
  );
};

interface Step2FormProps {
  companyData: Company;
  onSubmit: (data: FormationsFormData) => Promise<void>;
  onBackClick: () => void;
}
const mapState = (state: { name: string; code: string }) => ({
  name: state.name,
  value: state.code,
});
const Step2Form = ({ companyData, onSubmit, onBackClick }: Step2FormProps) => {
  const { data: industryOptionsData } = useIndustryOptions();
  const isScorp = companyData.entityType === 'S-CORP';
  const isSoleProp = companyData.entityType === ENTITY_MAPPING.sole_prop;
  const fields = [
    {
      type: FormationsFormFields.AutoComplete,
      name: 'stateOfIncorporation',
      label: 'State of Incorporation',
      options: {
        placeholder: 'Washington',
        options: STATES.map(mapState),
      },
    },
    {
      type: FormationsFormFields.AutoComplete,
      name: 'industry',
      label: 'Business Industry',
      options: {
        placeholder: '',
        options: industryOptionsData?.map(({ title, code }) => ({
          name: title,
          value: code,
        })),
      },
    },
    {
      type: FormationsFormFields.TextArea,
      name: 'description',
      label: 'Business Description',
      options: {
        placeholder: 'Individual and Family Psychotherapy Practice',
        characterLimit: BUSINESS_DESCRIPTION_LIMIT,
      },
    },
  ];
  const formInstance = useForm({
    defaultValues: {
      stateOfIncorporation: STATES.find(
        (item) => item.code === companyData.stateOfIncorporation,
      )?.code,
      industry: industryOptionsData?.find(
        (item) => item.code === companyData.industry,
      )?.code,
      description: companyData.description,
    },
    resolver: yupResolver(companyDetailsCustomerStep2),
    context: {
      isSoleProp,
      isScorp,
    },
  });
  const {
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = formInstance;
  useEffect(() => {
    const data = getValues();
    if (data.industry) {
      const description = findIndustryDescriptionFromCode(
        industryOptionsData ?? [],
        data.industry,
      );
      setValue('description', description, {
        shouldDirty: false,
      });
    }
  }, [watch('industry')]);

  return (
    <FormationsFormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormationsFormContent>
          {fields.map((field) => (
            <FormationsFormItem key={field.name}>
              {renderField(field, formInstance)}
            </FormationsFormItem>
          ))}
        </FormationsFormContent>
        <FormationsFormActions>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <FormationsGhostButton
              type="button"
              size="large"
              startIcon={<ArrowBack />}
              onClick={onBackClick}
              disabled={isSubmitting}
            >
              Back
            </FormationsGhostButton>
            <FormationsTertiaryButton
              isLoading={isSubmitting}
              type="submit"
              size="large"
              data-testid="save-step-2"
              loadingPosition="end"
              endIcon={<SaveIcon />}
            >
              Save and Continue
            </FormationsTertiaryButton>
          </Box>
        </FormationsFormActions>
      </form>
    </FormationsFormContainer>
  );
};

interface TParams {
  handleComplete: (status: ProgressTrackerStatus) => void;
  stageCompleted: boolean;
  companyData: Company;
  status: ProgressTrackerStatus | null;
}

export const shouldShowReadOnly = (
  companyData: Company,
  status: ProgressTrackerStatus | null,
): boolean => {
  if (status === ProgressTrackerStatus.Completed) {
    return true;
  }
  const fields = [
    companyData.name,
    companyData.incorporationDate,
    companyData.stateOfIncorporation,
    companyData.industry,
    companyData.description,
  ];
  const allFilled = fields.every((i) => i != null);
  if (!allFilled) {
    return false;
  }
  if (status === ProgressTrackerStatus.Started && companyData.ein == null) {
    return true;
  }
  return false;
};

export const CompanyDetailsBusinessInABox = ({
  handleComplete,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  stageCompleted,
  companyData,
  status,
}: TParams) => {
  const [formStep, setFormStep] = useState(0);
  const { t } = useTranslation();
  const { data: industryOptionsData } = useIndustryOptions();

  const { mutateAsync: updateCompany } = useUpdateCompany({
    onSuccess: () =>
      queryClient.invalidateQueries(['getCompany', companyData?.id]),
  });

  const onStep1Submit = async (formData: Company) => {
    const companyDetails = {
      suggestedNames: formData?.suggested?.filter((name) => name)
    };
    if (companyData?.id) {
      await updateCompany({ id: companyData.id, data: companyDetails });
      setFormStep(1);
      if (status === null) {
        handleComplete(ProgressTrackerStatus.Started);
      }
    }
  };

  const onStep2Submit = async (formData: Company) => {
    const companyDetails = {
      industry: formData.industry,
      stateOfIncorporation: formData.stateOfIncorporation,
      description: formData.description,
    };
    if (companyData?.id) {
      await updateCompany({ id: companyData.id, data: companyDetails });
      handleComplete(ProgressTrackerStatus.Completed);
    }
  };

  return (
    <div>
      <Typography variant="h5B" component="h5" data-test-id="company-details">
        Company Details
      </Typography>
      {shouldShowReadOnly(companyData, status) ? (
        <ReadOnlyForm
          items={[
            {
              key: 'company-name',
              title: 'Company Name',
              value:
                companyData?.name ??
                companyData?.suggested?.map(
                  (item: any) => item?.name ?? item,
                ) ??
                '',
            },
            {
              key: 'State-of-Incorporation',
              title: 'State of Incorporation',
              value: companyData?.stateOfIncorporation,
            },
            {
              key: 'Business-Industry',
              title: 'Business Industry',
              value: findIndustryTitleFromCode(
                industryOptionsData ?? [],
                companyData?.industry,
              ),
            },
            {
              key: 'Business-Description',
              title: 'Business Description',
              value: companyData?.description,
            },
          ]}
        />
      ) : (
        <>
          <Box
            sx={{
              display: formStep === 0 ? 'block' : 'none',
            }}
          >
            <HeadingText variant="body1">{t('progressTracker.incorporation.businessInABoxStep1Subtitle')}</HeadingText>
            <Step1Form onSubmit={onStep1Submit} companyData={companyData} />
          </Box>
          <Box
            sx={{
              display: formStep === 1 ? 'block' : 'none',
            }}
          >
            <HeadingText variant="body1">
              {t('progressTracker.incorporation.whatBusinessIndustryYouAreIn')}
            </HeadingText>
            <Step2Form
              companyData={companyData}
              onSubmit={onStep2Submit}
              onBackClick={() => {
                setFormStep(0);
              }}
            />
          </Box>
        </>
      )}
    </div>
  );
};

interface ContainerProps {
  company: Company;
}

export const CompanyDetailsContainerBusinessInABox = ({ company }: ContainerProps) => {
  const { currentAccount } = useCurrentAccount();
  const { mutateAsync: updateAccountProgress } = useUpdateAccountProgress(
    currentAccount?.id,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['progressTracker']);
      },
    },
  );
  const handleComplete = async (status: ProgressTrackerStatus) => {
    const requestProps: ProgressTrackerGroupRequest = {
      group: ProgressTrackerGroups.Incorporation,
      status,
      stage: ProgressTrackerStages.CompanyDetails,
    };

    await updateAccountProgress({
      progress: [requestProps],
      eventData: {
        stage: ProgressTrackerStages.CompanyDetails,
        stageStatus: status,
        entityType: (company?.entityType ?? company?.legacyEntityType) ?? '',
      },
    });
  };

  const stateCompleted = isProgressTrackerStageCompleted(currentAccount ?? null, ProgressTrackerStages.CompanyDetails);
  const status = getProgressTrackerStageStatus(
    currentAccount ?? null,
    ProgressTrackerStages.CompanyDetails,
  );

  return (
    <CompanyDetailsBusinessInABox
      handleComplete={handleComplete}
      status={status ?? null}
      stageCompleted={stateCompleted}
      companyData={company}
    />
  );
};