import { FormationsListFilter } from 'components/common/FormationsFilters/FormationsListFilter';
import { ICategoryState } from 'components/documentsV1/CategorizeSelectors';
import { useDocumentDataOptions } from 'components/documentsV1/helper';
import { useDocumentCategories, useDocumentQuery } from 'hooks/api';
import { useMemo } from 'react';

export const DocumentYearFilter = () => {
  const { categories } = useDocumentCategories();

  const mockCategoryState: ICategoryState = {
    year: 'Permanent',
    department: 'Tax',
    category: 'Business',
    subcategory: 'Tax Return',
  };

  const { yearOptions } = useDocumentDataOptions(mockCategoryState, categories);
  const [query, setQuery] = useDocumentQuery();
  const { forYear } = query;

  const uniqueYears = useMemo(
    () =>
      yearOptions.map((item) => ({
        value: item,
        title: item,
      })),
    [yearOptions],
  );

  return (
    <FormationsListFilter
      name="forYear"
      query={query}
      setQuery={setQuery}
      list={uniqueYears}
      selected={forYear || []}
    />
  );
}

export const DocumentPillarFilter = () => {
  const { categories } = useDocumentCategories();
  const [query, setQuery] = useDocumentQuery();
  const { department } = query;

  // remove duplicate departments
  const uniquePillars = useMemo(
    () =>
      categories
        ?.filter(
          (item, index, self) =>
            self.findIndex((cat) => cat.department === item.department) ===
            index,
        )
        .map((item) => ({
          value: item.department,
          title: item.department,
        })),
    [categories],
  );

  return (
    <FormationsListFilter
      name="department"
      query={query}
      setQuery={setQuery}
      list={uniquePillars}
      selected={department || []}
    />
  );
};

export const DocumentCategoryFilter = () => {
  const { categories } = useDocumentCategories();
  const [query, setQuery] = useDocumentQuery();
  const { category } = query;

  // remove duplicate categories
  const uniqueCategories = useMemo(
    () =>
      categories
        ?.filter(
          (item, index, self) =>
            item?.category &&
            self.findIndex((cat) => cat.category === item.category) === index,
        )
        .map((item) => ({ title: item.category, value: item.category })),
    [categories],
  );

  return (
    <FormationsListFilter
      name="category"
      query={query}
      setQuery={setQuery}
      list={uniqueCategories}
      selected={category || []}
    />
  );
};

export const DocumentSubCategoryFilter = () => {
  const { categories } = useDocumentCategories();
  const [query, setQuery] = useDocumentQuery();
  const { subcategory } = query;

  // remove duplicate sub-categories
  const uniqueSubCategories = useMemo(
    () =>
      categories
        ?.filter(
          (item, index, self) =>
            item?.subcategory &&
            self.findIndex((cat) => cat.subcategory === item.subcategory) ===
              index,
        )
        .map((item) => ({
          title: item.subcategory,
          value: item.subcategory,
        })),
    [categories],
  );

  return (
    <FormationsListFilter
      name="subcategory"
      query={query}
      setQuery={setQuery}
      list={uniqueSubCategories}
      selected={subcategory || []}
    />
  );
};
