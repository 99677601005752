import styled from '@emotion/styled';
import { FormationsGhostButton } from 'components/common/Buttons';
import { DocumentFilters } from 'hooks/api';
import { TransactionsFilters } from 'hooks/api/useTransactions';
import { TaxesFilters } from 'hooks/api/useTaxes';

export const highlightCSS = {
  backgroundColor: 'others.newYellow',
  '&:hover': { backgroundColor: 'others.newYellow' },
};

export const isHighlighted = (
  column: string,
  query: Partial<TransactionsFilters & DocumentFilters & TaxesFilters>,
): boolean => {
  switch (column) {
    case 'keyword':
      return (
        query.keyword != null ||
        (query.orderBy === 'keyword' && query.order != null)
      );
    case 'date':
      return (
        query.to != null ||
        query.from != null ||
        (query.orderBy === 'date' && query.order != null)
      );
    case 'amount':
      return (
        query.amountFrom != null ||
        query.amountTo != null ||
        (query.orderBy === 'amount' && query.order != null)
      );
    case 'description':
      return !!query.description;
    case 'expenseType':
      return !!query.expenseType;
    case 'department':
      return !!query.department;
    case 'category':
      return !!query.category;
    case 'subcategory':
      return !!query.subcategory;
    case 'forYear':
      return !!query.forYear;
    case 'status':
      return query.status !== null && query.status !== undefined;
    default:
      return false;
  }
};

export const FilterButton = styled(FormationsGhostButton)({
  width: '24px',
  height: '24px',
});
