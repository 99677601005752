import { ALLOWED_BANKS_BACKEND, getBankDisplayName } from 'enums';
import moment from 'moment';
import {
  Company,
  CompanyApi,
  CompanySetupState,
  CreateOwnerData,
  Owner,
  UpdateCompanySetupState,
  UpdateIncorporationStatusSetting,
} from 'models/company';
import { isNil, omitBy } from 'lodash';
import { CoverLetterData } from 'hooks/api';
import { api } from './axios';

export const transformCompany = (company: CompanyApi): Company =>
  omitBy(
    {
      ...company,
      bankName: getBankDisplayName(company.bankName ?? '') ?? company.bankName,
    } as Company,
    isNil,
  );

export const getCompanyById = async (id: string): Promise<Company> => {
  const { data } = await api.get<{ data: CompanyApi }>(`companies/${id}`);
  return transformCompany(data.data);
};

export const getCompanyByUserId = async (id?: string): Promise<Company[]> => {
  const userId = id;
  const { data } = await api.get<{ data: CompanyApi[] }>(
    `users/${userId}/companies`,
  );

  return data.data.map(transformCompany);
};

export const transformCompanyDataForUpdate = (params: Partial<Company>) => {
  const newParams: CompanyApi = { ...params };
  if (newParams.incorporationDate) {
    newParams.incorporationDate = moment(params.incorporationDate).format(
      'MM/DD/YYYY',
    );
  }
  if (newParams.incorporationRenewalDate) {
    newParams.incorporationRenewalDate = moment(
      params.incorporationRenewalDate,
    ).format('MM/DD/YYYY');
  }
  if (!newParams.ein) {
    delete newParams.ein;
  }
  if (newParams.bankName) {
    newParams.bankName =
      ALLOWED_BANKS_BACKEND[newParams.bankName] || newParams.bankName;
  }
  return newParams;
};

export const createCompanyByUserId = async (
  userId: string,
  params: Company,
) => {
  const newParams = transformCompanyDataForUpdate(params);
  await api.post(`users/${userId}/companies`, newParams);
};

export const updateCompanyById = async (id: string, params: Company) => {
  const param = transformCompanyDataForUpdate(params);
  const { data } = await api.patch(`companies/${id}`, param);
  return data;
};

export const upsertCompanyByUserId = async (id: string, params: Company) => {
  const param = transformCompanyDataForUpdate(params);
  const { data } = await api.post(`users/${id}/companies`, param);
  return data;
};

export const updateIncorporationStatus = async (
  id: string,
  payload: UpdateIncorporationStatusSetting,
) => {
  const { data } = await api.patch(`companies/${id}/incorporation-status`, {
    ...payload,
  });
  return data;
};

export type { Company };

export const initIncorporationStatus = async (id: string) => {
  const { data } = await api.post(`companies/${id}/incorporation-status`);
  return data;
};

export const updateCompanyCoverLetter = async (
  companyId: string,
  params: CoverLetterData,
) => api.post(`companies/${companyId}/cover-letter`, params);

export const getCompanyCoverLetter = async (
  companyId: string,
): Promise<CoverLetterData> => {
  const { data } = await api.get<CoverLetterData>(
    `companies/${companyId}/cover-letter`,
  );
  return data;
};

export const createOwner = async (
  companyId: string,
  params: CreateOwnerData,
) => {
  const param = { ...params };
  if (param.dob) {
    param.dob = moment(params.dob).format('MM/DD/YYYY');
  }
  await api.post(`companies/${companyId}/owner`, param);
};

export const updateOwner = async (
  ownerId: string,
  companyId: string,
  params: Partial<Owner>,
) => {
  const param = { ...params };
  if (param.dob) {
    param.dob = moment(params.dob).format('MM/DD/YYYY');
  }
  await api.post(`companies/${companyId}/owner/${ownerId}`, param);
};

export const deleteOwner = async (
  ownerId: string,
  companyId: string,
) => {
  await api.delete(`companies/${companyId}/owner/${ownerId}`);
};

export const getOwners = async (
  companyId: string,
): Promise<Owner[]> => {
  const { data } = await api.get<Owner[]>(
    `companies/${companyId}/owner`,
  );
  return data;
}

export const getOwner = async (
  ownerId: string,
  companyId: string,
  viewSSN: boolean = false,
): Promise<Owner> => {
  const { data } = await api.get<Owner>(`companies/${companyId}/owner/${ownerId}${viewSSN ? '?viewssn=true' : ''}`);
  return data;
}

export const getCompanySetupState = async (companyId: string) => {
  const { data } = await api.get<CompanySetupState>(`companies/${companyId}/setup-status`);
  return data;
}

export const setCompanySetupState = async (companyId: string, state: UpdateCompanySetupState) => {
  const { data } = await api.post<UpdateCompanySetupState>(`companies/${companyId}/setup-status`, state);
  return data;
}
