import { Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { 
  DoneAllIcon,
  OutlinedFlagIcon,
  TimelapseIcon,
} from 'components/common/Icon';
import { FormationsTableDocument } from 'components/common/tables/DocumentsTable';
import { EDocumentStatus } from 'hooks/dataFormatters/useDocumentsTableData';

export const DocumentStatusCell = ({
  document,
}: {
  document: FormationsTableDocument;
}) => {
  const { text, Icon } = useMemo(() => {
    switch (document.status) {
      case EDocumentStatus.Approved:
        return {
          text: 'Approved',
          Icon: DoneAllIcon,
        };
      case EDocumentStatus.Rejected:
        return {
          text: 'Rejected',
          Icon: OutlinedFlagIcon,
        };
      case EDocumentStatus.Submitted:
      default:
        return {
          text: 'Submitted',
          Icon: TimelapseIcon,
        };
    }
  }, [document]);

  return (
    <div data-testid="document-status-cell">
      <Tooltip
        placement="top"
        title={<Typography variant="tooltip">{text}</Typography>}
      >
        <span>
          <Icon 
            sx={{ pl: 1 }}
            data-testid={`document-status-icon-${document.status}`}
          />
        </span>
      </Tooltip>
    </div>
  );
};
