import { Grid, TextField } from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import { Controller, FieldError, UseFormMethods } from 'react-hook-form';
import { IFormField } from './types';

interface ISuggestedNames {
  values: string[];
  onChange: (data: string[]) => void;
  error?: FieldError;
  maxLength?: number;
}

type TError = {
  values: string[];
  value: string;
  index: number;
  error?: FieldError;
};

const isError = ({ values, value, index, error }: TError) => {
  if (error == null) return false;
  switch (error.type) {
    case 'invalidCompanyName':
      return value.trim().startsWith('0');
    case 'suggested':
      return !value;
    case 'suggestedDuplicates':
      return values.some(
        (v: string, i: number) => v.trim() === value.trim() && index !== i,
      );
    default:
      return false;
  }
};

export const SuggestedNames = ({
  values,
  onChange,
  error,
  maxLength,
}: ISuggestedNames) => {
  const onTextChange = (id: number, v: string) => {
    const newData = values.map((item, index) => (index === id ? v : item));
    onChange(newData);
  };

  const onAddField = () => {
    const newData = [...values, ''];
    onChange(newData);
  };

  const getFields = () =>
    values.map((value, index) => {
      const err = isError({
        values,
        value,
        index,
        error,
      });
      return (
        <Grid item xs={12} key={`Option${index + 1}`}>
          <TextField
            value={value}
            onChange={({ target: { value: v } }) => onTextChange(index, v)}
            variant="outlined"
            fullWidth
            label={`Company Name - Option ${index + 1}`}
            placeholder={`New LLC Name Choices ${index + 1}`}
            error={err}
            helperText={err && error ? error.message : ''}
            inputProps={{
              maxLength,
            }}
            name={`suggested${index + 1}`}
            data-testid={`field-suggested-${index + 1}`}
          />
        </Grid>
      );
    });

  return (
    <Grid container spacing={2}>
      {getFields()}
      {values.length <= 5 && (
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <FormationsGhostButton
            onClick={onAddField}
            id="addAnotherCompanyName"
          >
            +Add another option
          </FormationsGhostButton>
        </Grid>
      )}
    </Grid>
  );
};

type TFormationsSuggestionOptions = {
  maxLength?: number;
  defaultValue: string[];
};
type TFormationsSuggestionField = {
  field: IFormField<TFormationsSuggestionOptions>;
  formInstance: UseFormMethods;
};

export const FormationsSuggestionField = ({
  field,
  formInstance,
}: TFormationsSuggestionField) => {
  const { control, errors } = formInstance;
  const { options: { maxLength } = {}, defaultValue = ['', '', ''] } = field;
  return (
    <Controller
      name={field.name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <SuggestedNames
          values={value}
          error={errors[field.name]}
          onChange={(item) => {
            onChange(item);
          }}
          maxLength={maxLength}
        />
      )}
    />
  );
};
