import { TaxesFilters, useTaxesQuery } from 'hooks/api/useTaxes';
import { SortingDirection } from 'enums';
import _ from 'lodash';
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Typography,
} from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import { ArrowDropDownIcon } from 'components/common/Icon';
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { QueryParamConfigMap, SetQuery } from 'use-query-params';
import {
  FilterPopover,
  Sort,
} from 'components/common/FormationsFilters/FilterPopover';
import styled from '@emotion/styled';
import { Search } from 'components/common/FormationsFilters/FilterPopover/Search';

export const highlightCSS = {
  backgroundColor: 'others.newYellow',
  '&:hover': { backgroundColor: 'others.newYellow' },
};

export const isHighlighted = (query: Partial<TaxesFilters>): boolean =>
  (query.years !== null && query.years !== undefined) ||
  (query.sort_by === 'year' && query.sort !== null && query.sort !== undefined);

export const FilterButton = styled(FormationsGhostButton)({
  width: '24px',
  height: '24px',
});

export type FilterItem = {
  value: string;
  title: string;
};

type ListFilterProps = {
  list: FilterItem[];
  defaultSelected?: (string | null)[];
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  onClear: () => void;
  onClose: () => void;
  onConfirm: (options: (string | null)[], order: string | undefined) => void;
  sortOrder?: SortingDirection | null;
};

export const ListFilter = ({
  list,
  defaultSelected = [],
  open,
  anchorEl,
  onClear,
  onClose,
  onConfirm,
  sortOrder = null,
}: ListFilterProps) => {
  // sorting state
  const [order, setOrder] = useState<SortingDirection | null>(null);
  // search and select state
  const [defaultState, setDefaultState] = useState<(string | null)[]>([]);
  const [value, setValue] = useState<string>('');
  const [selected, setSelected] = useState<(string | null)[]>([]);

  const allSelected = selected?.length === list.length;

  const initialState =
    defaultSelected.length === 0
      ? list.map((item) => item.value) // if no filter applied select all
      : defaultSelected;

  useEffect(() => {
    setSelected(initialState);
    setDefaultState(initialState);
  }, [defaultSelected, list]);

  useEffect(() => {
    setOrder(sortOrder);
  }, [sortOrder]);

  const filteredCategoryBySearchText = useMemo(
    () =>
      list.filter((item) =>
        item?.title.toLowerCase().includes(value.toLowerCase()),
      ),
    [value, list, selected],
  );

  const handleCheckBox = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const found = selected.find((item) => item === e.target.value);
      if (found) {
        setSelected(selected.filter((item) => item !== e.target.value));
      } else {
        setSelected([...selected, e.target.value]);
      }
    },
    [selected],
  );

  const handleSelectAllCheckBox = useCallback(() => {
    if (allSelected) {
      setSelected([]);
    } else {
      setSelected(list.map((category) => category.value));
    }
  }, [allSelected]);

  const handleClear = useCallback(() => {
    setSelected([]);
    setValue('');
    setOrder(null);
    onClear();
  }, [onClear]);

  const handleClose = useCallback(() => {
    setOrder(sortOrder);
    setSelected(initialState);
    setDefaultState(initialState);
    onClose();
  }, [sortOrder, initialState]);

  const handleConfirm = useCallback(() => {
    onConfirm(!allSelected && selected ? selected : [], order ?? undefined);
  }, [onConfirm, allSelected, selected, order]);

  const isDirty = useMemo(
    () => !_.isEqual(selected.sort(), defaultState.sort()),
    [selected, defaultState],
  );

  return (
    <FilterPopover
      open={open}
      anchorEl={anchorEl}
      onClear={handleClear}
      onClose={handleClose}
      onConfirm={handleConfirm}
      saveDisabled={!isDirty && order === sortOrder}
      clearDisabled={allSelected && !order}
    >
      <FilterPopover.Header>
        <Sort sortOrder={order} onSort={setOrder} />
        <Typography sx={{ m: 3 }} variant="body2B" component="h6">
          Filter By
        </Typography>
        <Search
          value={value}
          onChange={setValue}
          sx={{ width: '100% !important', paddingLeft: '0 !important' }}
        />
      </FilterPopover.Header>
      <FilterPopover.Content sx={{ maxHeight: '216px', overflowY: 'auto' }}>
        {value === '' && (
          <MenuItem value="all">
            <FormControlLabel
              componentsProps={{
                typography: {
                  variant: 'body2S',
                },
              }}
              label="Select All"
              value={allSelected}
              control={
                <Checkbox
                  checked={allSelected}
                  onChange={handleSelectAllCheckBox}
                />
              }
            />
          </MenuItem>
        )}
        {filteredCategoryBySearchText.map((category) => (
          <MenuItem key={category.value}>
            <FormControlLabel
              componentsProps={{
                typography: {
                  variant: 'body2S',
                },
              }}
              label={category.title}
              value={category.value}
              control={
                <Checkbox
                  checked={allSelected || selected.includes(category.value)}
                  onChange={handleCheckBox}
                  inputProps={{
                    'aria-labelledby': category.title,
                    // @ts-ignore
                    'data-testid': category.value,
                  }}
                />
              }
            />
          </MenuItem>
        ))}
      </FilterPopover.Content>
    </FilterPopover>
  );
};

type TProps = {
  name: string;
  query: TaxesFilters;
  setQuery: SetQuery<QueryParamConfigMap>;
  list: FilterItem[];
  selected?: (string | null)[];
};

export const YearListFilter = ({
  name,
  query,
  setQuery,
  list,
  selected = [],
}: TProps) => {
  const { sort_by: sortBy, sort } = query;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openPopover = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const setQueryWithPageReset = useCallback(
    (options: {
      [key: string]: (string | null)[] | undefined | string | null;
    }) => setQuery({ page: 1, ...options }),
    [setQuery],
  );

  const handleFilter = useCallback(
    (options: (string | null)[], order: string | undefined) => {
      const isSortSet = Boolean(order) || sortBy === 'year';
      const newSortKey = order ? 'year' : undefined;
      if (options && options?.length > 0) {
        setQueryWithPageReset({
          [name]: options,
          sort: isSortSet ? order : sort,
          sort_by: isSortSet ? newSortKey : sortBy,
        });
      } else {
        setQueryWithPageReset({
          [name]: undefined,
          sort: isSortSet ? order : sort,
          sort_by: isSortSet ? newSortKey : sortBy,
        });
      }
      setAnchorEl(null);
    },
    [name, sort, sortBy],
  );

  const handleClear = useCallback(() => {
    setQueryWithPageReset({
      [name]: undefined,
      sort: sortBy === 'year' ? undefined : sort,
      sort_by: sortBy === 'year' ? undefined : sortBy,
    });
    setAnchorEl(null);
  }, [sort, sortBy]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <FilterButton
        rounded
        onClick={openPopover}
        data-testid={`${name}-filter-btn`}
        sx={isHighlighted(query) ? highlightCSS : {}}
      >
        <ArrowDropDownIcon />
      </FilterButton>
      <ListFilter
        list={list}
        defaultSelected={selected}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClear={handleClear}
        onClose={handleClose}
        onConfirm={handleFilter}
        sortOrder={sortBy === 'year' ? sort : null}
      />
    </>
  );
};

export const YearFilterCell = () => {
  const [query, setQuery] = useTaxesQuery();
  const { years } = query;

  const currentYear = new Date().getFullYear();
  const yearsList = Array.from(
    Array(5).fill(currentYear),
    (x, index) => x - index,
  ).map((taxYear) => ({
    title: taxYear.toString(),
    value: taxYear.toString(),
  }));

  return (
    <YearListFilter
      name="years"
      query={query}
      setQuery={setQuery}
      list={yearsList}
      selected={years ?? []}
    />
  );
};
