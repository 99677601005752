import logoText from 'icons/formationsLogo/formationslogo-word-black-large.png';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { BusinessType } from './BusinessType';
import { IncorporationStatus } from './IncorporationStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(3)} ${theme.spacing(2)}`,
  },
  [theme.breakpoints.up('md')]: {
    root: {
      margin: `${theme.spacing(12)} ${theme.spacing(10)}`,
    },
  },
}));

export const Onboarding = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  return (
    <div className={classes.root}>
      <img src={logoText} width="150px" alt="Formations-logo" />
      {id === 'business-type' && <BusinessType />}
      {id === 'incorporation-status' && <IncorporationStatus />}
    </div>
  );
};
