import { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FormationsTertiaryButton } from 'components/common/Buttons';
import { AddIcon, SaveIcon } from 'components/common/Icon';
import {
  useCurrentUser,
  UserIdentity,
  useUpdateAccountProgress,
  useUserIdentityById,
  useCreateOwner,
  useOwners,
} from 'hooks/api';
import {
  IAccount,
  ProgressTrackerGroupRequest,
  ProgressTrackerGroups,
  ProgressTrackerStages,
  ProgressTrackerStatus,
} from 'models/account';
import { Company, CreateOwnerData } from 'models/company';
import { UserInfo } from 'services/users';
import { queryClient } from 'states/reactQueryClient';
import { AlertError } from 'components/FormationsAlert';
import { OwnershipCreateDialog } from 'components/ProgressTracker/Incorporation/OwnershipFormDialog';
import {
  DeleteOwner,
  EditOwner,
  EditOwnershipPercentage,
  ReadOnlyTable,
} from './helpers';
import { isProgressTrackerStageCompleted } from './state';

interface OwnershipDetailsProps {
  handleComplete: () => void;
  isUpdatingAccountProgress: boolean;
  stageCompleted: boolean;
  companyData: Company;
  accountData: IAccount;
  userData: UserInfo | undefined;
  userIdentity: UserIdentity | undefined;
  onContinueToAccountVerification: () => void;
}

export const OwnershipDetails = ({
  handleComplete,
  isUpdatingAccountProgress,
  stageCompleted,
  companyData,
  accountData,
  userData,
  userIdentity,
  onContinueToAccountVerification,
}: OwnershipDetailsProps) => {
  const [openAddAnotherOwnerPopup, setOpenAddAnotherOwnerPopup] =
    useState(false);
  const [showOwnershipPercentageWarning, setShowOwnershipPercentageWarning] =
    useState(false);

  const companyId = companyData.id;
  const { owners } = useOwners(companyId);

  const { mutate: createOwner } = useCreateOwner({
    onSuccess: () =>
      queryClient.invalidateQueries(['companyOwners', companyId]),
  });

  // call createOwner to add the first owner to the list if the first owner hasn't been added yet
  useEffect(() => {
    if (Array.isArray(owners) && owners.length === 0) {
      const firstOwnerData: CreateOwnerData = {
        companyId: companyData.id ?? '',
        accountId: accountData.id,
        isPrimary: true,
        name: {
          First: userData?.name?.first ?? '',
          Middle: userData?.name?.middle,
          Last: userData?.name?.last ?? '',
        },
        ssn: `${userIdentity?.ssn?.first5}${userIdentity?.ssn?.last4}`,
        dob: userData?.dob,
        email: userData?.email,
        ownershipPercentage: 100,
      };
      createOwner({ companyId, data: firstOwnerData });
    }
  }, [owners]);

  const ownershipPercentageList = useMemo(() => {
    let ownerArray: number[] = [100];
    if (owners) {
      ownerArray = owners
        .map((owner) => owner.ownershipPercentage)
        .filter((percentage): percentage is number => percentage !== undefined);
    }
    return ownerArray;
  }, [owners]);

  useEffect(() => {
    // if the sum of ownershipPercentageList does not equal 100 then set the warning
    const sum = ownershipPercentageList.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );
    if (sum !== 100 && owners && owners.length > 0) {
      setShowOwnershipPercentageWarning(true);
    } else {
      setShowOwnershipPercentageWarning(false);
    }
  }, [ownershipPercentageList]);

  const handleSaveAndContinue = () => {
    handleComplete();
    if (
      companyData?.entityType !== 'Sole-Prop' &&
      accountData?.formationsPlan !== 'BIB'
    ) {
      onContinueToAccountVerification();
    }
  };

  return (
    <Grid container spacing={1} style={{ maxWidth: '100%' }}>
      <Grid item xs={12}>
        <Typography variant="subtitle1L" color="text.primary">
          Ownership Details
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2S" color="text.secondary">
          Add Owner Information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h7B" color="text.primary">
          Ownership
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {stageCompleted && owners ? (
          <ReadOnlyTable owners={owners} />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ width: '30%' }}>
                  Name
                </TableCell>
                <TableCell align="left" style={{ width: '20%' }}>
                  Date of Birth
                </TableCell>
                <TableCell align="left" style={{ width: '25%' }}>
                  Social Security
                </TableCell>
                <TableCell align="left" style={{ width: '20%' }}>
                  Ownership
                </TableCell>
                <TableCell style={{ width: '2.5%' }} />
                <TableCell style={{ width: '2.5%' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {owners ? (
                owners.map((owner) => (
                  <TableRow key={owner.id}>
                    <TableCell style={{ width: '30%' }}>
                      {owner.name?.First} {owner.name?.Last}
                    </TableCell>
                    <TableCell style={{ width: '20%' }}>
                      {owner.dob ? `${owner.dob}` : 'N/A'}
                    </TableCell>
                    <TableCell style={{ width: '25%' }}>***-**-*****</TableCell>
                    <TableCell style={{ width: '20%' }}>
                      <EditOwnershipPercentage owner={owner} />
                    </TableCell>
                    <TableCell style={{ width: '2.5%' }}>
                      <EditOwner
                        ownerId={owner.id}
                        companyId={owner.companyId}
                        accountId={owner.accountId}
                        isPrimary={owner.isPrimary}
                      />
                    </TableCell>
                    <TableCell style={{ width: '2.5%' }}>
                      <DeleteOwner owner={owner} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No owners available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </Grid>
      {showOwnershipPercentageWarning && (
        <Grid item xs={12} sx={{ mt: 3 }}>
          <AlertError text="Total ownership must equal 100%. Please review the ownership percentage before you can continue." />
        </Grid>
      )}
      {!stageCompleted && (
        <>
          <Grid item xs={12}>
            <FormationsTertiaryButton
              sx={{ mt: 3 }}
              size="large"
              startIcon={<AddIcon sx={{ pb: 0.25 }} />}
              onClick={() => setOpenAddAnotherOwnerPopup(true)}
            >
              Add an Owner
            </FormationsTertiaryButton>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            <FormationsTertiaryButton
              isLoading={isUpdatingAccountProgress}
              onClick={handleSaveAndContinue}
              size="large"
              data-testid="save-ownership-details"
              loadingPosition="end"
              disabled={showOwnershipPercentageWarning || owners?.length === 1}
              endIcon={<SaveIcon />}
            >
              Save and Continue
            </FormationsTertiaryButton>
          </Grid>
        </>
      )}
      {openAddAnotherOwnerPopup && (
        <OwnershipCreateDialog
          accountId={accountData.id}
          companyId={companyData.id ?? ''}
          open={openAddAnotherOwnerPopup}
          onClose={() => setOpenAddAnotherOwnerPopup(false)}
        />
      )}
    </Grid>
  );
};

interface OwnershipDetailsContainerProps {
  company: Company;
  account: IAccount;
  onContinueToAccountVerification: () => void;
}

export const OwnershipDetailsContainer = ({
  company,
  account,
  onContinueToAccountVerification,
}: OwnershipDetailsContainerProps) => {
  const {
    mutateAsync: updateAccountProgress,
    isLoading: isUpdatingAccountProgress,
  } = useUpdateAccountProgress(account.id, {
    onSuccess: () => queryClient.invalidateQueries(['progressTracker']),
  });

  const handleComplete = async () => {
    const requestProps: ProgressTrackerGroupRequest = {
      group: ProgressTrackerGroups.Incorporation,
      status: ProgressTrackerStatus.Completed,
      stage: ProgressTrackerStages.OwnershipDetails,
    };

    await updateAccountProgress({
      progress: [requestProps],
      eventData: {
        stage: ProgressTrackerStages.OwnershipDetails,
        stageStatus: ProgressTrackerStatus.Completed,
        entityType: company?.entityType ?? company?.legacyEntityType ?? '',
      },
    });
  };

  const stagedCompleted = isProgressTrackerStageCompleted(
    account,
    ProgressTrackerStages.OwnershipDetails,
  );

  const { currentUser: user } = useCurrentUser();
  const { userIdentity } = useUserIdentityById(user?.id || '', {
    enabled: !!user?.id,
  });

  return (
    <OwnershipDetails
      handleComplete={handleComplete}
      isUpdatingAccountProgress={isUpdatingAccountProgress}
      stageCompleted={stagedCompleted}
      companyData={company}
      accountData={account}
      userData={user}
      userIdentity={userIdentity}
      onContinueToAccountVerification={onContinueToAccountVerification}
    />
  );
};
