import { Alert, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    backgroundColor: theme.palette.primary.contrastText,
    borderColor: theme.palette.error.main,
    borderWidth: 1,
    borderStyle: 'solid',
    '& .MuiSvgIcon-root': {
      height: '100%',
      color: theme.palette.error.main,
    },
  },
}));

export const AlertError = ({ text }: { text: string | ReactNode }) => {
  const classes = useStyles();
  return (
    <Alert severity="error" className={classes.error}>
      <Typography
        variant="body2"
        display="inline"
        sx={{ color: 'warning.textDark' }}
      >
        {text}
      </Typography>
    </Alert>
  );
};