import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: "'Spartan', 'Telegraf', sans-serif",
  h1: {
    fontFamily: 'Telegraf, Spartan, sans-serif',
    fontSize: '96px',
    letterSpacing: '-1.5px',
    lineHeight: '112px',
  },
  h1B: {
    fontFamily: 'Telegraf, Spartan, sans-serif',
    fontSize: '96px',
    letterSpacing: '-1.5px',
    lineHeight: '112px',
    fontWeight: 900,
  },
  h2: {
    fontFamily: 'Telegraf, Spartan, sans-serif',
    fontSize: '60px',
    letterSpacing: '-0.5px',
    lineHeight: '72px',
  },
  h2B: {
    fontFamily: 'Telegraf, Spartan, sans-serif',
    fontSize: '60px',
    letterSpacing: '-0.5px',
    lineHeight: '72px',
    fontWeight: 900,
  },
  h3: {
    fontFamily: 'Telegraf, Spartan, sans-serif',
    fontSize: '48px',
    lineHeight: '56px',
  },
  h3B: {
    fontFamily: 'Telegraf, Spartan, sans-serif',
    fontSize: '48px',
    lineHeight: '56px',
    fontWeight: 900,
  },
  h4: {
    fontFamily: 'Telegraf, Spartan, sans-serif',
    fontSize: '34px',
    letterSpacing: '0.25px',
    lineHeight: '42px',
  },
  h4B: {
    fontFamily: 'Telegraf, Spartan, sans-serif',
    fontSize: '34px',
    letterSpacing: '0.25px',
    lineHeight: '42px',
    fontWeight: 900,
  },
  h5: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '24px',
    lineHeight: '32px',
  },
  h5B: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 'bold',
  },
  h6: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '-0.15px',
    fontWeight: 500,
  },
  h6B: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '-0.15px',
    fontWeight: 'bold',
  },
  h7B: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '18px',
    lineHeight: '30px',
    letterSpacing: '-0.15px',
    fontWeight: 'bold',
  },
  h8B: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '-0.15px',
    fontWeight: 'bold',
  },
  h9B: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '-0.1px',
    fontWeight: 'bold',
  },
  subtitle1XL: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 500,
  },
  subtitle1L: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '-0.15px',
    fontWeight: 500,
  },
  subtitle1LT: {
    fontFamily: 'Telegraf, sans-serif',
    fontSize: '22px',
    lineHeight: '38px',
    letterSpacing: '0.5px',
    fontWeight: 'normal',
  },
  subtitle1MB: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '-0.15px',
    fontWeight: 'bold',
  },
  subtitle1M: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.15px',
    fontWeight: 500,
  },
  subtitle1: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.15px',
    fontWeight: 500,
  },
  subtitle2: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.1px',
    fontWeight: 500,
  },
  body1XL: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '-0.15px',
    fontWeight: 500,
  },
  body1L: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '-0.15px',
  },
  body1M: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '18px',
    lineHeight: '28px',
  },
  body1B: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.15px',
    fontWeight: 'bold',
  },
  body1S: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  body1: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    letterSpacing: '-0.15px',
  },
  body2B: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700,
  },
  body2S: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.15px',
    fontWeight: 500,
  },
  body2Link: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.15px',
    fontWeight: 600,
    textDecoration: 'underline',
  },
  body2: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.15px',
    fontWeight: 400,
  },
  body3: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '13px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  body3B: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '13px',
    lineHeight: '24px',
    fontWeight: 700,
  },
  body3S: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '13px',
    lineHeight: '22px',
    fontWeight: 500,
  },
  body3Link: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '13px',
    lineHeight: '22px',
    fontWeight: 600,
  },
  caption: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '13px',
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  chip: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: 500,
  },
  inputLabel: {
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '-0.15px',
    fontWeight: 500,
  },
  helperText: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 500,
  },
  inputText: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '-0.15px',
  },
  tooltip: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
  },
  menu: {
    fontFamily: 'Spartan, sans-serif',
    fontSize: '17px',
    lineHeight: '26px',
    fontWeight: 600,
    letterSpacing: '-0.15px',
  },
};
