import { api } from './axios';

export const cancelPayroll = (
  accountId: string,
  payrollUUID: string,
  reason: string,
): Promise<void> =>
  api.post('/tickets/cancel-payroll', {
    account_id: accountId,
    payroll_uuid: payrollUUID,
    reason,
  });

export const requestChangePayroll = (
  accountId: string,
  reason: string,
  date?: string,
  payrollUUID?: string,
) =>
  api.post('tickets/request-payroll-change', {
    account_id: accountId,
    payroll_uuid: payrollUUID,
    reason,
    date,
  });

export const submitRequestOffCyclePayroll = (
  amount: number,
  date: string,
  accountId: string,
  includeTaxes: boolean,
  reason?: string,
): Promise<void> =>
  api.post('/tickets/payroll-off-cycle', {
    amount,
    date,
    accountId,
    includeTaxes,
    reason,
  });

export const createSupportTicket = (accountId: string, type: string) =>
  api.post(`/tickets/${accountId}/${type}`, type);
