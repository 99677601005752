import { FormationsTertiaryButton } from 'components/common/Buttons';
import { ExitToAppIcon } from 'components/common/Icon';

import { useLogout } from '../../hooks';

export const Logout = () => {
  const { logout } = useLogout();

  return (
    <FormationsTertiaryButton
      sx={{ ml: '2%' }}
      onClick={logout}
      data-testid="logout"
      startIcon={<ExitToAppIcon />}
    >
      Log Out
    </FormationsTertiaryButton>
  );
};
