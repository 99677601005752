import { AuthService, HubspotService, UsersService } from 'services';
import { CHURN_ZERO_EVENT_NAMES, CHURN_ZERO_EVENTS } from 'constants/common';

export enum ActionType {
  TrackEvent = 'trackEvent',
  SetContact = 'setContact',
  SetAttribute = 'setAttribute',
}

type ChurnZeroEvent =
  | ['trackEvent', string, string | undefined]
  | ['trackEvent', string, string, number]
  | ['trackEvent', string, string, number, object];
type ChurnZeroAttribute = ['setAttribute', string, string, number];
type ChurnZeroAttributes = ['setAttribute', string, object];
type ChurnZeroSetAppKey = ['setAppKey', string];
type ChurnZeroSetContact = ['setContact', string, string];
type ChurnZeroOpenHelp = ['open'];

interface ChurnZeroInstance {
  push: (
    action:
      | ChurnZeroEvent
      | ChurnZeroAttribute
      | ChurnZeroAttributes
      | ChurnZeroSetAppKey
      | ChurnZeroSetContact
      | ChurnZeroOpenHelp,
  ) => void;
}

// getting churnZero
const CHURN_ZERO_MAX_RETRIES = 10;
const CHURN_ZERO_RETRY_DELAY = 50;
const getChurnZero = (): Promise<ChurnZeroInstance> =>
  new Promise((resolve, reject) => {
    let count = 0;
    const retrieve = () => {
      setTimeout(() => {
        if (count > CHURN_ZERO_MAX_RETRIES) {
          reject(new Error('Churn Zero not available'));
        }
        count += 1;
        if (window.ChurnZero) {
          resolve(window.ChurnZero);
        } else {
          retrieve();
        }
      }, CHURN_ZERO_RETRY_DELAY);
    };
    retrieve();
  });
const churnZeroErroHandler = (error: Error) => console.error(error);

const runWithErrorHandler = function (callback: () => void): void {
  try {
    callback();
  } catch (e: unknown) {
    if (e instanceof Error) {
      console.log(e.message);
    } else {
      throw e;
    }
  }
};

export const setChurnZeroContact = async (): Promise<void> => {
  const currentUser = await UsersService.getCurrentUser();
  if (currentUser.contactId && AuthService.isCustomer()) {
    const {
      associatedCompanyID: accountExternalId,
      hubspotId: hubspotContactId,
    } = await HubspotService.getHubSpotUserProperties(currentUser.contactId);
    if (accountExternalId) {
      runWithErrorHandler(() => {
        getChurnZero()
          .then((churnZero) =>
            churnZero.push([
              ActionType.SetContact,
              accountExternalId,
              hubspotContactId,
            ]),
          )
          .catch(churnZeroErroHandler);
      });
    }
  }
};

export const sendChurnZeroEvent = (
  name: string,
  description?: string,
): void | undefined => {
  runWithErrorHandler(() => {
    if (!AuthService.isCustomer()) {
      return;
    }
    getChurnZero()
      .then((churnZero) =>
        churnZero.push([ActionType.TrackEvent, name, description]),
      )
      .catch(churnZeroErroHandler);
  });
};

export const sendChurnZeroEventWithMetadata = (
  name: string,
  description: string = '',
  quality: number = 1,
  metadata: object = {},
): void => {
  runWithErrorHandler(() => {
    if (!AuthService.isCustomer()) {
      return;
    }
    getChurnZero()
      .then((churnZero) =>
        churnZero.push([
          ActionType.TrackEvent,
          name,
          description,
          quality,
          metadata,
        ]),
      )
      .catch(churnZeroErroHandler);
  });
};

export const sendChurnZeroLoginEvent = () => {
  if (localStorage.getItem(CHURN_ZERO_EVENTS.LOGIN) === 'success') {
    return;
  }
  sendChurnZeroEvent(CHURN_ZERO_EVENT_NAMES.LOGIN);
  localStorage.setItem(CHURN_ZERO_EVENTS.LOGIN, 'success');
};

export const openChurnZeroHelpCenter = () =>
  getChurnZero().then((churnZero) => churnZero.push(['open']));
