import { Typography, TypographyProps } from '@mui/material';

export const Heading = ({
  variant = 'body2B',
  children,
  ...props
}: TypographyProps) => (
  // @ts-ignore
  <Typography
    variant={variant}
    sx={{ pl: 2, pr: 2, mb: 1 }}
    component="h3"
    {...props}
  >
    {children}
  </Typography>
);
