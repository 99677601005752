import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AttachFileIcon } from 'components/common/Icon';
import { Routes } from 'fnRoutes';
import { Link } from 'react-router-dom';
import { ReactComponent as FilingNotReadyIcon } from 'icons/filing-not-ready.svg';
import { ReactComponent as FilingReadyIcon } from 'icons/filing-ready.svg';
import { ReadOnlyForm } from 'components/common/ReadOnlyForm';
import { Company } from 'models/company';
import { FormationsDivider } from 'components/common';
import { useDocumentsQuery } from 'hooks/api';
import { formatEIN } from 'helpers/progressTracker';
import { useTranslation } from 'react-i18next';
import { FormationsPrimaryButton } from 'components/common/Buttons';

const IconContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const IconHeader = styled(Typography)(({ theme }) => ({
  marginTop: '16px',
  color: theme.palette.text.secondary,
}));

interface TParams {
  accountId: string;
  companyData: Company;
  completedSteps: number;
  stageCompleted: boolean;
  onContinueToAccountVerification: () => void;
}

export const FilingDocumentsBusinessInABox = ({
  accountId,
  companyData,
  completedSteps,
  stageCompleted,
  onContinueToAccountVerification,
}: TParams) => {
  const { t } = useTranslation();
  const { documents } = useDocumentsQuery({
    accountId,
    page: 1,
    category: ['Organizational Docs'],
    subcategory: ['Miscellaneous'],
  });

  if (completedSteps < 3) {
    return (
      <div>
        <Typography component="h5" variant="h5B">
          We need more info...
        </Typography>
        <IconHeader variant="body1">
          {t('progressTracker.incorporation.completePreviousSteps')}
        </IconHeader>
        <IconContainer>
          <FilingNotReadyIcon />
        </IconContainer>
      </div>
    );
  }
  if (!stageCompleted) {
    return (
      <div>
        <Typography
          component="h5"
          variant="h5B"
          data-test-id="got-your-information"
        >
          {t('progressTracker.incorporation.gotIncorporationInfo')}
        </Typography>
        <IconHeader variant="body1">
          {t('progressTracker.incorporation.businessInABoxIncorporationSubtitle')}
        </IconHeader>
        <IconContainer>
          <FilingReadyIcon />
        </IconContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormationsPrimaryButton
            onClick={onContinueToAccountVerification}
            data-testid="continue-to-account-verification"
          >
            Continue to Identity Verification
          </FormationsPrimaryButton>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Typography component="h5" variant="h5B">
        {t('progressTracker.incorporation.weHaveReceivedIncorporationInfo')}
      </Typography>
      <IconHeader variant="body1">
        {t('progressTracker.incorporation.timeToCelebrate', {
          link: (
            <Link to={Routes.DOCUMENTS}>
              <Typography variant="body1B">Documents</Typography>
            </Link>
          ),
        })}
      </IconHeader>
      <ReadOnlyForm
        items={[
          {
            key: 'Company Name (Legal)',
            title: 'Company Name (Legal)',
            value: companyData?.name,
          },
          {
            key: 'Date Of Incorporation',
            title: 'Date Of Incorporation',
            value: companyData?.incorporationDate,
          },
          {
            key: 'Employer Identification Number (EIN)',
            title: 'Employer Identification Number (EIN)',
            value: companyData?.ein
              ? formatEIN(companyData?.ein)
              : companyData?.ein,
          },
        ]}
      />
      {documents && documents?.length > 0 && (
        <Box
          sx={{
            mt: 2,
            mb: 4,
          }}
        >
          <FormationsDivider />
          <Typography component="h6" variant="h8B" sx={{ mb: 2 }}>
            {t(
              'progressTracker.accountVerification.documentOfIncorporation.title',
            )}
          </Typography>
          {documents?.map((item) => (
            <Typography key={item.id} variant="body1S" component="p" sx={{ mb: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AttachFileIcon sx={{ verticalAlign: 'text-bottom' }} />
                {item.title}
              </Box>
            </Typography>
          ))}
        </Box>
      )}
    </div>
  );
};
