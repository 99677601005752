import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Grid,
  Typography,
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { ExpandMoreIcon } from 'components/common/Icon';
import {
  useCurrentAccount,
  useUpdateAccountProgress,
} from 'hooks/api/useAccounts';
import { useCurrentCompany } from 'hooks/api/useCompanies';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import {
  IAccount,
  ProgressTrackerGroupRequest,
  ProgressTrackerGroups,
  ProgressTrackerStages,
  ProgressTrackerStatus,
} from 'services/account';
import { Company } from 'services/companies';
import { queryClient } from 'states/reactQueryClient';
import { initialStateBIB, reducer } from './state';
import { AccountVerificationLayout } from './AccountVerificationLayout';
import { StageSkeleton } from '../StageSkeleton';
import { AccordionCTA } from '../AccordionCTA';

interface AccountVerificationComponentProps {
  companyData: Company;
  account: IAccount;
  expanded: boolean | string;
  handleExpand: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  onContinueToNextSection: () => void;
}

const AccountVerificationBIB = ({
  companyData,
  account: accData,
  expanded,
  handleExpand,
  onContinueToNextSection,
}: AccountVerificationComponentProps) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(
    reducer,
    initialStateBIB(),
  );
  /**
   *
   * Logic:
   *
   * - Handling the active step based on the response of the api.
   * - After every update, we need to call updated progress api to update the status.
   * - On success of update progress, we will invalidate the get current account api.
   *   We need to have latest status changes. Which will inturn set the active step.
   *
   */

  const [incorporationActiveStep, setIncorporationActiveStep] = useState(0);
  const [completedStep, setCompletedStep] = useState(0);

  const incStep = () => {
    setIncorporationActiveStep((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    /**
     * Only update and show correct step only when we land first time or do refresh.
     *
     * On update, the onSuccess callback will handle updation of the step.
     */

    const states = Object.keys(state);

    if (states.length > 0) {
      let completedCount: number = 0;
      states.forEach((item) => {
        if (state[item]?.status === ProgressTrackerStatus.Completed) {
          completedCount += 1;
        }
      });
      setCompletedStep(completedCount);
    }
  }, [state]);

  const { mutateAsync: updateAccountProgress, isLoading: IsUpdatingProgress } =
    useUpdateAccountProgress(accData?.id, {
      onSuccess: () => queryClient.invalidateQueries(['progressTracker']),
    });

  useEffect(() => {
    if (accData?.progress.length > 0) {
      dispatch({
        type: ProgressTrackerStages.UploadIdentityProof,
        value: accData?.progress,
      });
      dispatch({
        type: ProgressTrackerStages.UploadLastYearTaxStatement,
        value: accData?.progress,
      });
      dispatch({
        type: ProgressTrackerStages.HomeAddressDetails,
        value: accData?.progress,
      });
    }
  }, [accData]);

  const onContinue = async (stage: ProgressTrackerStages, status: ProgressTrackerStatus) => {
    const requestProps: ProgressTrackerGroupRequest = {
      group: ProgressTrackerGroups.AccountVerification,
      stage,
      status,
    };

    await updateAccountProgress({
      progress: [requestProps],
      eventData: {
        stage,
        entityType:
          (companyData?.entityType ?? companyData?.legacyEntityType) ?? '',
      },
    });

    incStep();
  };

  const steps = Object.keys(state);
  const stageCompleted = () => completedStep === steps.length;

  return (
    <Accordion
      expanded={expanded === 'account-verification'}
      onChange={handleExpand('account-verification')}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="account-verification-content"
        id="account-verification-header"
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h6B">Identity Verification</Typography>
            <Typography variant="subtitle1">
              {t(
                'progressTracker.accountVerification.accountVerificationSubTitle',
              )}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              {t('progressTracker.accountVerification.processingTime')}
            </Typography>
            <AccordionCTA
              expanded={expanded}
              handleExpand={handleExpand}
              completed={completedStep}
              totalStep={steps.length}
              testId="start-account-verification"
              accordionId="account-verification"
            />
          </Grid>
          {!expanded && (
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
              {!stageCompleted() && !expanded && (
                <Typography variant="h8B">
                  {completedStep}/{steps.length}
                </Typography>
              )}
              {stageCompleted() && !expanded && (
                <Typography variant="h8B" sx={{ display: 'flex' }}>
                  <CheckCircle color="secondary" />
                  &nbsp; {t('progressTracker.complete')}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <AccountVerificationLayout
          activeStep={incorporationActiveStep}
          setActiveStep={setIncorporationActiveStep}
          handleContinue={onContinue}
          isLoading={IsUpdatingProgress}
          state={state}
          entityType={companyData?.entityType ?? ''}
          account={accData}
          onContinueToNextSection={onContinueToNextSection}
        />
      </AccordionDetails>
    </Accordion>
  );
};

interface AccountVerificationComponentBIBProps {
  expanded: boolean | string;
  handleExpand: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  onContinueToNextSection: () => void;
}

export const AccountVerificationComponentBIB: React.FC<AccountVerificationComponentBIBProps> = ({ expanded, handleExpand, onContinueToNextSection }) => {
  const {
    currentCompany: companyData,
    isLoading: companyDataLoading,
    isError: isCompanyDataError,
  } = useCurrentCompany();
  const {
    currentAccount,
    isLoading: fetchingCurrentAccount,
    isError: isAccountError,
  } = useCurrentAccount();

  if (fetchingCurrentAccount || companyDataLoading) {
    return <StageSkeleton />;
  }
  if (companyData != null && currentAccount != null) {
    return (
      <AccountVerificationBIB companyData={companyData} account={currentAccount} handleExpand={handleExpand} expanded={expanded} onContinueToNextSection={onContinueToNextSection}/>
    );
  }
  if (isCompanyDataError && isAccountError) {
    return (
      <Alert severity="error">
        Error fetching data. Please try to refresh or contact for support
      </Alert>
    );
  }
  return null;
};