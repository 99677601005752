import React, { ReactNode, useEffect } from 'react';
import {
  DefaultValues,
  FieldValues,
  useForm,
  UseFormMethods,
  UseFormOptions,
} from 'react-hook-form';
import { Grid } from '@mui/material';
import { AnyObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  FormationsPrimaryButton,
  FormationsGhostButton,
} from 'components/common/Buttons';
import {
  FormationsFormActions,
  FormationsFormContainer,
  FormationsFormContent,
} from 'components/common/FormationsForm2/FormationsFormContainer';
import {
  FormationsFormData,
  IFormField,
} from 'components/common/FormationsForm2/types';
import { renderField } from 'components/common/FormationsForm2/fieldRefactor';
import { CreateDocumentData } from 'services/document';

const emptyFn = () => Promise.resolve();
interface FormationsFormProps {
  onSubmit: (
    data: FormationsFormData,
  ) => Promise<void> | Promise<CreateDocumentData>;
  onCancel?: () => void | Promise<void>;
  defaultValues?: DefaultValues<FieldValues>;
  fields: IFormField[];
  saveButtonLabel?: string;
  cancelButtonLabel?: string;
  validationSchema?: AnyObjectSchema;
  onChange?: (data: FormationsFormData, formInstance: UseFormMethods) => void;
  useFormOptions?: UseFormOptions;
  renderActions?: (formInstance: UseFormMethods) => ReactNode;
  loading?: boolean;
  radioValue: string;
  setFile: Function;
  resetValues: boolean;
}

export function TaxesDirectPaymentForm({
  onSubmit,
  onCancel = emptyFn,
  defaultValues = {},
  fields,
  validationSchema = yup.object(),
  saveButtonLabel = 'Save',
  cancelButtonLabel = 'Cancel',
  onChange = () => null,
  useFormOptions = {},
  renderActions,
  loading = false,
  radioValue,
  setFile,
  resetValues,
}: Readonly<FormationsFormProps>) {
  const formInstance = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    ...useFormOptions,
  });
  const { handleSubmit, watch, getValues, reset } = formInstance;
  useEffect(() => {
    onChange(getValues(), formInstance);
  }, [watch()]);

  useEffect(() => {
    if (resetValues) {
      reset({ amount: '', year: '', paymentDate: null });
      setFile(null);
    }
  }, [radioValue]);

  return (
    <FormationsFormContainer>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <FormationsFormContent>
          {fields.map((field) => (
            <Grid item key={field.name} xs={12} sx={{ mb: 1 }}>
              {renderField(field, formInstance)}
            </Grid>
          ))}
        </FormationsFormContent>
        <FormationsFormActions>
          {renderActions == null ? (
            <>
              <FormationsGhostButton
                onClick={onCancel}
                data-testid="form-cancel-btn"
                disabled={loading}
                sx={{ mr: 2 }}
              >
                {cancelButtonLabel}
              </FormationsGhostButton>
              <FormationsPrimaryButton
                isLoading={loading}
                type="submit"
                data-testid="form-save-btn"
              >
                {saveButtonLabel}
              </FormationsPrimaryButton>
            </>
          ) : (
            renderActions(formInstance)
          )}
        </FormationsFormActions>
      </form>
    </FormationsFormContainer>
  );
}
