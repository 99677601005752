import {
  FormationsFormFields,
  IFormField,
} from 'components/common/FormationsForm2';
import { useDeleteDocument, useDeleteOwner, useUpdateOwner } from 'hooks/api';
import { queryClient } from 'states/reactQueryClient';
import { Owner } from 'models/company';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { showErrorToast } from 'components/toast/showToast';
import { getErrorMessage } from 'helpers/error';
import {
  FormationsGhostButton,
  FormationsPrimaryButton,
  FormationsTertiaryButton,
} from 'components/common/Buttons';
import { CancelIcon, DeleteIcon, EditIcon } from 'components/common/Icon';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ReadOnlySSNInOwnerSection } from 'components/common/ReadOnlySSNInOwnerSection';
import { OwnershipEditDialog } from 'components/ProgressTracker/Incorporation/OwnershipFormDialog';

export const nameFields: IFormField[] = [
  {
    name: 'firstName',
    type: FormationsFormFields.Text,
    label: 'First Name',
    options: {
      maxLength: 20,
    },
  },
  {
    name: 'middleName',
    type: FormationsFormFields.Text,
    label: 'Middle Name',
    options: {
      maxLength: 20,
    },
  },
  {
    name: 'lastName',
    type: FormationsFormFields.Text,
    label: 'Last Name',
    options: {
      maxLength: 20,
    },
  },
];

export const otherFields: IFormField[] = [
  {
    type: FormationsFormFields.DatePicker,
    name: 'dateOfBirth',
    label: 'Date of birth',
    options: {
      placeholder: 'MM/DD/YYYY',
    },
  },
  {
    name: 'socialSecurityNumber',
    type: FormationsFormFields.SecuredText,
    label: 'Social security number (SSN)',
    options: {
      maxLength: 9,
    },
  },
  {
    name: 'email',
    type: FormationsFormFields.Text,
    label: 'Email address',
    options: {
      maxLength: 40,
    },
  },
  {
    name: 'ownershipPercentage',
    type: FormationsFormFields.Number,
    label: 'Ownership percentage',
    options: {
      maxLength: 2,
    },
  },
];

export const ownershipDetailsSchema = yup.object().shape({
  firstName: yup.string().nullable().required('Please enter a first name'),
  lastName: yup.string().nullable().required('Please enter a last name'),
  dateOfBirth: yup.string().nullable().required('Please enter a date of birth'),
  socialSecurityNumber: yup
    .string()
    .length(9, 'ssn must be 9 digits')
    .nullable()
    .required('Please enter an ssn'),
  email: yup.string().nullable().required('Please enter an email'),
  ownershipPercentage: yup
    .number()
    .nullable()
    .min(0, 'Please enter a percentage greater than 0')
    .max(100, 'Please enter a percentage less than 100')
    .required('Please enter a percentage'),
});

export const ReadOnlyTable = ({ owners }: { owners: Owner[] }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell align="left" style={{ width: '30%' }}>
          Name
        </TableCell>
        <TableCell align="left" style={{ width: '30%' }}>
          Date of Birth
        </TableCell>
        <TableCell align="left" style={{ width: '30%' }}>
          Social Security
        </TableCell>
        <TableCell align="left" style={{ width: '10%' }}>
          Ownership
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {owners.map((owner) => (
        <TableRow key={owner.id}>
          <TableCell style={{ width: '30%' }}>
            {owner.name?.First} {owner.name?.Last}
          </TableCell>
          <TableCell style={{ width: '30%' }}>
            {owner.dob ? `${owner.dob}` : 'N/A'}
          </TableCell>
          <TableCell style={{ width: '30%' }}>
            <ReadOnlySSNInOwnerSection
              ownerId={owner.id}
              companyId={owner.companyId}
            />
          </TableCell>
          <TableCell style={{ width: '10%' }}>
            {owner.ownershipPercentage
              ? `${owner.ownershipPercentage}%`
              : 'N/A'}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export const EditOwnershipPercentage = ({ owner }: { owner: Owner }) => {
  const [ownershipPercentage, setOwnershipPercentage] = useState(
    owner.ownershipPercentage,
  );
  const { id: ownerId, companyId } = owner;

  const { mutateAsync: updateOwner } = useUpdateOwner({
    onSuccess: () => {
      queryClient.invalidateQueries(['companyOwners', companyId]);
      queryClient.invalidateQueries(['companyOwner', ownerId, companyId]);
    },
  });

  useEffect(() => {
    setOwnershipPercentage(owner.ownershipPercentage);
  }, [owner]);

  const handlePercentageChange = async (event: SelectChangeEvent<number>) => {
    const value = Number(event.target.value);
    setOwnershipPercentage(value);
    // call the api to update this owner with the new ownership percentage
    await updateOwner({
      ownerId,
      companyId,
      data: {
        ...owner,
        ownershipPercentage: value,
      },
    });
  };

  return (
    <Select
      value={ownershipPercentage}
      onChange={handlePercentageChange}
      sx={{ minWidth: 80, fontSize: '0.875rem' }}
    >
      {Array.from({ length: 101 }, (_, i) => (
        <MenuItem key={i} value={i}>
          {i}%
        </MenuItem>
      ))}
    </Select>
  );
};

export const EditOwner = ({
  ownerId,
  companyId,
  accountId,
  isPrimary,
}: {
  ownerId: string;
  companyId: string;
  accountId: string;
  isPrimary: boolean;
}) => {
  const [open, setOpen] = useState(false);
  if (isPrimary) {
    return null;
  }
  return (
    <>
      <FormationsGhostButton rounded onClick={() => setOpen(true)}>
        <EditIcon />
      </FormationsGhostButton>
      {open && (
        <OwnershipEditDialog
          open={open}
          onClose={() => setOpen(false)}
          companyId={companyId}
          accountId={accountId}
          ownerId={ownerId}
        />
      )}
    </>
  );
};

export const DeleteOwner = ({ owner }: { owner: Owner }) => {
  const { id: ownerId, companyId, proofOfIds, accountId } = owner;
  const [
    openDeleteOwnerConfirmationPopup,
    setOpenDeleteOwnerConfirmationPopup,
  ] = useState(false);

  const { mutateAsync: deleteDocument } = useDeleteDocument();

  const { mutateAsync: deleteOwner } = useDeleteOwner({
    onSuccess: () => {
      queryClient.invalidateQueries(['companyOwners', companyId]);
      queryClient.invalidateQueries(['documents', 'accountId', accountId]);
    },
  });

  const handleDeleteAdditionalOwner = async () => {
    try {
      if (proofOfIds) {
        await Promise.all(proofOfIds.map((id) => deleteDocument(id)));
      }
      await deleteOwner({ ownerId, companyId });
      setOpenDeleteOwnerConfirmationPopup(false);
    } catch (e: unknown) {
      if (e instanceof Error) {
        showErrorToast(getErrorMessage(e));
      } else {
        showErrorToast('An error occurred while deleting additional owner');
      }
    }
  };

  if (owner.isPrimary) {
    return null;
  }

  return (
    <>
      <FormationsGhostButton
        rounded
        onClick={() => setOpenDeleteOwnerConfirmationPopup(true)}
      >
        <CancelIcon fill={1} />
      </FormationsGhostButton>
      {openDeleteOwnerConfirmationPopup && (
        <Dialog
          open={openDeleteOwnerConfirmationPopup}
          onClose={() => setOpenDeleteOwnerConfirmationPopup(false)}
          fullWidth
        >
          <DialogTitle>
            You are about to change the ownership details.
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" sx={{ pt: 2 }}>
              Are you sure you want to delete this owner?
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              sx={{ pt: 3 }}
            >
              <FormationsTertiaryButton
                startIcon={<DeleteIcon sx={{ pb: 0.75 }} />}
                onClick={handleDeleteAdditionalOwner}
                sx={{ mr: 2 }}
              >
                Delete this Owner
              </FormationsTertiaryButton>
              <FormationsPrimaryButton
                onClick={() => setOpenDeleteOwnerConfirmationPopup(false)}
              >
                Cancel
              </FormationsPrimaryButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
