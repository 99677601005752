import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  TOP_NAV_NOTIFICATION_MENU_FLAG,
  useFeatureFlag,
} from 'hooks/useFeatureFlag';
import { useCurrentUser, useCurrentUserRole, useNavigate, useSpoof } from 'hooks/api';
import Switch from '@mui/material/Switch';
import { FormControlLabel, Link } from '@mui/material';
import { Link as LogoLink} from 'components/Link';
import logo from 'icons/formationsLogo/formationslogo-mark-black-large.png';
import { FeatureName, TransactionDataSource } from 'services/features';
import { useFeature, useUpdateFeature } from 'hooks/api/useFeatures';
import { showErrorToast } from 'components/toast/showToast';
import { getErrorMessage } from 'helpers/error';
import { Routes } from 'fnRoutes';
import { useMemo, useState } from 'react';
import { useMediaBreakpoints } from 'hooks';
import { MAIN_COLOR } from 'theme/constant';
import { ProfileMenu } from './ProfileMenu';
import { HelpCenterMenu } from './HelpCenterMenu';
import { PreferencesMenu } from './PreferencesMenu';
import { QuickActionMenu } from './QuickActionMenu';
import NotificationMenu from './NotificationMenu';
import { YesNoModal } from '../modals';

export const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export const TopNavBar = () => {
  const notificationMenuEnabled = useFeatureFlag(
    TOP_NAV_NOTIFICATION_MENU_FLAG,
  );
  const { isAdmin } = useCurrentUserRole();
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const { isMobile } = useMediaBreakpoints();
  const { isAdminSpoof } = useSpoof();
  const home = isAdminSpoof ? Routes.CUSTOMER_HOME : Routes.HOME();
  const accountId = currentUser?.accountId || '';
  const { feature } = useFeature(FeatureName.DataSourceToggle, accountId);
  const [showConfirm, setShowConfirm] = useState(false);

  const { updateFeatureAsync, isLoading: togglingFeatureFlag } =
    useUpdateFeature();

  const toggleNewUI = async () => {
    setShowConfirm(true);
  };

  const onConfirmFeatureToggle = async () => {
    try {
      await updateFeatureAsync({
        featureId: feature?.id || '',
        accountID: accountId,
        params: {
          data: {
            source:
              feature?.data?.source === TransactionDataSource.Hurdlr
                ? TransactionDataSource.Xero
                : TransactionDataSource.Hurdlr,
          },
        },
      });
      navigate(Routes.CUSTOMER_HOME);
      window.location.reload();
    } catch (e: unknown) {
      showErrorToast(getErrorMessage(e));
    }
  };

  const toggleDialogTitle = useMemo(() => {
    if (feature?.data?.source === TransactionDataSource.Hurdlr) {
      return 'Are you Sure?';
    }
    return 'Switch to Beta?';
  }, [feature?.data?.source]);
  const toggleDialogContent = useMemo(() => {
    if (feature?.data?.source === TransactionDataSource.Hurdlr) {
      return (
        <Typography variant="body2S">
          You are about to switch back to Formations&apos; current
          configuration. You can switch back to Beta at any time to use the new
          features. We&apos;d love to get your feedback on the new features and
          any additional product ideas you have. You can complete the survey{' '}
          <Link
            href="https://survey.hsforms.com/1-VmuIw22TEaeLYm6kzX1mg3y9mn"
            variant="body2B"
            target="_blank"
          >
            here
          </Link>{' '}
          .
        </Typography>
      );
    }
    return (
      <Typography variant="body2S">
        You are about to turn on Formations&apos; new beta features, including
        bank connection management and more streamlined transaction management.
        You still have access to all your Formations features and can switch
        back to the current features at any time.
      </Typography>
    );
  }, [feature?.data?.source]);

  const allowToggleUI = feature?.enabled;

  return (
    <AppBar
      position="static"
      color="transparent"
      sx={{
        boxShadow: 0,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        position: 'fixed',
        zIndex: 100,
        background: 'rgba(255,255,255,1)',
        width: isMobile ? '100%' : 'calc(100% - 258px)',
      }}
    >
      <Toolbar 
        sx={{ margin: isMobile ? '0 12px' : '0 48px' }}
      >
        {isMobile && (
          <LogoLink to={home}>
            <img
              src={logo}
              alt="formations"
              width="32px"
              color={MAIN_COLOR}
              style={{marginTop: "8px", marginLeft: "8px"}}
            />
          </LogoLink>
        )} 
        {allowToggleUI && !isMobile && (
          <FormControlLabel
            control={
              <Android12Switch
                checked={feature?.data?.source === TransactionDataSource.Hurdlr}
                onChange={toggleNewUI}
              />
            }
            label={
              <Typography variant="body3S" color="primary">
                Beta
              </Typography>
            }
          />
        )}
        <Typography variant="h7B" sx={{ flexGrow: 1 }} color="primary" />
        {!isAdmin && <QuickActionMenu />}
        <HelpCenterMenu />
        {!isAdmin && notificationMenuEnabled && <NotificationMenu />}
        {!isAdmin && <PreferencesMenu />}
        <ProfileMenu />
      </Toolbar>
      {showConfirm && (
        <YesNoModal
          open
          heading={toggleDialogTitle}
          onSave={onConfirmFeatureToggle}
          isLoading={togglingFeatureFlag}
          onClose={() => setShowConfirm(false)}
          okText="Confirm"
        >
          {toggleDialogContent}
        </YesNoModal>
      )}
    </AppBar>
  );
};
