import { List, Box, Typography } from '@mui/material';
import { useFlags } from 'hooks/useFeatureFlag';
import { Link } from 'components/Link';
import { Routes } from 'fnRoutes';
import { MAIN_COLOR } from 'theme/constant';
import { CONSTANTS } from 'constants/common';
import logoFull from 'icons/formationsLogo/formationslogo-full-black-large.png';

import { useCurrentUser, useSpoof } from 'hooks/api';
import { useCompanyByUserId } from 'hooks/api/useCompanies';
import { useCurrentUserFeatureList } from 'hooks/api/useFeatures';
import { IAction } from '../constant';
import { filterVisibleActions } from '../helpers';
import { MenuItem } from './MenuItem';
import { AccountMenuItem } from './AccountMenuItem';
import { useSidebarOpen } from './sidebarContext';

interface Props {
  actions: IAction[];
}

export const HeaderDesktop = ({ actions }: Props) => {
  const { currentUser } = useCurrentUser();
  const { isAdminSpoof } = useSpoof();
  const home = isAdminSpoof ? Routes.CUSTOMER_HOME : Routes.HOME();

  const { company } = useCompanyByUserId(
    currentUser?.role === CONSTANTS.USER_ROLES.CUSTOMER
      ? currentUser?.id
      : undefined,
  );
  // feature flags
  const flags = useFlags();
  const featureList = useCurrentUserFeatureList();
  const enabledActions = filterVisibleActions(actions, flags, featureList);
  const open = useSidebarOpen();

  const isAccountMenuItems = enabledActions.every(
    (item) => item.type === 'account',
  );

  return (
    <Box>
      <Box p={4} mb={4} sx={{ opacity: open ? 1 : 0 }}>
        <Box data-testid="logo">
          <Link to={home}>
            <img
              src={logoFull}
              alt="formations"
              width="168px"
              color={MAIN_COLOR}
            />
          </Link>
        </Box>
        <Box mt={1}>
          <Typography
            variant="body1S"
            component="p"
            sx={{
              whiteSpace: 'break-spaces',
              wordWrap: 'break-word',
              fontSize: '14px',
            }}
          >
            {company?.name}
          </Typography>
        </Box>
      </Box>
      {isAccountMenuItems ? (
        <List>
          {enabledActions.map((action) => (
            <AccountMenuItem
              key={action.id}
              {...action}
              active={action.id === 'welcome'}
            />
          ))}
        </List>
      ) : (
        <List>
          {enabledActions.map((action) => (
            <MenuItem
              key={action.id}
              {...action}
              active={action.id === 'welcome'}
            />
          ))}
        </List>
      )}
    </Box>
  );
};
