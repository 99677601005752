import { Controller, useFormContext } from 'react-hook-form';
import { Typography, TextField, Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BANK_ACCOUNT_OPTIONS } from 'enums';

export const BankingInfoBIB = ({ bankName } : { bankName: string | undefined }) => {
  const {t} = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Typography variant="h5B" component="h5">
        {t('progressTracker.bankSetup.bankInfoBIBTitle')}
      </Typography>
      <Typography variant='body2S' sx={{ mt: 2, display: 'block' }}>
        {t('progressTracker.bankSetup.bankInfoBIBSubTitle')}
      </Typography>
      {bankName === 'other' && (
        <Controller
          name="bankName"
          control={control}
          rules={{
            required: { value: true, message: t('progressTracker.error.enterBankName') }
          }}
          render={({ onChange, value }) => (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label={t('progressTracker.bankSetup.yourBusinessBankName')}
              onChange={(e) => onChange(e.target.value)}
              value={value || ""}
              inputProps={{
                maxLength: 80,
              }}
              error={errors?.bankName}
              helperText={errors?.bankName?.message}
              sx={{ maxWidth: '442px' }}
            />
          )}
        />
      )}
      <Controller
        name="routingNumber"
        control={control}
        rules={{
          required: { value: true, message: t('Please enter a routing number.')},
          maxLength: { value: 9, message: t('Routing number should be 9 digits.')},
          pattern: { value: /^\d+$/, message: t('Routing number should be a number.')},
        }}
        render={({ onChange, value }) => (
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={t('Routing Number')}
            onChange={(e) => onChange(e.target.value)}
            value={value || ""}
            inputProps={{
              maxLength: 9,
            }}
            error={errors?.routingNumber}
            helperText={errors?.routingNumber?.message}
            sx={{ maxWidth: '442px', mt: 2 }}
          />
        )}
      />
      <Controller
        name="bankAccountNumber"
        control={control}
        rules={{
          required: { value: true, message: t('Please enter an account number') },
          pattern: { value: /^\d+$/, message: t('Account number should be a number.')}
        }}
        render={({ onChange, value }) => (
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={t('Account Number')}
            onChange={(e) => onChange(e.target.value)}
            value={value || ""}
            inputProps={{
              maxLength: 15,
            }}
            error={errors?.bankAccountNumber}
            helperText={errors?.bankAccountNumber?.message}
            sx={{ maxWidth: '442px', mt: 2 }}
          />
        )}
      />
      <Controller
        name="bankAccountType"
        control={control}
        rules={{
          required: { value: true, message: t('Please select account type') },
        }}
        render={({ onChange, value }) => (
          <FormControl variant="outlined" margin='none' fullWidth error={errors?.bankAccountType} sx={{ maxWidth: '442px', mt: 2 }}>
            <InputLabel>{t('Account Type')}</InputLabel>
            <Select
              label={t('Account Type')}
              onChange={(e) => onChange(e.target.value)}
              value={value || ""}
              inputProps={{
                maxLength: 80,
              }}
              data-testid="account-type-select"
            >
              <MenuItem value={BANK_ACCOUNT_OPTIONS[0].value}>{BANK_ACCOUNT_OPTIONS[0].label}</MenuItem>
              <MenuItem value={BANK_ACCOUNT_OPTIONS[1].value}>{BANK_ACCOUNT_OPTIONS[1].label}</MenuItem>
            </Select>
            {errors?.bankAccountType && <FormHelperText>{errors?.bankAccountType?.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );
};
