import { ActiveTaxYearActivityLog } from 'components/ActiveTaxYears/types';
import { ApiListResp, defaultPageInfo } from 'models/api';
import { UseQueryOptions, useMutation, useQuery } from 'react-query';
import {
  ActiveTaxYear,
  createActiveTaxYears,
  getActiveTaxYearsActivity,
  listActiveTaxYears,
  updateActiveTaxYears,
  listAllTaxYears,
} from 'services/taxYears';

export const useActiveTaxYears = () => {
  const { data: activeTaxYears, ...rest } = useQuery<ActiveTaxYear[], unknown>(
    ['activeTaxYears'],
    () => listActiveTaxYears(),
  );
  // make sure taxYear is sorted by year desc
  const sortedTaxYears = activeTaxYears?.sort((a, b) => b.year - a.year);
  return {
    activeTaxYears: sortedTaxYears,
    ...rest,
  };
};

export const useAllTaxYears = () => {
  const { data: activeTaxYears, ...rest } = useQuery<ActiveTaxYear[], unknown>(
    ['allTaxYears'],
    () => listAllTaxYears(),
  );
  // make sure taxYear is sorted by year desc
  const sortedTaxYears = activeTaxYears?.sort((a, b) => b.year - a.year);
  return {
    allTaxYears: sortedTaxYears,
    ...rest,
  };
};

export const useActiveTaxYearsActivity = (
  page: number = 1,
  options: UseQueryOptions<ApiListResp<ActiveTaxYearActivityLog[]>> = {},
) => {
  const { data, ...rest } = useQuery<ApiListResp<ActiveTaxYearActivityLog[]>>(
    ['activeTaxYearsActivity', page],
    () => getActiveTaxYearsActivity(page),
    options,
  );
  return {
    data: data?.data ?? [],
    pageInfo: data?.pageInfo ?? defaultPageInfo,
    ...rest,
  };
}

export const useCreateActiveTaxYears = (queryProps?: any) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    (params: Partial<ActiveTaxYear>) => createActiveTaxYears(params),
    queryProps,
  );
  return {
    createActiveTaxYears: mutate,
    createActiveTaxYearsAsync: mutateAsync,
    ...rest,
  };
};

export const useUpdateActiveTaxYears = (queryProps?: any) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    (params: Partial<ActiveTaxYear>) => updateActiveTaxYears(params),
    queryProps,
  );
  return {
    updateActiveTaxYears: mutate,
    updateActiveTaxYearsAsync: mutateAsync,
    ...rest,
  };
};
