import { FormationsGhostButton } from 'components/common/Buttons';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useMemo, useState } from 'react';
import { formatSsn } from 'helpers/text-transformer';
import { useOwner } from 'hooks/api';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '200px',
    alignItems: 'center',
  },
  securedTextField: {
    fontFamily: 'password',
  },
}));

interface TParams {
  ownerId: string;
  companyId: string
}

export const ReadOnlySSNInOwnerSection = ({ ownerId, companyId }: TParams) => {
  const classes = useStyles();
  const [visibility, setVisibility] = useState(false);

  const { owner } = useOwner( ownerId, companyId, true );
  const value = useMemo(() => owner?.ssn, [owner]);

  if(!value){
    return (<span>N/A</span>)
  }

  return (
    <div className={classes.root}>
      <span>
        {visibility? formatSsn(value) : `***-**-${value.substring(5)}`}
      </span>
      <FormationsGhostButton
        rounded
        onClick={() => setVisibility(!visibility)}
        sx={{
          padding: 0,
        }}
      >
        {visibility ? <VisibilityOff />: <Visibility />}
      </FormationsGhostButton>
    </div>
  );
};