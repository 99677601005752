import { Box, Container, Grid, Typography } from '@mui/material';
import { FormationsPrimaryButton } from 'components/common/Buttons';

export const AuthErrorPage = ({ error }: { error: unknown }) => {
  let message;
  if (error instanceof Error) {
    message = error.message;
  }
  const refreshHandler = () => window.location.reload();
  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid sx={{ maxWidth: 800 }}>
          <Box mt={8} sx={{ textAlign: 'center' }}>
            <Typography variant="h1" data-testid="title-not-found">
              Unknown Error
            </Typography>
            <Typography mt={4}>
              We are sorry, there are some thing wrong happened. Please try to
              refresh the page. If the error persists, please contact us at{' '}
              <strong>
                <a href="support@formationscorp.com">
                  support@formationscorp.com
                </a>
              </strong>
            </Typography>
            {message && (
              <Typography mt={4}>Error details: {message}</Typography>
            )}
            <Box mt={2}>
              <FormationsPrimaryButton
                onClick={refreshHandler}
                data-testid="btn-homepage"
              >
                Refresh
              </FormationsPrimaryButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
