import { useMutation, UseMutationOptions } from 'react-query';
import {
  cancelPayroll,
  createSupportTicket,
  requestChangePayroll,
  submitRequestOffCyclePayroll,
} from 'services/tickets';
import { queryClient } from 'states/reactQueryClient';

export const useCreateCancelTicket = (
  accountId: string,
  payrollUUID: string,
  options: UseMutationOptions<unknown, unknown, string> = {},
) => {
  const { mutateAsync, mutate, ...rest } = useMutation(
    (reason) => cancelPayroll(accountId, payrollUUID, reason),
    {
      onSuccess: () => queryClient.invalidateQueries(['upcomingPayrolls']),
      ...options,
    },
  );
  return {
    createTicket: mutate,
    createTicketAsync: mutateAsync,
    ...rest,
  };
};

type UseCreateRequestChangeTicketParams = {
  reason: string;
  date?: string;
};
export const useCreateRequestChangeTicket = (
  accountId: string,
  payrollUUID: string,
  options?: UseMutationOptions<
    unknown,
    unknown,
    UseCreateRequestChangeTicketParams
  >,
) => {
  const { mutate, mutateAsync, ...rest } = useMutation<
    unknown,
    unknown,
    UseCreateRequestChangeTicketParams
  >(
    ({ reason, date }) =>
      requestChangePayroll(accountId, reason, date, payrollUUID),
    {
      onSuccess: () => queryClient.invalidateQueries(['upcomingPayrolls']),
      ...options,
    },
  );

  return {
    createChangeTicket: mutate,
    createChangeTicketAsync: mutateAsync,
    ...rest,
  };
};

export const useCreatePayrollPlanChangeTicket = (
  accountId: string,
  options?: UseMutationOptions<unknown, unknown, string>,
) => {
  const { mutate, mutateAsync, ...rest } = useMutation<
    unknown,
    unknown,
    string
  >((detail) => requestChangePayroll(accountId, detail), {
    onSuccess: () => queryClient.invalidateQueries(['upcomingPayrolls']),
    ...options,
  });

  return {
    createChangeTicket: mutate,
    createChangeTicketAsync: mutateAsync,
    ...rest,
  };
};

interface RequestPayrollDataInfo {
  amount: number;
  date: string;
  accountId: string;
  includeTaxes: boolean;
  reason?: string;
}

export const useRequestOffCyclePayroll = (
  mutationProps?: UseMutationOptions<unknown, unknown, RequestPayrollDataInfo>,
) => {
  const { mutateAsync } = useMutation((data: RequestPayrollDataInfo) => {
    const { amount, date, accountId, includeTaxes, reason } = data;
    return submitRequestOffCyclePayroll(
      amount,
      date,
      accountId,
      includeTaxes,
      reason,
    );
  }, mutationProps);

  return {
    requestOffCyclePayroll: mutateAsync,
  };
};

export const useCreateSupportTicket = (accountId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation((type: string) =>
    createSupportTicket(accountId, type),
  );
  return {
    createSupportTicket: mutate,
    createSupportTicketAsync: mutateAsync,
    ...rest,
  };
};
