import { SvgIcon, Typography } from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import { 
  MoreVertIcon, 
  AddCommentIcon, 
  CustomIconComponent, 
  CloudDownloadIcon, 
  CreateIcon, 
  DeleteIcon, 
  FavoriteIcon,
  FileCopyIcon,
  RateReviewIcon, 
} from 'components/common/Icon';
import React, { useMemo, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import i18n from 'translations/i18n';
import { downloadDocument } from 'services/document';
import { downloadFileBlob } from 'helpers/documents';
import { sendChurnZeroEvent } from 'helpers/churnZero';
import { CHURN_ZERO_EVENT_NAMES } from 'constants/common';
import { useTranslation } from 'react-i18next';
import { Routes } from 'fnRoutes';
import { showSuccessToast, showErrorToast } from 'components/toast/showToast';
import { EditNameDialog } from '../dialogs/EditNameDialog';
import { FormationsTableDocument } from '../../common/tables/DocumentsTable';
import { DeleteFileDialog } from '../dialogs/DeleteFileDialog';
import {
  DOCUMENT_HUB_FAVOURITE_FLAG,
  DOCUMENT_HUB_NOTE_FLAG,
  useFeatureFlag,
} from '../../../hooks/useFeatureFlag';
import { EditNoteDialog } from '../dialogs/EditNoteDialog';

const ActionsMenu = styled(Menu)(({ theme }) => ({
  '& .MuiMenu-list': {
    padding: 0,
    '& .MuiMenuItem-root:hover': {
      '& .actionIcon': {
        color: theme.palette.primary.main,
      },
      '& .actionText': {
        color: theme.palette.primary.main,
      },
    },
  },
}));

const IconTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

interface IMoreActionsCell {
  accountId?: string;
  document: FormationsTableDocument;
  isFavourite?: boolean;
  isAdmin?: boolean;
  onReviewFile?: (document: FormationsTableDocument) => void;
}

interface IMenuItem {
  key: string;
  name: string;
  icon?: typeof SvgIcon | CustomIconComponent;
  hidden?: boolean;
}

const createNoteMenu = [
  {
    key: 'addNote',
    name: i18n.t('documentHub.actions.createNote'),
    icon: AddCommentIcon,
  },
];

const normalMenu = [
  {
    key: 'renameFile',
    name: i18n.t('documentHub.actions.renameFile'),
    icon: CreateIcon,
  },
  {
    key: 'download',
    name: i18n.t('documentHub.actions.download'),
    icon: CloudDownloadIcon,
  },
];

const favouriteMenu = [
  {
    key: 'addToFavourites',
    name: i18n.t('documentHub.actions.addToFavourites'),
    icon: FavoriteIcon,
  },
];

const removeFavouriteMenu = [
  {
    key: 'removeFavourites',
    name: i18n.t('documentHub.actions.removeFavourites'),
    icon: FavoriteIcon,
  },
];

const reviewMenu = [
  {
    key: 'reviewFile',
    name: i18n.t('documentHub.actions.reviewFile'),
    icon: RateReviewIcon,
  },
];

const copyLinkMenu = [
  {
    key: 'copyLink',
    name: i18n.t('documentHub.actions.copyLink'),
    icon: FileCopyIcon,
  },
];

const deleteMenu = [
  {
    key: 'delete',
    name: i18n.t('documentHub.actions.delete'),
    icon: DeleteIcon,
  },
];

const adminMenu = [
  ...createNoteMenu,
  ...normalMenu,
  ...reviewMenu,
  ...copyLinkMenu,
  ...deleteMenu,
];

export const MoreActionsCell = ({
  accountId = '',
  document,
  isFavourite = false,
  isAdmin = false,
  onReviewFile = () => null,
}: IMoreActionsCell) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [visibleDialog, setVisibleDialog] = useState<
    'addNote' | 'editName' | 'deleteFile' | null
  >(null);
  const showFavouriteMenu = useFeatureFlag(DOCUMENT_HUB_FAVOURITE_FLAG);
  const showNoteColumn = useFeatureFlag(DOCUMENT_HUB_NOTE_FLAG);
  const { t } = useTranslation();

  const menuList = useMemo(() => {
    if (isAdmin) {
      return adminMenu;
    }
    const favouriteMenuList = isFavourite ? removeFavouriteMenu : favouriteMenu;
    return [
      ...(showNoteColumn ? createNoteMenu : []),
      ...normalMenu,
      ...(showFavouriteMenu ? favouriteMenuList : []),
      ...reviewMenu,
    ];
  }, [isFavourite, showNoteColumn, showFavouriteMenu, isAdmin]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowMenu(true);
  };

  const handleClose = () => {
    setShowMenu(false);
  };

  const closeDialog = () => {
    setVisibleDialog(null);
    handleClose();
  };

  const onDownload = async () => {
    const blob = await downloadDocument(document.id);
    downloadFileBlob(blob, document.title);
    sendChurnZeroEvent(CHURN_ZERO_EVENT_NAMES.DOCUMENT_DOWNLOAD);
    setAnchorEl(null);
  };

  const deeplinkUrl = useMemo(
    () =>
      `${window.location.origin}${Routes.DOCUMENT_PREVIEW}?accountId=${accountId}&fileId=${document.id}`,
    [document, accountId],
  );

  const onCopyLinkClick = async () => {
    try {
      await navigator.clipboard.writeText(deeplinkUrl);
      showSuccessToast(t('documentHub.preview.copySuccessToast'));
    } catch (e) {
      if (e instanceof Error) {
        showErrorToast(e.message);
      } else {
        showErrorToast(String(e));
      }
    }
  };

  const handleButtonClick = async (key: string) => {
    switch (key) {
      case 'addNote':
        setVisibleDialog('addNote');
        break;
      case 'renameFile':
        setVisibleDialog('editName');
        break;
      case 'reviewFile':
        onReviewFile(document);
        break;
      case 'download':
        await onDownload();
        break;
      case 'copyLink':
        await onCopyLinkClick();
        break;
      case 'delete':
        setVisibleDialog('deleteFile');
        break;
      default:
        break;
    }
    handleClose();
  };

  return (
    <>
      <FormationsGhostButton
        rounded
        data-testid="document-actions-cell"
        onClick={handleClick}
      >
        <MoreVertIcon data-testid='MoreVertIcon' />
      </FormationsGhostButton>
      <ActionsMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={handleClose}
        open={showMenu}
      >
        {menuList.map((item: IMenuItem) =>
          item.hidden ? null : (
            <MenuItem
              sx={{
                display: 'flex',
                px: 1,
                minWidth: 175,
              }}
              key={item.name}
              data-testkey="menu-item"
              onClick={() => handleButtonClick(item.key)}
            >
              {item.icon ? (
                <item.icon
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  className="actionIcon"
                />
              ) : null}
              <IconTitle className="actionText" variant="body3S" ml={1}>
                {item.name}
              </IconTitle>
            </MenuItem>
          ),
        )}
      </ActionsMenu>
      {visibleDialog === 'addNote' && (
        <EditNoteDialog
          anchorEl={anchorEl}
          documentId={document.id}
          documentAccountId={document.accountId}
          unreadNote={isAdmin ? document.adminUnread : document.customerUnread}
          open
          onClose={closeDialog}
        />
      )}
      {visibleDialog === 'deleteFile' && (
        <DeleteFileDialog
          anchorEl={anchorEl}
          document={document}
          open
          onClose={closeDialog}
        />
      )}
      {visibleDialog === 'editName' && (
        <EditNameDialog
          document={document}
          anchorEl={anchorEl}
          open
          onClose={closeDialog}
        />
      )}
    </>
  );
};
