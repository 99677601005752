import {
  Typography,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Paper,
  TablePagination,
  Grid,
  Box,
} from '@mui/material';
import { FormationsTable } from 'components/common/FormationsTable';
import { useCurrentUserRole } from 'hooks/api';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { EmptyState } from 'components/common/EmptyState';
import { queryClient } from 'states/reactQueryClient';
import {
  useGetDirectPayments,
  useUpdateDirectPayment,
  useTaxesQuery,
} from 'hooks/api/useTaxes';
import { Loading } from 'components/common';
import { showErrorToast, showSuccessToast } from 'components/toast/showToast';
import { FormationsFormDialog } from 'components/common/FormationsFormDialog';
import {
  FormationsFormFields,
  FormationsFormData,
} from 'components/common/FormationsForm2';
import {
  NOTE_MAX_LENGTH,
  defaultTablePageSize,
  defaultTableRowsPerPageOptions,
} from 'constants/common';
import * as yup from 'yup';
import { DirectPayment } from 'components/taxes/types';
import { YesNoModal } from 'components/common/modals';
import { DateFilterCell } from 'components/taxes/filters/DateFilterCell';
import { TypeAndSourceFilterCell } from 'components/taxes/filters/TypeAndSourceFilterCell';
import { YearFilterCell } from 'components/taxes/filters/YearFilterCell';
import { PaymentStatus } from 'enums';
import { LoadFilePreviewDialog } from './LoadFilePreviewDialog';
import { ActionsCell } from './ActionsCell';
import {
  StatusCell,
  DateCell,
  TypeCell,
  YearCell,
  AmountCell,
  ApproveCell,
  AdminStatusCell,
  StatusUpdatedCell,
  StatusFilterCell,
} from './TaxesTableCells';

export type CellColumnProps = {
  row: DirectPayment;
  accountId: string | undefined;
  onViewClick: (row: DirectPayment) => void;
  onApproveClick: (row: DirectPayment) => void;
  onRejectClick: (row: DirectPayment) => void;
};

const TaxesTableContainer = styled('div')(() => ({
  flex: '1 1 auto',
  display: 'flex',
  flexFlow: 'column',
  minHeight: 100,
  width: '100%',
}));

const TaxesTableRow = styled(TableRow)(() => ({
  '&:hover': {
    cursor: 'pointer',
  },
}));

const alignHeader = {
  Approve: 'center' as const,
  Amount: 'flex-end' as const,
  Status: 'center' as const,
  Actions: 'center' as const,
};

const headerFilterCells = {
  'Tax Year': <YearFilterCell />,
  Type: <TypeAndSourceFilterCell />,
  Status: <StatusFilterCell />,
  'Date Payment Made': <DateFilterCell />,
};

const rowCellsCustomer = {
  Status: StatusCell,
  'Tax Year': YearCell,
  'Date Payment Made': DateCell,
  Type: TypeCell,
  Amount: AmountCell,
  Actions: ActionsCell,
};

const headersCustomer = [
  'Status',
  'Tax Year',
  'Date Payment Made',
  'Type',
  'Amount',
  'Actions',
];

const rowCellsAdmin = {
  Approve: ApproveCell,
  Status: AdminStatusCell,
  'Tax Year': YearCell,
  'Status Updated On': StatusUpdatedCell,
  'Date Payment Made': DateCell,
  Type: TypeCell,
  Amount: AmountCell,
  Actions: ActionsCell,
};

const headersAdmin = [
  'Approve',
  'Status',
  'Tax Year',
  'Status Updated On',
  'Date Payment Made',
  'Type',
  'Amount',
  'Actions',
];

const EmptyTable = ({ headers }: { headers: string[] }) => (
  <TableRow data-testid="document-empty-table">
    <TableCell sx={{ borderBottom: 'none' }} colSpan={headers.length}>
      <EmptyState message="No payment information available!" />
    </TableCell>
  </TableRow>
);

type Props = {
  accountId: string | undefined;
  isLoadingUser?: boolean;
  setPaymentToEdit?: Function;
};

export const TaxesDirectPaymentTable = ({
  accountId,
  isLoadingUser = false,
  setPaymentToEdit = () => {},
}: Props) => {
  const { t } = useTranslation();
  const { isAdmin } = useCurrentUserRole();
  // pagination and filter state
  const [query, setQuery] = useTaxesQuery();
  // file preview dialog state
  const [selectedDocumentId, setSelectedDocumentId] = useState('');
  // admin add note and approve/reject payment state
  const [paymentToChangeStatus, setPaymentToChangeStatus] =
    useState<DirectPayment | null>(null);
  const [showAddNoteDialog, setShowAddNoteDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const {
    data: directPaymentGetData,
    isLoading: isLoadingData,
    isError: isGetError,
  } = useGetDirectPayments(accountId, query);
  const { data: directPaymentTableData, pageInfo } = directPaymentGetData ?? {};

  const { mutateAsync: updateDirectPayment, isLoading: updatingDirectPayment } =
    useUpdateDirectPayment({
      onSuccess: async () => {
        await queryClient.invalidateQueries(['direct-payments']);
        await queryClient.invalidateQueries(['account', accountId]);
        setPaymentToChangeStatus(null);
        setShowConfirmationDialog(false);
        setShowAddNoteDialog(false);
        showSuccessToast('Successfully updated direct payment status');
      },
      onError: () => {
        showErrorToast(`Failed to update direct payment status`);
      },
    });

  useEffect(() => {
    if (isGetError) {
      showErrorToast('Failed to load direct payment information');
    }
  }, [isGetError]);

  const onApproveClick = (tableRow: DirectPayment) => {
    setPaymentToChangeStatus(tableRow);
    setShowConfirmationDialog(true);
  };

  const onYesClick = () => {
    const mutateVariables = {
      data: { status: PaymentStatus.Approved },
      accountId: accountId ?? '',
      paymentId: paymentToChangeStatus!.id,
    };
    return updateDirectPayment(mutateVariables);
  };

  const onRejectClick = (tableRow: DirectPayment) => {
    setPaymentToChangeStatus(tableRow);
    setShowAddNoteDialog(true);
  };

  const onNoteSubmit = (data: FormationsFormData) => {
    const mutateVariables = {
      data: { status: PaymentStatus.Rejected, note: data.note },
      accountId: accountId ?? '',
      paymentId: paymentToChangeStatus!.id,
    };
    return updateDirectPayment(mutateVariables);
  };

  const onViewClick = (tableRow: DirectPayment) => {
    const { documentId } = tableRow;
    setSelectedDocumentId(documentId);
  };

  const handleTablePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setQuery({ page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setQuery({ size: parseInt(event.target.value, 10), page: 1 });
  };

  const onTableRowClick = (tableRow: DirectPayment) => {
    setPaymentToEdit(tableRow);
  };

  const headers = isAdmin ? headersAdmin : headersCustomer;
  const rowCells = isAdmin ? rowCellsAdmin : rowCellsCustomer;

  if (isLoadingUser || isLoadingData) {
    return <Loading />;
  }

  return (
    <TaxesTableContainer>
      <TableContainer
        component={Paper}
        sx={{ width: '100%', overflow: 'auto' }}
      >
        <FormationsTable
          stickyHeader
          aria-label="tax-table"
          data-testid="table-taxes"
          id="taxes-table"
          sx={{
            minWidth: '320px',
          }}
        >
          {/* Table Header Row */}
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell
                  key={header}
                  data-testid={`taxes-header-cell-${header.replaceAll(
                    ' ',
                    '',
                  )}`}
                >
                  <Grid
                    container
                    gap={1}
                    alignItems="center"
                    justifyContent={
                      alignHeader[header as keyof typeof alignHeader] ??
                      'flex-start'
                    }
                    wrap="nowrap"
                  >
                    <Box sx={{ width: 'max-content' }}>
                      {header}
                    </Box>
                    <Box>
                      {headerFilterCells[
                        header as keyof typeof headerFilterCells
                      ] ?? null}
                    </Box>
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* Table Body Rows */}
          <TableBody>
            {directPaymentTableData?.length ? (
              directPaymentTableData?.map((row, index) => (
                <TaxesTableRow
                  key={row.id}
                  hover
                  data-testid={`table-row-taxes-${index}`}
                  onClick={() => onTableRowClick(row)}
                >
                  {headers.map((header) => {
                    const CellColumn = rowCells[
                      header as keyof (
                        | typeof rowCellsAdmin
                        | typeof rowCellsCustomer
                      )
                    ] as React.FC<CellColumnProps>;
                    return (
                      <CellColumn
                        key={header}
                        accountId={accountId}
                        row={row}
                        onViewClick={onViewClick}
                        onApproveClick={onApproveClick}
                        onRejectClick={onRejectClick}
                      />
                    );
                  })}
                </TaxesTableRow>
              ))
            ) : (
              <EmptyTable headers={headers} />
            )}
          </TableBody>
        </FormationsTable>
      </TableContainer>
      {/* Table Pagination */}
      {directPaymentTableData?.length ? (
        <TablePagination
          component="div"
          color="primary"
          sx={{
            mt: 3,
            mb: 4,
            '.MuiTablePagination-select': {
              border: '1px solid',
              borderColor: 'others.stroke',
              borderRadius: '4px',
              pr: 4,
            },
          }}
          page={query?.page ? query.page - 1 : 0}
          count={pageInfo!.totalCount}
          onPageChange={handleTablePageChange}
          rowsPerPage={query?.size ?? defaultTablePageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={defaultTableRowsPerPageOptions}
        />
      ) : null}
      {/* File Preview Dialog */}
      {selectedDocumentId && (
        <LoadFilePreviewDialog
          documentId={selectedDocumentId}
          setSelectedDocumentId={setSelectedDocumentId}
          accountId={accountId}
        />
      )}
      {/* Payment Rejection Add Note Dialog */}
      <FormationsFormDialog
        title={t('taxes.noteDialog.title')}
        isOpen={showAddNoteDialog}
        onClose={() => {
          setPaymentToChangeStatus(null);
          setShowAddNoteDialog(false);
        }}
        fields={[
          {
            type: FormationsFormFields.TextArea,
            name: 'note',
            label: 'Note',
            options: {
              placeholder: t('taxes.noteDialog.placeHolderText'),
              characterLimit: NOTE_MAX_LENGTH,
              autoFocus: true,
            },
          },
        ]}
        validationSchema={yup.object().shape({
          note: yup
            .string()
            .trim()
            .required('Please enter a note')
            .max(
              NOTE_MAX_LENGTH,
              `Please use less than ${NOTE_MAX_LENGTH} characters`,
            ),
        })}
        onSubmit={onNoteSubmit}
        loading={updatingDirectPayment}
      />
      {/* Confirmation Dialog */}
      <YesNoModal
        open={showConfirmationDialog}
        heading={t('taxes.confirmation.title')}
        onClose={() => {
          setPaymentToChangeStatus(null);
          setShowConfirmationDialog(false);
        }}
        onSave={onYesClick}
        isLoading={updatingDirectPayment}
      >
        <Typography variant="body1">{t('taxes.confirmationText')}</Typography>
      </YesNoModal>
    </TaxesTableContainer>
  );
};
