import React from 'react';
import { IAccount } from 'models/account';

export interface MenuListItem {
  name: string;
  icon?: React.FC;
  clickHandler?: () => void;
  hidden?: boolean;
  disabled?: boolean;
  navigation?: {
    href: string;
  };
  disabledForStatus?: string[];
  hiddenForStatus?: string[];
  shouldDisable?: (acc?: IAccount) => boolean;
}

export const disabledForStatuses = (statuses: string[]) => (acc?: IAccount) =>
  statuses.includes(acc?.status?.label ?? '');
