import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions, Grid, Popover } from '@mui/material';
import {
  FormationsPrimaryButton,
  FormationsGhostButton,
} from 'components/common/Buttons';
import { useTranslation } from 'react-i18next';
import { showErrorToast } from 'components/toast/showToast';
import { queryClient } from 'states/reactQueryClient';
import { useDeleteDocument } from 'hooks/api';
import { useEffect, useMemo } from 'react';
import { FormationsTableDocument } from 'components/common/tables/DocumentsTable';
import { ActionPermissions, usePermissions } from 'hooks/usePermissions';
import { CONSTANTS } from 'constants/common';
import { AlertSuccess, AlertWarning } from 'components/FormationsAlert';

interface IDeleteFileDialog {
  anchorEl: Element | null;
  document: FormationsTableDocument;
  open: boolean;
  onClose: () => void;
}

export const DeleteFileDialog = ({
  anchorEl,
  document,
  open,
  onClose,
}: IDeleteFileDialog) => {
  const { t } = useTranslation();
  const overrideDelete = usePermissions(ActionPermissions.DOCUMENT_DELETE);

  // only allow admins to delete documents that are uploaded by admins and without note attached
  // superadmins have the permission to override and delete any file anytime
  const allowDelete = useMemo(
    () => (document.role !== CONSTANTS.USER_ROLES.CUSTOMER && !document.hasNotes) || overrideDelete,
    [document.role, document.hasNotes, overrideDelete],
  );

  const {
    mutateAsync: deleteDocument,
    isLoading: isDocumentDeleting,
    isSuccess,
  } = useDeleteDocument();

  const handleDeleteFile = async () => {
    try {
      await deleteDocument(document.id);
    } catch (e: unknown) {
      if (e instanceof Error) {
        showErrorToast(e.message);
      } else {
        showErrorToast('An error occurred while deleting file.');
      }
    }
  };
  useEffect(
    () => () => {
      if (isSuccess) {
        // only refresh file table if delete is successful and when we close the dialog
        queryClient.invalidateQueries(['documents']);
      }
    },
    [isSuccess],
  );

  const body = useMemo(() => {
    if (isSuccess) {
      return <AlertSuccess text={t('documentHub.delete.success')} />;
    }
    if (!allowDelete) {
      if (document.role === CONSTANTS.USER_ROLES.CUSTOMER) {
        return <AlertWarning text={t('documentHub.delete.deleteCustomerFileReject')} />;
      }
      if (document.hasNotes) {
        return <AlertWarning text={t('documentHub.delete.deleteDocumentWithNoteReject')} />;
      }
    }
    return <AlertWarning text={t('documentHub.delete.warning')} />;
  }, [isSuccess, allowDelete, t, document.role, document.hasNotes]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      onClose={onClose}
      open={open}
    >
      <DialogTitle sx={{ p: 3 }}>{t('documentHub.delete.title')}</DialogTitle>
      <DialogContent sx={{ p: 0, mx: 3, my: 2, width: 384 }}>
        {body}
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Grid container justifyContent="flex-end" gap={1}>
          {isSuccess || !allowDelete ? (
            <FormationsPrimaryButton
              size="large"
              onClick={onClose}
              data-testid="form-cancel-btn"
            >
              {t('actions.close')}
            </FormationsPrimaryButton>
          ) : (
            <>
              <FormationsGhostButton
                size="large"
                onClick={onClose}
                data-testid="form-cancel-btn"
                disabled={isDocumentDeleting}
              >
                {t('actions.cancel')}
              </FormationsGhostButton>
              <FormationsPrimaryButton
                isLoading={isDocumentDeleting}
                size="large"
                onClick={handleDeleteFile}
                data-testid="form-delete-btn"
              >
                {t('documentHub.delete.confirm')}
              </FormationsPrimaryButton>
            </>
          )}
        </Grid>
      </DialogActions>
    </Popover>
  );
};
