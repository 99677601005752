import { FormationsPlan, IAccount, PlanNames } from 'models/account';
import { Routes } from 'fnRoutes';
import { CONSTANTS } from 'constants/common';
import { Company } from 'models/company';
import { UserInfo } from 'services/users';

export const pathOrder = (pathname: string) => {
  switch (pathname) {
    case Routes.LOGIN:
    case Routes.LOGIN1120S:
    case Routes.LOGINBIB:
    case Routes.LOGOUT:
    case Routes.POST_LOGIN:
    case Routes.POST_SIGNUP:
    case Routes.POST_LOGIN_1120S:
    case Routes.POST_SIGNUP_1120S:
    case Routes.POST_LOGIN_BIB:
    case Routes.POST_SIGNUP_BIB:
    case Routes.FIRST_EXPERIENCE_STEP_1:
    case Routes.FIRST_EXPERIENCE_STEP_2:
    case Routes.FIRST_EXPERIENCE_STEP_3:
    case Routes.FIRST_EXPERIENCE_STEP_4:
    case Routes.FIRST_EXPERIENCE_STEP_5:
    case Routes.FIRST_EXPERIENCE_PLAN_SELECTION:
      return 0;
    case Routes.TERMS_CONDITIONS:
      return 5;
    case Routes.ENTITY_SELECTION:
      return 10;
    case Routes.INCORPORATION_STATUS:
      return 15;
    case Routes.CHURN_ZERO_AUTHENTICATION:
      return 20;
    case Routes.PRICING_TABLE:
    case Routes.PROGRESS_TRACKER:
      return 25;
    default:
      return 30;
  }
};

interface Options {
  currentPath: string;
  account: IAccount;
  user: UserInfo;
  company: Company | undefined;
  businessType: string;
  isAdminSpoof: boolean;
  alreadyPaid: boolean;
}
export class Redirection {
  path: string = '';

  account: IAccount;

  user: UserInfo;

  company: Company | undefined;

  businessType: string;

  isAdminSpoof: boolean;

  alreadyPaid: boolean;

  constructor(
    currentPath: string,
    account: IAccount,
    user: UserInfo,
    company: Company | undefined,
    businessType: string = '',
    isAdminSpoof: boolean = false,
    alreadyPaid: boolean = false,
  ) {
    this.path = currentPath;
    this.account = account;
    this.user = user;
    this.company = company;
    this.businessType = businessType;
    this.isAdminSpoof = isAdminSpoof;
    this.alreadyPaid = alreadyPaid;
  }

  apply(func: (options: Options) => string): this {
    this.path = func({
      currentPath: this.path,
      user: this.user,
      account: this.account,
      company: this.company,
      businessType: this.businessType,
      isAdminSpoof: this.isAdminSpoof,
      alreadyPaid: this.alreadyPaid,
    });
    return this;
  }

  getPath(): string {
    return this.path;
  }
}

export const wrongHomeRedirect = ({
  currentPath,
  user,
  isAdminSpoof,
}: Options): string => {
  if (
    user.role === CONSTANTS.USER_ROLES.CUSTOMER &&
    currentPath === Routes.ADMIN_HOME
  ) {
    return Routes.CUSTOMER_HOME;
  }
  if (
    user.role !== CONSTANTS.USER_ROLES.CUSTOMER &&
    !isAdminSpoof &&
    currentPath === Routes.CUSTOMER_HOME
  ) {
    return Routes.ADMIN_HOME;
  }
  return currentPath;
};

export const progressTrackerRedirect = ({
  currentPath,
  user,
  account,
}: Options): string => {
  if (
    user.role === CONSTANTS.USER_ROLES.CUSTOMER &&
    pathOrder(currentPath) > pathOrder(Routes.PROGRESS_TRACKER) &&
    (account?.status?.label == null || account?.status?.label === 'NEW')
  ) {
    // allow progress tracker user access document and my account page
    if (currentPath === Routes.DOCUMENTS || currentPath === Routes.MY_ACCOUNT) {
      return currentPath;
    }
    // Todo:- Remove after new onboarding flow enabled for customers
    if (currentPath === Routes.ACCOUNT_SETTING) {
      return currentPath;
    }
    return Routes.PROGRESS_TRACKER;
  }

  return currentPath;
};

export const taxOnlyCustomerRedirect = ({
  currentPath,
  account,
}: Options): string => {
  const disabledPaths = [
    Routes.TRANSACTIONS,
    Routes.ACCOUNTING_ADD_TRANSACTION,
    Routes.ACCOUNTING_ALL_TRANSACTIONS,
    Routes.ACCOUNTING_UNKNOWN_TRANSACTIONS,
    Routes.TAX_LIABILITY_FORM,
    Routes.PAYROLL,
    Routes.PAYROLL_SETTINGS,
    Routes.PAYROLL_ONBOARDING,
    Routes.TAXES,
  ];
  if (
    account.formationsPlan === FormationsPlan.TaxOnly &&
    disabledPaths.includes(currentPath)
  ) {
    return Routes.CUSTOMER_HOME;
  }
  return currentPath;
};

export const entitySelectRedirect = ({
  currentPath,
  user,
  company,
  businessType,
}: Options): string => {
  if (
    user.role === CONSTANTS.USER_ROLES.CUSTOMER &&
    pathOrder(currentPath) > pathOrder(Routes.ENTITY_SELECTION) &&
    company?.entityType == null &&
    businessType !== 'incorporated'
  ) {
    return Routes.ENTITY_SELECTION;
  }
  return currentPath;
};

export const incorporationStatusRedirect = ({
  currentPath,
  user,
  company,
  businessType,
}: Options): string => {
  if (
    user.role === CONSTANTS.USER_ROLES.CUSTOMER &&
    pathOrder(currentPath) > pathOrder(Routes.INCORPORATION_STATUS) &&
    company?.entityType == null &&
    businessType === 'incorporated'
  ) {
    return Routes.INCORPORATION_STATUS;
  }
  return currentPath;
};

export const termNConditionRedirect = ({
  currentPath,
  user,
}: Options): string => {
  const accepted =
    user.lastAcceptedVersion != null &&
    new Date(user.lastAcceptedVersion).getTime() >=
      new Date(CONSTANTS.TERM_AND_CONDITION_VERSION).getTime();

  if (
    user.role !== CONSTANTS.USER_ROLES.CUSTOMER &&
    currentPath === Routes.TERMS_CONDITIONS
  ) {
    return Routes.ADMIN_HOME;
  }
  if (user.role === CONSTANTS.USER_ROLES.CUSTOMER) {
    if (accepted && currentPath === Routes.TERMS_CONDITIONS) {
      return Routes.CUSTOMER_HOME;
    }
    if (
      !accepted &&
      pathOrder(currentPath) > pathOrder(Routes.TERMS_CONDITIONS)
    ) {
      return Routes.TERMS_CONDITIONS;
    }
  }

  return currentPath;
};

export const getRedirectRouteBaseStatus = (
  isWithinFirstExperiencePath: boolean,
  plan?: FormationsPlan,
  name?: string,
  entityType?: string,
  taxFilingStatus?:
    | 'SINGLE'
    | 'MARRIED_FILING_JOINTLY'
    | 'MARRIED_FILING_SEPARATELY'
    | 'HEAD_OF_HOUSEHOLD'
    | 'WIDOW_WITH_DEPENDENT_CHILD',
  acceptedLatestTerm?: boolean,
  isActive?: boolean,
  paidAlready?: boolean,
  recommendedPlan?: PlanNames,
  planSelection?: PlanNames,
): string | undefined => {
  if (isActive) {
    return undefined;
  }
  switch (plan) {
    case FormationsPlan.FullSuite:
      if (isWithinFirstExperiencePath) {
        return undefined;
      }
      if (!name) {
        return Routes.FIRST_EXPERIENCE_STEP_1;
      }
      if (!entityType) {
        return Routes.FIRST_EXPERIENCE_STEP_2;
      }
      if (!taxFilingStatus) {
        return Routes.FIRST_EXPERIENCE_STEP_3;
      }
      if (
        (paidAlready && !acceptedLatestTerm) ||
        (!paidAlready && !recommendedPlan)
      ) {
        return Routes.FIRST_EXPERIENCE_STEP_4;
      }
      if (
        !paidAlready &&
        !acceptedLatestTerm &&
        recommendedPlan &&
        !planSelection
      ) {
        return Routes.FIRST_EXPERIENCE_STEP_5;
      }
      if (
        !paidAlready &&
        acceptedLatestTerm &&
        recommendedPlan &&
        !planSelection
      ) {
        return Routes.FIRST_EXPERIENCE_PLAN_SELECTION;
      }
      return undefined;
    case FormationsPlan.BIB:
      if (isWithinFirstExperiencePath) {
        return undefined;
      }
      if (!name) {
        return Routes.FIRST_EXPERIENCE_STEP_1;
      }
      if (!entityType) {
        return Routes.FIRST_EXPERIENCE_STEP_2;
      }
      if (!taxFilingStatus) {
        return Routes.FIRST_EXPERIENCE_STEP_3;
      }
      if (!acceptedLatestTerm) {
        return Routes.FIRST_EXPERIENCE_STEP_4;
      }
      return undefined;
    case FormationsPlan.TaxOnly:
      if (isWithinFirstExperiencePath) {
        return undefined;
      }
      if (!name || !entityType) {
        return Routes.FIRST_EXPERIENCE_STEP_1;
      }
      if (!taxFilingStatus) {
        return Routes.FIRST_EXPERIENCE_STEP_2;
      }
      if (!acceptedLatestTerm) {
        return Routes.FIRST_EXPERIENCE_STEP_3;
      }
      return undefined;
    default:
      return undefined;
  }
};

export const firstExperienceRedirect = ({
  account,
  currentPath,
  user,
  company,
  alreadyPaid,
}: Options): string => {
  const accepted =
    user.lastAcceptedVersion != null &&
    new Date(user.lastAcceptedVersion).getTime() >=
      new Date(CONSTANTS.TERM_AND_CONDITION_VERSION).getTime();
  if (
    user.role !== CONSTANTS.USER_ROLES.CUSTOMER &&
    currentPath === Routes.TERMS_CONDITIONS
  ) {
    return Routes.ADMIN_HOME;
  }

  if (user.role === CONSTANTS.USER_ROLES.CUSTOMER) {
    const isWithinFirstExperiencePath =
      currentPath === Routes.FIRST_EXPERIENCE_STEP_1 ||
      currentPath === Routes.FIRST_EXPERIENCE_STEP_2 ||
      currentPath === Routes.FIRST_EXPERIENCE_STEP_3 ||
      currentPath === Routes.FIRST_EXPERIENCE_STEP_4 ||
      currentPath === Routes.FIRST_EXPERIENCE_STEP_5 ||
      currentPath === Routes.FIRST_EXPERIENCE_PLAN_SELECTION;

    const redirectRoute = getRedirectRouteBaseStatus(
      isWithinFirstExperiencePath,
      account?.formationsPlan,
      user.name?.first ?? user.name?.last,
      company?.entityType,
      account?.generalTaxInfo?.taxFilingStatus,
      !!user.lastAcceptedVersion,
      account.status.label === 'ACTIVE',
      alreadyPaid,
      account?.planRecommendation?.recommendedPlan,
      account?.planSelection,
    );
    if (redirectRoute) {
      return redirectRoute;
    }
    if (accepted && currentPath === Routes.TERMS_CONDITIONS) {
      return Routes.CUSTOMER_HOME;
    }
    // if renewed terms, redirect Terms
    if (
      !accepted &&
      pathOrder(currentPath) > pathOrder(Routes.TERMS_CONDITIONS)
    ) {
      return Routes.TERMS_CONDITIONS;
    }
  }
  return currentPath;
};

const staffRoles: string[] = [
  CONSTANTS.USER_ROLES.ADMIN,
  CONSTANTS.USER_ROLES.SUPER_ADMIN,
  CONSTANTS.USER_ROLES.SUCCESS,
  CONSTANTS.USER_ROLES.FULFILLMENT,
  CONSTANTS.USER_ROLES.CUSTOMER_SERVICE,
];

export const isRoleNotAllowed = (
  allowedRoles: string[],
  role: string,
  currentPath: string,
) => {
  const hasAny = allowedRoles.includes('any');
  const isAllowed = allowedRoles.includes(role);
  const isStaffAllowed =
    allowedRoles.includes('staff') && staffRoles.includes(role);
  const homeUrl =
    role === CONSTANTS.USER_ROLES.CUSTOMER
      ? Routes.CUSTOMER_HOME
      : Routes.ADMIN_HOME;
  if (hasAny || isAllowed || isStaffAllowed) {
    return false;
  }
  if (homeUrl === currentPath) {
    return false;
  }
  return true;
};
