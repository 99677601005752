import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormationsPrimaryButton } from 'components/common/Buttons';
import { queryClient } from 'states/reactQueryClient';
import useLoading from 'hooks/useLoading';
import {
  useCreateDocument,
  useDocumentsByAccount,
  useUpdateDocument,
  useDeleteDocument,
  UpdateDocumentVariables,
  useDocumentQuery,
} from 'hooks/api/useDocuments';
import { useDocumentsTableData } from 'hooks/dataFormatters';
import { YesNoModal } from 'components/common/modals';
import {
  DocumentsTable as DocumentsTableV1,
  Uploader,
} from 'components/common';
import {
  showInfoToast,
  showSuccessToast,
  showErrorToast,
} from 'components/toast/showToast';
import { FilePreviewDialog } from 'components/FilePreview';
import {
  DocumentKey,
  FormationsTableDocument,
} from 'components/common/tables/DocumentsTable';
import { FormationsDocument } from 'services/documentTypes';
import { EditDocumentDialog } from 'components/documentsV1/EditDocumentDialog';
import { AdminUploadDialog } from 'components/documentsV1/AdminUploadDialog';
import { IFormationsPreviewDocument } from 'components/FilePreview/FilePreviewComponent/FilePreviewComponent';
import {
  castFTDToFormationsPreviewDocument,
  castListOfFormationTableDocument,
} from 'helpers/documents';
import { DocumentsTable } from 'components/documentsV2';
import { getErrorMessage } from 'helpers/error';
import { useCurrentUserRole } from 'hooks/api';
import { CHURN_ZERO_EVENT_NAMES } from 'constants/common';
import { sendChurnZeroEvent } from 'helpers/churnZero';
import { VisibilityPopup } from './VisibilityPopup';

interface IHeaders {
  title: string;
  key: DocumentKey;
}

const headers: Array<IHeaders> = [
  { title: 'Name', key: 'title' },
  { title: 'Year', key: 'forYear' },
  { title: 'Department', key: 'department' },
  { title: 'Category', key: 'category' },
  { title: 'Subcategory', key: 'subcategory' },
  { title: 'Uploaded', key: 'uploaded' },
];
const adminHeaders: Array<IHeaders> = [
  ...headers,
  { title: 'Visibility', key: 'visibleToCustomer' },
  { title: 'Notes', key: 'hasNotes' },
  { title: '', key: 'actions' },
];

export const Documents = ({
  companyId,
  accountId,
}: {
  companyId: string;
  accountId: string;
}) => {
  const [query, setQuery] = useDocumentQuery();

  const [deleteFiles, setDeleteFiles] = useState<string[]>([]);
  const [visibility, setVisibility] = useState({ id: '', status: false });
  const [isAdminUploadModalOpen, setIsAdminUploadModalOpen] = useState(false);
  const [isVisibilityModalOpen, setIsVisibilityModalOpen] = useState(false);
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [deleteModalText, setDeleteModalText] = useState('Are you sure?');

  const [selectedFile, setSelectedFile] =
    useState<IFormationsPreviewDocument>();
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const handleClosePreviewDialog = () => setSelectedFile(undefined);
  const [editedDocuments, setEditedDocuments] = useState<FormationsDocument[]>(
    [],
  );

  useEffect(() => {
    if (selectedFile) {
      setIsPreviewDialogOpen(true);
    } else {
      setIsPreviewDialogOpen(false);
    }
  }, [selectedFile]);

  const {
    documents,
    pageInfo: { totalCount },
    isLoading: isLoadingDocuments,
  } = useDocumentsByAccount(
    accountId,
    {
      page: query.page,
      size: query.size,
      keyword: query.keyword,
    },
    { enabled: !!accountId },
  );

  const { mutateAsync: createDocument, isLoading: isDocumentCreating } =
    useCreateDocument({
      onMutate: () => {
        showInfoToast('Uploading document');
      },
      onSuccess: () => {
        showSuccessToast('Uploading document successfully');
        sendChurnZeroEvent(CHURN_ZERO_EVENT_NAMES.DOCUMENT_UPLOAD);
        queryClient.invalidateQueries(['documents', 'accountId', accountId]);
      },
      onError: () => {
        showErrorToast(
          `Unable to upload document, request timed out. Please check your internet connection and try again.`,
        );
      },
    });

  const { mutateAsync: updateDocument, isLoading: isDocumentUpdating } =
    useUpdateDocument({
      onMutate: () => {
        showInfoToast('Updating document');
      },
      onSuccess: () => {
        showSuccessToast('Document updated successfully');
        queryClient.invalidateQueries(['documents', 'accountId', accountId]);
      },
      onError: (err) => {
        showErrorToast(`Unable to update document, ${getErrorMessage(err)}`);
      },
    });

  const { mutateAsync: deleteDocument, isLoading: isDocumentDeleting } =
    useDeleteDocument({
      onMutate: () => {
        showInfoToast('Deleting document');
      },
      onSuccess: () => {
        showSuccessToast('Document deleted successfully');
        queryClient.invalidateQueries(['documents', 'accountId', accountId]);
      },
      onError: (err) => {
        showErrorToast(`Unable to delete document, ${getErrorMessage(err)}`);
      },
    });

  const loading = useLoading(
    isLoading ||
      isLoadingDocuments ||
      isDocumentCreating ||
      isDocumentUpdating ||
      isDocumentDeleting,
  );

  const data = useDocumentsTableData(documents);

  const numberOfPages = Math.ceil(totalCount / query.size);
  const { isAdmin } = useCurrentUserRole();

  const onRecategorize = (id: string) => {
    const docs = documents.find((item) => item.id === id);
    if (docs) {
      setEditedDocuments([docs]);
    }
  };

  const onDelete = (ids: string[]) => {
    let filteredFiles = ids;
    const adminFiles = [];
    if (!isAdmin) {
      filteredFiles = [];
      ids.forEach((id) => {
        const file = data.find((item) => item.id === id);
        if (file?.role !== 'Admin') {
          filteredFiles.push(id);
        } else {
          adminFiles.push(id);
        }
      });
      if (adminFiles.length > 0) {
        setDeleteModalText(
          `You have selected a file added by the Admin, which cannot be deleted. Click delete to proceed deleting ${filteredFiles.length} files selected, this action can not be undone.`,
        );
      } else {
        setDeleteModalText(
          `Click delete to proceed deleting ${filteredFiles.length} files selected, this action can not be undone.`,
        );
      }
    }

    setIsDeleteModelOpen(true);
    setDeleteFiles(filteredFiles);
  };

  const openAdminPopup = () => {
    setIsAdminUploadModalOpen(true);
  };

  const closeAdminPopup = () => {
    setIsAdminUploadModalOpen(false);
  };

  const closeVisibilityPopup = () => {
    setIsVisibilityModalOpen(false);
  };

  const handleDelete = () => {
    deleteFiles.forEach((id) => {
      deleteDocument(id)
        .then(() => setSelected([]))
        .catch(() => {
          console.error('Error in Deleting File Id:', id);
        });
    });
    setIsDeleteModelOpen(false);
    setDeleteFiles([]);
  };

  const closeDeleteModal = () => {
    setIsDeleteModelOpen(false);
    setDeleteFiles([]);
  };

  const onCustomerUpload = (targetFiles: File[]) =>
    Promise.all(
      targetFiles.map((file: File) =>
        createDocument({
          form: {
            accountId,
            file,
            title: file.name,
            year: new Date().getFullYear(),
            companyId,
          },
        }),
      ),
    );

  const onUpload = (targetFiles: File[]) => onCustomerUpload(targetFiles);

  const onVisibilityModalOpen = async (id: string, status: boolean) => {
    setVisibility({ id, status });
    setIsVisibilityModalOpen(true);
  };

  const onPageChange = (value: number) => {
    setQuery({
      page: value,
    });
  };

  const onSearchChange = (value: string) => {
    setQuery({
      keyword: value,
    });
  };

  const onVisibilityChange = async () => {
    closeVisibilityPopup();

    const { id, status } = visibility;

    await updateDocument({
      id,
      form: {
        visibleToCustomer: status,
      },
    } as UpdateDocumentVariables);
  };

  const handleFileNameClick = async (file: FormationsTableDocument) => {
    setSelectedFile(castFTDToFormationsPreviewDocument(file));
  };

  return (
    <>
      {loading}
      <Grid container sx={{ justifyContent: 'space-between' }}>
        <Grid item md={9}>
          {isAdmin && (
            <Typography variant="body1B" color="primary">
              Documents
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={3} sx={{ textAlign: 'right' }}>
          {isAdmin ? (
            <FormationsPrimaryButton
              size="large"
              onClick={openAdminPopup}
              data-testid="btn-upload"
            >
              Upload Document
            </FormationsPrimaryButton>
          ) : (
            <Uploader
              text="Upload New Document"
              disabled={!companyId}
              onUpload={onUpload}
              data-testid="btn-upload"
            />
          )}
        </Grid>
      </Grid>
      {isAdmin ? (
        <DocumentsTable accountId={accountId} />
      ) : (
        <DocumentsTableV1
          data={data}
          headers={isAdmin ? adminHeaders : headers}
          page={query.page}
          numberOfPages={numberOfPages}
          onPageChange={onPageChange}
          search={query?.keyword ?? ''}
          onSearchChange={onSearchChange}
          onRecategorize={onRecategorize}
          selected={selected}
          setSelected={setSelected}
          onDelete={onDelete}
          setLoading={setIsLoading}
          onChangeVisibility={onVisibilityModalOpen}
          onFileNameClick={handleFileNameClick}
        />
      )}
      {isAdminUploadModalOpen && (
        <AdminUploadDialog
          open={isAdminUploadModalOpen}
          onClose={closeAdminPopup}
          accountId={accountId}
          defaultData={{
            accountId,
            companyId,
          }}
        />
      )}

      <VisibilityPopup
        open={isVisibilityModalOpen}
        status={visibility.status}
        onSave={onVisibilityChange}
        onClose={closeVisibilityPopup}
      />

      <YesNoModal
        open={isDeleteModelOpen}
        heading={deleteModalText}
        okText="Delete"
        okButtonStyle="error"
        onSave={handleDelete}
        onClose={closeDeleteModal}
      />
      {selectedFile && (
        <FilePreviewDialog
          accountId={accountId}
          open={isPreviewDialogOpen}
          file={selectedFile}
          files={castListOfFormationTableDocument(data)}
          onClose={handleClosePreviewDialog}
        />
      )}
      {editedDocuments.length > 0 && (
        <EditDocumentDialog
          accountId={accountId}
          documents={editedDocuments}
          open={editedDocuments.length > 0}
          onClose={() => setEditedDocuments([])}
        />
      )}
    </>
  );
};
