import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { 
  ArrowBackIcon, 
  SaveIcon 
} from 'components/common/Icon';
import { HubspotService } from 'services';
import { ProgressTrackerStatus } from 'services/account';
import { UserInfo } from 'services/users';
import { FormProvider, useForm } from 'react-hook-form';
import { FormationsTertiaryButton, FormationsGhostButton } from 'components/common/Buttons';
import { ALLOWED_BANKS } from 'enums';
import { Company } from 'models/company';
import { ENTITY_MAPPING } from 'constants/common';
import { useUpdateCompany } from 'hooks/api';
import { useUpdateTimeContactProperties } from 'hooks';
import { queryClient } from 'states/reactQueryClient';
import { HaveAccount } from './HaveAccount';
import { HaveAccountWith } from './HaveAccountWith';
import { BankLikeToUse } from './BankLikeToUse';
import { WillingToSwitch } from './WillingToSwitch';
import { ProvideBankName } from './ProvideBankName';
import { SelectedBank } from './SelectedBank';

type TFormData = {
  haveAccount: string;
  haveAccountWith: string;
  bankLikeToUse: string;
  bankName: string;
  willingToSwitch: string;
};

const toFormData = (company: Company) => ({
  haveAccount:
    company.entityType === ENTITY_MAPPING.sole_prop ? 'No' : undefined,
  haveAccountWith: !company?.useExistingBank ? company?.bankName ?? '' : '',
  bankName: company?.useExistingBank ? company?.bankName ?? '' : '',
  willingToSwitch: '',
  bankLikeToUse: '',
});

const getSteps = () => [
  'Bank Selection', // Do you have a business bank account?
  'Bank Selection', // Which bank do you have a business account with?
  'Bank Selection', // Would you be willing to switch to one of our partner banks?
  'Bank Selection', // Which bank would you like to use?
  'Bank Account Setup', // Please enter your business bank name.
  '', // Result - Bank Selection
];

const getInitialActiveStep = (isSoleProp: boolean) => {
  let step = 0;
  if (isSoleProp) {
    /**
     * For sole proprietors, we should automatically say "no" to "do you have a
     * business bank account?"
     * because they don't have their EIN yet and so cannot have a business bank account.
     */
    step = 4;
  }
  return step;
};

const getStepContent = (step: number) => {
  switch (step) {
    case 0:
      return <HaveAccount />;
    case 1:
      return <HaveAccountWith />;
    case 2:
      return <WillingToSwitch />;
    case 3:
      return <ProvideBankName />;
    case 4:
      return <BankLikeToUse />;
    default:
      return null;
  }
};

interface TParams {
  company: Partial<Company>;
  handleComplete: (status: ProgressTrackerStatus, eventData: Object) => void;
  user: Partial<UserInfo>;
}

export const BankSelection = ({ company, handleComplete, user }: TParams) => {
  const isSoleProp = company.entityType === ENTITY_MAPPING.sole_prop;
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(
    getInitialActiveStep(isSoleProp),
  );
  const [prevStep, setPrevStep] = useState(getInitialActiveStep(isSoleProp));

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: toFormData(company),
    shouldUnregister: false,
  });

  const { mutateAsync: updateCompany, isLoading: isCompanyUpdating } =
    useUpdateCompany({
      onSuccess: () => {
        queryClient.invalidateQueries(['getCompany', company?.id]);
      },
    });

  const { mutate: updateTimeContactProperties, isLoading: isContactUpdating } =
    useUpdateTimeContactProperties();

  const updateCompanyDetails = async (formData: TFormData) => {
    if (company?.id && user?.contactId) {
      const {
        haveAccount,
        haveAccountWith,
        bankLikeToUse,
        bankName,
        willingToSwitch,
      } = formData;
      let bank = bankLikeToUse || haveAccountWith;
      if (willingToSwitch === 'No') {
        bank = bankName;
      }
      const isExistingBank = !!ALLOWED_BANKS[bank];
      const data = {
        bankName: bank,
        useOtherBank: !isExistingBank,
        useExistingBank: isExistingBank,
        hasBankAccount: haveAccount === 'Yes' && willingToSwitch !== 'Yes',
      };
      await updateCompany({ id: company.id, data });
      updateTimeContactProperties({
        contactId: user.contactId,
        property: HubspotService.TimestampAccountType.BANKPREFERENCE,
      });
      handleComplete(ProgressTrackerStatus.Completed, {
        bankName: bank,
      });
    }
  };

  const handleNext = (data: any) => {
    if (
      (data.haveAccount === 'No' && activeStep === 0) ||
      (activeStep === 2 && data?.willingToSwitch === 'Yes')
    ) {
      setPrevStep(activeStep);
      setActiveStep(4);
    } else if (
      activeStep === 3 ||
      activeStep === 4 ||
      !!ALLOWED_BANKS[data?.haveAccountWith]
    ) {
      updateCompanyDetails(data);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 4 && !isSoleProp) {
      setActiveStep(prevStep);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <div data-testid="step-bank-selection">
      {company?.bankName ? (
        <SelectedBank company={company} user={user} />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleNext)} noValidate>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '8px',
                color: 'text.secondary',
              }}
            >
              {steps[activeStep]}
            </Typography>
            {getStepContent(activeStep)}
            {activeStep < steps.length - 1 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '48px',
                }}
              >
                {activeStep > 0 && !isSoleProp && (
                  <FormationsGhostButton
                    onClick={handleBack}
                    startIcon={<ArrowBackIcon />}
                    data-testid="back-btn"
                  >
                    Back
                  </FormationsGhostButton>
                )}
                <FormationsTertiaryButton
                  isLoading={isCompanyUpdating || isContactUpdating}
                  type="submit"
                  size="large"
                  data-testid="continue"
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  sx={{ ml: 'auto' }}
                >
                  Save and Continue
                </FormationsTertiaryButton>
              </Box>
            )}
          </form>
        </FormProvider>
      )}
    </div>
  );
};
