import { defaultTablePageSize } from 'constants/common';

export interface PageInfo {
  currentPage: number;
  nextPage: number | null;
  pageCount: number;
  pageSize: number;
  prevPage: number | null;
  totalCount: number;
}

export const defaultPageInfo: PageInfo = {
  currentPage: 1,
  nextPage: null,
  pageCount: 1,
  pageSize: defaultTablePageSize,
  prevPage: null,
  totalCount: 0,
};

export interface ApiResponse<T> {
  data: T;
}

export type ApiListResp<T> = ApiResponse<T> & {
  pageInfo: PageInfo;
};

export type ApiArrayResp<T> = {
  data: T[];
  pageInfo: PageInfo;
};

export const transformApiListResp = <T, K>(
  resp: ApiArrayResp<T>,
  transformFn: (input: T) => K,
): ApiArrayResp<K> => ({
  data: resp.data.map(transformFn),
  pageInfo: resp.pageInfo,
});

export const getDefaultApiListResp = <T>(): ApiListResp<T> => ({
  data: [] as unknown as T,
  pageInfo: defaultPageInfo,
});
