import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { isValidRedirectUrl } from 'fnRoutes';
import { useNavigate } from 'react-router-dom';
import { CONFIG } from '../config';

type AppState = {
  returnTo?: string;
};

interface IAuth0ProviderWithHistory {
  children: React.ReactNode;
}

export const Auth0ProviderWithHistory = ({
  children,
}: IAuth0ProviderWithHistory) => {
  // eslint-disable-next-line no-undef
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    if (appState?.returnTo && isValidRedirectUrl(appState?.returnTo)) {
      navigate(
        `${window.location.pathname}?redirectTo=${encodeURIComponent(
          appState?.returnTo,
        )}`,
      );
    } else {
      navigate(`${window.location.pathname}`);
    }
  };

  return (
    <Auth0Provider
      domain={CONFIG.auth0Domain}
      clientId={CONFIG.auth0ClientId}
      redirectUri={window.location.origin}
      audience={CONFIG.auth0Audience}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
