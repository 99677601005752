import React, { useEffect } from 'react';
import { t } from 'i18next';
import { Button } from '@mui/material';
import { showErrorToast } from 'components/toast/showToast';
import { getErrorMessage } from 'helpers/error';
import { ViewSDKClient } from './ViewSDKClient';

interface IAdobeViewProp {
  showAnnotationTools: boolean;
  showLeftHandPanel: boolean;
  showPageControls: boolean;
  showDownloadPDF: boolean;
  showPrintPDF: boolean;
  dockPageControls: boolean;
  enableFormFilling: boolean;
  defaultViewMode: string;
}

const defaultViewProp: IAdobeViewProp = {
  showAnnotationTools: false,
  showLeftHandPanel: false,
  showPageControls: true,
  showDownloadPDF: true,
  showPrintPDF: true,
  dockPageControls: true,
  enableFormFilling: false,
  defaultViewMode: 'FIT_WIDTH',
};
interface RenderPDFProps {
  blob: Blob;
  filename: string;
  viewProps?: IAdobeViewProp;
}

export const RenderPDF = (props: RenderPDFProps) => {
  const { blob, filename, viewProps } = props;

  const openDocumentInNewTab = () => {
    const file = new Blob([blob], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');
  };

  useEffect(() => {
    if (blob && filename) {
      const viewSDKClient = new ViewSDKClient();
      viewSDKClient.ready().then(() => {
        try {
          viewSDKClient.previewFile(
            'pdf-div',
            {
              ...defaultViewProp,
              ...viewProps,
            },
            blob,
            filename,
          );
        } catch (e) {
          showErrorToast(getErrorMessage(e, t('documentHub.preview.generalErrorMessage')))
        }
      });
    }
  }, [blob, filename, viewProps]);

  return (
    <>
      <Button
        variant="text"
        data-testid="refresh"
        onClick={() => openDocumentInNewTab()}
      >
        {t('documentHub.preview.openInNewTab')}
      </Button>
      <div
        id="pdf-div"
        style={{
          height: '80vh',
        }}
      />
    </>
  );
};
