import { Typography, Grid, Button, Link } from '@mui/material';
import LoginPic from 'icons/Hero_2_Final.jpg';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'fnRoutes';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import logoFull from 'icons/formationsLogo/formationslogo-full-black-large.png';

const BackgroundImage = styled(Grid)(() => ({
  alignSelf: 'stretch',
  backgroundImage: `url(${LoginPic})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
}));

const SignInButton = styled(Button)(({ theme }) => ({
  marginBottom: '20px',
  width: '300px',
  backgroundColor: '#FEF627',
  color: 'black',
  borderRadius: '80px',
  '&:hover': {
    backgroundColor: theme.palette.others.yellow,
  },
}));

const SignUpButton = styled(Button)(({ theme }) => ({
  width: '300px',
  backgroundColor: '#FFFDC2',
  color: 'black',
  borderRadius: '80px',
  '&:hover': {
    backgroundColor: theme.palette.others.yellow,
  },
}));

const MessageContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: theme.spacing(3),
  padding: theme.spacing(2),
  width: '70%',
  maxWidth: '900px !important',
}));

const FormationsLogo = styled('img')(() => ({
  objectFit: 'contain',
}));

const announcementLink =
  'https://get.formationscorp.com/customer-referral-program';

export const LoginPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSignIn = () => {
    navigate(Routes.LOGIN);
  };

  const onSignUp = () => {
    navigate(Routes.SIGNUP);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: '100vh' }}
    >
      <Grid
        container
        direction="column"
        item
        justifyContent="center"
        alignItems="center"
        xs={12}
        md={5}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          item
          sx={{ height: { xs: '100px', md: '150px' } }}
        >
          <FormationsLogo
            src={logoFull}
            alt="formations-icon"
            sx={{ width: { xs: '85%', sm: '60%', md: '70%', lg: '50%' } }}
            data-testid="formations-icon"
          />
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          item
          sx={{ mt: '15px' }}
        >
          <Typography
            textAlign="center"
            variant="h4"
            sx={{
              width: { xs: '95%', sm: '60%', md: '70%' },
              mb: 1,
              maxWidth: '600px',
            }}
            data-testid="formations-title"
          >
            {t('loginPage.header')}
          </Typography>
          <Typography
            textAlign="center"
            variant="h6"
            sx={{
              width: { xs: '95%', sm: '60%', md: '70%' },
              maxWidth: '600px',
            }}
            data-testid="formations-subtitle"
          >
            {t('loginPage.subHeader')}
          </Typography>
        </Grid>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: '50px', mb: '40px' }}
        >
          <SignInButton
            onClick={onSignIn}
            variant="contained"
            size="large"
            data-testid="formations-login-button"
          >
            <Typography variant="h6">Sign In</Typography>
          </SignInButton>
          <SignUpButton
            onClick={onSignUp}
            variant="contained"
            size="large"
            data-testid="formations-signup-button"
          >
            <Typography variant="h6">Sign Up</Typography>
          </SignUpButton>
        </Grid>
      </Grid>
      <BackgroundImage
        container
        item
        xs={12}
        md={7}
        sx={{ display: { xs: 'none', md: 'flex' } }}
        justifyContent="flex-start"
        alignItems="center"
        direction="column"
        wrap="nowrap"
      >
        <Grid item sx={{ height: '50%' }} />
        <MessageContainer
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            fontWeight="800"
            textAlign="center"
            variant="h4"
            data-testid="formations-landing-message-title"
          >
            {t('loginPage.message.title')}
          </Typography>
          <Typography
            textAlign="center"
            variant="body1"
            sx={{ mt: 2 }}
            data-testid="formations-landing-message-body"
          >
            {t('loginPage.message.body')}
          </Typography>
          <Typography
            textAlign="center"
            variant="body1"
            sx={{ mt: 2, fontWeight: 'bold' }}
          >
            {t('loginPage.message.bodyBold')}
          </Typography>
          <Link
            textAlign="center"
            variant="body1"
            sx={{ color: 'black', mt: 2 }}
            href={announcementLink}
            target="_blank"
          >
            {t('loginPage.message.link')}
          </Link>
        </MessageContainer>
      </BackgroundImage>
    </Grid>
  );
};

export default LoginPage;
