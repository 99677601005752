import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { Controller, UseFormMethods } from 'react-hook-form';
import { IFormField } from './types';

interface TFormationsCheckboxOptions {
  defaultValue?: boolean | null;
  indeterminate?: boolean;
}

type TFormationsCheckbox = {
  field: IFormField<TFormationsCheckboxOptions>;
  formInstance: UseFormMethods;
};

export const FormationsCheckbox = ({
  field,
  formInstance,
}: TFormationsCheckbox) => {
  const { control, errors } = formInstance;
  const {
    label = '',
    options: { defaultValue = false, indeterminate = false } = {},
  } = field;

  return (
    <FormControl data-testid={`field-${field.name}`}>
      <Controller
        name={field.name}
        control={control}
        defaultValue={defaultValue}
        render={({ value = false, onChange }) => (
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                size="medium"
                checked={value}
                name={field.name}
                disabled={field.disabled}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
                indeterminate={indeterminate}
              />
            }
            label={label as string}
          />
        )}
      />
      <FormHelperText error={!!errors[field?.name]}>
        {errors[field?.name]?.message}
      </FormHelperText>
    </FormControl>
  );
};
