import { StringParam, useQueryParams } from 'use-query-params';
import {
  useNavigate as useRouterNavigate,
  NavigateFunction,
  To,
  NavigateOptions,
  Path,
} from 'react-router-dom';
import { Routes } from 'fnRoutes';
import { useCurrentUserRole } from 'hooks/api/useUser';

export const useSpoof = (): {
  spoofID?: string;
  isAdminSpoof: boolean;
} => {
  const [query] = useQueryParams({
    spoofID: StringParam,
  });
  return {
    spoofID: query.spoofID || undefined,
    isAdminSpoof: !!query.spoofID,
  };
};

const generatePathWithSpoof = (path: string, spoofID: string = ''): string => {
  const url = new URL(`${window.location.origin}${path}`);
  url.pathname = `/spoof${url.pathname}`;
  url.searchParams.set('spoofID', spoofID);

  return `${url.pathname}${url.search}`;
};

const generateLocationObjectWithSpoof = (
  loc: Partial<Path>,
  spoofID: string = '',
): To => {
  const search = new URLSearchParams(loc.search);
  search.set('spoofID', spoofID);
  return `/spoof${loc.pathname}?${search.toString()}`;
};

export const usePathWithSpoof = (path: string) => {
  const { spoofID, isAdminSpoof } = useSpoof();
  if (!isAdminSpoof || path.includes('spoof')) {
    return path;
  }
  return generatePathWithSpoof(path, spoofID);
};

export const useNavigate = (): NavigateFunction => {
  const navigate = useRouterNavigate();
  const { spoofID, isAdminSpoof } = useSpoof();
  return (to: To | number, options?: NavigateOptions): void => {
    if (typeof to === 'number') {
      return navigate(to);
    }
    if (!isAdminSpoof || !spoofID) {
      return navigate(to, options);
    }
    if (typeof to === 'string') {
      const newPath = generatePathWithSpoof(to, spoofID);
      return navigate(newPath, options);
    }
    return navigate(generateLocationObjectWithSpoof(to, spoofID), options);
  };
};

export const useHomePath = () => {
  const { isAdmin } = useCurrentUserRole();
  return isAdmin ? Routes.ADMIN_HOME : Routes.CUSTOMER_HOME;
};
