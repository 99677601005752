import InputMask from 'react-input-mask';
import { TextField } from '@mui/material';
import { Controller, UseFormMethods } from 'react-hook-form';
import { get } from 'lodash';
import { IFormField } from './types';

interface TFormationsPhoneOptions {
  mask: string;
}

type TFormationsPhoneField = {
  field: IFormField<TFormationsPhoneOptions>;
  formInstance: UseFormMethods;
};

export const FormationsPhoneField = ({
  field,
  formInstance,
}: TFormationsPhoneField) => {
  const { control, errors } = formInstance;
  const { defaultValue = '', options = { mask: '(999) 999-9999' } } = field;
  const { mask } = options;
  const errorMsg = get(errors, [field.name, 'message'], '');
  return (
    <Controller
      name={field.name}
      control={control}
      defaultValue={defaultValue}
      render={({ value, onChange }) => (
        <InputMask mask={mask} value={value} onChange={onChange}>
          {() => (
            <TextField
              variant="outlined"
              name={field.name}
              fullWidth
              label={field.label}
              error={!!errorMsg}
              helperText={errorMsg}
              data-testid={`field-${field.name}`}
            />
          )}
        </InputMask>
      )}
    />
  );
};
