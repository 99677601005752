import { makeStyles, styled } from '@mui/styles';
import {
  Dialog,
  Typography,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from '@mui/material';
import {
  FormationsPrimaryButton,
  FormationsGhostButton
} from 'components/common/Buttons';

interface IProps {
  open: boolean;
  status: boolean;
  onSave: () => void;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.down('md')]: {
      minWidth: 250,
      maxWidth: 250,
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 430,
      maxWidth: 430,
    },
    padding: theme.spacing(2, 4, 4, 4),
  },
  secondaryButton: {
    marginLeft: `0 !important`,
  },
}));

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(0, 0, 4, 0),
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0),
}));

export function VisibilityPopup({ open, status, onSave, onClose }: IProps) {
  const classes = useStyles();

  const handleSave = () => {
    onSave();
  };

  const handleClose = () => {
    onClose();
  };

  const getMainText = () =>
    status
      ? 'Are you sure you want to make this document visible to customers?'
      : 'Are you sure you want to make this document invisible to customers?';

  const getOkayText = () =>
    status
      ? 'Yes, make it customer-visible'
      : 'Yes, make it hidden from customers';

  const getCancelText = () =>
    status
      ? 'No, keep it hidden from customers'
      : 'No, keep it visible to customers';

  return (
    <div>
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        onClose={handleClose}
        open={open}
      >
        <DialogContent>
          <div>
            <Typography variant="body1" component="div">
              {getMainText()}
            </Typography>
          </div>
        </DialogContent>

        <DialogActions>
          <FormationsPrimaryButton
            size="large"
            fullWidth
            onClick={handleSave}
          >
            {getOkayText()}
          </FormationsPrimaryButton>

          <FormationsGhostButton
            className={classes.secondaryButton}
            size="large"
            fullWidth
            onClick={handleClose}
            sx={{ mt: 1 }}
          >
            {getCancelText()}
          </FormationsGhostButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
