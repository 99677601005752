import { Children, PropsWithChildren } from 'react';
import { Box, BoxProps, Grid } from '@mui/material';
import { GridProps } from '@mui/material/Grid/Grid';

export const FormationsFormContainer = ({
  children,
  ...props
}: PropsWithChildren<BoxProps>) => <Box {...props}>{children}</Box>;

export const FormationsFormContent = ({
  children,
  ...props
}: PropsWithChildren<GridProps>) => (
  <Grid container spacing={2} sx={{ marginTop: '4px' }} {...props}>
    {Children.map(children, (child) => child)}
  </Grid>
);

export const FormationsFormItem = ({
  children,
  ...props
}: PropsWithChildren<GridProps>) => (
  <Grid item xs={12} {...props}>
    {children}
  </Grid>
);

export const FormationsFormActions = ({
  children,
  ...props
}: PropsWithChildren<GridProps>) => (
  <Grid container justifyContent="flex-end" sx={{ mt: 4, }} gap={1} {...props}>
    {Children.map(children, (child) => (
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end'}}>{child}</Grid>
    ))}
  </Grid>
);
