import moment from 'moment';
import * as yup from 'yup';

const dateValidation = (
  _value: string | undefined,
  originalValue: string | undefined,
) => {
  const parsedValue = moment(originalValue, 'M/D/YYYY', true);
  // if it's valid return the date object, otherwise return an `InvalidDate`
  return parsedValue.isValid() ? parsedValue.toDate() : new Date('');
};

const maxSpentAt = 255;
const nameRegex = /^[^<>]*$/;

export const transactionSchema = yup.object().shape({
  category: yup.string().required('Please provide transaction category.'),
  amount: yup.string().required('Please provide transaction amount.'),
  spentAt: yup
    .string()
    .when('type', (val: string | undefined, schema) =>
      val !== 'income'
        ? yup
            .string()
            .trim()
            .required('Please provide where the expense is from.')
            .max(maxSpentAt, 'Please provide a shorter name.')
            .matches(nameRegex, "Name can not contain '<' or '>'.")
        : schema.notRequired(),
    ),
  spentOn: yup
    .date()
    .when('type', (val: string | undefined) =>
      val !== 'income'
        ? yup
            .date()
            .transform(dateValidation)
            .typeError('Please provide valid date of expense.')
        : yup.date().notRequired(),
    ),
});
