import React, { useEffect, useState } from 'react';
import { SaveIcon } from 'components/common/Icon';
import { FormationsTertiaryButton } from 'components/common/Buttons';
import { Typography } from '@mui/material';
import { queryClient } from 'states/reactQueryClient';
import { businessAddressSchema } from 'schemas/businessAddressSchema';
import { BusinessAddressView } from 'views/BusinessAddressView';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormationsFormActions,
  FormationsFormContainer,
  FormationsFormContent,
  FormationsFormFields,
  FormationsFormItem,
  IFormField,
  renderField,
} from 'components/common/FormationsForm2';
import { formatZipCode } from 'components/common/FormationsForm2/FormationsTextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateAccountProgress, useUpdateCompany } from 'hooks/api';
import { Company } from 'models/company';
import {
  IAccount,
  ProgressTrackerGroupRequest,
  ProgressTrackerGroups,
  ProgressTrackerStages,
  ProgressTrackerStatus,
} from 'models/account';
import { isProgressTrackerStageCompleted } from 'components/ProgressTracker/Incorporation/state';
import { STATES } from '../../../enums';

const defaultAddress = {
  street1: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
};

interface TParams {
  handleComplete: () => void;
  stageCompleted: boolean;
  companyData: Company;
  accountData: IAccount;
  hasOwnershipSection: boolean;
  onContinueToAccountVerification: () => void;
}

interface IFormData {
  mailingAddress1: string;
  mailingAddress2: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  workPhone: string;
  physicalSameAsMailing: boolean;
  physicalAddress1: string;
  physicalAddress2: string;
  physicalCity: string;
  physicalState: string;
  physicalZip: string;
}

export const AddressInformation = ({
  handleComplete,
  stageCompleted,
  companyData,
  accountData,
  hasOwnershipSection,
  onContinueToAccountVerification,
}: TParams) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const { contactDetails } = companyData || {};

  const { mutateAsync: updateCompany } = useUpdateCompany({
    onSuccess: () => {
      queryClient.invalidateQueries(['getCompany', companyData?.id]);
    },
  });

  const {
    street1: physicalAddress1,
    street2: physicalAddress2,
    city: physicalCity,
    state: physicalState,
    zip: physicalZip,
  } = contactDetails?.physicalAddress ?? defaultAddress;

  const {
    street1: mailingAddress1,
    street2: mailingAddress2,
    city: mailingCity,
    state: mailingState,
    zip: mailingZip,
  } = contactDetails?.mailingAddress ?? defaultAddress;
  const mapState = (state: { name: string; code: string }) => ({
    name: state.name,
    value: state.code,
  });
  const [physicalSameAsMailing, setPhysicalSameAsMailing] = useState(true);
  let formFields: IFormField[] = [
    {
      type: FormationsFormFields.Text,
      name: 'mailingAddress1',
      label: 'Address Line 1',
      options: {
        placeholder: '1234 Ave E',
        autoFocus: true,
        maxLength: 25,
      },
    },
    {
      type: FormationsFormFields.Text,
      name: 'mailingAddress2',
      label: 'Address Line 2',
      options: {
        placeholder: '',
        maxLength: 250,
        autoFocus: true,
      },
    },
    {
      type: FormationsFormFields.Text,
      name: 'mailingCity',
      label: 'City',
      options: {
        placeholder: 'Seattle',
        maxLength: 50,
        autoFocus: true,
      },
    },
    {
      type: FormationsFormFields.AutoComplete,
      name: 'mailingState',
      label: 'State',
      options: {
        placeholder: 'Washington',
        options: STATES.map(mapState),
      },
    },
    {
      type: FormationsFormFields.Text,
      name: 'mailingZip',
      label: 'Zip Code',
      options: {
        placeholder: '12345',
        formatInput: formatZipCode,
      },
    },
    {
      type: FormationsFormFields.Phone,
      name: 'workPhone',
      label: 'Phone Number',
      options: {
        placeholder: '(999) 999-9999',
        mask: '(999) 999-9999',
      },
    },
    {
      type: FormationsFormFields.CheckboxField,
      name: 'physicalSameAsMailing',
      label: t('progressTracker.incorporation.physicalAddressSameAsMailing'),
      options: {
        placeholder: '',
      },
    },
  ];

  if (!physicalSameAsMailing) {
    formFields = [
      ...formFields,
      {
        type: FormationsFormFields.Text,
        name: 'physicalAddress1',
        options: {
          placeholder: '1234 Ave E',
          maxLength: 250,
        },
        label: 'Address Line 1',
      },
      {
        type: FormationsFormFields.Text,
        name: 'physicalAddress2',
        label: 'Address Line 2',
        options: {
          placeholder: '',
          maxLength: 250,
        },
      },
      {
        type: FormationsFormFields.Text,
        name: 'physicalCity',
        label: 'City',
        options: {
          placeholder: 'Seattle',
          maxLength: 50,
        },
      },
      {
        type: FormationsFormFields.AutoComplete,
        name: 'physicalState',
        label: 'State',
        options: {
          placeholder: 'Washington',
          options: STATES.map(mapState),
        },
      },
      {
        type: FormationsFormFields.Text,
        name: 'physicalZip',
        label: 'Zip Code',
        options: {
          placeholder: '12345',
          formatInput: formatZipCode,
        },
      },
    ];
  }

  const formInstance = useForm({
    defaultValues: {
      mailingAddress1,
      mailingAddress2,
      mailingCity,
      mailingState: STATES.find((item) => item.code === mailingState) ?? null,
      mailingZip,
      workPhone: contactDetails?.workPhone,
      physicalSameAsMailing,
      physicalAddress1,
      physicalAddress2,
      physicalCity,
      physicalState: STATES.find((item) => item.code === physicalState) ?? null,
      physicalZip,
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    },
    resolver: yupResolver(businessAddressSchema),
  });

  useEffect(() => {
    if (formInstance.getValues('physicalSameAsMailing')) {
      setPhysicalSameAsMailing(true);
    } else {
      setPhysicalSameAsMailing(false);
    }
  }, [formInstance.watch('physicalSameAsMailing')]);

  const onFormSubmit = async (formData: IFormData) => {
    setSubmitting(true);
    const address = {
      contactDetails: {
        mailingAddress: {
          street1: formData.mailingAddress1,
          street2: formData.mailingAddress2,
          city: formData.mailingCity,
          state: formData.mailingState,
          zip: formData.mailingZip,
        },
        physicalAddress: {
          street1: formData.physicalAddress1,
          street2: formData.physicalAddress2,
          city: formData.physicalCity,
          state: formData.physicalState,
          zip: formData.physicalZip,
        },
        workPhone: formData.workPhone,
      },
    };
    if (formData.physicalSameAsMailing) {
      address.contactDetails.physicalAddress =
        address.contactDetails.mailingAddress;
    }

    if (companyData?.id) {
      await updateCompany({ id: companyData.id, data: address });
      handleComplete();
      if (companyData?.entityType !== 'Sole-Prop' && accountData?.formationsPlan !== 'BIB' && !hasOwnershipSection) {
        onContinueToAccountVerification();
      }
    }
  };

  return (
    <div>
      <Typography variant="h5B" component="h5" data-test-id="address-details">
        Business Address
      </Typography>
      {stageCompleted && contactDetails && (
        <BusinessAddressView contactDetails={contactDetails} />
      )}
      {!stageCompleted && (
        <>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            {t('progressTracker.incorporation.wouldYouLikeToReceiveEmail')}
          </Typography>
          <FormationsFormContainer>
            <form onSubmit={formInstance.handleSubmit(onFormSubmit)}>
              <FormationsFormContent>
                {formFields.map((field) => (
                  <FormationsFormItem key={field.name}>
                    {renderField(field, formInstance)}
                  </FormationsFormItem>
                ))}
              </FormationsFormContent>
              <FormationsFormActions>
                <FormationsTertiaryButton
                  isLoading={submitting}
                  type="submit"
                  size="large"
                  data-testid="save-address"
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                >
                  Save and Continue
                </FormationsTertiaryButton>
              </FormationsFormActions>
            </form>
          </FormationsFormContainer>
        </>
      )}
    </div>
  );
};

interface ContainerProps {
  company: Company;
  account: IAccount;
  hasOwnershipSection: boolean;
  onContinueToAccountVerification: () => void;
}
export const AddressInformationContainer = ({
  company,
  account,
  hasOwnershipSection,
  onContinueToAccountVerification,
}: ContainerProps) => {
  const { mutateAsync: updateAccountProgress } = useUpdateAccountProgress(
    account.id,
    {
      onSuccess: () => queryClient.invalidateQueries(['progressTracker']),
    },
  );

  const handleComplete = async () => {
    const requestProps: ProgressTrackerGroupRequest = {
      group: ProgressTrackerGroups.Incorporation,
      status: ProgressTrackerStatus.Completed,
      stage: ProgressTrackerStages.AddressDetails,
    };

    await updateAccountProgress({
      progress: [requestProps],
      eventData: {
        stage: ProgressTrackerStages.AddressDetails,
        stageStatus: ProgressTrackerStatus.Completed,
        entityType: (company?.entityType ?? company?.legacyEntityType) ?? '',
      },
    });
  };
  const stagedCompleted = isProgressTrackerStageCompleted(
    account,
    ProgressTrackerStages.AddressDetails,
  );
  return (
    <AddressInformation
      handleComplete={handleComplete}
      stageCompleted={stagedCompleted}
      companyData={company}
      accountData={account}
      hasOwnershipSection={hasOwnershipSection}
      onContinueToAccountVerification={onContinueToAccountVerification}
    />
  );
};
