export enum PaymentSource {
  Direct = 'direct',
  Payroll = 'payroll',
}

export const PaymentSourceToLabel = {
  [PaymentSource.Direct]: 'Direct',
  [PaymentSource.Payroll]: 'Payroll',
};

export enum PaymentType {
  State = 'state',
  Federal = 'federal',
}

export const PaymentTypeToLabel = {
  [PaymentType.State]: 'State',
  [PaymentType.Federal]: 'IRS',
};

export enum PaymentStatus {
  Approved = 'approved',
  Submitted = 'submitted',
  Rejected = 'rejected',
}

export const PaymentStatusToLabel = {
  [PaymentStatus.Approved]: 'Approved',
  [PaymentStatus.Submitted]: 'Pending',
  [PaymentStatus.Rejected]: 'Rejected',
};
