import { Alert, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    backgroundColor: theme.palette.primary.contrastText,
    borderColor: theme.palette.success.main,
    borderWidth: 1,
    borderStyle: 'solid',
    '& .MuiSvgIcon-root': {
      height: '100%',
      color: theme.palette.success.main,
    },
  },
}));

export const AlertSuccess = ({ text }: { text: string | ReactNode }) => {
  const classes = useStyles();
  return (
    <Alert severity="success" className={classes.success}>
      <Typography
        variant="body2"
        display="inline"
        sx={{ color: 'success.main' }}
      >
        {text}
      </Typography>
    </Alert>
  );
};
