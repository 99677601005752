import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Alert, AlertTitle } from '@mui/material';
import { FormationsPrimaryButton } from 'components/common/Buttons';
import { Routes } from 'fnRoutes';
import i18n from 'translations/i18n';

interface Props {
  error: Error;
  componentStack: string | null;
  resetError: () => void;
}

export const ErrorFallback = ({ error, componentStack, resetError }: Props) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <Grid
      container
      item
      direction="column"
      alignItems="center"
      justifyContent="center"
      mt={5}
    >
      <Typography
        sx={{
          textAlign: 'center',
          fontWeight: 200,
          fontSize: '50px',
          lineHeight: '1.2em',
        }}
      >
        {i18n.t('errorPage.errorHeader')}
      </Typography>
      <Typography sx={{ lineHeight: '2em' }}>
        {i18n.t('errorPage.errorMessage')}
      </Typography>
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={3}
      >
        <Link to={Routes.HOME()} onClick={resetError}>
          <FormationsPrimaryButton sx={{ mr: 1 }}>
            Go to Homepage
          </FormationsPrimaryButton>
        </Link>
        <FormationsPrimaryButton
          onClick={() => setShowDetails(!showDetails)}
          sx={{ ml: 1 }}
        >
          Details
        </FormationsPrimaryButton>
      </Grid>
      {showDetails && (
        <Alert severity="error" sx={{ my: 3, width: 600, overflowWrap: 'anywhere', whiteSpace: 'pre-wrap' }}>
          <AlertTitle>{error.message}</AlertTitle>
          {`Component trace:${componentStack}`}
        </Alert>
      )}
    </Grid>
  );
};
