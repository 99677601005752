import { Tooltip, Typography, Badge } from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import React, { useMemo, useState } from 'react';
import { NotesIcon } from 'components/common/Icon';
import { useTranslation } from 'react-i18next';
import { FormationsTableDocument } from 'components/common/tables/DocumentsTable';
import { EditNoteDialog } from '../dialogs/EditNoteDialog';

interface INoteIconCell {
  document?: FormationsTableDocument;
  isAdmin?: boolean;
}

export const NoteIconCell = ({ document, isAdmin = false }: INoteIconCell) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);

  const showBadge = useMemo(() => {
    if (isAdmin) {
      return document?.adminUnread;
    }
    return document?.customerUnread;
  }, [isAdmin, document]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setVisibleDialog(true);
  };

  const handleClose = () => {
    setVisibleDialog(false);
  };

  return (
    <>
      <Tooltip
        data-testid="document-notes-cell"
        placement="top"
        title={
          <Typography variant="tooltip">
            {t('transactionNote.noteTooltip')}
          </Typography>
        }
      >
        <Badge
          variant="dot"
          invisible={!showBadge}
          overlap="circular"
          color="error"
        >
          <FormationsGhostButton
            rounded
            onClick={handleClick}
            data-testid="notes-icon"
          >
            <NotesIcon />
          </FormationsGhostButton>
        </Badge>
      </Tooltip>
      {visibleDialog && (
        <EditNoteDialog
          anchorEl={anchorEl}
          documentId={document?.id ?? ''}
          documentAccountId={document?.accountId ?? ''}
          unreadNote={!!showBadge}
          open
          onClose={handleClose}
        />
      )}
    </>
  );
};
