import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserInfo } from 'services/users';
import { capitalizeFirstLetter } from 'helpers/text-transformer';
import { FormationsPrimaryButton } from 'components/common/Buttons';

export const SelectedBankInfo = ({ user, onContinueToNextSection }: { user: Partial<UserInfo>, onContinueToNextSection: () => void; }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5B" component="h5" sx={{ mb: 2 }}>
        {t('Bank Information')}
      </Typography>
      <Typography variant="body2" component="p">
       {t('Routing Number')}
      </Typography>
      <Typography variant="body2B" component="p" sx={{ mb: 1 }}>
        {user?.routingNumber}
      </Typography>

      <Typography variant="body2" component="p">
       {t('Account Number')}
      </Typography>
      <Typography variant="body2B" component="p" sx={{ mb: 1 }}>
        {user?.bankAccountNumber}
      </Typography>

      <Typography variant="body2" component="p">
       {t('Account Type')}
      </Typography>
      <Typography variant="body2B" component="p">
        {user?.bankAccountType && capitalizeFirstLetter(user.bankAccountType)}
      </Typography>
      <Box mt={4}>
        <FormationsPrimaryButton
          onClick={onContinueToNextSection}
        >
          Continue to Business Package
        </FormationsPrimaryButton>
      </Box>
    </>
  );
};
