import { useMutation, useQuery } from 'react-query';
import {
  connectBankAccount,
  getBankAccounts,
  createPlaidLinkToken,
  removeBankAccount,
  refreshBankAccounts,
  createHurdlrLinkTokenForUpdate,
  ConnectBankAccountParams,
} from 'services/bankAccounts';

export const useBankAccounts = (accountId: string | undefined) => {
  const { data, ...rest } = useQuery(
    ['bank-accounts', accountId],
    () => getBankAccounts(accountId ?? ''),
    {
      enabled: !!accountId,
    },
  );
  return {
    bankAccounts: data ?? [],
    ...rest,
  };
};

export const usePlaidLinkToken = (accountId: string) => {
  const { data, ...rest } = useQuery(
    ['plaid-link-token', accountId],
    () => createPlaidLinkToken(accountId),
    {
      refetchOnMount: 'always', // plaid link token should be refreshed when dialog is opened
    },
  );
  return {
    linkToken: data?.linkToken ?? '',
    ...rest,
  };
};

export const useHurdlrLinkTokenForUpdate = (
  accountId: string,
  plaidItemId: number,
  enableAccountSelection: boolean = false,
) => {
  const { data, ...rest } = useQuery(
    ['plaid-update-link-token', accountId, plaidItemId],
    () =>
      createHurdlrLinkTokenForUpdate(
        accountId,
        plaidItemId,
        enableAccountSelection,
      ),
  );
  return {
    linkToken: data?.linkToken ?? '',
    ...rest,
  };
};

export const useConnectBankAccount = () => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    (params: ConnectBankAccountParams) => connectBankAccount(params),
  );

  return {
    connectBankAccount: mutate,
    connectBankAccountAsync: mutateAsync,
    ...rest,
  };
};

export const useRemoveBankAccount = () => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({ accountId, plaidItemId }: { accountId: string; plaidItemId: number }) =>
      removeBankAccount(accountId, plaidItemId),
  );

  return {
    removeBankAccount: mutate,
    removeBankAccountAsync: mutateAsync,
    ...rest,
  };
};

export const useRefreshBankAccounts = () => {
  const { mutate, mutateAsync, ...rest } = useMutation((accountId: string) =>
    refreshBankAccounts(accountId),
  );

  return {
    refreshBankAccounts: mutate,
    refreshBankAccountsAsync: mutateAsync,
    ...rest,
  };
};
