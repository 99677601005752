import { ReactNode } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

interface Props extends Omit<ButtonProps, 'classes'>, LoadingButtonProps {
  children: ReactNode;
  rounded?: boolean;
  isLoading?: boolean;
}

export const FormationsTertiaryButton = ({
  className,
  variant = 'outlined',
  color = 'tertiary',
  rounded = false,
  isLoading = false,
  ...buttonProps
}: Props) =>
  !isLoading ? (
    <Button
      variant={variant}
      color={color}
      className={
        rounded ? `roundedButton ${className ?? ''}`.trim() : className
      }
      {...buttonProps}
    />
  ) : (
    <LoadingButton
      variant={variant}
      color={color}
      className={
        rounded ? `roundedButton ${className ?? ''}`.trim() : className
      }
      loading={isLoading}
      {...buttonProps}
    />
  );
