import {
  ADMIN_ACTIONS,
  CUSTOMER_ACTIONS,
  CUSTOMER_PAYROLL_DISABLED_ACTIONS,
  SUPERADMIN_ACTIONS,
  CUSTOMER_PAYROLL_SETUP_ACTIONS,
  IAction,
  generateAdminAccountActions,
} from 'components/common/sidebar/constant';
import { useLocation } from 'react-router-dom';
import { AuthService } from 'services';
import {
  useAcceptTermsOfServiceStatus,
  useCurrentAccount,
  useCurrentUser,
  useCurrentUserRole,
  usePayrollOnboardingStatus,
  usePermissions,
  useSpoof,
} from 'hooks/api';

const isAccountPath = (pathname: string) => {
  const pathArray = pathname.substring(1).split('/');
  return (
    (pathArray.length === 3 || pathArray.length === 4) &&
    pathArray[0] === 'dashboard' &&
    pathArray[1] === 'accounts'
  );
};

export const useMenuItems = (): IAction[] => {
  const { pathname } = useLocation();
  const { currentUser } = useCurrentUser();
  const { isAdmin } = useCurrentUserRole();
  const { spoofID, isAdminSpoof } = useSpoof();
  const isSuperAdmin = AuthService.isSuperAdmin();
  const { currentAccount } = useCurrentAccount();
  const { onboardingStatus, isError: onboardingStatusError } =
    usePayrollOnboardingStatus(
      currentAccount?.id,
      currentAccount?.gusto?.companyUUID,
    );
  const { accepted, isError: acceptedStatusError } =
    useAcceptTermsOfServiceStatus(
      currentAccount?.id,
      currentAccount?.gusto?.companyUUID,
    );
  const { hasPermission } = usePermissions();

  if (currentUser == null) {
    return [];
  }

  if (isAdmin && isAccountPath(pathname)) {
    const pathArray = pathname.substring(1).split('/');
    return generateAdminAccountActions(pathArray[2]);
  }

  // check if user is super admin and not spoofing
  if (isSuperAdmin && !spoofID) {
    return [...ADMIN_ACTIONS, ...SUPERADMIN_ACTIONS];
  }

  if (isAdmin) {
    return ADMIN_ACTIONS;
  }

  if (
    (onboardingStatus?.onboardingCompleted === false &&
      onboardingStatus?.stateSetup === false) ||
    (!hasPermission('read:payrolls:all') && isAdminSpoof)
  ) {
    return CUSTOMER_PAYROLL_DISABLED_ACTIONS;
  }

  if (
    accepted === false ||
    onboardingStatus?.approveCompany === false ||
    onboardingStatusError ||
    acceptedStatusError
  ) {
    return CUSTOMER_PAYROLL_SETUP_ACTIONS;
  }

  return CUSTOMER_ACTIONS;
};
