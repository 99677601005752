import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Dialog as MuiDialog } from '@mui/material';
import HelloSign from 'hellosign-embedded';
import {
  DropboxSignTemplates,
  createHelloSignTemplate,
  getHelloSignSignatureURL,
} from 'services/dropboxSign';
import { showToastOnError } from 'components/toast/showToast';
import { CONFIG } from 'config';

const BootstrapDialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2, 2, 2, 3),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2, 3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const client = new HelloSign({
  clientId: CONFIG.hellosign.clientId,
});

interface IHelloSignDialog {
  open: boolean;
  onClose: () => void;
  accountId: string;
  templateName: DropboxSignTemplates;
  handleSuccess: (signatureRequestID: string) => void;
}

export const HelloSignDialog: React.FC<IHelloSignDialog> = ({
  open,
  onClose,
  accountId,
  templateName,
  handleSuccess,
}) => {
  const onCloseDialog = useCallback(
    (event: Object, reason: string) => {
      if (reason === 'backdropClick') {
        return;
      }
      if (reason === 'escapeKeyDown') {
        return;
      }
      onClose();
    },
    [onClose],
  );
  const [mounted, setMounted] = useState(false);

  const helloSignContainer = useCallback((node) => {
    if (node !== null) {
      setMounted(true);
    }
  }, []);

  const initHelloSign = useCallback(
    (signatureURL: string, signatureRequestID: string) => {
      client.open(signatureURL, {
        clientId: CONFIG.hellosign.clientId,
        skipDomainVerification: CONFIG.hellosign.skipDomainVerification,
        allowCancel: false,
        debug: CONFIG.hellosign.debug,
        locale: HelloSign.locales.EN_US,
        container: document.getElementById('HelloSignEmbeded') || undefined,
      });
      client.on('close', () => {
        if (CONFIG.hellosign.debug) {
          // eslint-disable-next-line no-console
          console.log('close hellosign dialog');
        }
      });
      client.on('error', () => {
        if (CONFIG.hellosign.debug) {
          // eslint-disable-next-line no-console
          console.log('error hellosign dialog');
        }
        onClose();
      });
      client.on('sign', () => {
        if (CONFIG.hellosign.debug) {
          // eslint-disable-next-line no-console
          console.log('signed with id', signatureRequestID);
        }
        handleSuccess(signatureRequestID);
      });
    },
    [onClose, handleSuccess],
  );

  const fetchHelloSignTemplate = useCallback(
    async (aID: string, tName: DropboxSignTemplates) => {
      try {
        const res = await createHelloSignTemplate(aID, tName);
        const sign = await getHelloSignSignatureURL(
          accountId,
          res.signatures[0].signature_id,
        );
        if (sign.sign_url) {
          const signURL = sign.sign_url;
          const signatureRequestID = res.signature_request_id;
          initHelloSign(signURL, signatureRequestID);
        } else {
          onClose();
        }
      } catch (error) {
        showToastOnError(error);
        onClose();
      }
    },
    [accountId, initHelloSign, onClose],
  );

  useEffect(() => {
    if (open && mounted && accountId && templateName) {
      fetchHelloSignTemplate(accountId, templateName);
    }
  }, [open, mounted, accountId, templateName, fetchHelloSignTemplate]);

  return (
    <BootstrapDialog
      onClose={onCloseDialog}
      open={open}
      fullWidth
      disableEscapeKeyDown
      maxWidth={false}
    >
      <div
        ref={helloSignContainer}
        id="HelloSignEmbeded"
        style={{ height: '80vh' }}
      />
    </BootstrapDialog>
  );
};
