import {
  Typography, 
  Grid, 
  Box
} from "@mui/material";
import { useState } from "react";
import { 
  FormationsTertiaryButton, 
  FormationsPrimaryButton, 
  FormationsGhostButton 
} from "components/common/Buttons";
import { ArrowBackIcon } from "components/common/Icon";
import { PrimaryChip } from 'components/common/PrimaryChip';
import { useTaxesQuery } from "hooks/api/useTaxes";
import { 
  PaymentStatus, 
  PaymentStatusToLabel, 
  PaymentType,
  PaymentTypeToLabel, 
  PaymentSource,
  PaymentSourceToLabel,
} from "enums";

interface TaxesMobileFilterProps {
  onClose: () => void;
}

const resetButtonFocusStyle = {
  '&:focus': {
    animation: 'focusAnimation 0.3s ease forwards',
    animationDelay: '0.25s',
  },
  '@keyframes focusAnimation': {
    'from': {
      color: 'initial',
      borderColor: 'initial',
      backgroundColor: 'initial',
    },
    'to': {
      color: 'black',
      borderColor: 'black',
      backgroundColor: 'transparent',
    }
  }
}

const chipButtonStyle = (isFilled: boolean) => ({
  '&.MuiChip-clickable:focus': {
    backgroundColor: isFilled ? '#003CB2' : 'transparent',
    '& .MuiChip-label, & .MuiChip-icon': {
      color: isFilled ? 'white' : 'black',
    },
    borderColor: isFilled ? 'transparent' : 'black',
  },
  '@media (max-width: 600px)': { // This is to override the default MUI hover effect on phones to target IOS focus behavior
    '&.MuiChip-clickable:hover': {
      backgroundColor: isFilled ? '#003CB2' : 'transparent', // Masking hover effect because IOS re-hovers after button click
      '& .MuiChip-label, & .MuiChip-icon': {
        color: isFilled ? 'white' : 'black',
      },
      borderColor: isFilled ? 'transparent' : 'black',
    },
  },
});

export const TaxesMobileFilter = ({ onClose }: TaxesMobileFilterProps) => {
  const [query, setQuery] = useTaxesQuery();

  const currentYear = new Date().getFullYear();
  const yearsList: string[] = Array.from({length: 5}, (_, i) => (currentYear - i).toString());

  // All the current mobile filter states
  const [status, setStatus] = useState<(PaymentStatus | null)[]>(query.status ?? []);
  const [type, setType] = useState<PaymentType | null | undefined>(query.type ?? undefined);
  const [source, setSource] = useState<PaymentSource | null | undefined>(query.source ?? undefined);
  const [years, setYears] = useState<(string | null)[]>(query.years ?? []);

  const handleFilterChips = (
    newFilter: PaymentType | PaymentSource | null | undefined, 
    currentFilter: PaymentType | PaymentSource | null | undefined,
    setter: any
  ) => {
    // This only works for single select filter options, so not the array types. 
    if (newFilter === currentFilter) {
      setter(undefined);
    } else {
      setter(newFilter);
    }
  }

  const handleArrayFilterChips = (
    newFilter: PaymentStatus | string | null,
    currentFilter: (PaymentStatus | string | null)[],
    setter: any
  ) => {
    // This only works for multi select filter options, so not the single select types.
    if (currentFilter.includes(newFilter)) {
      setter(currentFilter.filter((filter) => filter !== newFilter)); // remove filter from the filter array
    } else {
      setter([...currentFilter, newFilter]); // add filter to the filter array
    }
  }

  const handleResetChips = () => {
    setStatus([]);
    setType(undefined);
    setSource(undefined);
    setYears([]);
    setQuery({
      status: undefined,
      type: undefined,
      source: undefined,
      years: undefined,
      page: undefined
    });
  }

  const handleConfirmFilter = () => {
    setQuery({
      status,
      type,
      source,
      years,
      page: undefined
    });
    onClose();
  }

  return (
    <>
      <Grid container justifyContent="flex-start" px={2.5} pt={2.5}>
        <FormationsGhostButton
          rounded
          aria-label="close"
          onClick={handleConfirmFilter}
        >
          <ArrowBackIcon size={35} sx={{ pr: 1.3 }} />
        </FormationsGhostButton>
      </Grid>
      <Grid container direction="column" gap={2} px={4} pb={4} pt={2}>
        <Grid item>
          <Typography variant='subtitle1MB'>
            Filter
          </Typography>
        </Grid>
        <Grid container item direction='column' gap={2}>
          <Grid item>
            <Typography variant='body3B'>
              Status
            </Typography>
          </Grid>
          <Grid container item spacing={1} data-testid='status-chip-container'>
            {Object.values(PaymentStatus).map(filter => (
              <Grid item key={filter}>
                <PrimaryChip
                  key={`${filter}-${status.includes(filter) ? 'filled' : 'outlined'}`}
                  label={PaymentStatusToLabel[filter]}
                  variant={status.includes(filter) ? 'filled' : 'outlined'}
                  sx={chipButtonStyle(status.includes(filter))}
                  onClick={() => handleArrayFilterChips(filter, status, setStatus)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container item direction='column' gap={2}>
          <Grid item>
            <Typography variant='body3B'>
              Years
            </Typography>
          </Grid>
          <Grid container item spacing={1} data-testid='status-chip-container'>
            {Object.values(yearsList).map(filter => (
              <Grid item key={filter}>
                <PrimaryChip
                  key={`${filter}-${years.includes(filter) ? 'filled' : 'outlined'}`}
                  label={filter}
                  variant={years.includes(filter) ? 'filled' : 'outlined'}
                  sx={chipButtonStyle(years.includes(filter))}
                  onClick={() => handleArrayFilterChips(filter, years, setYears)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container item direction='column' gap={2}>
          <Grid item>
            <Typography variant='body3B'>
              Type
            </Typography>
          </Grid>
          <Grid container item spacing={1} data-testid='type-chip-container'>
            {Object.values(PaymentType).map(filter => (
              <Grid item key={filter}>
                <PrimaryChip
                  key={`${filter}-${type === filter ? 'filled' : 'outlined'}`}
                  label={PaymentTypeToLabel[filter]}
                  variant={type === filter ? 'filled' : 'outlined'}
                  sx={chipButtonStyle(type === filter)}
                  onClick={() => handleFilterChips(filter, type, setType)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container item direction='column' gap={2}>
          <Grid item>
            <Typography variant='body3B'>
              Source
            </Typography>
          </Grid>
          <Grid container item spacing={1} data-testid='note-chip-container'>
            {Object.values(PaymentSource).map(filter => (
              <Grid item key={filter}>
                <PrimaryChip
                  key={`${filter}-${source === filter ? 'filled' : 'outlined'}`}
                  label={PaymentSourceToLabel[filter]}
                  variant={source === filter ? 'filled' : 'outlined'}
                  sx={chipButtonStyle(source === filter)}
                  onClick={() => handleFilterChips(filter, source, setSource)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Box mt={4} data-testid='reset-and-filter-container'>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
            <FormationsTertiaryButton 
              onClick={handleResetChips}
              sx={resetButtonFocusStyle}
            >
              Reset
            </FormationsTertiaryButton>
            </Grid>
            <Grid item>
              <FormationsPrimaryButton onClick={handleConfirmFilter}>
                View Filtered List
              </FormationsPrimaryButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
}