import React, { useMemo, useState } from 'react';
import { EditIcon } from 'components/common/Icon';
import { Grid, Typography } from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import { queryClient } from 'states/reactQueryClient';
import { businessAddressSchema } from 'schemas/businessAddressSchema';
import { Company } from 'services/companies';
import { useUpdateCompany } from 'hooks/api/useCompanies';
import { FormationsFormDialog } from 'components/common/FormationsFormDialog';
import { BusinessAddressView } from 'views/BusinessAddressView';
import {
  FormationsFormData,
  FormationsFormFields,
  IFormField,
} from 'components/common/FormationsForm2';
import { STATES_OPTIONS } from 'enums';
import { formatZipCode } from 'components/common/FormationsForm2/FormationsTextField';
import { Address } from 'models/company';

interface IBusinessAddressProps {
  handleComplete: () => void;
  userData: any;
  companyData: Company;
}

const defaultAddress = {
  street1: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
};

const formFields: IFormField[] = [
  {
    type: FormationsFormFields.Text,
    name: 'mailingAddress1',
    label: 'Address Line 1',
    options: {
      placeholder: '1234 Ave E',
      autoFocus: true,
      maxLength: 250,
    },
  },
  {
    type: FormationsFormFields.Text,
    name: 'mailingAddress2',
    label: 'Address Line 2',
    options: {
      placeholder: '',
      maxLength: 250,
    },
  },
  {
    type: FormationsFormFields.Text,
    name: 'mailingCity',
    label: 'City',
    options: {
      placeholder: 'Seattle',
      maxLength: 50,
    },
  },
  {
    type: FormationsFormFields.AutoComplete,
    name: 'mailingState',
    label: 'State',
    options: {
      placeholder: 'Washington',
      options: STATES_OPTIONS,
    },
  },
  {
    type: FormationsFormFields.Text,
    name: 'mailingZip',
    label: 'Zip Code',
    options: {
      placeholder: '12345',
      formatInput: formatZipCode,
    },
  },
  {
    type: FormationsFormFields.Phone,
    name: 'workPhone',
    label: 'Phone Number',
    options: {
      placeholder: '(999) 999-9999',
      mask: '(999) 999-9999',
    },
  },
  {
    type: FormationsFormFields.CheckboxField,
    name: 'physicalSameAsMailing',
    label:
      'My business physical address is the same as my business mailing address',
    options: {
      placeholder: '',
    },
  },
];
const formFieldsWithPhysicalAddress: IFormField[] = [
  ...formFields,
  {
    type: FormationsFormFields.Text,
    name: 'physicalAddress1',
    label: 'Address Line 1',
    options: {
      placeholder: '1234 Ave E',
    },
  },
  {
    type: FormationsFormFields.Text,
    name: 'physicalAddress2',
    label: 'Address Line 2',
    options: {
      placeholder: '',
    },
  },
  {
    type: FormationsFormFields.Text,
    name: 'physicalCity',
    label: 'City',
    options: {
      placeholder: 'Seattle',
    },
  },
  {
    type: FormationsFormFields.AutoComplete,
    name: 'physicalState',
    label: 'State',
    options: {
      options: STATES_OPTIONS,
      placeholder: 'Washington',
    },
  },
  {
    type: FormationsFormFields.Text,
    name: 'physicalZip',
    label: 'Zip Code',
    options: {
      placeholder: '12345',
      formatInput: formatZipCode,
    },
  },
];

export const isPhysicalSameAsMailing = (
  mailingAdd: Address | undefined = defaultAddress,
  physicalAddress: Address | undefined = defaultAddress,
) =>
  mailingAdd.street1 === physicalAddress.street1 &&
  mailingAdd.street2 === physicalAddress.street2 &&
  mailingAdd.state === physicalAddress.state &&
  mailingAdd.city === physicalAddress.city &&
  mailingAdd.zip === physicalAddress.zip &&
  mailingAdd.country === physicalAddress.country;

export const BusinessAddress: React.FC<IBusinessAddressProps> = ({
  userData,
  companyData,
  handleComplete,
}) => {
  const { id: userId } = userData;
  const { contactDetails } = companyData;
  const {
    street1: mailingAddress1,
    street2: mailingAddress2,
    city: mailingCity,
    state: mailingState,
    zip: mailingZip,
  } = contactDetails?.mailingAddress || defaultAddress;

  const {
    street1: physicalAddress1,
    street2: physicalAddress2,
    city: physicalCity,
    state: physicalState,
    zip: physicalZip,
  } = contactDetails?.physicalAddress || defaultAddress;

  const [physicalSameAsMailing, setPhysicalSameAsMailing] = useState<boolean>(
    isPhysicalSameAsMailing(
      contactDetails?.mailingAddress,
      contactDetails?.physicalAddress,
    ),
  );

  const fields = useMemo<IFormField[]>(
    () => (!physicalSameAsMailing ? formFieldsWithPhysicalAddress : formFields),
    [physicalSameAsMailing],
  );
  const onChange = (formData: FormationsFormData) => {
    if (formData?.physicalSameAsMailing !== physicalSameAsMailing) {
      setPhysicalSameAsMailing(formData?.physicalSameAsMailing ?? true);
    }
  };

  const [showEditModal, setShowEditModal] = React.useState(false);

  const { mutateAsync: updateCompany, isLoading } = useUpdateCompany({
    onSuccess: async () => {
      await queryClient.invalidateQueries(['company/user', 'userid', userId]);
      await queryClient.invalidateQueries(['getCompany', companyData.id]);
      setShowEditModal(false);
    },
  });

  const onFormSubmit = async (formData: { [key: string]: string }) => {
    const address = {
      contactDetails: {
        mailingAddress: {
          street1: formData.mailingAddress1,
          street2: formData.mailingAddress2,
          city: formData.mailingCity,
          state: formData.mailingState,
          zip: formData.mailingZip,
        },
        physicalAddress: {
          street1: formData.physicalAddress1,
          street2: formData.physicalAddress2,
          city: formData.physicalCity,
          state: formData.physicalState,
          zip: formData.physicalZip,
        },
        workPhone: formData.workPhone,
      },
    };
    if (formData.physicalSameAsMailing) {
      address.contactDetails.physicalAddress =
        address.contactDetails.mailingAddress;
    }
    await updateCompany({ id: companyData.id!, data: address });
    handleComplete();
  };

  return (
    <div>
      <Grid container direction="column">
        <Grid container direction="row" alignItems="baseline" rowGap={2}>
          <Typography variant="h5B" component="span">
            Business Address
          </Typography>
          <FormationsGhostButton
            aria-label="delete"
            sx={{ marginLeft: '24px' }}
            startIcon={<EditIcon size={22} sx={{ pb: 0.25 }} />}
            onClick={() => setShowEditModal(true)}
            data-testid="business-address-edit-btn"
          >
            Edit
          </FormationsGhostButton>
        </Grid>
        {companyData?.contactDetails && (
          <BusinessAddressView contactDetails={companyData?.contactDetails} />
        )}
      </Grid>
      {showEditModal && (
        <FormationsFormDialog
          title="Edit Business Address"
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          onChange={onChange}
          onSubmit={onFormSubmit}
          fields={fields}
          data-testid="edit-business-address-dialog"
          defaultValues={{
            mailingAddress1,
            mailingAddress2,
            mailingCity,
            mailingState,
            mailingZip,
            workPhone: contactDetails?.workPhone,
            physicalSameAsMailing,
            physicalAddress1,
            physicalAddress2,
            physicalCity,
            physicalState,
            physicalZip,
            mode: 'onSubmit',
            reValidateMode: 'onChange',
          }}
          validationSchema={businessAddressSchema}
          loading={isLoading}
        />
      )}
    </div>
  );
};
