import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { EmptyState } from 'components/common/EmptyState';
import { styled } from '@mui/material/styles';
import { documentsHeaders } from '../header/DocumentHeader';

const EmptyRow = styled(TableCell)(() => ({
  borderBottom: 'none',
}));

export const EmptyTable = () => (
    <TableRow data-testid="document-empty-table">
      <EmptyRow colSpan={documentsHeaders.length}>
        <EmptyState message="No documents available!" />
      </EmptyRow>
    </TableRow>
);
