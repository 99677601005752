import { api } from './axios';

export interface RelayApplicationStatus {
  IDField?: string; 
  accountID: string;
  relayApplicationID: string;
  relayApplicationStatus: string;
  documentsUploaded: any;
  createdAt: string;
  updatedAt: string;
}

export const createRelayAccountApplication = async (accountId: string) => {
  const { data } = await api.post<{ data: RelayApplicationStatus }>(`relay-banking/${accountId}/application`);
  return data;
}

export const getRelayAccountApplication = async (accountId: string) => {
  const { data } = await api.get<RelayApplicationStatus>(`relay-banking/${accountId}/application`);
  return data;
}