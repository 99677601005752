import { BankAccount } from 'models/bankAccounts';

export const getBankAccountDataSummary = (
  bankAccounts: BankAccount[],
): {
  connectedHurdlrBankAccountsCount: number;
  activeHurdlrBankAccountsCount: number;
  inactiveHurdlrBankAccountsCount: number;
  errorHurdlrBankAccountsCount: number;
  hurdlrBankNames: string;
} => {
  const connectedHurdlrBankAccountsCount = bankAccounts.length;
  const activeHurdlrBankAccountsCount = bankAccounts.filter(
    (bankAccount) => bankAccount.status === 'ACTIVE',
  ).length;
  const inactiveHurdlrBankAccountsCount = bankAccounts.filter(
    (bankAccount) => bankAccount.status === 'INACTIVE',
  ).length;
  const errorHurdlrBankAccountsCount = bankAccounts.filter(
    (bankAccount) => bankAccount.errorCode !== null,
  ).length;
  const hurdlrBankNamesInit = bankAccounts.map(
    (bankAccount) => bankAccount.institutionName,
  );
  const hurdlrBankNames = [...new Set(hurdlrBankNamesInit)]
    .sort((a, b) => a.localeCompare(b))
    .join(', ');
  return {
    connectedHurdlrBankAccountsCount,
    activeHurdlrBankAccountsCount,
    inactiveHurdlrBankAccountsCount,
    errorHurdlrBankAccountsCount,
    hurdlrBankNames,
  };
};
