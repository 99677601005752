import { useState, MouseEvent } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import { 
  ArrowForwardIcon, 
  CircleIcon,
  MoreHorizIcon,
  NotificationsActiveIcon, 
} from 'components/common/Icon';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { useCurrentAccount } from 'hooks/api';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  ListItemIcon: {
    minWidth: '24px !important',
  },
}));

const notificationsList = [
  {
    id: '1',
    type: 'DEFAULT',
    title: 'New Unknown Transaction',
    message:
      'You have 10 new unknown transactions from Chase to be categorized.',
    readState: 'unread',
    timestamp: '2022-06-13T11:40:09.376Z',
    link: '/dashboard/accounting/unknown-transactions',
  },
  {
    id: '2',
    type: 'DEFAULT',
    title: 'New Unknown Transaction',
    message:
      'You have 10 new unknown transactions from Chase to be categorized.',
    readState: 'unread',
    timestamp: '2022-06-09T16:30:09.376Z',
    link: '/dashboard/accounting/unknown-transactions',
  },
  {
    id: '3',
    type: 'DEFAULT',
    title: 'Recommendation',
    message:
      'Please provide us personal and company information to create your LLC ',
    readState: 'unread',
    timestamp: '2022-06-09T14:30:09.376Z',
    link: '/dashboard/accounting/unknown-transactions',
  },
  {
    id: '4',
    type: 'DEFAULT',
    title: 'To-do',
    message:
      'You have 10 new unknown transactions from Chase to be categorized.',
    readState: 'read',
    timestamp: '2022-06-09T13:30:09.376Z',
    link: '/dashboard/accounting/unknown-transactions',
  },
  {
    id: '5',
    type: 'DEFAULT',
    title: 'New Unknown Transaction',
    message:
      'You have 10 new unknown transactions from Chase to be categorized.',
    readState: 'read',
    timestamp: '2022-06-09T12:30:09.376Z',
    link: '/dashboard/accounting/unknown-transactions',
  },
];

export default function NotificationMenu() {
  const { t } = useTranslation();
  const { currentAccount } = useCurrentAccount();
  const customerStatus = currentAccount?.status?.label ?? '';
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actions = [
    {
      name: t('topNavBar.notification.settings'),
      icon: ArrowForwardIcon,
      clickHandler: () => undefined,
      hiddenForStatus: ['NEW'],
    },
  ];

  const menuActions = () =>
    actions.map((action) =>
      action.hiddenForStatus.includes(customerStatus) ? null : (
        <MenuItem
          key={action.name}
          onClick={action.clickHandler}
        >
          <Grid container alignItems="center">
            <Typography variant="body3S" mr={1}>
              {action.name}
            </Typography>
            <action.icon />
          </Grid>
        </MenuItem>
      ),
    );

  const classes = useStyles();
  return (
    <Box>
      <Tooltip title={t('topNavBar.notification.tooltipTitle')}>
        <FormationsGhostButton rounded onClick={handleOpen}>
          <NotificationsActiveIcon />
        </FormationsGhostButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ style: { minWidth: '180px', paddingTop: 0 } }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Typography variant="h6" sx={{ opacity: 0.5, p: 2 }}>
          {t('topNavBar.notification.name')}
        </Typography>
        <Divider />

        {notificationsList.map((notification) => {
          const unread = notification.readState === 'unread';
          return (
            <MenuItem
              key={notification.id}
              sx={{
                mb: unread ? '8px' : '0px',
                background: unread ? '#F2F3F6' : '#FFF',
              }}
            >
              <ListItemIcon className={classes.ListItemIcon}>
                {unread && (
                  <CircleIcon color="warning" sx={{ fontSize: '12px' }} size={12} fill={1} />
                )}
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="body3S"
                  component="span"
                  sx={{ fontWeight: 700 }}
                >
                  {notification.title}&nbsp;
                </Typography>
                <Typography variant="body3S" component="span" color="GrayText">
                  {moment(notification.timestamp).fromNow()}
                </Typography>
                <FormHelperText
                  sx={{ maxWidth: '276px', whiteSpace: 'normal' }}
                >
                  {notification.message}
                </FormHelperText>
              </ListItemText>
              <ListItemIcon
                className={classes.ListItemIcon}
                sx={{ ml: '12px' }}
              >
                <MoreHorizIcon />
              </ListItemIcon>
            </MenuItem>
          );
        })}
        <Divider />
        {menuActions()}
      </Menu>
    </Box>
  );
}
