import { Typography } from '@mui/material';
import { ALLOWED_BANKS_BACKEND, displaySelectedBankName } from 'enums';
import { BankNameKey, Company } from 'models/company';
import { useTranslation } from 'react-i18next';
import { UserInfo } from 'services/users';

export const SelectedBank = ({ company, isBIB, user }: { company: Company, isBIB?: boolean, user: Partial<UserInfo> }) => {
  const { t } = useTranslation();
  let bankName: BankNameKey | string = company.bankName ?? '';
  if (isBIB && bankName === 'other') {
    bankName = user?.bankName ?? '';
  }
  const bankDisplayName = displaySelectedBankName(ALLOWED_BANKS_BACKEND[bankName] ?? bankName);
  return (
    <>
      <Typography variant="h5B" component="h5" sx={{ mb: 2 }}>
        {t('progressTracker.bankSetup.bankSelection')}
      </Typography>
      {!company?.hasBankAccount && !isBIB && (
        <>
          <Typography variant="body1" component="p">
          {t('progressTracker.bankSetup.doNotHaveBusinessAccount')}
          </Typography>

          <Typography variant="body1" component="p" sx={{ mb: 2 }}>
          {t('progressTracker.bankSetup.likeToUseThisBank')}
          </Typography>
        </>
      )}
      <Typography variant="body2" component="p">
       {t('progressTracker.bankSetup.bankName')}
      </Typography>
      <Typography variant="body2B" component="p">
        {bankDisplayName}
      </Typography>
    </>
  );
};
