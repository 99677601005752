import React, { useEffect, useMemo, useState } from 'react';
import { FormationsDialog } from 'components/FormationsDialog';
import { queryClient } from 'states/reactQueryClient';
import { useDownloadDocument, useIncreaseCustomerReadCount, useUpdateDocument } from 'hooks/api/useDocuments';
import { VisibilityPopup } from 'components/documentsV1/VisibilityPopup';
import { downloadFileBlob } from 'helpers/documents';
import { CHURN_ZERO_EVENT_NAMES } from 'constants/common';
import { sendChurnZeroEvent } from 'helpers/churnZero';
import { FilePreviewComponent } from './FilePreviewComponent';
import { IFormationsPreviewDocument } from './FilePreviewComponent/FilePreviewComponent';
import { PreviewHeader, PreviewHeaderAction } from './PreviewActionComponent';
import { useCurrentUserRole, useSpoof } from '../../hooks/api';
import { Routes } from '../../fnRoutes';
import { readCountShouldIncrease } from './helpers';

interface IFilePreviewDialogProps {
  open: boolean;
  onClose: () => void;
  enableAction?: boolean;
  file: IFormationsPreviewDocument;
  files: IFormationsPreviewDocument[];
  accountId: string;
  taxPaymentMobileView?: boolean;
  isMobile?: boolean;
}

export const FilePreviewDialog: React.FC<IFilePreviewDialogProps> = ({
  open,
  onClose,
  enableAction = false,
  file,
  files,
  accountId,
  taxPaymentMobileView = false,
  isMobile = false,
}) => {
  const { isAdmin } = useCurrentUserRole();
  const { isAdminSpoof } = useSpoof();
  const { mutate: increaseCustomerReadCount } = useIncreaseCustomerReadCount();
  const [loading, setLoading] = useState<boolean>(false);
  const [isVisibilityModalOpen, setIsVisibilityModalOpen] =
    useState<boolean>(false);
  const [currentViewingFile, setCurrentViewingFile] =
    useState<IFormationsPreviewDocument>(file);

  useEffect(() => setCurrentViewingFile(file), [file]);

  useEffect(() => {
    if (readCountShouldIncrease(currentViewingFile, isAdmin, isAdminSpoof, accountId)) {
      increaseCustomerReadCount(currentViewingFile.id);
    }
  }, [currentViewingFile]);

  const onSideArrowClick = (newFileIndex: number) => {
    if (files[newFileIndex]) {
      setCurrentViewingFile(files[newFileIndex]);
    }
  };

  const deeplinkUrl = useMemo(
    () =>
      `${window.location.origin}${Routes.DOCUMENT_PREVIEW}?accountId=${accountId}&fileId=${file.id}`,
    [file, accountId],
  );

  const disableVisibleEdit = useMemo(() => {
    if (
      currentViewingFile?.isVisibilityEditable ||
      currentViewingFile?.role === 'Admin'
    ) {
      return false;
    }
    return true;
  }, [currentViewingFile]);

  const refreshGrid = () => {
    queryClient.invalidateQueries(['documents', 'accountId', accountId]);
  };

  const { blob: downloadDocumentBlob, isLoading: isDocumentDownloading } =
    useDownloadDocument(currentViewingFile?.id || '');
  const { mutateAsync: updateDocument, isLoading: isDocumentUpdating } =
    useUpdateDocument({
      onSuccess: (data) => {
        setCurrentViewingFile({
          ...currentViewingFile,
          visibleToCustomer: data?.data?.visibleToCustomer,
        });
        refreshGrid();
      },
    });

  const isLoading = useMemo(
    () => loading || isDocumentUpdating || isDocumentDownloading,
    [isDocumentUpdating, loading, isDocumentDownloading],
  );

  const onCloseDialog = () => {
    onClose();
  };

  const handleEditVisibility = async () => {
    setIsVisibilityModalOpen(false);
    await updateDocument({
      id: currentViewingFile?.id || '',
      form: {
        visibleToCustomer: !currentViewingFile?.visibleToCustomer,
      },
    });
  };

  const handleDocumentDownload = async () => {
    if (downloadDocumentBlob) {
      setLoading(true);
      downloadFileBlob(downloadDocumentBlob, currentViewingFile?.title || '');
      sendChurnZeroEvent(CHURN_ZERO_EVENT_NAMES.DOCUMENT_DOWNLOAD);
      setLoading(false);
    }
  };

  return (
    <FormationsDialog
      open={open}
      onClose={onCloseDialog}
      heading={
        <PreviewHeader
          document={currentViewingFile}
          title={currentViewingFile?.title || ''}
          disableEditVisibility={disableVisibleEdit}
          visibleToCustomer={currentViewingFile?.visibleToCustomer || false}
          onToggleVisibility={() => setIsVisibilityModalOpen(true)}
          isMobile={isMobile}
        />
      }
      headingRight={!taxPaymentMobileView && (
        <PreviewHeaderAction
          isLoading={isLoading}
          showCopyUrlButtons={isAdmin}
          deeplinkUrl={deeplinkUrl}
          handleDocumentDownload={handleDocumentDownload}
        />
      )
      }
      backButton
      fullScreen
      excludeFooter
    >
      {currentViewingFile && (
        <FilePreviewComponent
          accountId={accountId}
          enableAction={enableAction}
          file={currentViewingFile}
          files={files}
          actionCallBack={onCloseDialog}
          showSideArrow
          onSideArrowClick={onSideArrowClick}
        />
      )}

      <VisibilityPopup
        open={isVisibilityModalOpen}
        status={!currentViewingFile?.visibleToCustomer}
        onSave={handleEditVisibility}
        onClose={() => setIsVisibilityModalOpen(false)}
      />
    </FormationsDialog>
  );
};
