import { FormationsGhostButton } from 'components/common/Buttons';
import { AddIcon } from 'components/common/Icon';
import { useTranslation } from 'react-i18next';
import { QuickFilterSection } from './QuickFilterSection';
import { TaxesDirectPaymentTable } from './TaxesDirectPaymentTable';
import { TaxesDirectPaymentCard } from './TaxesDirectPaymentCard';

type Props = {
  accountId: string | undefined;
  isLoadingUser?: boolean;
  setPaymentToEdit?: Function;
  setShowFormDialog: Function;
  isMobile?: boolean;
};

export const TaxesFilterAndTable = ({
  accountId,
  isLoadingUser,
  setPaymentToEdit,
  setShowFormDialog,
  isMobile = false,
}: Props) => {
  
  const { t } = useTranslation();

  return (
    <>
      <QuickFilterSection isMobile={isMobile} />
      {isMobile && (
        <FormationsGhostButton
          aria-label="edit"
          startIcon={<AddIcon sx={{ pb: 0.25 }} />}
          onClick={() => setShowFormDialog(true)}
          data-testid="upload-btn"
          sx={{ mb: 2 }}
        >
          {t('taxes.uploadButton')}
        </FormationsGhostButton>
      )}
      {isMobile ? (
        <TaxesDirectPaymentCard
          accountId={accountId}
          isLoadingUser={isLoadingUser}
          setPaymentToEdit={setPaymentToEdit}
        />
      ) : (
        <TaxesDirectPaymentTable
          accountId={accountId}
          isLoadingUser={isLoadingUser}
          setPaymentToEdit={setPaymentToEdit}
        />
      )}
    </>
  )
};
