import { Button, Checkbox, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { FavoriteIcon } from 'components/common/Icon';
import { 
  InsertDriveFileOutlined as InsertDriveFileIcon, 
  PlayCircleOutlined as PlayCircleIcon 
} from '@mui/icons-material';
import { useCurrentUserRole } from 'hooks/api';
import { FormationsTableDocument } from '../../common/tables/DocumentsTable';

const DocumentNameContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
}));
const DocumentIconContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const DocumentTitle = styled(Typography)(() => ({
  display: 'inline-block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textAlign: 'left',
}));

interface Props {
  document: FormationsTableDocument;
  isFavourite: boolean;
  openDocumentPreview: (document: FormationsTableDocument) => void;
  checked: boolean;
  onCheckboxClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DocumentIcon = ({
  document,
  className,
  sx,
}: {
  document: FormationsTableDocument;
  className?: string;
  sx?: any;
}) => {
  const type = document?.metadata?.mimeType;
  switch (type) {
    case 'video/mp4':
      return <PlayCircleIcon sx={sx} className={className} />;
    case 'application/pdf':
    case 'image/png':
    case 'image/jpeg':
    case 'image/jpg':
    default:
      return <InsertDriveFileIcon sx={sx} className={className} />;
  }
};

export const DocumentNameCell = ({
  document,
  openDocumentPreview,
  checked,
  onCheckboxClick,
  // TODO: implement favourite
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isFavourite,
}: Props) => {
  const handleDocumentClick = (event: React.MouseEvent<unknown>) => {
    event.stopPropagation();
    openDocumentPreview(document);
  };
  const { isAdmin } = useCurrentUserRole();

  const adminIcon = isFavourite ? (
    <FavoriteIcon />
  ) : (
    <DocumentIcon document={document} sx={{ padding: '9px' }} />
  );
  return (
    <DocumentNameContainer>
      {isAdmin ? (
        adminIcon
      ) : (
        <DocumentIconContainer>
          {checked ? (
            <Checkbox
              className="activeCheckbox"
              color="primary"
              checked={checked}
              inputProps={{
                'aria-labelledby': document.id,
              }}
              onChange={onCheckboxClick}
              sx={{ height: 42 }}
            />
          ) : (
            <DocumentIcon
              className="activeIcon"
              document={document}
              sx={{ padding: '9px' }}
            />
          )}
          <Checkbox
            className="hoverCheckbox"
            color="primary"
            checked={checked}
            inputProps={{
              'aria-labelledby': document.id,
            }}
            onChange={onCheckboxClick}
            sx={{ height: 42 }}
          />
        </DocumentIconContainer>
      )}
      <Tooltip
        placement="top"
        title={<Typography variant="tooltip">{document.title}</Typography>}
        data-testid="document-name-cell"
      >
        <Button
          aria-label="edit"
          data-testid="title-btn"
          size="large"
          sx={{
            width: isAdmin ? '100%' : 'auto',
          }}
          onClick={handleDocumentClick}
        >
          <DocumentTitle
            variant="chip"
            sx={{ width: isAdmin ? '100%' : '200px' }}
          >
            {document.title}
          </DocumentTitle>
        </Button>
      </Tooltip>
    </DocumentNameContainer>
  );
};
