import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Grid,
  Typography,
} from '@mui/material';
import { ExpandMoreIcon } from 'components/common/Icon';
import {
  useCurrentAccount,
} from 'hooks/api/useAccounts';
import { useCurrentCompany } from 'hooks/api/useCompanies';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IAccount,
} from 'services/account';
import { Company } from 'services/companies';
import { useCurrentPaymentStatus } from 'hooks/api/useStripe';
import { ReviewAndPayView } from './ReviewAndPayView';
import { StageSkeleton } from '../StageSkeleton';
import { AccordionCTA } from '../AccordionCTA';

interface ReviewAndPayProps {
  companyData: Company;
  account: IAccount;
  expanded: boolean | string;
  handleExpand: (
    panel: string,
  ) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  disabled: boolean;
}

export const ReviewAndPay = ({
  companyData,
  account: accData,
  expanded,
  handleExpand,
  disabled,
}: ReviewAndPayProps) => {
  const { t } = useTranslation();
  const { alreadyPaid } = useCurrentPaymentStatus();

  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    if (alreadyPaid) {
      setCompleted(1);
    }
  }, [alreadyPaid]);


  return (
    <Accordion
      expanded={expanded === 'review-and-pay'}
      onChange={handleExpand('review-and-pay')}
      disabled={disabled}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="review-and-pay-content"
        id="review-and-pay-header"
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h6B">
              {t('Pay and Continue')}
            </Typography>
            <Typography variant="subtitle1">
              Review your information and complete your payment
            </Typography>
            <Typography variant="body2" color='text.secondary'>
              Processing time: instant
            </Typography>
            <AccordionCTA
              expanded={expanded}
              handleExpand={handleExpand}
              completed={completed}
              totalStep={1}
              testId="start-review-and-pay"
              accordionId="review-and-pay"
            />
          </Grid>
        </Grid>
        {!expanded && (
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
            <Typography variant="h8B">
              {completed}/{1}
            </Typography>
          </Grid>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <ReviewAndPayView
          account={accData}
          company={companyData}
        />
      </AccordionDetails>
    </Accordion>
  );
};

interface ReviewAndPayComponentProps {
  expanded: boolean | string;
  handleExpand: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  disabled: boolean;
}

export const ReviewAndPayComponent: React.FC<ReviewAndPayComponentProps> = ({ expanded, handleExpand, disabled }) => {
  const {
    currentCompany: companyData,
    isLoading: companyDataLoading,
    isError: isCompanyDataError,
  } = useCurrentCompany();
  const {
    currentAccount,
    isLoading: fetchingCurrentAccount,
    isError: isAccountError,
  } = useCurrentAccount();

  if (fetchingCurrentAccount || companyDataLoading) {
    return <StageSkeleton />;
  }
  if (companyData != null && currentAccount != null) {
    return (
      <ReviewAndPay 
        companyData={companyData} 
        account={currentAccount}
        handleExpand={handleExpand}
        expanded={expanded}
        disabled={disabled}
      />
    );
  }
  if (isCompanyDataError && isAccountError) {
    return (
      <Alert severity="error">
        Error fetching data. Please try to refresh or contact for support
      </Alert>
    );
  }
  return null;
};
