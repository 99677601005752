import { TextField } from '@mui/material';
import { Controller, UseFormMethods } from 'react-hook-form';
import { get } from 'lodash';
import { useMemo } from 'react';
import { IFormField } from './types';

interface TTextAreaOptions {
  placeholder?: string;
  characterLimit?: number;
  maxRow?: number;
  minRow?: number;
  InputProps?: any;
}

type TFormationsTextAreaProps = {
  field: IFormField<TTextAreaOptions>;
  formInstance: UseFormMethods;
};

const defaultOptions: TTextAreaOptions = {
  placeholder: '',
  maxRow: undefined,
  minRow: undefined,
};

export const FormationsTextArea = ({
  field,
  formInstance,
}: TFormationsTextAreaProps) => {
  const { disabled = false } = field;
  const { errors, control, watch } = formInstance;
  const { options = defaultOptions, defaultValue = '' } = field;
  const { placeholder, characterLimit, maxRow, minRow = 4, InputProps } = options;
  const errorMsg = get(errors, [field.name, 'message'], '');
  const wordCount = get(watch(field.name), 'length', 0);
  const wordCountText = useMemo(() => {
    if (characterLimit) {
      return `${wordCount}/${characterLimit}`;
    }
    return wordCount.toString();
  }, [watch(field.name)]);
  return (
    <Controller
      name={field.name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <TextField
          name={field.name}
          onChange={onChange}
          value={value}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          fullWidth
          label={field.label}
          placeholder={placeholder}
          multiline
          maxRows={maxRow}
          minRows={minRow}
          error={
            !!errorMsg || (characterLimit != null && wordCount > characterLimit)
          }
          helperText={errorMsg || wordCountText}
          data-testid={`field-${field.name}`}
          disabled={disabled}
          InputProps={InputProps}
        />
      )}
    />
  );
};
