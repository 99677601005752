import {
  IndustryTableData,
  IndustryOptionsActivityLog,
} from 'components/IndustryOptions/types';
import { ApiListResp } from 'models/api';
import { api } from './axios';

export const getIndustryOptions = async (): Promise<IndustryTableData[]> => {
  const { data } = await api.get<IndustryTableData[]>('industry-options');
  return data;
};

export const getIndustryOptionsActivity = async (page: number = 1): Promise<
  ApiListResp<IndustryOptionsActivityLog[]>
> => {
  const { data } = await api.get<ApiListResp<IndustryOptionsActivityLog[]>>(
    'industry-options/op-logs',
    {
      params: { page },
    },
  );
  return data;
};

export const updateIndustryOptions = async (
  params: IndustryTableData,
): Promise<IndustryTableData> => {
  const { data } = await api.put<IndustryTableData>('industry-options', params);
  return data;
};

export const createIndustryOptions = async (
  params: IndustryTableData,
): Promise<void> => {
  await api.post('industry-options', params);
};
