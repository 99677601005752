import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { theme } from 'theme';
import { Controller, UseFormMethods } from 'react-hook-form';
import { IFormField } from './types';

const useStyles = makeStyles(() => ({
  securedTextField: {
    '& input': {
      fontFamily: 'password',
    },
  },
}));

type TFormationsSecuredOptions = {
  helperText?: string;
  maxLength?: string;
  placeholder?: string;
  readOnly?: boolean;
  visibility?: boolean;
};

type TFormationsSecuredField = {
  field: IFormField<TFormationsSecuredOptions>;
  formInstance: UseFormMethods;
};

export const FormationsSecuredField = ({
  field,
  formInstance,
}: TFormationsSecuredField) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { errors, control } = formInstance;
  const {
    readOnly = false,
    options: { helperText = '', maxLength, placeholder = '' } = {},
  } = field;
  const [visibility, setVisibility] = useState(!!field?.options?.visibility);
  const helpText = errors[field?.name]?.message || helperText;

  const renderVisibilityControls = () => (
    <InputAdornment position="end">
      <IconButton
        aria-label={`toggle ${field.name} visibility`}
        onClick={() => setVisibility(!visibility)}
        onMouseDown={() => setVisibility(!visibility)}
        edge="end"
        component="span"
        data-testid="reveal-btn"
        disabled={field?.disabled}
        sx={{ color: theme.palette.text.secondary }}
      >
        {visibility ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <Controller
      name={field.name}
      control={control}
      defaultValue={field.defaultValue || ''}
      render={({ onChange, value }) => (
        <TextField
          onChange={onChange}
          value={value}
          label={field.label}
          name={field.name}
          placeholder={placeholder}
          variant="outlined"
          fullWidth
          className={`${!visibility && classes.securedTextField}`}
          disabled={field.disabled}
          inputProps={{
            maxLength,
            readOnly,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            endAdornment: renderVisibilityControls(),
          }}
          error={!!errors[field?.name]}
          helperText={t(helpText)}
          data-testid={`field-${field.name}`}
        />
      )}
    />
  );
};
