import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Grid, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import React, { useEffect, useState } from 'react';
import { EditIcon } from 'components/common/Icon';
import { EditNameDialog } from './EditNameDialog';
import { useCurrentUserRole } from '../../../hooks/api';
import { IFormationsPreviewDocument } from '../FilePreviewComponent/FilePreviewComponent';

interface Props {
  document?: IFormationsPreviewDocument;
  title: string;
  disableEditVisibility?: boolean;
  visibleToCustomer?: boolean;
  onToggleVisibility: () => void;
  isMobile?: boolean;
}

export const PreviewHeader = (props: Props) => {
  const {
    document,
    title,
    disableEditVisibility = false,
    visibleToCustomer = false,
    onToggleVisibility,
    isMobile = false,
  } = props;
  const [cacheDocument, setCacheDocument] = useState<
    IFormationsPreviewDocument | undefined
  >(document);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { isAdmin } = useCurrentUserRole();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeDialog = () => {
    setAnchorEl(null);
  };

  const onSuccess = (newName: string) => {
    if (!document) return;
    setCacheDocument({
      ...document,
      title: newName,
    });
    setAnchorEl(null);
  };

  useEffect(() => {
    if (document) setCacheDocument(document);
  }, [document]);

  return (
    <Grid item>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid container direction="row" alignItems="center"> 
          <Typography 
            variant={isMobile ? 'body3B' : 'h6'} 
            sx={{ 
              width: isMobile ? 200 : undefined, 
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis' 
            }}
          >
            {cacheDocument?.title ?? title}
          </Typography>
          <FormationsGhostButton rounded sx={{ ml: 1 }} onClick={handleClick}>
            <EditIcon />
          </FormationsGhostButton>
        </Grid>
        {isAdmin && (
          <FormControlLabel
            control={
              <Checkbox
                checkedIcon={<Visibility />}
                icon={<VisibilityOff />}
                name="checked"
                checked={visibleToCustomer}
                disabled={disableEditVisibility}
                onClick={onToggleVisibility}
              />
            }
            label={
              visibleToCustomer ? 'Customer-visible' : 'Not customer-visible'
            }
          />
        )}
      </Grid>
      {cacheDocument && anchorEl && (
        <EditNameDialog
          document={cacheDocument}
          anchorEl={anchorEl}
          open
          onClose={closeDialog}
          onSuccess={onSuccess}
        />
      )}
    </Grid>
  );
};
