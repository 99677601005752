import { IndustryTableData } from './types';

export const findIndustryTitleFromCode = (
  industryOptions: IndustryTableData[],
  industryCode?: string,
): string => {
  const found = industryOptions.find((option) => option.code === industryCode);
  return found ? found.title : '';
};

export const findIndustryDescriptionFromCode = (
  industryOptions: IndustryTableData[],
  industryCode?: string,
): string => {
  const found = industryOptions.find((option) => option.code === industryCode);
  return found ? found.description : '';
};
