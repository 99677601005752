import _ from 'lodash';
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Typography,
} from '@mui/material';
import { ArrowDropDownIcon } from 'components/common/Icon';
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { QueryParamConfigMap, SetQuery } from 'use-query-params';
import { FilterButton, highlightCSS, isHighlighted } from './common';
import { FilterPopover } from './FilterPopover';
import { Search } from './FilterPopover/Search';

export type FilterItem = {
  value: string;
  title: string;
};

type ListFilterProps = {
  list: FilterItem[];
  defaultSelected?: (string | null)[];
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  onClear: () => void;
  onClose: () => void;
  onConfirm: (options: (string | null)[]) => void;
};

export const ListFilter = ({
  list,
  defaultSelected = [],
  open,
  anchorEl,
  onClear,
  onClose,
  onConfirm,
}: ListFilterProps) => {
  const [defaultState, setDefaultState] = useState<(string | null)[]>([]);
  const [value, setValue] = useState<string>('');
  const [selected, setSelected] = useState<(string | null)[]>([]);
  const allSelected = selected?.length === list.length;

  const initialState =
    defaultSelected.length === 0
      ? list.map((item) => item.value) // if no filter applied select all
      : defaultSelected;

  useEffect(() => {
    setSelected(initialState);
    setDefaultState(initialState);
  }, [defaultSelected, list]);

  const filteredCategoryBySearchText = useMemo(
    () =>
      list.filter((item) =>
        item?.title.toLowerCase().includes(value.toLowerCase()),
      ),
    [value, list, selected],
  );

  const handleCheckBox = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const found = selected.find((item) => item === e.target.value);
      if (found) {
        setSelected(selected.filter((item) => item !== e.target.value));
      } else {
        setSelected([...selected, e.target.value]);
      }
    },
    [selected],
  );

  const handleSelectAllCheckBox = useCallback(() => {
    if (allSelected) {
      setSelected([]);
    } else {
      setSelected(list.map((category) => category.value));
    }
  }, [allSelected]);

  const handleClear = useCallback(() => {
    setSelected([]);
    setValue('');
    onClear();
  }, []);

  const handleClose = useCallback(() => {
    setSelected(initialState);
    setDefaultState(initialState);
    onClose();
  }, [initialState]);

  const handleConfirm = useCallback(
    () => onConfirm(!allSelected && selected ? selected : []),
    [selected, allSelected],
  );

  const isDirty = useMemo(
    () => !_.isEqual(selected.sort(), defaultState.sort()),
    [selected, defaultState],
  );

  return (
    <FilterPopover
      open={open}
      anchorEl={anchorEl}
      onClear={handleClear}
      onClose={handleClose}
      onConfirm={handleConfirm}
      saveDisabled={!isDirty}
      clearDisabled={allSelected}
    >
      <FilterPopover.Header>
        <Typography sx={{ m: 3 }} variant="body2B" component="h6">
          Filter By
        </Typography>
        <Search
          value={value}
          onChange={setValue}
          sx={{ width: '100% !important', paddingLeft: '0 !important' }}
        />
      </FilterPopover.Header>
      <FilterPopover.Content sx={{ maxHeight: '216px', overflowY: 'auto' }}>
        {value === '' && (
          <MenuItem value="all">
            <FormControlLabel
              componentsProps={{
                typography: {
                  variant: 'body2S',
                },
              }}
              label="Select All"
              value={allSelected}
              control={
                <Checkbox
                  checked={allSelected}
                  onChange={handleSelectAllCheckBox}
                />
              }
            />
          </MenuItem>
        )}
        {filteredCategoryBySearchText.map((category) => (
          <MenuItem key={category.value}>
            <FormControlLabel
              componentsProps={{
                typography: {
                  variant: 'body2S',
                },
              }}
              label={category.title}
              value={category.value}
              control={
                <Checkbox
                  checked={allSelected || selected.includes(category.value)}
                  onChange={handleCheckBox}
                  inputProps={{
                    'aria-labelledby': category.title,
                    // @ts-ignore
                    'data-testid': category.value,
                  }}
                />
              }
            />
          </MenuItem>
        ))}
      </FilterPopover.Content>
    </FilterPopover>
  );
};

type TProps = {
  name: string;
  query: { [key: string]: unknown };
  setQuery: SetQuery<QueryParamConfigMap>;
  list: FilterItem[];
  selected?: (string | null)[];
};

export const FormationsListFilter = ({
  name,
  query,
  setQuery,
  list,
  selected = [],
}: TProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const setQueryWithPageReset = useCallback(
    (options: { [key: string]: (string | null)[] | undefined }) =>
      setQuery({ page: 1, ...options }),
    [setQuery],
  );

  const handleFilter = useCallback((options: (string | null)[]) => {
    if (options && options?.length > 0) {
      setQueryWithPageReset({
        [name]: options,
      });
    } else {
      setQueryWithPageReset({
        [name]: undefined,
      });
    }
    setAnchorEl(null);
  }, []);

  const handleClear = useCallback(() => {
    setQueryWithPageReset({
      [name]: undefined,
    });
    setAnchorEl(null);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <FilterButton
        rounded
        onClick={openPopover}
        data-testid={`${name}-filter-btn`}
        sx={isHighlighted(name, query) ? highlightCSS : {}}
      >
        <ArrowDropDownIcon />
      </FilterButton>
      <ListFilter
        list={list}
        defaultSelected={selected}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClear={handleClear}
        onClose={handleClose}
        onConfirm={handleFilter}
      />
    </>
  );
};
