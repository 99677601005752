import { Grid, Typography, TableCell, Tooltip } from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import { 
  CancelIcon, 
  CheckCircleIcon,
  DoneAllIcon,
  TimelapseIcon,
  WarningIcon 
} from 'components/common/Icon';
import { numberFormat } from 'helpers/currencyFormat';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { PaymentStatus, PaymentType } from 'enums';
import { MOMENT_DATE_FORMAT } from 'helpers/dateTimeFormat';
import { capitalizeFirstLetter } from 'helpers/text-transformer';
import { DirectPayment } from 'components/taxes/types';
import { FormationsListFilter } from 'components/common/FormationsFilters/FormationsListFilter';
import { useTaxesQuery } from 'hooks/api/useTaxes';

const SubmittedChip = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.others.newYellow,
  borderRadius: theme.spacing(2),
}));

const ApprovedChip = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.others.green,
  borderRadius: theme.spacing(2),
  '&.MuiTypography-root': {
    color: '#FFFFFF',
  },
}));

const DeclinedChip = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.others.red,
  borderRadius: theme.spacing(2),
  '&.MuiTypography-root': {
    color: '#FFFFFF',
  },
}));

export const textMappings = {
  [PaymentStatus.Submitted]: 'pending',
  [PaymentStatus.Rejected]: 'declined',
  [PaymentType.Federal]: 'IRS',
};

const SubmittedChipCell = ({ text }: { text: string }) => (
  <SubmittedChip
    variant="chip"
    color="text.primary"
    sx={{ pl: 2, pr: 2, pt: 1, pb: 1, whiteSpace: 'nowrap' }}
  >
    {capitalizeFirstLetter(textMappings[text as keyof typeof textMappings])}
  </SubmittedChip>
);

const ApprovedChipCell = ({ text }: { text: string }) => (
  <ApprovedChip
    variant="chip"
    color="text.primary"
    sx={{ pl: 2, pr: 2, pt: 1, pb: 1, whiteSpace: 'nowrap' }}
  >
    {capitalizeFirstLetter(text)}
  </ApprovedChip>
);

const DeclinedChipCell = ({ text }: { text: string }) => (
  <DeclinedChip
    variant="chip"
    color="text.primary"
    sx={{ pl: 2, pr: 2, pt: 1, pb: 1, whiteSpace: 'nowrap' }}
  >
    {capitalizeFirstLetter(textMappings[text as keyof typeof textMappings])}
  </DeclinedChip>
);

const statusChips = {
  [PaymentStatus.Submitted]: SubmittedChipCell,
  [PaymentStatus.Approved]: ApprovedChipCell,
  [PaymentStatus.Rejected]: DeclinedChipCell,
};

export const AdminStatusCell = ({ row }: { row: DirectPayment }) => {
  const getChip = (status: string) => {
    const Chip = statusChips[status as keyof typeof statusChips];
    return <Chip text={status} />;
  };

  return <TableCell align="center">{getChip(row.status)}</TableCell>;
};

const statusIcons = {
  [PaymentStatus.Submitted]: <TimelapseIcon sx={{ pl: 6 }} />,
  [PaymentStatus.Approved]: <DoneAllIcon sx={{ pl: 6 }} />,
  [PaymentStatus.Rejected]: <WarningIcon sx={{ pl: 6 }} />,
};

export const StatusCell = ({ row }: { row: DirectPayment }) => (
  <TableCell align="center">
    <Tooltip
      placement="top"
      title={
        <Typography variant="tooltip">
          {capitalizeFirstLetter(
            textMappings[row.status as keyof typeof textMappings] ?? row.status,
          )}
        </Typography>
      }
      data-testid="payment-status-cell"
    >
      {statusIcons[row.status as keyof typeof statusIcons]}
    </Tooltip>
  </TableCell>
);

export const DateCell = ({ row }: { row: DirectPayment }) => (
  <TableCell>
    <span data-testid="payment-date-cell">
      {moment(row.paymentDate).utc().format(MOMENT_DATE_FORMAT)}
    </span>
  </TableCell>
);

export const TypeCell = ({ row }: { row: DirectPayment }) => (
  <TableCell>
    <span>{`${capitalizeFirstLetter(row.source)} - ${
      textMappings[row.type as keyof typeof textMappings] ??
      capitalizeFirstLetter(row.type)
    }`}</span>
  </TableCell>
);

export const YearCell = ({ row }: { row: DirectPayment }) => (
  <TableCell>
    <span>{row.year}</span>
  </TableCell>
);

export const AmountCell = ({ row }: { row: DirectPayment }) => (
  <TableCell align="right">
    <span>{numberFormat(row.amount, 2)}</span>
  </TableCell>
);

export const ApproveCell = ({
  row,
  onApproveClick,
  onRejectClick,
}: {
  row: DirectPayment;
  onApproveClick: (row: DirectPayment) => void;
  onRejectClick: (row: DirectPayment) => void;
}) => (
  <TableCell>
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ flexWrap: 'nowrap' }}
    >
      <FormationsGhostButton
        rounded
        sx={{
          '&.MuiButton-root': {
            color:
              row.status === PaymentStatus.Submitted
                ? 'others.red'
                : 'gray.100',
          },
          '&.MuiButton-root:hover': {
            color: '#FFFFFF',
          },
        }}
        disabled={row.status !== PaymentStatus.Submitted}
        onClick={(event) => {
          event.stopPropagation();
          onRejectClick(row);
        }}
      >
        <CancelIcon />
      </FormationsGhostButton>
      <FormationsGhostButton
        rounded
        sx={{
          '&.MuiButton-root': {
            color:
              row.status === PaymentStatus.Submitted
                ? 'others.green'
                : 'gray.100',
          },
          '&.MuiButton-root:hover': {
            color: '#FFFFFF',
          },
        }}
        disabled={row.status !== PaymentStatus.Submitted}
        onClick={(event) => {
          event.stopPropagation();
          onApproveClick(row);
        }}
      >
        <CheckCircleIcon />
      </FormationsGhostButton>
    </Grid>
  </TableCell>
);

export const StatusUpdatedCell = ({ row }: { row: DirectPayment }) => (
  <TableCell>
    <span>
      {row.statusUpdatedAt
        ? moment(row.statusUpdatedAt).utc().format(MOMENT_DATE_FORMAT)
        : 'N/A'}
    </span>
  </TableCell>
);

export const StatusFilterCell = () => {
  const [query, setQuery] = useTaxesQuery();
  const { status } = query;

  const statusList = [
    {
      title: 'Approved',
      value: PaymentStatus.Approved,
    },
    {
      title: 'For Admin Review',
      value: PaymentStatus.Submitted,
    },
    {
      title: 'For My Attention',
      value: PaymentStatus.Rejected,
    },
  ];

  return (
    <FormationsListFilter
      name="status"
      query={query}
      setQuery={setQuery}
      list={statusList}
      selected={status ?? []}
    />
  );
};
