import { useMutation, useQuery } from 'react-query';
import {
  getFeatureList,
  getSpecificFeature,
  createFeature,
  updateFeature,
  deleteFeature,
  FeatureName,
  FormationFeature,
} from 'services/features';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUser } from './useUser';

export const useFeatureList = (accountId?: string) => {
  const { data, ...rest } = useQuery<FormationFeature[]>(
    ['featureList', accountId],
    () => getFeatureList({ accountId: accountId! }),
    { enabled: !!accountId },
  );
  return {
    featureList: data ?? [],
    ...rest,
  };
};

export const useCurrentUserFeatureList = () => {
  const { isAuthenticated } = useAuth0();
  const { currentUser, isLoading: isLoadingUser } = useCurrentUser({
    enabled: isAuthenticated,
    staleTime: Infinity,
  });
  const { featureList, isLoading: isLoadingFormationFeatures } = useFeatureList(
    currentUser?.accountId || '',
  );
  if (isLoadingUser || isLoadingFormationFeatures || !isAuthenticated) return [];
  return featureList;
};

export const useFeature = (featureName: FeatureName, accountId?: string) => {
  const { data, ...rest } = useQuery<FormationFeature | null>(
    ['feature', accountId, featureName],
    () => getSpecificFeature({ accountId: accountId!, featureName }),
    { enabled: !!accountId },
  );
  return {
    feature: data ?? null,
    ...rest,
  };
};

export const useCreateFeature = (queryProps?: any) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    (params: Partial<FormationFeature>) => createFeature(params),
    queryProps,
  );
  return {
    createFeature: mutate,
    createFeatureAsync: mutateAsync,
    ...rest,
  };
};

export const useUpdateFeature = (queryProps?: any) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({
      featureId,
      accountID,
      params,
    }: {
      featureId: string;
      accountID: string;
      params: Partial<FormationFeature>;
    }) => updateFeature(featureId, accountID, params),
    queryProps,
  );
  return {
    updateFeature: mutate,
    updateFeatureAsync: mutateAsync,
    ...rest,
  };
};

export const useDeleteFeature = (queryProps?: any) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    (featureId: string) => deleteFeature(featureId),
    queryProps,
  );
  return {
    deleteFeature: mutate,
    deleteFeatureAsync: mutateAsync,
    ...rest,
  };
};
