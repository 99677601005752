import { useCurrentUser } from 'hooks/api';
import { useEffect } from 'react';
import { getCurrentUser } from 'services/users';
import { getAccount, getCompanyById } from 'services';
import { CONSTANTS } from 'constants/common';

interface Heap {
  track: (event: string, properties?: Object) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Object) => void;
  addEventProperties: (properties: Object) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
}

class HeapError extends Error {}

const getHeap = function (): Heap {
  // @ts-ignore
  if (window.heap) {
    // @ts-ignore
    return window.heap;
  }
  throw new HeapError('heap is not found');
};

const runWithErrorHandler = function (callback: () => void): void {
  try {
    callback();
  } catch (e: unknown) {
    if (e instanceof HeapError) {
      console.log(e.message);
    } else {
      throw e;
    }
  }
};

export const useHeapIdentify = () => {
  const { currentUser, isSuccess } = useCurrentUser();
  useEffect(() => {
    if (currentUser && isSuccess) {
      runWithErrorHandler(() => {
        getHeap().identify(currentUser.id);
        getHeap().addUserProperties({ Email: currentUser.email });
      });
    }
  }, [currentUser, isSuccess]);
};

export const setIdentify = (email: string): void => {
  runWithErrorHandler(() => {
    getHeap().identify(email);
  });
};

export const setProperty = (properties: Object): void => {
  runWithErrorHandler(() => {
    getHeap().addUserProperties(properties);
  });
};

export const resetIdentity = (): void => {
  runWithErrorHandler(() => {
    getHeap().resetIdentity();
  });
};

export const sendHeapEvent = (name: string, eventProperties: Object): void => {
  runWithErrorHandler(() => {
    getHeap().track(name, eventProperties);
  });
};

const getCustomerData = (
  isCustomer: boolean,
  value: undefined | string | number | boolean,
  defaultValue = 'N/A',
): string | number | boolean => {
  if (!isCustomer) {
    return defaultValue;
  }
  return value ?? defaultValue;
};

export const identifyUserForHeap = async (): Promise<void> => {
  try {
    const currentUser = await getCurrentUser();
    if (currentUser?.accountId == null) {
      throw new Error('account id is not found');
    }
    if (currentUser.companyId == null) {
      throw new Error('company id is not found');
    }
    const account = await getAccount(currentUser.accountId);
    const company = await getCompanyById(currentUser.companyId);
    runWithErrorHandler(() => {
      getHeap().identify(currentUser.email);
    });
    runWithErrorHandler(() => {
      const isCustomer = currentUser.role === CONSTANTS.USER_ROLES.CUSTOMER;
      getHeap().addUserProperties({
        Status: getCustomerData(isCustomer, account.status.label),
        Role: currentUser.role,
        'Entity Type': getCustomerData(isCustomer, account.entityType),
        Industry: getCustomerData(isCustomer, company.industry),
        'Projected Gross Income': getCustomerData(isCustomer, account.projectedGrossIncome)
      });
    });
  } catch (e) {
    console.error(e);
  }
};
