import * as yup from 'yup';
import moment from 'moment';

export const hurdlrTransactionSchema = yup.object().shape({
  amount: yup.string().nullable().required('Please provide payment amount.'),
  category: yup.number().nullable().required('Please enter a category.'),
  vendor: yup.number().nullable().required('Please enter a vendor.'),
  description: yup.string().nullable().optional(),
  spentOn: yup
    .date()
    .transform((_value, originalValue) => {
      const parsedValue = moment(originalValue, 'M/D/YYYY', true);

      // if it's valid return the date object, otherwise return an `InvalidDate`
      return parsedValue.isValid() ? parsedValue.toDate() : new Date('');
    })
    .typeError('Please enter a date of payment in MM/DD/YYYY.'),
});
