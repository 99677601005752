import React, { Dispatch, SetStateAction, ChangeEvent, useState } from 'react';
import { debounce } from 'lodash';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Paper,
  Checkbox,
  ButtonBase,
} from '@mui/material';
import { FormationsTable } from 'components/common/FormationsTable';
import { FormationsTertiaryButton } from 'components/common/Buttons';
import { InsertDriveFileIcon } from 'components/common/Icon';
import {
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import clsx from 'clsx';
import Pagination from '@mui/material/Pagination';
import makeStyles from '@mui/styles/makeStyles';
import { DocumentYear, IDocumentMetadata } from 'services/documentTypes';
import { AuthService } from 'services';
import { downloadDocument } from 'services/document';
import { downloadFileBlob } from 'helpers/documents';
import { EDocumentStatus } from 'hooks/dataFormatters/useDocumentsTableData';
import { sendChurnZeroEvent } from 'helpers/churnZero';
import { CHURN_ZERO_EVENT_NAMES } from 'constants/common';
import { DocumentSearch } from './DocumentSearch';
import { MoreActionsCell } from '../../documentsV2/tableCell/MoreActionsCell';
import { NoteIconCell } from '../../documentsV2/tableCell/NoteIconCell';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'column',
    minHeight: 100,
  },
  paper: {
    width: '100%',
    overflow: 'auto',
  },
  table: {
    minWidth: 320,
  },
  pagination: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    flex: '0 1 auto',
    '@media (max-width: 767px)': {
      marginBottom: theme.spacing(10),
    },
  },
  fileCell: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  fileIcon: {
    marginRight: theme.spacing(1),
  },
  visibilityDisabled: {
    color: theme.palette.gray[60],
  },
  visibility: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  visibilityOff: {
    color: theme.palette.gray[60],
    cursor: 'pointer',
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  temp: {
    height: theme.spacing(8),
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.background,
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

// debounce function, for not re-fetch query while typing search
type SetSearch = (value: string) => void;
const debounceSearch = debounce<(setSearch: SetSearch, value: string) => void>(
  (setSearch, value: string) => {
    setSearch(value);
  },
  500,
);

export interface FormationsTableDocument {
  category?: string;
  id: string;
  title: string;
  forYear: DocumentYear;
  department?: string;
  subcategory?: string;
  visibleToCustomer?: boolean;
  isVisibilityEditable?: boolean;
  uploaded?: string;
  uploadedAt?: string;
  role?: string;
  status: EDocumentStatus;
  companyId: string;
  statusReason: string;
  hasNotes: boolean;
  adminUnread: boolean;
  customerUnread: boolean;
  metadata: IDocumentMetadata;
  accountId: string;
  uploader: {
    accountId: string;
  };
  customerReadCount: number;
}

export type DocumentKey =
  | 'id'
  | 'category'
  | 'title'
  | 'forYear'
  | 'department'
  | 'subcategory'
  | 'visibleToCustomer'
  | 'isVisibilityEditable'
  | 'uploaded'
  | 'role'
  | 'status'
  | 'hasNotes'
  | 'actions';

interface ToolbarProps {
  data: FormationsTableDocument[];
  selected: string[];
  onDelete: (ids: string[]) => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onRecategorize: (id: string) => void;
}
const EnhancedTableToolbar = ({
  data,
  selected,
  setLoading,
  onRecategorize,
  onDelete,
}: ToolbarProps) => {
  const classes = useToolbarStyles();
  const numSelected = selected.length;

  const onDownload = async () => {
    setLoading(true);
    await Promise.all(
      selected.map(async (selectedRowId) => {
        const fileRow = data.find((item) => item.id === selectedRowId);
        if (fileRow != null) {
          const blob = await downloadDocument(selectedRowId);
          downloadFileBlob(blob, fileRow.title);
          sendChurnZeroEvent(CHURN_ZERO_EVENT_NAMES.DOCUMENT_DOWNLOAD);
        }
      }),
    );
    setLoading(false);
  };

  const handleRecategorize = () => {
    const selectedRowId = selected[0];
    onRecategorize(selectedRowId);
  };

  const handleDelete = () => {
    if (selected.length > 0) {
      onDelete(selected);
    }
  };

  const RenderDelete = () => (
    <Tooltip title="Delete">
      <FormationsTertiaryButton
        className={classes.button}
        size="large"
        onClick={handleDelete}
      >
        Delete
      </FormationsTertiaryButton>
    </Tooltip>
  );
  const RenderDownload = () => (
    <Tooltip title="Download">
      <FormationsTertiaryButton
        className={classes.button}
        size="large"
        onClick={onDownload}
      >
        Download
      </FormationsTertiaryButton>
    </Tooltip>
  );

  if (!selected.length) {
    return <div className={classes.temp} />;
  }

  if (selected.length > 1) {
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <RenderDownload />
        <RenderDelete />
      </Toolbar>
    );
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <>
        <RenderDownload />
        <RenderDelete />
        {AuthService.isAdmin() ? (
          <Tooltip title="Recategorize">
            <FormationsTertiaryButton
              className={classes.button}
              size="large"
              onClick={handleRecategorize}
              data-testid="categorize-btn"
            >
              Recategorize
            </FormationsTertiaryButton>
          </Tooltip>
        ) : null}
      </>
    </Toolbar>
  );
};

interface TableHeadProps {
  headers: Array<{
    key: DocumentKey;
    title: string;
  }>;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  numSelected: number;
  rowCount: number;
}

const EnhancedTableHead = ({
  rowCount,
  headers,
  onSelectAllClick,
  numSelected,
}: TableHeadProps) => (
  <TableHead>
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={onSelectAllClick}
          inputProps={{ 'aria-label': 'select all desserts' }}
        />
      </TableCell>
      {headers.map((item) => (
        <TableCell key={item.key} component="th" scope="row">
          {item.title}
        </TableCell>
      ))}
      <TableCell />
    </TableRow>
  </TableHead>
);

interface Props {
  data: FormationsTableDocument[];
  headers: Array<{
    key: DocumentKey;
    title: string;
  }>;
  numberOfPages: number;
  selected: string[];
  page: number;
  search: string;
  onSearchChange: (value: string) => void;
  onPageChange: (page: number) => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setSelected: (selected: string[]) => void;
  onRecategorize: (id: string) => void;
  onDelete: (ids: string[]) => void;
  onChangeVisibility: (id: string, visibility: boolean) => void;
  onFileNameClick: (row: FormationsTableDocument) => Promise<void>;
}

export const DocumentsTable = ({
  data,
  headers,
  page,
  search,
  numberOfPages,
  setLoading,
  onSearchChange,
  selected,
  setSelected,
  onPageChange,
  onRecategorize,
  onDelete,
  onChangeVisibility,
  onFileNameClick,
}: Props) => {
  const classes = useStyles();
  // required to retain search text as we debounce on calling callback to fetch data
  const [searchText, setSearchText] = useState(search);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    onPageChange(value);
  };

  // when search change page should reset
  const resetPage = () => {
    if (page !== 0) {
      onPageChange(0);
    }
  };

  const handleSearchChange = (value: string) => {
    debounceSearch(onSearchChange, value);
    setSearchText(value);
    resetPage();
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id);
      setSelected(selected.length === 0 ? newSelected : []);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };
  
  const renderCell = (
    item: {
      key: DocumentKey;
      title: string;
    },
    row: FormationsTableDocument,
    i: number,
  ) => {
    let cellValue;
    switch (item.key) {
      case 'actions':
        cellValue = (
          <MoreActionsCell
            isAdmin
            document={row}
            onReviewFile={(doc) => onFileNameClick(doc)}
          />
        );
        break;
      case 'visibleToCustomer':
        cellValue = <Visibility className={classes.visibilityDisabled} />;
        if (row.isVisibilityEditable) {
          cellValue = row.visibleToCustomer ? (
            <Visibility
              className={classes.visibility}
              onClick={() => onChangeVisibility(row.id, false)}
            />
          ) : (
            <VisibilityOff
              className={classes.visibilityOff}
              onClick={() => onChangeVisibility(row.id, true)}
            />
          );
        }
        break;
      case 'hasNotes':
        cellValue = row.hasNotes ? <NoteIconCell document={row} isAdmin /> : '';
        break;
      default:
        cellValue = row[item.key] ?? '';
        break;
    }
    return (
      <>
        {i === 0 ? (
          // todo: fix this to use action/icon flag instead of index in later implementation
          <ButtonBase
            className={classes.fileCell}
            onClick={() => onFileNameClick(row)}
          >
            <InsertDriveFileIcon className={classes.fileIcon} />
            {cellValue}
          </ButtonBase>
        ) : (
          <span>{cellValue}</span>
        )}
      </>
    );
  };

  return (
    <div className={classes.tableContainer}>
      <EnhancedTableToolbar
        data={data}
        selected={selected}
        setLoading={setLoading}
        onRecategorize={onRecategorize}
        onDelete={onDelete}
      />
      <DocumentSearch
        searchText={searchText}
        handleSearchChange={handleSearchChange}
      />
      <TableContainer component={Paper} className={classes.paper}>
        <FormationsTable
          className={classes.table}
          stickyHeader
          aria-label="documents table"
          data-testid="table-documents"
        >
          <EnhancedTableHead
            rowCount={data.length}
            headers={headers}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
          />

          <TableBody>
            {data.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  key={row.id}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                      onClick={() => handleClick(row.id)}
                      sx={{ color: 'inherit' }}
                    />
                  </TableCell>
                  {headers.map((item, i) => (
                    <TableCell key={item.key} component="td" scope="row">
                      {renderCell(item, row, i)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </FormationsTable>
      </TableContainer>

      <Pagination
        className={classes.pagination}
        page={page}
        onChange={handlePageChange}
        count={numberOfPages}
      />
    </div>
  );
};
