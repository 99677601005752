import React, { ReactNode } from 'react';
import { Box, BoxProps, Grid, Typography } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import { useMediaBreakpoints } from 'hooks';

interface Props extends Omit<BoxProps, 'title'> {
  children?: ReactNode;
  title?: string | ReactNode;
  subtitle?: string;
  gridSx?: SxProps;
}

export const Layout = (props: Props) => {
  const { children, title, subtitle, gridSx, ...boxProps } = props;
  const { isMobile } = useMediaBreakpoints();

  return (
    <Box {...boxProps}>
      <Grid container flexDirection="column">
        <Grid container flexDirection="column" sx={{ py: 4, px: isMobile ? 4 : 9, ...gridSx }}>
          <Grid item xs={12} sx={{ pb: 2 }}>
            {typeof title === 'string' ? (
              <Typography 
              variant="body1B"
              color="text.primary"
              component="h1"
              >
                {title}
              </Typography>
            ) : (
              title
            )}
            {subtitle && (
              <Typography
                variant="body3S"
                color="text.secondary"
                component="div"
                sx={{ mt: 1 }}
              >
                {subtitle}
              </Typography>
            )}
          </Grid>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};
